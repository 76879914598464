import docCookies from '../docCookies';

function isLoggedIn() {
  const token = docCookies.getItem('userToken');
  return !!token;
}

const getColorScheme = () => {
  const style = getComputedStyle(document.body);

  const palette = {
    main: {
      primary: {
        _100: style.getPropertyValue('--main-primary'),
        _90: style.getPropertyValue('--main-primary-90'),
        _80: style.getPropertyValue('--main-primary-80'),
        _70: style.getPropertyValue('--main-primary-70'),
        _60: style.getPropertyValue('--main-primary-60'),
        _50: style.getPropertyValue('--main-primary-50'),
        _40: style.getPropertyValue('--main-primary-40'),
        _30: style.getPropertyValue('--main-primary-30'),
        _20: style.getPropertyValue('--main-primary-20'),
        _10: style.getPropertyValue('--main-primary-10'),
      },
      secondary: {
        _100: style.getPropertyValue('--main-secondary'),
        _90: style.getPropertyValue('--main-secondary-90'),
        _80: style.getPropertyValue('--main-secondary-80'),
        _70: style.getPropertyValue('--main-secondary-70'),
        _60: style.getPropertyValue('--main-secondary-60'),
        _50: style.getPropertyValue('--main-secondary-50'),
        _40: style.getPropertyValue('--main-secondary-40'),
        _30: style.getPropertyValue('--main-secondary-30'),
        _20: style.getPropertyValue('--main-secondary-20'),
        _10: style.getPropertyValue('--main-secondary-10'),
      },
      success: {
        _100: style.getPropertyValue('--main-success'),
        _90: style.getPropertyValue('--main-success-90'),
        _80: style.getPropertyValue('--main-success-80'),
        _70: style.getPropertyValue('--main-success-70'),
        _60: style.getPropertyValue('--main-success-60'),
        _50: style.getPropertyValue('--main-success-50'),
        _40: style.getPropertyValue('--main-success-40'),
        _30: style.getPropertyValue('--main-success-30'),
        _20: style.getPropertyValue('--main-success-20'),
        _10: style.getPropertyValue('--main-success-10'),
      },
      danger: {
        _100: style.getPropertyValue('--main-danger'),
        _90: style.getPropertyValue('--main-danger-90'),
        _80: style.getPropertyValue('--main-danger-80'),
        _70: style.getPropertyValue('--main-danger-70'),
        _60: style.getPropertyValue('--main-danger-60'),
        _50: style.getPropertyValue('--main-danger-50'),
        _40: style.getPropertyValue('--main-danger-40'),
        _30: style.getPropertyValue('--main-danger-30'),
        _20: style.getPropertyValue('--main-danger-20'),
        _10: style.getPropertyValue('--main-danger-10'),
      },
      warning: {
        _100: style.getPropertyValue('--main-warning'),
        _90: style.getPropertyValue('--main-warning-90'),
        _80: style.getPropertyValue('--main-warning-80'),
        _70: style.getPropertyValue('--main-warning-70'),
        _60: style.getPropertyValue('--main-warning-60'),
        _50: style.getPropertyValue('--main-warning-50'),
        _40: style.getPropertyValue('--main-warning-40'),
        _30: style.getPropertyValue('--main-warning-30'),
        _20: style.getPropertyValue('--main-warning-20'),
        _10: style.getPropertyValue('--main-warning-10'),
      },
      info: {
        _100: style.getPropertyValue('--main-info'),
        _90: style.getPropertyValue('--main-info-90'),
        _80: style.getPropertyValue('--main-info-80'),
        _70: style.getPropertyValue('--main-info-70'),
        _60: style.getPropertyValue('--main-info-60'),
        _50: style.getPropertyValue('--main-info-50'),
        _40: style.getPropertyValue('--main-info-40'),
        _30: style.getPropertyValue('--main-info-30'),
        _20: style.getPropertyValue('--main-info-20'),
        _10: style.getPropertyValue('--main-info-10'),
      },
    },
    grayscale: {
      _100: style.getPropertyValue('--grayscale-100'),
      _90: style.getPropertyValue('--grayscale-90'),
      _80: style.getPropertyValue('--grayscale-80'),
      _70: style.getPropertyValue('--grayscale-70'),
      _60: style.getPropertyValue('--grayscale-60'),
      _50: style.getPropertyValue('--grayscale-50'),
      _40: style.getPropertyValue('--grayscale-40'),
      _30: style.getPropertyValue('--grayscale-30'),
      _20: style.getPropertyValue('--grayscale-20'),
      _10: style.getPropertyValue('--grayscale-10'),
    },
    other: [
      {
        _100: style.getPropertyValue('--other-1-100'),
        _90: style.getPropertyValue('--other-1-90'),
        _80: style.getPropertyValue('--other-1-80'),
        _70: style.getPropertyValue('--other-1-70'),
        _60: style.getPropertyValue('--other-1-60'),
        _50: style.getPropertyValue('--other-1-50'),
        _40: style.getPropertyValue('--other-1-40'),
        _30: style.getPropertyValue('--other-1-30'),
        _20: style.getPropertyValue('--other-1-20'),
        _10: style.getPropertyValue('--other-1-10'),
      },
      {
        _100: style.getPropertyValue('--other-2-100'),
        _90: style.getPropertyValue('--other-2-90'),
        _80: style.getPropertyValue('--other-2-80'),
        _70: style.getPropertyValue('--other-2-70'),
        _60: style.getPropertyValue('--other-2-60'),
        _50: style.getPropertyValue('--other-2-50'),
        _40: style.getPropertyValue('--other-2-40'),
        _30: style.getPropertyValue('--other-2-30'),
        _20: style.getPropertyValue('--other-2-20'),
        _10: style.getPropertyValue('--other-2-10'),
      },
      {
        _100: style.getPropertyValue('--other-3-100'),
        _90: style.getPropertyValue('--other-3-90'),
        _80: style.getPropertyValue('--other-3-80'),
        _70: style.getPropertyValue('--other-3-70'),
        _60: style.getPropertyValue('--other-3-60'),
        _50: style.getPropertyValue('--other-3-50'),
        _40: style.getPropertyValue('--other-3-40'),
        _30: style.getPropertyValue('--other-3-30'),
        _20: style.getPropertyValue('--other-3-20'),
        _10: style.getPropertyValue('--other-3-10'),
      },
      {
        _100: style.getPropertyValue('--other-4-100'),
        _90: style.getPropertyValue('--other-4-90'),
        _80: style.getPropertyValue('--other-4-80'),
        _70: style.getPropertyValue('--other-4-70'),
        _60: style.getPropertyValue('--other-4-60'),
        _50: style.getPropertyValue('--other-4-50'),
        _40: style.getPropertyValue('--other-4-40'),
        _30: style.getPropertyValue('--other-4-30'),
        _20: style.getPropertyValue('--other-4-20'),
        _10: style.getPropertyValue('--other-4-10'),
      },
      {
        _100: style.getPropertyValue('--other-5-100'),
        _90: style.getPropertyValue('--other-5-90'),
        _80: style.getPropertyValue('--other-5-80'),
        _70: style.getPropertyValue('--other-5-70'),
        _60: style.getPropertyValue('--other-5-60'),
        _50: style.getPropertyValue('--other-5-50'),
        _40: style.getPropertyValue('--other-5-40'),
        _30: style.getPropertyValue('--other-5-30'),
        _20: style.getPropertyValue('--other-5-20'),
        _10: style.getPropertyValue('--other-5-10'),
      },
    ],
  };
  return palette;
};

async function getUserDetails() {
  const token = docCookies.getItem('userToken');
  let userDetails = [];
  await fetch(process.env.REACT_APP_API_PREFIX_V2 + 'dbrequest.php', {
    method: 'POST',
    body: JSON.stringify({
      token,
      node: '/user/info',
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return (userDetails = data[0]);
    });
  return userDetails;
}

function handleError(code) {
  switch (code) {
    case -2:
      localStorage.clear();
      docCookies.removeItem('userToken');
      break;

    default:
      break;
  }
}

const getLastXDays = (days) => {
  const today = new Date();
  const lastXDays = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - days
  );
  return lastXDays;
};

const snakeCase = (string) => {
  return string
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('_');
};

export {
  getUserDetails,
  handleError,
  isLoggedIn,
  getColorScheme,
  getLastXDays,
  snakeCase,
};
