import React from 'react';
import { Row, Col } from 'reactstrap';

const Oee = ({ data }) => {
  const { oee, availability, performance, quality } = data;

  const valueStyle = { fontWeight: 500 };
  const parentStyle = {
    display: 'flex',
    overflow: 'hidden',
    justifyContent: 'space-between',
  };
  return (
    <React.Fragment>
      <div className="mb-1 border-top font-weight-bold">
        Overall equipment efficiency - OEE
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <div style={{ minWidth: 250, maxWidth: '25%', width: '100%' }}>
          <div style={parentStyle}>
            <span style={{ fontWeight: 'bold' }}>OEE</span>
            <span style={valueStyle}>{Math.floor(Number(oee))}%</span>
          </div>
          <div style={parentStyle}>
            <span>Availability</span>
            <span style={valueStyle}>{Math.floor(Number(availability))}%</span>
          </div>
          <div style={parentStyle}>
            <span>Performance</span>
            <span style={valueStyle}>{Math.floor(Number(performance))}%</span>
          </div>
          <div style={parentStyle}>
            <span>Quality</span>
            <span style={valueStyle}>{Math.floor(Number(quality))}%</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Oee;
