import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

const TooltipWrapper = ({ children, ...args }) => (
  <Tippy trigger="mouseenter" delay={300} theme="light" {...args}>
    <span>{children}</span>
  </Tippy>
);

export default TooltipWrapper;
