import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { FiMenu, FiSettings, FiPower, FiBookOpen } from 'react-icons/fi';
import { /*IoIosBarcode,*/ IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import './rightSidebar.css';
import { Redirect } from 'react-router-dom';
import Cart from '../../modules/MAS/views/Orders/partials/Cart';
import docCookies from '../../lib/docCookies';
import { getColorScheme, getUserDetails } from '../../lib/helpers';
import Avatar from 'react-avatar';
import TooltipWrapper from '../../components/TooltipWrapper';
import { useStore } from '../../store';
import ProductionLineCollapse from './ProductionLineCollapse';
import { getMachinesState } from '../../pages/Settings/calls/machines';
import { toast } from 'react-toastify';
import { handleLogout, mainAuth } from '../../utils/firebase';

const Navbar = () => {
  const [isCartEmpty, setIsCartEmpty] = useState(true);
  const [rightSidebar, setRightSidebar] = useState(false);
  const [userDetails, setUserDetails] = useState(mainAuth.currentUser);
  //const [redirect, setRedirect] = useState(false)
  const [redirectToScanner] = useState(false);
  const [collapse, setCollapse] = useState(false);

  const { state, dispatch } = useStore();
  const colorScheme = getColorScheme();

  const toggle = () => setCollapse(!collapse);

  const toggleSidebar = () =>
    dispatch({ type: 'IS_SIDEBAR_OPEN', payload: !state.isSidebarOpen });

  const toggleRightSidebar = () => setRightSidebar(!rightSidebar);

  return (
    <React.Fragment>
      <nav
        className="navbar navbar-default border-bottom p-1 sticky-top"
        style={{ zIndex: 100 }}
      >
        <div style={{ display: 'flex', flex: 1, alignItems: 'baseline' }}>
          <button
            className="btn bg-white text-muted"
            id="sidebarCollapse"
            onClick={toggleSidebar}
          >
            <FiMenu style={{ fontSize: '1.5em' }} />
          </button>
          {/*<div className="blob red animate"></div>*/}

          {/* TODO: uncomment and refactor this when user have more then one project */}
          {/* <span
            onClick={toggle}
            style={{
              flex: 1,
              textAlign: 'left',
              fontWeight: 500,
              cursor: 'pointer',
            }}
          >
            {state.selectedProject.project_name}
            {collapse ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </span> */}
        </div>

        {/* <TooltipWrapper title="View cart">
              <Link
                    role="button"
                    onClick={togglerightSidebar}
                    className="btn text-muted">
                    {
                        isCartEmpty ?
                            <FiShoppingCart style={{ fontSize: "1.5em" }} /> :
                            <span style={badgeContainerStyle}><FiShoppingCart style={{ fontSize: "1.5em" }} /><sup data-show="true" style={badgeStyle}></sup></span>
                    }
                </Link>
            </TooltipWrapper>*/}
        <TooltipWrapper content="User manual">
          <a
            role="button"
            className="btn text-muted"
            target="_BLANK"
            href={`http://${process.env.REACT_APP_IP}/docs/wp-content/uploads/2021/02/user_manual_MAS_v1.pdf`}
            rel="noreferrer"
          >
            <FiBookOpen />
          </a>
        </TooltipWrapper>
        <TooltipWrapper content="Scan QR code">
          {/* <a role="button" className="btn text-muted qrscanner" href="https://89.212.90.207/qrcamera.html">
                    <IoIosBarcode style={{ fontSize: "1.5em" }} />
                </a>*/}
          {/*<Link
                    onClick={handleQrButtonClick}
                    to={redirectToScanner ? '/qrscanner' : ''}                            
                    role="button"
                    className="btn text-muted qrscanner">
                    <IoIosBarcode style={{ fontSize: "1.5em" }} />
                </Link>*/}
        </TooltipWrapper>
        <UncontrolledDropdown>
          <DropdownToggle
            className="text-center px-2 py-0"
            color="none"
            style={{ lineHeight: '.85em' }}
          >
            {
              userDetails && (
                <Avatar
                  name={userDetails.email}
                  size={32}
                  round="50px"
                  color={colorScheme.main.primary._100}
                />
              )
              /* <React.Fragment>
                             {userDetails.name}<br />
                             <small className="text-muted">{userDetails.user_role}</small>
                         </React.Fragment>*/
            }
          </DropdownToggle>
          <DropdownMenu right>
            <div
              className="text-muted px-3 text-center"
              style={{ fontSize: '.75em' }}
            >
              <strong>{userDetails && userDetails.email}</strong>
              {/* <br />{userDetails && userDetails.user_role} */}
            </div>
            <DropdownItem divider />
            {/*    <a target="_BLANK" href={`http://${process.env.REACT_APP_IP}/docs/wp-content/uploads/2021/01/user_manual_MAS.pdf`}>
                        <DropdownItem>
                            <FiBookOpen />
                            <span className="ml-2"> User manual</span>
                        </DropdownItem>
                </a>*/}
            <Link to={'/settings'}>
              <DropdownItem>
                <FiSettings />
                <span className="ml-2"> Settings</span>
              </DropdownItem>
            </Link>
            <Link onClick={handleLogout} to={'/login'}>
              <DropdownItem>
                <FiPower />
                <span className="ml-2"> Logout</span>
              </DropdownItem>
            </Link>
          </DropdownMenu>
        </UncontrolledDropdown>
        <Modal
          isOpen={rightSidebar}
          toggle={toggleRightSidebar}
          className="right shadow-lg"
          modalClassName="right"
        >
          <ModalHeader toggle={toggleRightSidebar}>Cart</ModalHeader>
          <ModalBody className="p-0">
            <Cart isCartEmpty={isCartEmpty} />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </nav>
      {/* <ProductionLineCollapse collapse={collapse} toggle={toggle} /> */}
    </React.Fragment>
  );
};

export default Navbar;
