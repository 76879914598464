import React, { useCallback, useContext, useState } from 'react';
import logo from '../../media/logotip_Gostol.png';
import classnames from 'classnames';
import {
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Spinner,
} from 'reactstrap';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import firebase from 'firebase/app';
import { AuthContext } from '../../utils/firebaseAuth';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.grayscale._10};
  height: 100vh;
  width: 100%;
`;
const Login = ({ history }) => {
  const emailRegex = new RegExp(
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  );

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleLogin = useCallback(
    async (event) => {
      setLoading(true);
      setError(false);
      event.preventDefault();
      const { email, password } = event.target.elements;
      const isValidEmail = emailRegex.test(email.value);
      const signUpEmail = isValidEmail
        ? email.value
        : `${email.value}@example.com`;

      try {
        await firebase
          .auth()
          .signInWithEmailAndPassword(signUpEmail, password.value);
        history.push('/');
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    },
    [history]
  );
  const { currentUser } = useContext(AuthContext);
  // function handleLogin(e) {
  //   e.preventDefault();
  //   const isValidEmail = emailRegex.test(email);
  //   const signUpEmail = isValidEmail ? email : `${email}@example.com`;
  //   mainAuth
  //     .signInWithEmailAndPassword(signUpEmail, password)
  //     .then(() => {
  //       history.push('/');
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setError(error);
  //     });
  // }
  if (currentUser) {
    return <Redirect to="/" />;
  }
  return (
    <Wrapper>
      <Card className="p-5">
        <img
          className="img-fluid mb-4"
          style={{ maxWidth: 300 }}
          src={logo}
          alt="Gostol Logo"
        />
        <CardBody className="bg-white px-0">
          <h4>Login</h4>
          <p className="text-muted small">Sign in to your account</p>
          <Form onSubmit={handleLogin}>
            <FormGroup>
              {/*<Label for="exampleEmail">Username</Label>*/}
              <Input
                invalid={error}
                autoFocus={true}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                type="text"
                name="email"
                placeholder="Username"
              />
            </FormGroup>
            <FormGroup>
              {/*<Label for="exampleEmail">Password</Label>   */}
              <Input
                invalid={error}
                type="password"
                name="password"
                autoComplete="off"
                placeholder="Password"
              />
            </FormGroup>
            <Button
              color="danger"
              size="lg"
              type="submit"
              block
              className={classnames('mt-4', error ? 'failed-login' : '')}
            >
              {loading ? <Spinner color="light" /> : 'Login'}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </Wrapper>
  );
};

export default Login;
