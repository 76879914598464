import React from 'react';
import { IoFlash } from 'react-icons/io5';
import Energy from './views/Energy';

export default {
  title: 'Energy',
  icon: <IoFlash />,
  iconText: 'Energy',
  url: '/energy',
  api: {},
  view: Energy,
};
