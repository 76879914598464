import {
  IoIosBuild,
  IoIosColorFill,
  IoIosFlask,
  IoMdColorFilter,
} from 'react-icons/io';
import { FiDroplet, FiEye, FiBookOpen, FiCheckSquare } from 'react-icons/fi';

export const generateCalendarData = (data, isLog = false) => {
  if (!data || !data.length) return [];

  const output = data.map((item) => {
    const {
      method,
      assembly,
      personelLevel,
      document,
      machine,
      frequency,
      parts,
    } = item;
    const start = isLog
      ? item.info && item.info[0].status.status.dueDateTime
      : item.calculatedTaskDue &&
        item.calculatedTaskDue[0].status.status.dueDateTime;
    const todayTS = isLog ? item.completedTime : +new Date();
    const startTS = start && +new Date(start);
    const isOverdue = start && todayTS > startTS;
    const overdue =
      isOverdue && Math.floor((todayTS - startTS) / 1000 / 60 / 60 / 24);

    return {
      title: item.task,
      id: item.id,
      userModified: false,
      machineId: '',
      // allDay: true,
      start: !isLog ? start : item.completedTime,
      lastCompletedAt: isLog
        ? item.info && item.info[0].status.status.dueDateTime
        : item.calculatedTaskDue &&
          item.calculatedTaskDue[0].status.status.lastCompletedAt,
      method,
      overdue,
      assembly,
      personelLevel,
      document,
      machine,
      frequency,
      parts,
    };
  });
  const sorted = output.sort((a, b) => +new Date(a.start) - +new Date(b.start));
  return sorted;
};
export const generateLogTimes = (data) => {
  if (!data) return;

  const dueDateTimeTS =
    data.info && +new Date(data.info[0].status.status.dueDateTime);
  const completedTimeTS = data.completedTime;
  const isOverdue = dueDateTimeTS && completedTimeTS > dueDateTimeTS;
  const overdue =
    isOverdue &&
    Math.floor((completedTimeTS - dueDateTimeTS) / 1000 / 60 / 60 / 24);
  return {
    completedTimeTS,
    dueDateTimeTS,
    isOverdue,
    overdue,
  };
};
export const generateEventModalDataFromCalendar = (data) => {
  if (!data) return null;
  const { extendedProps, title, start, id } = data;
  return {
    title,
    start,
    id,
    ...extendedProps,
  };
};

export const getIcon = (method) =>
  ({
    Visually: <FiEye />,
    Tools: <IoIosBuild />,
    Water: <FiDroplet />,
    Grease: <IoMdColorFilter />,
    Oil: <IoIosColorFill />,
    Detergent: <IoIosFlask />,
  }[method]);
