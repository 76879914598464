import React, { useContext, useState } from 'react';
import firebase from 'firebase';
import { FiSend } from 'react-icons/fi';
import { Spinner } from 'reactstrap';
import { StyledButton } from '../../../../../components/DateSelection/DateSelection';
import TableReact from '../../../../../pages/Settings/TableTemplate';
import FileUpload from '../../../../../components/FileUpload';
import NoContent from '../../../../../components/NoContent';
import { snakeCase } from '../../../../../lib/helpers';
import { useStore } from '../../../../../store';
import {
  Attachments,
  Composer,
  Container,
  PreviewAndUpload,
} from './OrderAttachments.styles';
import { AuthContext } from '../../../../../utils/firebaseAuth';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { useEffect } from 'react/cjs/react.development';
import AttachmentGrid from './AttachmentGrid';
const OrderAttachments = () => {
  const [loading, setLoading] = useState(false);
  const [newFiles, setNewFiles] = useState([]);
  const { state } = useStore();
  const { currentUser } = useContext(AuthContext);

  const projectRef = useFirestore()
    .collection('orders')
    .doc(state.selectedOrder.details.id);
  const { data } = useFirestoreDocData(projectRef);

  const handleSendMessage = () => {
    console.log('sending');
    if (!newFiles.length) {
      console.log('empty files');
      return;
    }
    sendAttachmentsToStorage(newFiles);
  };

  const sendDataToFirestore = async (attachments) => {
    const orderId = state.selectedOrder.details.id;
    const data = {
      ...state.selectedOrder,
      attachments: [
        ...attachments,
        ...(state.selectedOrder?.attachments || []),
      ],
    };
    console.log('sendDataToFirestore: ', { data });
    const fsdb = firebase.firestore();
    await fsdb
      .collection('orders')
      .doc(orderId.toString())
      .set(data, { merge: true });
  };
  const getOrder = () => state.selectedOrder.details.id;
  const sendAttachmentsToStorage = (files) => {
    const orderId = getOrder();
    const promises = files.map((file) => {
      let fileRef = firebase
        .storage()
        .ref()
        .child(`orders/${orderId}/${snakeCase(file.name)}`);
      return fileRef
        .put(file.File)
        .then(() => fileRef.getDownloadURL())
        .then((url) => {
          const { File, ...rest } = file;
          return { ...rest, fileURL: url };
        });
    });
    Promise.all(promises)
      .then((attachments) => {
        sendDataToFirestore(attachments);
        setNewFiles([]);
      })
      .catch((err) => {
        console.error({ err });
        alert(err.code);
      });
  };

  const putAttachmentsIntoState = (files) => {
    const attachments = files.map((item) => {
      const { type, name, size } = item;
      return {
        File: item,
        created_at: new Date(),
        created_by: currentUser.email,
        type,
        name,
        size,
      };
    });
    console.log({ attachments });
    setNewFiles(attachments);
  };
  const columns = [
    {
      id: 'type',
      Header: 'type',
      accessor: 'type',
    },
    {
      id: 'name',
      Header: 'name',
      accessor: 'name',
    },
    {
      id: 'size',
      Header: 'size',
      accessor: 'size',
    },
    {
      id: 'created_by',
      Header: 'created_by',
      accessor: 'created_by',
    },
    {
      id: 'created_at.seconds',
      Header: 'created_at.seconds',
      accessor: 'created_at.seconds',
    },
  ];
  return state.selectedOrder?.details?.status === 'open' ? (
    <NoContent title="Attachments disabled">
      Attachments unavailable in open orders
    </NoContent>
  ) : (
    <Container>
      <Attachments>
        {!data?.attachments ? (
          <NoContent title="No attachments" />
        ) : (
          // <TableReact columns={columns} data={data.attachments} />

          <AttachmentGrid data={data?.attachments} />
        )}
      </Attachments>
      <Composer>
        <PreviewAndUpload>
          <FileUpload
            accept="video/*, image/*, application/pdf"
            label="Attachments"
            multiple
            updateFilesCb={putAttachmentsIntoState}
          />
        </PreviewAndUpload>

        <StyledButton onClick={handleSendMessage} disabled={!newFiles}>
          {loading ? (
            <>
              <Spinner size="sm" /> Send
            </>
          ) : (
            <>
              <FiSend />
              Send
            </>
          )}
        </StyledButton>
      </Composer>
    </Container>
  );
};

export default OrderAttachments;
