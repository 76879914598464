import React from 'react';
import Overview from './views/Overview/index';
import { IoBuild } from 'react-icons/io5';

export default {
  title: 'Maintenance And Service',
  icon: <IoBuild />,
  iconText: 'Maintenance and Service',
  url: '/mas',
  api: {},
  view: Overview,
};
