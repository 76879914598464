import React from 'react';
import { Card, CardBody } from 'reactstrap';
import Tabs from '../../../../../components/Tabs';
import OrderAttachments from '../OrderAttachments';
import OrderChat from '../OrderChat';

/**
 *  In case of micro frontends this component will have sub components (exp. chat, attachments, history, ...), so adding them as property looks best
 */
const OrderActivities = ({ chat, attachments, history }) => {
  const tabs = [
    chat && 'Chat',
    attachments && 'Attachments',
    history && 'History', // doesn't exists, just example how to add another sub component
  ].filter(Boolean);

  return (
    <Card className="mb-3">
      <CardBody className="px-0">
        <Tabs tabs={tabs}>
          {chat && <OrderChat />}
          {attachments && <OrderAttachments />}
          {/* {history && <p>history component goes here ...</p>} */}
        </Tabs>
      </CardBody>
    </Card>
  );
};

export default OrderActivities;
