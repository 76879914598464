import 'firebase/firestore';
import firebase from 'firebase';
import { toast } from 'react-toastify';

async function rootFetch(machineModelNo) {
  const fsdb = firebase.firestore();
  const req = await fsdb
    .collection('machine_bom')
    // .where('ni_vidno', '==', false)
    .doc(`${machineModelNo}`)
    .get()
    .catch((res) => {
      console.log({ catch: res });
      //   setLoading(false);
      //   setError(res);
    });

  return req.data();
}

const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

const generateTempOrderNumber = () =>
  `TMP-${Math.floor(Math.random() * 10)}-${Math.floor(Math.random() * 100000)}`;

const generateTempCustomerOrderNumber = () =>
  `TMP-${Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, 2)
    .toUpperCase()}${Math.floor(Math.random() * 1000)}`;

const structureDetailsData = ({
  description = '',
  date = new Date(),
  type = 'service',
  released = 0,
  status = 'open',
  project_id,
  project_name,
}) => {
  return {
    order_no: generateTempOrderNumber(),
    customer_order_no: generateTempCustomerOrderNumber(),
    description,
    date,
    type,
    released,
    status,
    // project: project_id,
    // location: project_name,
  };
};

const structureItemsData = (data) => {
  if (!data) return [];
  const tmp = data.map(({ data }) => {
    const {
      dno,
      formula,
      ni_vidno,
      level,
      not_last_value,
      not_last_level,
      prodaja_podrejeni,
      prodaja_pozicije,
      ...rest
    } = data;
    return rest;
  });
  return convertArrayToObject(tmp, 'material_code');
};

const generateMetaObject = (data) => {
  const { customer, ...project } = data;
  const { id, ...rest } = customer;
  const ref = `/customers/${id}`;
  return {
    project,
    customer: {
      _id_: id,
      _ref_: ref,
      data: rest,
    },
  };
};
const saveAsOpenOrder = async (data) => {
  //TODO: error handling missing
  const fsdb = firebase.firestore();
  const OrdersCollection = fsdb.collection('orders');
  const id = data.details?.id || OrdersCollection.doc().id;
  const OrderRef = OrdersCollection.doc(id);
  const customerRef = fsdb.collection('project').doc('0');
  const getProject = await customerRef.get();
  // adding additional data
  const project = getProject.data();
  const metaObject = generateMetaObject(project);

  const orderNo = data.details.order_no || (await getOrderNumber(data));
  const serverTime = firebase.firestore.FieldValue.serverTimestamp();

  const status = data.details?.status || 'open';
  const details = {
    id,
    date: serverTime,
    status,
    order_no: orderNo,
    isGostol: false,
    ...data.details,
  };

  await OrderRef.set(
    { ...data, ...metaObject, details, modifiedAt: serverTime },
    { merge: true }
  );
  toast.success('Order saved');
  return { ...data, ...metaObject, details, modifiedAt: serverTime };
  // toast.error('error text');
};
export const testGetLastOrder = async () => {
  const fsdb = firebase.firestore();
  const lastOrderRef = fsdb
    .collection('orders')
    .orderBy('details.date', 'desc')
    .limit(3);

  return lastOrderRef.get().then((data) => {
    const first = data.docs[0].data();
    const second = data.docs[1].data();
    const third = data.docs[2].data();
    console.log({ first, second, third });
  });
};

const getOrderNumber = async (data) => {
  const fsdb = firebase.firestore();
  const customerRef = fsdb.collection('project').doc('0');
  const getProject = await customerRef.get();
  const project = getProject.data();
  let orderNo;
  const currentYear = new Date().getFullYear();
  const projectId = project.project_id;
  const defaultOrderNo = `ORD_${projectId}_1_${currentYear}`;

  return fsdb
    .collection('orders')
    .where('details.isGostol', '!=', true)
    .orderBy('details.isGostol')
    .orderBy('details.date', 'desc')
    .limit(1)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // return doc.data();
        // console.log('querySnapshot: ', { doc: doc.data() });
        const lastOrderNo = doc.data()?.details?.order_no || defaultOrderNo;
        // console.log('querySnapshot: ', { lastOrderNo });
        const [code, projectId, index, year] = lastOrderNo.split('_');
        // console.log('querySnapshot: ', { code, projectId, index, year });
        orderNo = `ORD_${projectId}_${
          Number(year) === currentYear ? Number(index) + 1 : 1
        }_${currentYear}`;
      });
      return orderNo || defaultOrderNo;
    });
};
const releaseOrder = async (data) => {
  //TODO: error handling missing
  const fsdb = firebase.firestore();
  let orderNo = data.details.order_no || (await getOrderNumber(data));
  const OrdersCollection = fsdb.collection('orders');
  const id = data.details.id || OrdersCollection.doc().id;
  const OrderRef = OrdersCollection.doc(id);
  const customerRef = fsdb.collection('project').doc('0');
  const getProject = await customerRef.get();
  // adding additional data
  const project = getProject.data();
  const metaObject = generateMetaObject(project);
  const serverTime = firebase.firestore.FieldValue.serverTimestamp();
  const released = (Number(data.details.released) || 0) + 1;

  const details = {
    id,
    date: serverTime,
    order_no: orderNo,
    ...data.details,
    status: 'released',
    isGostol: false,
    released,
  };

  await OrderRef.set(
    { ...data, ...metaObject, details, modifiedAt: serverTime },
    { merge: true }
  );

  toast.success('Order released successfully');
  // toast.error('error text');
};

async function lazyFetch(ref) {
  const fsdb = firebase.firestore();
  let children = [];
  await fsdb
    .collection(ref + '/bom')
    .get()
    .then((querySnapshot) =>
      querySnapshot.forEach((child) => children.push({ data: child.data() }))
    )
    .catch((res) => console.log({ res }));
  return children;
}

function process(obj, index, path) {
  const {
    Item, //podatki iz NAV šifranta kosov
    BomNode, //podatki iz NAV drevesa
    Translations, //podatki iz NAV šifranta prevodov
    data, //posebni podatki za aplikacijo (kljukice...)
    _ref_, //lokacija dokumenta v Firestore
  } = obj;
  // Spodnji data objekt je konstrukt,
  // pripravljen za delovanje PrimeReact komponente;
  // ne mešaj ga z 'data' gnezdenim objektom v Firestore kosovnici.
  const _data = {
    Description: BomNode.Description,
    Translations: {
      SVN: {
        Description: BomNode.Description,
        Language_Code: 'SVN',
      },
      ...Translations,
    },
    No: BomNode.No,
    Indentation: BomNode.Indentation,
    QtyperParent: BomNode.QtyperParent,
    Price: data.price || 0,
    IsLeaf: BomNode.IsLeaf,
    prodaja_pozicije: data.prodaja_pozicije,
    prodaja_podrejeni: data.prodaja_podrejeni,
    ni_vidno: data.ni_vidno,
    dno: data.dno,
    quick_select: data.quick_select || false,
    quick_select_quantity: data.quick_select_quantity || null,
    quick_select_price: data.quick_select_price || null,
  };
  //'key' - edinstven označevalec vrstice
  const _key = path ? path + '-' + index.toString() : index.toString();
  //'leaf' - ali ima kos potomce?
  const _leaf = BomNode.IsLeaf;
  return {
    key: _key,
    leaf: _leaf,
    data: _data,
    ref: _ref_,
  };
}
//Funkcija za vstavitev otrok na pravo mesto v drevesu:
function insert(root, key, input, mode) {
  let regex = new RegExp('^' + root.key);
  if (key.match(regex)) {
    if (root.key == key) {
      //Ničen modus pomeni vstavitev otrok,
      //opredeljen pa vstavitev podatka:
      if (mode) {
        root.data[mode] = input;
      } else {
        root.children = input;
      }
      return root;
    } else {
      root.children = root.children
        ? root.children.map((child) => {
            return insert(child, key, input, mode);
          })
        : [];
      return root;
    }
  } else {
    return root;
  }
}

export {
  rootFetch,
  process,
  insert,
  lazyFetch,
  convertArrayToObject,
  structureItemsData,
  structureDetailsData,
  releaseOrder,
  saveAsOpenOrder,
};
