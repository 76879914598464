import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Badge, Card, CardBody, Input } from 'reactstrap';
import DateSelection, {
  StyledButton,
} from '../../../../components/DateSelection/DateSelection';
import { useStore } from '../../../../store';
import { DetailsHeader, Value } from './OrderDetails.styles';
// import { releaseOrder } from './OrderDetails.helpers';
import { saveAsOpenOrder, releaseOrder } from './ShopItems.helpers';
import { toast } from 'react-toastify';
import {
  confirmOrder,
  deleteOrder,
  discardOrder,
  displayCurrency,
  getOrderQuote,
  returnOrder,
} from './OrderDetails.helpers';
import { StyledBadge } from './OrdersTable/OrdersTable.styles';

const OrderDetails = () => {
  const { state, dispatch } = useStore();
  const { details, ...rest } = state.selectedOrder;
  const [custOrderNumber, setCustOrderNumber] = useState('');
  const [quote, setQuote] = useState('-');
  const [description, setDescription] = useState(
    state.selectedOrder?.details?.description || ''
  );
  const [expDeliveryDate, setExpDeliveryDate] = useState([expDateFromOrder()]);
  const [type, setType] = useState(
    state.selectedOrder?.details?.type || 'normal'
  );
  function expDateFromOrder() {
    //1. če naročilo obstaja v bazi pretvori sekunde
    // 2. če naročilo še ni oddano podaj obstoječi datum
    // 3. čene dodaj današnji datum
    if (!details?.exp_delivery_date?.seconds) return new Date();
    if (details.exp_delivery_date.seconds)
      return new Date(DateTime.fromSeconds(details.exp_delivery_date.seconds));
    return details.exp_delivery_date;
  }
  useEffect(() => {
    modifyDetails();
  }, []);

  useEffect(() => {
    modifyDetails();
  }, [expDeliveryDate, custOrderNumber, description, type]);

  useEffect(() => {
    if (!state.selectedOrder?.items || !state.selectedOrder?.items.length) {
      setQuote('-');
      return;
    }
    const orderQuote = getOrderQuote(
      state.selectedOrder.items,
      'order details'
    );
    setQuote(orderQuote);
  }, [state.selectedOrder]);

  const modifyDetails = () => {
    const modifiedDetails = {
      ...details,
      exp_delivery_date: expDeliveryDate[0],
      customer_order_no: custOrderNumber,
      description,
      type,
    };
    dispatch({
      type: 'SELECTED_ORDER',
      payload: {
        ...rest,
        details: modifiedDetails,
      },
    });
  };
  const handleRelease = () => {
    const { selectedOrder } = state;
    if (!selectedOrder.items.length) {
      toast.error('Cannot release empty order');
      return;
    }
    releaseOrder(selectedOrder);
    dispatch({ type: 'SELECTED_ORDER', payload: null });
  };

  const handleSave = () => {
    const { selectedOrder } = state;
    if (!selectedOrder.items.length) {
      toast.error('Cannot save empty order');
      return;
    }
    saveAsOpenOrder(state.selectedOrder);
    dispatch({ type: 'SELECTED_ORDER', payload: null });
  };

  const handleDelete = () => {
    // 1. change status of order to 'delete'
    // 2. inform user with toast, if order is deleted or not
    deleteOrder(state.selectedOrder);
    toast.success('Order is now deleted');
  };

  const handleReturn = () => {
    returnOrder(state.selectedOrder);
    dispatch({ type: 'SELECTED_ORDER', payload: null });
  };

  const handleConfirm = () => {
    confirmOrder(state.selectedOrder);
    dispatch({ type: 'SELECTED_ORDER', payload: null });
  };

  const handleDiscard = () => {
    // NOTE: how to check if order is still in local State or from firebase? Stored orders have ID of a document
    // 1. check if selected order have ID
    // 1.1. if ID exists delete order from firebase
    // 1.2 if there is no ID then set selectedOrder to null
    // 2. inform user with toast, if order is deleted or not
    if (state.selectedOrder?.details?.id) {
      discardOrder(state.selectedOrder);
    }
    dispatch({ type: 'SELECTED_ORDER', payload: null });
  };
  return (
    <Card className="mb-3">
      <CardBody className="p-0">
        <DetailsHeader>
          <div className="title">
            <div>
              <span className="muted">Order Nr.:</span> {details?.order_no}{' '}
              {/* <Badge pill>{details?.status?.toUpperCase() || 'OPEN'}</Badge> */}
            </div>
            <div>
              <StyledBadge status={details?.status}>
                {details?.status?.toUpperCase() || 'OPEN'}
              </StyledBadge>{' '}
              <StyledBadge status={details?.type}>
                {details?.type?.toUpperCase() || 'OPEN'}
              </StyledBadge>
            </div>
          </div>
          <div className="actions">
            {(details?.status === 'proposed' ||
              details?.status === 'returned') && (
              <StyledButton className="text-danger" onClick={handleDelete}>
                Delete
              </StyledButton>
            )}
            {details?.status === 'accepted' && (
              <>
                <StyledButton color="primary" onClick={handleReturn}>
                  Return
                </StyledButton>
                <StyledButton color="primary" onClick={handleConfirm}>
                  Confirm
                </StyledButton>
              </>
            )}
            {details?.status === 'open' && (
              <>
                <StyledButton className="text-danger" onClick={handleDiscard}>
                  Discard
                </StyledButton>
                <StyledButton onClick={handleSave}>Save</StyledButton>
              </>
            )}
            {(details?.status === 'open' ||
              details?.status === 'proposed' ||
              details?.status === 'returned') && (
              <StyledButton color="primary" onClick={handleRelease}>
                Release
              </StyledButton>
            )}
          </div>
        </DetailsHeader>
        <p style={{ padding: '1em' }}>
          {details.status === 'open' ? (
            <Input
              type="textarea"
              placeholder="Enter order description (Optional) ..."
              value={details?.description}
              onChange={(e) => setDescription(e.target.value)}
            />
          ) : (
            details.description
          )}
        </p>
        <Value muted>
          <div>Location</div>
          <div>{details?.location || '-'}</div>
        </Value>
        <Value>
          <div>Project</div>
          <div>{details?.project || '-'}</div>
        </Value>
        <Value muted>
          <div>Created</div>
          <div>
            {details.date?.seconds
              ? DateTime.fromSeconds(details.date.seconds).toFormat(
                  'dd.LL.yyyy'
                )
              : '-'}
            {details.createdBy && ` by ${details.createdBy}`}
          </div>
        </Value>
        <Value>
          <div>Expected delivery date</div>
          <div>
            {' '}
            {details.status === 'open' ? (
              <DateSelection
                hidePrevNext
                minDate={new Date()}
                setDates={setExpDeliveryDate}
                selectedDates={expDeliveryDate}
              />
            ) : details.exp_delivery_date?.seconds ? (
              DateTime.fromSeconds(details.exp_delivery_date.seconds).toFormat(
                'dd.LL.yyyy'
              )
            ) : (
              '-'
            )}
          </div>
        </Value>
        <Value muted>
          <div>Estimated delivery date</div>
          <div>
            {' '}
            {details.est_delivery_date
              ? DateTime.fromSeconds(
                  details.est_delivery_date.seconds
                ).toFormat('dd.LL.yyyy')
              : '-'}
          </div>
        </Value>
        <Value>
          <div>Type</div>
          <div>
            {' '}
            {details.status === 'open' ? (
              <Input
                value={type}
                type="select"
                name="select"
                id="exampleSelect"
                onChange={(e) => setType(e.target.value)}
              >
                <option value="normal">Normal</option>
                <option value="service">Service</option>
              </Input>
            ) : (
              details?.type || '-'
            )}
          </div>
        </Value>
        <Value muted>
          <div>Release nr.</div>
          <div>{details?.status === 'open' ? 0 : details?.released || '-'}</div>
        </Value>
        <Value>
          <div>Customer order nr.</div>
          <div>
            {details?.status === 'open' ? (
              <Input
                value={details?.customer_order_no || custOrderNumber}
                onChange={(e) => setCustOrderNumber(e.target.value)}
              />
            ) : details?.customer_order_no ? (
              details?.customer_order_no
            ) : (
              '-'
            )}
          </div>
        </Value>
        <Value muted>
          <div>Order quote</div>
          <div className="big">
            {details.quote || quote}
            {displayCurrency(details.currency)}
          </div>
        </Value>
      </CardBody>
    </Card>
  );
};

export default OrderDetails;
