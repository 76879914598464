import React from 'react';
import { Col, Card, CardHeader, CardBody, Row } from 'reactstrap';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/chart/custom.js';
import { getColorScheme } from '../../../../../lib/helpers';

const ConsumptionModalData = ({ data, setModalData, sums }) => {
  const colorScheme = getColorScheme();
  const bolderStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingTop: 0,
    fontSize: '1.5em',
    fontWeight: 'bold',
    flexWrap: 'wrap',
  };
  const optionElectricity = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)',
    },
    legend: {
      orient: 'vertical',
      left: 10,
      data: ['Mixers', 'Entry', 'Main', 'Oven'],
    },
    series: [
      {
        name: 'Pieces',
        type: 'pie',
        data: [
          {
            value: Number(sums?.energy?.mixers_kwh).toFixed(2),
            name: 'Mixers',
            itemStyle: { color: colorScheme.other[4]._100 },
          },
          {
            value: Number(sums?.energy?.entry_kwh).toFixed(2),
            name: 'Entry',
            itemStyle: { color: colorScheme.other[4]._80 },
          },
          {
            value: Number(sums?.energy?.main_kwh).toFixed(2),
            name: 'Main',
            itemStyle: { color: colorScheme.other[4]._60 },
          },
          {
            value: Number(sums?.energy?.oven_kwh).toFixed(2),
            name: 'Oven',
            itemStyle: { color: colorScheme.other[4]._40 },
          },
        ],
      },
    ],
  };
  const optionSteam = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)',
    },
    legend: {
      orient: 'vertical',
      left: 10,
      data: ['Main', 'Oven'],
    },
    series: [
      {
        name: 'Steam',
        type: 'pie',
        data: [
          {
            value: Number(sums?.energy?.steam_main_m3).toFixed(2),
            name: 'Main',
            itemStyle: { color: colorScheme.other[1]._100 },
          },
          {
            value: Number(sums?.energy?.steam_oven_m3).toFixed(2),
            name: 'Oven',
            itemStyle: { color: colorScheme.other[1]._80 },
          },
        ],
      },
    ],
  };

  const header = 'Consumption';
  return (
    <Row>
      <Col lg={12}>
        {' '}
        <Card className="mb-3">
          <CardHeader>Electricity</CardHeader>
          <CardBody
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              paddingTop: 0,
              flexWrap: 'wrap',
              paddingTop: '.25rem',
              paddingBottom: '.25rem',
              fontWeight: 'bold',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: colorScheme.grayscale._40 }}>Total</div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div style={{ fontSize: '1.5em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Number(
                      Number(sums?.energy?.mixers_kwh) +
                        Number(sums?.energy?.entry_kwh) +
                        Number(sums?.energy?.main_kwh) +
                        Number(sums?.energy?.oven_kwh)
                    ).toFixed(2)
                  )}
                  kWh
                </div>
                <div style={{ paddingLeft: '1em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Number(
                      (Number(sums?.energy?.mixers_kwh) +
                        Number(sums?.energy?.entry_kwh) +
                        Number(sums?.energy?.main_kwh) +
                        Number(sums?.energy?.oven_kwh)) /
                        (Number(sums?.counters?.mass_g?.total) / 1000000)
                    ).toFixed(2)
                  )}
                  kWh/t
                </div>
              </div>
            </div>
          </CardBody>
          <CardBody
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              paddingTop: 0,
              flexWrap: 'wrap',
              paddingTop: '.25rem',
              paddingBottom: '.25rem',
              fontWeight: 'bold',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: colorScheme.grayscale._40 }}>
                Mixers Part
              </div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div style={{ fontSize: '1.5em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Number(sums?.energy?.mixers_kwh).toFixed(2)
                  )}
                  kWh
                </div>
                <div style={{ paddingLeft: '1em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Number(
                      Number(sums?.energy?.mixers_kwh) /
                        (Number(sums?.counters?.mass_g?.total) / 1000000)
                    ).toFixed(2)
                  )}
                  kWh/t
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: colorScheme.grayscale._40 }}>Entry Part</div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div style={{ fontSize: '1.5em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Number(sums?.energy?.entry_kwh).toFixed(2)
                  )}
                  kWh
                </div>
                <div style={{ paddingLeft: '1em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Number(
                      Number(sums?.energy?.entry_kwh) /
                        (Number(sums?.counters?.mass_g?.total) / 1000000)
                    ).toFixed(2)
                  )}
                  kWh/t
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: colorScheme.grayscale._40 }}>Main Part</div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div style={{ fontSize: '1.5em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Number(sums?.energy?.main_kwh).toFixed(2)
                  )}
                  kWh
                </div>
                <div style={{ paddingLeft: '1em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Number(
                      Number(sums?.energy?.main_kwh) /
                        (Number(sums?.counters?.mass_g?.total) / 1000000)
                    ).toFixed(2)
                  )}
                  kWh/t
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: colorScheme.grayscale._40 }}>Oven</div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div style={{ fontSize: '1.5em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Number(sums?.energy?.oven_kwh).toFixed(2)
                  )}
                  kWh
                </div>
                <div style={{ paddingLeft: '1em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Number(
                      Number(sums?.energy?.oven_kwh) /
                        (Number(sums?.counters?.mass_g?.total) / 1000000)
                    ).toFixed(2)
                  )}
                  kWh/t
                </div>
              </div>
            </div>
          </CardBody>
          <CardBody>
            <ReactEchartsCore echarts={echarts} option={optionElectricity} />
          </CardBody>
        </Card>
      </Col>

      <Col lg={3}>
        {' '}
        <Card className="mb-3">
          <CardHeader>Gas</CardHeader>
          <CardBody
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              paddingTop: 0,
              flexWrap: 'wrap',
              paddingTop: '.25rem',
              paddingBottom: '.25rem',
              fontWeight: 'bold',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: colorScheme.grayscale._40 }}>Total</div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div style={{ fontSize: '1.5em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Number(sums?.energy?.oven_gas_m3).toFixed(2)
                  )}
                  m<sup>3</sup>
                </div>
                <div style={{ paddingLeft: '1em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Number(
                      Number(sums?.energy?.oven_gas_m3) /
                        (Number(sums?.counters?.mass_g?.total) / 1000000)
                    ).toFixed(2)
                  )}
                  m<sup>3</sup>/t
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col lg={3}>
        {' '}
        <Card className="mb-3">
          <CardHeader>Compressed Air</CardHeader>
          <CardBody
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              paddingTop: 0,
              flexWrap: 'wrap',
              paddingTop: '.25rem',
              paddingBottom: '.25rem',
              fontWeight: 'bold',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: colorScheme.grayscale._40 }}>Total</div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div style={{ fontSize: '1.5em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Number(sums?.energy?.compr_air_m3).toFixed(2)
                  )}
                  m<sup>3</sup>
                </div>
                <div style={{ paddingLeft: '1em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Number(
                      Number(sums?.energy?.compr_air_m3) /
                        (Number(sums?.counters?.mass_g?.total) / 1000000)
                    ).toFixed(2)
                  )}
                  m<sup>3</sup>/t
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col lg={12}>
        {' '}
        <Card className="mb-3">
          <CardHeader>Steam</CardHeader>
          <CardBody
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              paddingTop: 0,
              flexWrap: 'wrap',
              paddingTop: '.25rem',
              paddingBottom: '.25rem',
              fontWeight: 'bold',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: colorScheme.grayscale._40 }}>Total</div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div style={{ fontSize: '1.5em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Number(
                      Number(sums?.energy?.steam_main_m3) +
                        Number(sums?.energy?.steam_oven_m3)
                    ).toFixed(2)
                  )}
                  kg
                </div>
                <div style={{ paddingLeft: '1em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Number(
                      (Number(sums?.energy?.steam_main_m3) +
                        Number(sums?.energy?.steam_oven_m3)) /
                        (Number(sums?.counters?.mass_g?.total) / 1000000)
                    ).toFixed(2)
                  )}
                  kg/t
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: colorScheme.grayscale._40 }}>Main Part</div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div style={{ fontSize: '1.5em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Number(sums?.energy?.steam_main_m3).toFixed(2)
                  )}
                  kg
                </div>
                <div style={{ paddingLeft: '1em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Number(
                      Number(sums?.energy?.steam_main_m3) /
                        (Number(sums?.counters?.mass_g?.total) / 1000000)
                    ).toFixed(2)
                  )}
                  kg/t
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: colorScheme.grayscale._40 }}>Oven</div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div style={{ fontSize: '1.5em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Number(sums?.energy?.steam_oven_m3).toFixed(2)
                  )}
                  kg
                </div>
                <div style={{ paddingLeft: '1em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Number(
                      Number(sums?.energy?.steam_oven_m3) /
                        (Number(sums?.counters?.mass_g?.total) / 1000000)
                    ).toFixed(2)
                  )}
                  kg/t
                </div>
              </div>
            </div>
          </CardBody>
          <CardBody>
            <ReactEchartsCore echarts={echarts} option={optionSteam} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ConsumptionModalData;
