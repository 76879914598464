import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ items, customEvent }) => {
  return (
    <Breadcrumb listClassName="bg-light">
      {items.map((item, index, arr) => {
        if (typeof item === 'string')
          return <BreadcrumbItem key={index}>{item}</BreadcrumbItem>;
        return index < arr.length - 1 ? (
          <BreadcrumbItem key={index}>
            <Link to={item.link} onClick={item.customEvent && customEvent}>
              {item.text}
            </Link>
          </BreadcrumbItem>
        ) : (
          <BreadcrumbItem key={index} active>
            {item.text}
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
