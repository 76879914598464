const generateReportTableData = (data) => {
  if (!data) return [];

  const tableData = data.map((item) => ({
    rec_batch_nr: item.lot.nr,
    rec_name: item.recipe.name,
    rec_batch_start: item.lot.start.ts,
    rec_batch_end: item.lot.end.ts,
    batch_duration: item.lot.duration.ms,
    pcs_produced: item.counters.divider.pieces.out,
    mass_produced_kg: Math.round(item.counters.divider.mass_g.out / 1000),
    oee: item.oee.divider.oee,
    availability: item.oee.divider.availability,
    performance: item.oee.divider.performance,
    quality: item.oee.divider.quality,
  }));
  return tableData;
};

const generateReportData = (data) => {
  const rec_batch_start = Math.min(
    ...data.map((item) => Number(item.lot.start.ts))
  );
  const rec_batch_end = Math.max(
    ...data.map((item) => Number(item.lot.end.ts))
  );

  const compressed_air_m3 = data
    ?.map((i) => Number(i.energy.compr_air_m3))
    .reduce((a, b) => a + b, 0);
  const energy_pcs_total = data
    ?.map((i) => Number(i.counters.divider.pieces.total))
    .reduce((a, b) => a + b, 0);
  const energy_weight_total_kg = data
    ?.map((i) => Number(i.counters.divider.mass_g.total / 1000))
    .reduce((a, b) => a + b, 0);
  const entry_part_kwh = data
    ?.map((i) => Number(i.energy.entry_kwh))
    .reduce((a, b) => a + b, 0);
  const lot_time_ms = data
    ?.map((i) => Number(i.lot.duration.ms))
    .reduce((a, b) => a + b, 0);
  const main_part_kwh = data
    ?.map((i) => Number(i.energy.main_kwh))
    .reduce((a, b) => a + b, 0);
  const main_part_steam_kg = data
    ?.map((i) => Number(i.energy.steam_main_m3))
    .reduce((a, b) => a + b, 0);
  const mass_out = data
    ?.map((i) => Number(i.counters.divider.mass_g.out / 1000))
    .reduce((a, b) => a + b, 0);
  const mass_rejected = data
    ?.map((i) => Number(i.counters.divider.mass_g.rejected / 1000))
    .reduce((a, b) => a + b, 0);
  const mass_total = data
    ?.map((i) => Number(i.counters.divider.mass_g.total / 1000))
    .reduce((a, b) => a + b, 0);
  const mass_total_hi = data
    ?.map((i) => Number(i.counters.divider.mass_g.high / 1000))
    .reduce((a, b) => a + b, 0);
  const mass_total_lo = data
    ?.map((i) => Number(i.counters.divider.mass_g.low / 1000))
    .reduce((a, b) => a + b, 0);
  const mass_total_ok = data
    ?.map((i) => Number(i.counters.divider.mass_g.ok / 1000))
    .reduce((a, b) => a + b, 0);
  const mixers_part_kwh = data
    ?.map((i) => Number(i.energy.mixers_kwh))
    .reduce((a, b) => a + b, 0);
  const oven_gas_m3 = data
    ?.map((i) => Number(i.energy.oven_gas_m3))
    .reduce((a, b) => a + b, 0);
  const oven_part_kwh = data
    ?.map((i) => Number(i.energy.oven_kwh))
    .reduce((a, b) => a + b, 0);
  const oven_steam_kg = data
    ?.map((i) => Number(i.energy.steam_oven_m3))
    .reduce((a, b) => a + b, 0);
  const pcs_out = data
    ?.map((i) => Number(i.counters.divider.pieces.out))
    .reduce((a, b) => a + b, 0);
  const pcs_rejected = data
    ?.map((i) => Number(i.counters.divider.pieces.rejected))
    .reduce((a, b) => a + b, 0);
  const pcs_total = data
    ?.map((i) => Number(i.counters.divider.pieces.total))
    .reduce((a, b) => a + b, 0);
  const pcs_total_hi = data
    ?.map((i) => Number(i.counters.divider.pieces.high))
    .reduce((a, b) => a + b, 0);
  const pcs_total_lo = data
    ?.map((i) => Number(i.counters.divider.pieces.low))
    .reduce((a, b) => a + b, 0);
  const pcs_total_ok = data
    ?.map((i) => Number(i.counters.divider.pieces.ok))
    .reduce((a, b) => a + b, 0);
  const pcs_last_counter = data
    ?.map((i) => Number(i.counters.charger.pieces.total))
    .reduce((a, b) => a + b, 0);
  const recuperation_kwh = data
    ?.map((i) => Number(i.energy.recuperation_kwh))
    .reduce((a, b) => a + b, 0);
  const run_time_ms = data
    ?.map((i) => Number(i.run_times.run.ms))
    .reduce((a, b) => a + b, 0);
  const stop_time_ms = data
    ?.map((i) => Number(i.run_times.stop.ms))
    .reduce((a, b) => a + b, 0);
  const stop_time_operator_ms = data
    ?.map((i) => Number(i.run_times.stop_planned.ms))
    .reduce((a, b) => a + b, 0);
  const stop_time_other_ms = data
    ?.map((i) => Number(i.run_times.stop_unplanned.ms))
    .reduce((a, b) => a + b, 0);

  const lotsDurations = data?.map(
    (i) => Number(i.lot.start.ts) - Number(i.lot.end.ts)
  );
  const oeeArray = data.map((item) => item.oee.divider);
  const availability = calculateOEE(oeeArray, lotsDurations, 'availability');
  const performance = calculateOEE(oeeArray, lotsDurations, 'performance');
  const quality = calculateOEE(oeeArray, lotsDurations, 'quality');
  const oee = calculateOEE(oeeArray, lotsDurations, 'oee');
  return {
    availability,
    compressed_air_m3,
    energy_pcs_total,
    energy_weight_total_kg,
    entry_part_kwh,
    lot_time_ms,
    main_part_kwh,
    main_part_steam_kg,
    mass_out,
    mass_rejected,
    mass_total,
    mass_total_hi,
    mass_total_lo,
    mass_total_ok,
    mixers_part_kwh,
    oee,
    oven_gas_m3,
    oven_part_kwh,
    oven_steam_kg,
    pcs_out,
    pcs_rejected,
    pcs_total,
    pcs_total_hi,
    pcs_total_lo,
    pcs_total_ok,
    pcs_last_counter,
    performance,
    quality,
    rec_batch_end,
    rec_batch_start,
    recuperation_kwh,
    run_time_ms,
    stop_time_ms,
    stop_time_operator_ms,
    stop_time_other_ms,
  };
};

const calculateOEE = (data, durations, key) => {
  const values = data.map((i) => i[key]);
  const durationSum = durations.reduce((a, b) => a + b, 0);
  const result = values
    .map((value, index) => (durations[index] / durationSum) * Number(value))
    .reduce((a, b) => a + b, 0);

  // const up = values.map((value, index) => durations[index]*Number(value)).reduce((a, b) => a + b, 0)
  // const down = durationSum * values.length
  //console.log({durationSum, durations, values, result})
  // return Math.floor(up / down) + "%"
  return Math.floor(result);
};

export { generateReportTableData, generateReportData };
