import React from 'react';
import { Card, CardBody, CardHeader, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';
import { generateDurationString } from '../../Diagnostics/helpers';
import TooltipWrapper from '../../../components/TooltipWrapper';
import { getColorScheme } from '../../../lib/helpers';
import {
  useDatabase,
  useDatabaseListData,
  useDatabaseObjectData,
} from 'reactfire';

const Terms = ({ data, right, bold }) => {
  return (
    <div className="d-flex">
      <div className="d-flex flex-column">
        {data.map(({ label, style, fullLabelText }, index) => {
          if (fullLabelText) {
            return (
              <TooltipWrapper key={index} content={fullLabelText}>
                <div
                  className={classnames(bold && 'font-weight-bold')}
                  style={style}
                >
                  {label}:{' '}
                </div>
              </TooltipWrapper>
            );
          }
          return (
            <div
              key={index}
              className={classnames(bold && 'font-weight-bold')}
              style={style}
            >
              {label}:{' '}
            </div>
          );
        })}
      </div>
      <div
        className={classnames(
          'd-flex flex-column px-2 font-weight-bolder',
          right && 'text-right'
        )}
      >
        {data.map(({ value, unit, style }, index) => (
          <div key={index} style={style}>
            {value} {unit}
          </div>
        ))}
      </div>
    </div>
  );
};

const CurrentRecipe = () => {
  const error = false;
  const db = useDatabase();

  const lotsRef = db.ref('current_lot');
  const lotsList = useDatabaseObjectData(lotsRef);
  const { data: currentRecipe } = lotsList;

  const machineStateRef = db.ref('machine_state/12086');
  const machineStateList = useDatabaseObjectData(machineStateRef);
  const { data: machineState } = machineStateList;

  if (!currentRecipe) return null;
  const colorScheme = getColorScheme();
  const colorValue = (value) => {
    const color =
      value < 60
        ? colorScheme.main.danger._100
        : value < 75
        ? colorScheme.main.warning._100
        : value >= 75
        ? colorScheme.main.success._100
        : '#F1F1F1';
    return color;
  };

  const statusBadgeStyle = {
    background:
      machineState?.machine_state === 'AUTO'
        ? colorScheme.main.success._10
        : machineState?.machine_state === 'STOP'
        ? colorScheme.main.danger._10
        : colorScheme.main.warning._10,
    color:
      machineState?.machine_state === 'AUTO'
        ? colorScheme.main.success._100
        : machineState?.machine_state === 'STOP'
        ? colorScheme.main.danger._100
        : colorScheme.main.warning._100,
    minWidth: 100,
    textAlign: 'center',
    padding: '.3em',
    marginLeft: '1em',
    borderRadius: 5,
    fontWeight: 500,
  };

  const oeeStyle = {
    color: colorValue(currentRecipe?.oee?.divider?.oee),
    fontSize: '2em',
  };

  return (
    <Card className="mb-3">
      <CardBody className="p-3">
        <div
          className="pb-3"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <strong style={{ fontSize: '1.2em' }}>
            Current Recipe Name: {currentRecipe?.recipe?.name || '-'}
          </strong>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            Status:{' '}
            <div style={statusBadgeStyle}>{machineState?.machine_state}</div>
          </div>
        </div>
        <Row style={{ fontSize: '1.2em' }} noGutters>
          <Col lg={4} style={{ background: colorScheme.grayscale._10 }}>
            <Row noGutters className="p-2">
              <Col lg={12} className="mb-2">
                {' '}
                <strong>Recipe Data</strong>
              </Col>
              <Col lg={6}>
                {/*<Term label="Rec.Nr" value={currentRecipe?.rec_nr} />
                                    <Term label="Weight" value={currentRecipe?.recipe.mass_g} unit="g" />
                                    <Term label="Capacity" value={currentRecipe?.recipe.capacity} unit="pcs/hr" />*/}
                <Terms
                  data={[
                    {
                      label: 'Rec. Nr',
                      fullLabelText: 'Recipe Number',
                      value: currentRecipe?.recipe?.nr || '-',
                    },
                    {
                      label: 'Lot ID',
                      value: currentRecipe?.lot?.nr || '-',
                    },
                    {
                      label: 'Weight',
                      value: currentRecipe?.recipe?.mass_g || '-',
                      unit: currentRecipe?.recipe?.mass_g && 'g',
                    },
                    {
                      label: 'Capacity',
                      value: currentRecipe?.recipe?.capacity || '-',
                      unit: currentRecipe?.recipe?.mass_g && 'pcs/hr',
                    },
                  ]}
                />
              </Col>
              <Col lg={6}>
                <Terms
                  right
                  data={[
                    {
                      label: 'Lot Start',
                      value: currentRecipe?.lot?.start?.ts
                        ? moment(Number(currentRecipe?.lot?.start?.ts)).format(
                            'HH:mm'
                          )
                        : '-',
                    },
                    {
                      label: 'Est. Proc. Time',
                      fullLabelText: 'Estimated Processing Time',
                      value: currentRecipe?.durations?.line_parts?.total?.ms
                        ? Math.round(
                            moment
                              .duration(
                                currentRecipe?.durations?.line_parts?.total?.ms,
                                'milliseconds'
                              )
                              .asMinutes() * 10
                          ) / 10
                        : '-',
                      unit:
                        currentRecipe?.durations?.line_parts?.total?.ms &&
                        'min',
                    },
                    {
                      label: 'Tot. Prod. Time',
                      fullLabelText: 'Total Production Time',
                      value: currentRecipe?.run_times?.production?.ms
                        ? generateDurationString(
                            currentRecipe.run_times?.production?.ms
                          )
                        : '-',
                      style: {
                        marginTop: '.25em',
                        borderTop: `solid 1px ${colorScheme.grayscale._20}`,
                      },
                    },
                    {
                      label: 'Operating Time',
                      fullLabelText: 'Operating Time',
                      value: currentRecipe?.run_times?.run?.ms
                        ? generateDurationString(
                            currentRecipe?.run_times?.run?.ms
                          )
                        : '-',
                    },
                    {
                      label: 'Stop Time',
                      value: currentRecipe?.run_times?.stop?.ms
                        ? generateDurationString(
                            currentRecipe?.run_times?.stop?.ms
                          )
                        : '-',
                    },
                  ]}
                />

                {/*<Term label="Lot ID" value={currentRecipe?.rec_batch_nr} />
                                    <Term label="Lot Start" value={currentRecipe?.rec_batch_start ? moment(currentRecipe?.rec_batch_start).format("HH:mm") : "-"} />
                                    <Term label="Est.Prod.Time" value={
                                        currentRecipe?.durations.line_parts.total.ms
 ?
                                            `${Math.round(moment.duration(currentRecipe?.durations.line_parts.total.ms
, 'seconds').asMinutes() * 10) / 10} min` :
                                    "-"} />*/}
              </Col>
            </Row>
          </Col>

          <Col lg={4} style={{ background: colorScheme.grayscale._20 }}>
            <Row noGutters className="p-2">
              <Col lg={12} className="mb-2">
                {' '}
                <strong>Statistic Data</strong>
              </Col>
              <Col lg={6}>
                {/*<Term label="All Pieces" value={Intl.NumberFormat(window.navigator.language).format(currentRecipe?.counters.divider.pieces.total
)} />
                                    <Term label="Good Pieces" value={Intl.NumberFormat(window.navigator.language).format(currentRecipe?.counters.divider.pieces.total
_ok)} />
                                <Term label="Ejected Pieces" value={Intl.NumberFormat(window.navigator.language).format(currentRecipe?.counters.divider.pieces.rejected
)} />*/}
                <Terms
                  right
                  data={[
                    {
                      label: 'All Pieces',
                      value: currentRecipe?.counters?.divider?.pieces?.total
                        ? Intl.NumberFormat(window.navigator.language).format(
                            currentRecipe?.counters?.divider?.pieces?.total
                          )
                        : '-',
                    },
                    {
                      label: 'In Production',
                      value: currentRecipe?.counters?.divider?.pieces?.out
                        ? Intl.NumberFormat(window.navigator.language).format(
                            currentRecipe?.counters?.divider?.pieces?.out
                          )
                        : '-',
                    },
                    {
                      label: 'Ejected Pieces',
                      value: currentRecipe?.counters?.divider?.pieces?.rejected
                        ? Intl.NumberFormat(window.navigator.language).format(
                            currentRecipe?.counters?.divider?.pieces?.rejected
                          )
                        : '-',
                    },
                  ]}
                />
              </Col>
              <Col lg={6}>
                {/* <Term label="All Mass" value={Intl.NumberFormat(window.navigator.language).format(Math.floor(currentRecipe?.counters.divider.mass_g.total
 / 1000))} unit="kg" />
                                    <Term label="Good Mass" value={Intl.NumberFormat(window.navigator.language).format(Math.floor(currentRecipe?.counters.divider.mass_g.total
_ok / 1000))} unit="kg" />
                                    <Term label="Ejected Mass" value={Intl.NumberFormat(window.navigator.language).format(Math.floor(currentRecipe?.counters?.divider.mass_g.rejected
 / 1000))} unit="kg" />*/}
                <Terms
                  right
                  data={[
                    {
                      label: 'All Mass',
                      value: currentRecipe?.counters?.divider?.mass_g?.total
                        ? Intl.NumberFormat(window.navigator.language).format(
                            Math.floor(
                              currentRecipe?.counters?.divider?.mass_g?.total /
                                1000
                            )
                          )
                        : '-',
                      unit:
                        currentRecipe?.counters?.divider?.mass_g?.total && 'kg',
                    },
                    {
                      label: 'In Production',
                      value: currentRecipe?.counters?.divider?.mass_g?.out
                        ? Intl.NumberFormat(window.navigator.language).format(
                            Math.floor(
                              currentRecipe?.counters?.divider?.mass_g?.out /
                                1000
                            )
                          )
                        : '-',
                      unit:
                        currentRecipe?.counters?.divider?.mass_g?.out && 'kg',
                    },
                    {
                      label: 'Ejected Mass',
                      value: currentRecipe?.counters?.divider?.mass_g?.rejected
                        ? Intl.NumberFormat(window.navigator.language).format(
                            Math.floor(
                              currentRecipe?.counters?.divider?.mass_g
                                ?.rejected / 1000
                            )
                          )
                        : '-',
                      unit:
                        currentRecipe?.counters?.divider?.mass_g?.rejected &&
                        'kg',
                    },
                  ]}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={4} style={{ background: colorScheme.grayscale._10 }}>
            <Row noGutters className="p-2">
              <Col lg={12} className="mb-2">
                {' '}
                <strong>Current Overall Equipment Effectiveness</strong>
              </Col>
              <Col>
                <Terms
                  right
                  bold
                  data={[
                    {
                      label: 'Availability',
                      value: currentRecipe?.oee?.divider?.availability
                        ? Math.floor(currentRecipe?.oee?.divider?.availability)
                        : '-',
                      unit: currentRecipe?.oee?.divider?.availability && '%',
                      style: {
                        color: colorScheme.other[0]._100,
                      },
                    },
                    {
                      label: 'Performance',
                      value: currentRecipe?.oee?.divider?.performance
                        ? Math.floor(currentRecipe?.oee?.divider?.performance)
                        : '-',
                      unit: currentRecipe?.oee?.divider?.performance && '%',
                      style: {
                        color: colorScheme.other[1]._100,
                      },
                    },
                    {
                      label: 'Quality',
                      value: currentRecipe?.oee?.divider?.quality
                        ? Math.floor(currentRecipe?.oee?.divider?.quality)
                        : '-',
                      unit: currentRecipe?.oee?.divider?.quality && '%',
                      style: {
                        color: colorScheme.other[2]._100,
                      },
                    },
                  ]}
                />
                {/*<Term label="Availability" value={Math.floor(currentRecipe?.oee.divider.availability)} unit="%" />
                                    <Term label="Performance" value={Math.floor(currentRecipe?.oee.divider.performance
)} unit="%" />
                                    <Term label="Quality" value={Math.floor(currentRecipe?.oee.divider.quality)} unit="%" />*/}
              </Col>
              <Col className="text-center">
                <div>
                  <strong className="text-right" style={{ fontSize: '1.1em' }}>
                    OEE:
                  </strong>
                  <br />
                  <strong style={oeeStyle}>
                    {' '}
                    {currentRecipe?.oee?.divider?.oee
                      ? Math.floor(currentRecipe?.oee?.divider?.oee) + '%'
                      : '-'}{' '}
                  </strong>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default CurrentRecipe;
