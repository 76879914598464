import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AssemblyForm from './AssemblyForm';
import Maintenances from '../Maintenances';
import {
  getAssemblySequence,
  getAssemblies,
  insertAssembly,
  updateAssembly,
  deleteAssembly,
} from '../calls/assemblies';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import Skeleton from 'react-loading-skeleton';
import ActionButtons from '../ActionButtons';
import TableReact from '../TableTemplate';

const AssembliesTable = ({ data }) => {
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [formData, setFormData] = useState(null);
  const [deleteData, setDeleteData] = useState({});
  const [assemblies, setAssemblies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rowIndex, setRowIndex] = useState(0);
  const [rowSelected, setRowSelected] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const {
        productionLine: { project_id },
        machine: { model_nr, serial_nr },
      } = data.selected;
      const result = await getAssemblies({ project_id, model_nr, serial_nr });
      setAssemblies(result.assemblies);
      setRowSelected(result.assemblies && result.assemblies[0]);
      setLoading(result.loading);
    };
    fetchData();
  }, []);

  const toggleForm = () => setIsOpenForm(!isOpenForm);
  const toggleDelete = () => setIsOpenDelete(!isOpenDelete);

  const handleOpenDelete = () => {
    setDeleteData(rowSelected.assembly_id);
    toggleDelete();
  };

  const handleCreate = async (item) => {
    const resultSeq = await getAssemblySequence();
    if (resultSeq.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }

    const itemWithId = { ...item, assembly_id: Number(resultSeq) };
    const result = await insertAssembly(itemWithId);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }

    const isFirstCreated = assemblies.length === 0;
    isFirstCreated && setRowSelected(itemWithId);
    setAssemblies([...assemblies, itemWithId]);
    toggleForm();
    toast.success('Assembly created successfully.');
  };

  const handleEdit = async (item) => {
    const { assembly_id } = item;
    const result = await updateAssembly(item);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }
    const tmp = assemblies.filter(
      (assembly) => assembly.assembly_id !== assembly_id
    );
    setAssemblies([...tmp, item]);
    setRowSelected(item);
    toggleForm();
    toast.success('Assembly edited successfully.');
  };

  const handleDelete = async () => {
    const result = await deleteAssembly(rowSelected);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }
    setAssemblies(assemblies.filter((item) => item.assembly_id !== deleteData));
    toggleDelete();
    toast.success('Assembly deleted.');
  };

  const handleOpenForm = (action, row = rowSelected) => {
    const isNew = action === 'create';
    const { selected } = data;
    const nextOrdNo = assemblies.length
      ? Math.trunc(Math.max(...assemblies.map((assembly) => assembly.pos))) + 1
      : 1;
    const tmp = { ...row, action, selected, pos: isNew ? nextOrdNo : row.pos };
    setFormData(tmp);
    toggleForm();
  };

  const handleRowSelect = (info) => {
    setRowIndex(info.index);
    setRowSelected(info.original);
  };

  const dataForChild = () =>
    !disabled && {
      ...rowSelected,
      breadcrumb: [...data.breadcrumb, rowSelected.name],
      selected: { ...data.selected, assembly: rowSelected },
    };

  const columns = [
    /*{
        id: 'assembly_id',
        Header: 'ID',
        accessor: "assembly_id"
    }, */ {
      id: 'pos',
      Header: 'Ord.No.',
      accessor: 'pos',
      width: 100,
      style: { textAlign: 'right', width: 100 },
    },
    {
      id: 'name',
      Header: 'Name',
      accessor: 'name',
    },
  ];

  const disabled = assemblies.length === 0;

  return loading ? (
    <Skeleton height={200} />
  ) : (
    <React.Fragment>
      <ActionButtons
        isDisabled={disabled}
        handleCreate={handleOpenForm}
        handleEdit={handleOpenForm}
        handleDuplicate
        handleDelete={handleOpenDelete}
      >
        <Maintenances data={dataForChild()} />
      </ActionButtons>
      <TableReact
        hover
        columns={columns}
        data={assemblies}
        rowIndex={rowIndex}
        handleCreate={handleOpenForm}
        handleRowSelect={handleRowSelect}
      />
      {formData && (
        <AssemblyForm
          data={formData}
          isOpen={isOpenForm}
          handleCreate={handleCreate}
          handleEdit={handleEdit}
          toggle={toggleForm}
        />
      )}
      <DeleteConfirmationModal
        text={'assembly'}
        handleDelete={handleDelete}
        isOpen={isOpenDelete}
        toggle={toggleDelete}
      />
    </React.Fragment>
  );
};

export default AssembliesTable;
