import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ItemsTreeTable from '../../../MAS/views/Overview/partials/Shop/ItemsTreeTable';
import { process, rootFetch } from './ShopItems.helpers';
import NoContent from '../../../../components/NoContent';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1em;

  .add-button {
    display: flex;
    justify-content: flex-end;
  }
`;

const ShopItems = ({ selectedMachine, styles }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!selectedMachine) return;
    const tmp = rootFetch(selectedMachine?.machine_id?.toString())
      .then((res) => {
        setData(res ? [process(res, 0, '')] : []);
      })
      .catch((err) => {
        setData([]);
      });
    setData(tmp);
  }, [selectedMachine]);
  return (
    <StyledContainer className="border-bottom" style={{ ...styles }}>
      {!data.length ? (
        <NoContent title="No records found">
          <p>Select different machine</p>
        </NoContent>
      ) : (
        <ItemsTreeTable data={data} />
      )}
    </StyledContainer>
  );
};

export default ShopItems;
