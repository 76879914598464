import React, { useState, useEffect } from 'react';
//import ReactTable from "react-table";
// import 'react-table/react-table.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CardBody, Card, CardHeader } from 'reactstrap';
import UserForm from './UserForm';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import ActionButtons from '../ActionButtons';
import Skeleton from 'react-loading-skeleton';
import TableReact from '../TableTemplate';
import {
  deleteProjectUser,
  getProjectUsers,
  getProjectUserSequence,
  insertProjectUser,
  updateProjectUser,
} from '../calls/users';
import { getColorScheme, getUserDetails } from '../../../lib/helpers';
import { FiEye, FiEyeOff } from 'react-icons/fi';
const Users = () => {
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [formData, setFormData] = useState({});
  const [rowSelected, setRowSelected] = useState(null);
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);
  const [loading] = useState(false);
  const [rowIndex, setRowIndex] = useState(0);
  const [showPassword, setShowPassword] = useState([]);
  const colorScheme = getColorScheme();
  // const { currentUser } = useContext(AuthContext);
  useEffect(() => {
    const fetchData = async () => {
      const result = await getProjectUsers();
      const userDetails = await getUserDetails();
      setRowSelected(result.users && result.users[0]);
      const usersToShow = filterUsersToShow(result.users, userDetails);
      setUsers(usersToShow);
      setCurrentUser(userDetails);
    };
    fetchData();
  }, []);

  const filterUsersToShow = (users, currentUser) => {
    if (currentUser.user_role === 'site-admin') return users;

    const getCurrentUserProjectIds = () => {
      const str = currentUser.project_ids;
      return str.slice(1, str.length - 1).split(',');
    };

    const currentUserProjectIds = getCurrentUserProjectIds();

    const filteredUsers = users
      .map((user) => {
        const str = user.project_ids;
        const ids = str.slice(1, str.length - 1).split(',');
        return (
          currentUserProjectIds.some((r) => ids.includes(r)) &&
          user.user_role !== 'site-admin' &&
          user
        );
      })
      .filter(Boolean);

    return filteredUsers;
  };

  const toggleForm = () => setIsOpenForm(!isOpenForm);
  const toggleDelete = () => setIsOpenDelete(!isOpenDelete);

  const handleOpenDelete = () => {
    setDeleteData(rowSelected.id);
    toggleDelete();
  };

  const handleOpenForm = (action, row = rowSelected) => {
    const data = { ...row, action, currentUser };
    setFormData(data);
    toggleForm();
  };

  const handleCreate = async (item) => {
    const resultSeq = await getProjectUserSequence();
    if (resultSeq.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }

    const itemWithId = { ...item, id: resultSeq };
    const result = await insertProjectUser(itemWithId);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }

    const isFirstCreated = users.length === 0;
    isFirstCreated && setRowSelected(itemWithId);
    setUsers([...users, itemWithId]);
    toggleForm();
    toast.success('User created successfully.');
  };

  const handleEdit = async (item) => {
    const { id } = item;
    const result = await updateProjectUser(item);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }
    const tmp = users.filter((user) => user.id !== id);
    setUsers([...tmp, item]);
    setRowSelected(item);
    toggleForm();
    toast.success('User edited successfully.');
  };

  const handleDelete = async () => {
    const result = await deleteProjectUser(rowSelected);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }
    setUsers(users.filter((item) => item.id !== deleteData));
    toggleDelete();
    toast.success('user deleted.');
  };

  const handleToggleShowPassword = (data) => {
    const exists = showPassword.find((i) => i === data.id);
    const updateArray = exists
      ? showPassword.filter((i) => i !== data.id)
      : [...showPassword, data.id];

    setShowPassword(updateArray);
  };

  const columns = [
    {
      id: 'id',
      Header: 'ID',
      accessor: 'id',
    },
    {
      id: 'usr_name',
      Header: 'Username',
      accessor: 'usr_name',
    },
    {
      id: 'usr_pwd',
      Header: 'Password',
      accessor: 'usr_pwd',
      Cell: ({ row }) => {
        const cellStatus = showPassword.find((i) => i === row.values.id);
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>{cellStatus ? row.values.usr_pwd : '••••••••••••'}</div>
            <div
              style={{ cursor: 'pointer', color: colorScheme.grayscale._70 }}
              onClick={() => handleToggleShowPassword(row.values)}
            >
              {cellStatus ? <FiEyeOff /> : <FiEye />}
            </div>
          </div>
        );
      },
    },
    {
      id: 'name',
      Header: 'Full name',
      accessor: 'name',
    },
    {
      id: 'company_name',
      Header: 'Company name',
      accessor: 'company_name',
    },
    {
      id: 'project_ids',
      Header: 'Projects',
      accessor: 'project_ids',
    },
    {
      id: 'user_role',
      Header: 'Role',
      accessor: 'user_role',
    },
  ];

  const handleRowSelect = (info) => {
    setRowIndex(info.index);
    setRowSelected(info.original);
  };

  const disabled = users.length === 0;
  return (
    <Card>
      <CardHeader>Users</CardHeader>
      <CardBody className="p-0">
        {loading ? (
          <Skeleton height={200} />
        ) : (
          <React.Fragment>
            <ActionButtons
              isDisabled={disabled}
              handleCreate={handleOpenForm}
              handleEdit={handleOpenForm}
              handleDuplicate
              handleDelete={handleOpenDelete}
            ></ActionButtons>
            <TableReact
              columns={columns}
              //data={tmpData}
              data={users}
              handleCreate={handleOpenForm}
              rowIndex={rowIndex}
              handleRowSelect={handleRowSelect}
            />
            {/* <ReactTable
                                filterable
                                columns={columns}
                                data={projects}
                                defaultPageSize={10}
                                style={{ border: "none" }}
                                getTrProps={(state, rowInfo) => highlightRow(rowInfo)}
                           className="-highlight -striped" />*/}
            {formData && (
              <UserForm
                data={formData}
                handleCreate={handleCreate}
                handleEdit={handleEdit}
                isOpen={isOpenForm}
                toggle={toggleForm}
              />
            )}
            <DeleteConfirmationModal
              text={'user'}
              handleDelete={handleDelete}
              isOpen={isOpenDelete}
              toggle={toggleDelete}
            />
          </React.Fragment>
        )}
      </CardBody>
    </Card>
  );
};

export default Users;
