import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CardBody, Card, CardHeader } from 'reactstrap';
import AlarmForm from './AlarmForm';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import ActionButtons from '../ActionButtons';
import Skeleton from 'react-loading-skeleton';
import TableReact from '../TableTemplate';
import {
  deleteStopReasons,
  getStopReasons,
  getStopReasonsSequence,
  insertStopReasons,
  updateStopReasons,
} from '../calls/alarms';

const Alarms = () => {
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [formData, setFormData] = useState({});
  const [rowSelected, setRowSelected] = useState(null);
  const [alarms, setAlarms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowIndex, setRowIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getStopReasons();
      setRowSelected(result.alarms && result.alarms[0]);
      setAlarms(result.alarms);
    };
    fetchData();
  }, []);

  const toggleForm = () => {
    setIsOpenForm(!isOpenForm);
  };
  const toggleDelete = () => setIsOpenDelete(!isOpenDelete);

  const handleOpenDelete = () => {
    setDeleteData(rowSelected.id);
    toggleDelete();
  };

  const handleOpenForm = (action, row = rowSelected) => {
    const data = { ...row, action };
    setFormData(data);
    toggleForm();
  };

  const handleCreate = async (item) => {
    const resultSeq = await getStopReasonsSequence();
    if (resultSeq.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }

    const itemWithId = { ...item, id: resultSeq };
    const result = await insertStopReasons(itemWithId);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }

    const isFirstCreated = alarms.length === 0;
    isFirstCreated && setRowSelected(itemWithId);
    setAlarms([...alarms, itemWithId]);
    toggleForm();
    toast.success('Stop reason created successfully.');
  };

  const handleEdit = async (item) => {
    const { id } = item;
    const result = await updateStopReasons(item);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }
    const tmp = alarms.filter((alarm) => alarm.id !== id);

    setAlarms([...tmp, item]);
    setRowSelected(item);
    toggleForm();
    toast.success('Stop reason edited successfully.');
  };

  const handleDelete = async () => {
    const result = await deleteStopReasons(rowSelected);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }
    setAlarms(alarms.filter((item) => item.id !== deleteData));
    toggleDelete();
    toast.success('Stop reason deleted.');
  };

  const columns = [
    {
      id: 'id',
      Header: 'id',
      accessor: 'id',
    },
    {
      id: 'color',
      Header: 'Color',
      accessor: 'color',
      width: 30,
      Cell: ({ row }) => {
        return (
          <div
            style={{
              background: row.values.color,
              height: '1.5em',
            }}
          ></div>
        );
      },
    },
    {
      id: 'description',
      Header: 'Description',
      accessor: 'description',
    },
    {
      id: 'project_ids',
      Header: 'Projects',
      accessor: 'project_ids',
    },
  ];

  const handleRowSelect = (info) => {
    setRowIndex(info.index);
    setRowSelected(info.original);
  };

  const disabled = alarms.length === 0;
  return (
    <Card>
      <CardHeader>Stop reasons</CardHeader>
      <CardBody className="p-0">
        {loading ? (
          <Skeleton height={200} />
        ) : (
          <React.Fragment>
            <ActionButtons
              isDisabled={disabled}
              handleCreate={handleOpenForm}
              handleEdit={handleOpenForm}
              handleDuplicate
              handleDelete={handleOpenDelete}
            ></ActionButtons>
            <TableReact
              hover
              columns={columns}
              data={alarms}
              handleCreate={handleOpenForm}
              rowIndex={rowIndex}
              handleRowSelect={handleRowSelect}
            />
            {formData && (
              <AlarmForm
                data={formData}
                handleCreate={handleCreate}
                handleEdit={handleEdit}
                isOpen={isOpenForm}
                toggle={toggleForm}
              />
            )}
            <DeleteConfirmationModal
              text={'user'}
              handleDelete={handleDelete}
              isOpen={isOpenDelete}
              toggle={toggleDelete}
            />
          </React.Fragment>
        )}
      </CardBody>
    </Card>
  );
};

export default Alarms;
