import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import Sidebar from './Sidebar';
import Content from './Content';

const Layout = () => {
  return (
    <React.Fragment>
      <Sidebar />
      <Content />
      <ToastContainer transition={Slide} />
    </React.Fragment>
  );
};

export default Layout;
