import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Button,
} from 'reactstrap';

const GroupForm = ({ toggle, isOpen, data, handleCreate, handleEdit }) => {
  const [group, setGroup] = useState({});

  useEffect(() => {
    setGroup(data);
  }, [data]);

  const handleSubmitForm = () => {
    data.action === 'create' && handleCreate(group);
    data.action === 'edit' && handleEdit(group);
  };

  const handleCancelForm = () => {
    setGroup({});
    toggle();
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle}>
        {data.action === 'create'
          ? 'Create'
          : data.action === 'edit'
          ? 'Edit'
          : ''}{' '}
        group
      </ModalHeader>
      <ModalBody>
        <Form autoComplete="off">
          {/*<FormGroup>
                        <Label for="groupId">ID</Label>
                        <Input
                            disabled
                            type="text"
                            id="groupId"
                            name="group[id]"
                            value={group.id} />
                    </FormGroup>*/}
          <FormGroup>
            <Label for="groupPos">Ord.No.</Label>
            <Input
              type="number"
              id="groupPos"
              name="group[pos]"
              value={group.pos}
              onChange={(e) =>
                setGroup({ ...group, pos: Number(e.target.value) })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="Name">Name</Label>
            <Input
              type="text"
              id="Name"
              name="group[group_name]"
              value={group.group_name}
              onChange={(e) =>
                setGroup({ ...group, group_name: e.target.value })
              }
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleCancelForm} color="light">
          Cancel
        </Button>
        <Button
          className="text-capitalize"
          onClick={handleSubmitForm}
          color="primary"
        >
          {data.action === 'edit' ? 'Confirm changes' : data.action}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default GroupForm;
