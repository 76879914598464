import React, { useEffect, useState } from 'react';
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import { StyledButton } from '../../../../components/DateSelection/DateSelection';

const StyledMachineList = styled.div`
  display: flex;
  gap: 1em;
  margin-bottom: 1em;
  padding: 0.5em;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grayscale._10};
    /* transition: background 0.2s ease-in; */
    cursor: pointer;
  }
  img {
    object-fit: cover;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    background-color: #ccc;
  }
  .details {
    font-size: 0.75em;
  }
`;

const MachineSelectionInput = ({
  data,
  allMachines,
  handleMachineSelect,
  buttonPosition = 'bottom',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filteredMachines, setFilteredMachines] = useState([]);

  useEffect(() => {
    setFilteredMachines(allMachines);
  }, [allMachines, data]);

  const toggle = () => setIsOpen(!isOpen);
  const handleSelect = (machine) => {
    handleMachineSelect(machine);
    toggle();
  };
  const handleFilter = (text) => {
    const textInLowerCase = text.toLowerCase();
    setFilteredMachines(
      allMachines.filter(
        (item) =>
          item.description.toLowerCase().indexOf(textInLowerCase) > -1 ||
          item.model_nr.toString().toLowerCase().indexOf(textInLowerCase) >
            -1 ||
          item.machine_id.toString().toLowerCase().indexOf(textInLowerCase) >
            -1 ||
          item.machine_name.toLowerCase().indexOf(textInLowerCase) > -1
      )
    );
  };

  return (
    <>
      {buttonPosition === 'top' && (
        <StyledButton onClick={toggle} type="button">
          Select machine
        </StyledButton>
      )}
      {data && (
        <StyledMachineList>
          <img src={data?.imageUrl} alt="machine" />
          <div className="details">
            <strong>{data?.description} </strong>
            <br />
            SN:{data?.machine_id} <br />
            ID:{data?.model_nr} <br />
            Name:{data?.machine_name}
          </div>
        </StyledMachineList>
      )}
      {buttonPosition === 'bottom' && (
        <StyledButton onClick={toggle} type="button">
          Select machine
        </StyledButton>
      )}

      <Modal toggle={toggle} isOpen={isOpen}>
        <ModalHeader toggle={toggle}>Select machine</ModalHeader>
        <ModalBody>
          <Input
            type="text"
            onChange={(e) => handleFilter(e.target.value)}
            placeholder="Search"
          />
          {filteredMachines
            ?.sort((a, b) => a.pos - b.pos)
            .map((item) => (
              <StyledMachineList
                onClick={() => handleSelect(item)}
                key={item.machine_id}
              >
                <img src={item.imageUrl} alt="machine" />
                <div className="details">
                  <strong>{item.description} </strong>
                  <br />
                  SN:{item.machine_id} <br />
                  ID:{item.model_nr} <br />
                  Name:{item.machine_name}
                </div>
              </StyledMachineList>
            ))}
        </ModalBody>
      </Modal>
    </>
  );
};

export default MachineSelectionInput;
