import React, { useState } from 'react';
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  Table,
  ModalHeader,
} from 'reactstrap';
import { FiArrowRight, FiChevronRight, FiChevronDown } from 'react-icons/fi';
import { IoIosWarning } from 'react-icons/io';
import { useTable, useExpanded, useSortBy } from 'react-table';
import { getColorScheme } from '../../../lib/helpers';

const ActiveAlarms = ({ data: { alarms } }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const alarmsArray = alarms.filter(
    (alarm) => alarm.alarm_type && alarm.alarm_type.toLowerCase() === 'alarm'
  );
  const warningsArray = alarms.filter(
    (alarm) => alarm.alarm_type && alarm.alarm_type.toLowerCase() === 'warning'
  );
  const infosArray = alarms.filter(
    (alarm) =>
      !alarm.alarm_type ||
      (alarm.alarm_type.toLowerCase() !== 'warning' &&
        alarm.alarm_type.toLowerCase() !== 'alarm')
  );
  const colorScheme = getColorScheme();
  const badgeStyle = (type) => {
    const color =
      {
        alarm: colorScheme.main.danger,
        warning: colorScheme.main.warning,
      }[type.toLowerCase()] || colorScheme.main.info;

    return {
      fontSize: '0.8em',
      color: color._100,
      background: color._10,
      padding: '0.2em 0.5em',
      fontWeight: 500,
      borderRadius: '3px',
    };
  };

  const columns = [
    {
      // Build our expander column
      id: 'expander', // Make sure it has an ID
      Header: '',
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <FiChevronDown /> : <FiChevronRight />}
        </span>
      ),
    },
    {
      id: 'type',
      Header: 'Type',
      accessor: 'alarm_type',
      style: { textAlign: 'center' },
      Cell: ({ cell }) => {
        const value = cell.value ? cell.value.toUpperCase() || 'INFO' : 'INFO';
        return <span style={badgeStyle(value)}>{value}</span>;
      },
    },
    {
      id: 'started',
      Header: 'Started at',
      accessor: 'last_known_time',
      Cell: ({ row }) => {
        const startedAt = new Date(row.values.started);
        const startString =
          ('0' + startedAt.getHours()).slice(-2) +
          ':' +
          ('0' + startedAt.getMinutes()).slice(-2) +
          ':' +
          ('0' + startedAt.getSeconds()).slice(-2);
        return startString;
      },
    },
    {
      id: 'description',
      Header: 'Description',
      accessor: 'alarm_text',
    },
    {
      id: 'machine_name',
      Header: 'On machine',
      accessor: 'machine_description',
    },
  ];

  return (
    <React.Fragment>
      <Card
        className="mb-3"
        style={{
          background: alarmsArray.length
            ? colorScheme.main.danger._100
            : colorScheme.main.warning._100,
        }}
        inverse
        onClick={toggle}
      >
        <CardBody className="p-3" style={{ fontSize: '1.2em' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <div>
              <span>
                <IoIosWarning />{' '}
              </span>
              <span className="ml-3">
                There are
                {!!alarmsArray.length && (
                  <strong> {alarmsArray.length} alarms </strong>
                )}
                {!!alarmsArray.length && !!warningsArray.length && ' and '}
                {!!warningsArray.length && (
                  <strong> {warningsArray.length} warnings </strong>
                )}
                {!!infosArray.length && (
                  <strong> {infosArray.length} infos </strong>
                )}
                currently active!
              </span>
            </div>
            <div>
              View more <FiArrowRight />
            </div>
          </div>
        </CardBody>
      </Card>
      <Modal
        toggle={toggle}
        fade={false}
        isOpen={isOpen}
        contentClassName="shadow-lg"
        size="xl"
      >
        <ModalHeader toggle={toggle}>Active alarms</ModalHeader>
        <ModalBody className="p-0">
          <TableReact data={alarms} columns={columns} />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const TableReact = ({ data, columns }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    visibleColumns,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [{ id: 'started', desc: true }],
      },
    },
    useSortBy,
    useExpanded
  );
  const headerCellsStyle = {
    border: '1px solid rgba(0,0,0,0.02)',
    textAlign: 'center',
  };
  const cellsStyle = {
    border: '1px solid rgba(0,0,0,0.02)',
    padding: '.5em',
  };

  const renderRowSubComponent = ({ row }) => {
    const {
      last_known_time,
      title_eng,
      value_age,
      machine_name,
      alarm_class,
      machine_description,
      measurement_name,
    } = row.original;
    return (
      <React.Fragment>
        <p>
          <strong>{title_eng}</strong>
        </p>
        <p>
          Started: <strong>{last_known_time}</strong> <br />
          Duration: <strong>{value_age}</strong> <br />
          Class: <strong>{alarm_class}</strong> <br />
          Location: <strong>{machine_description}</strong> - {machine_name}{' '}
          <br />
          Measurement: <strong>{measurement_name}</strong> <br />
        </p>
      </React.Fragment>
    );
  };

  return (
    <Table striped bordered {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} style={headerCellsStyle}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <React.Fragment key={row.getRowProps()['key']}>
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    style={{ ...cell.column.style, ...cellsStyle }}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
              {row.isExpanded ? (
                <tr>
                  <td colSpan={visibleColumns.length}>
                    {renderRowSubComponent({ row })}
                  </td>
                </tr>
              ) : null}
            </React.Fragment>
          );
        })}
      </tbody>
    </Table>
  );
};

export default ActiveAlarms;
export { TableReact };
