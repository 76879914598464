import React from 'react';
import styled from 'styled-components';
import icon from '../../media/icon-gostol.png';

const StyledLoadingPage = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  gap: 0.51em;
  flex-direction: column;
`;

const LoadingPage = () => {
  return (
    <StyledLoadingPage>
      {' '}
      <img alt="gostol icon" width={48} src={icon} />
      Loading...
    </StyledLoadingPage>
  );
};

export default LoadingPage;
