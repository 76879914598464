import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Button,
} from 'reactstrap';

const ProductionLineForm = ({
  toggle,
  isOpen,
  data,
  handleCreate,
  handleEdit,
}) => {
  const [productionLine, setProductionLine] = useState(data);

  useEffect(() => {
    setProductionLine(data);
  }, [data]);

  const handleSubmitForm = () => {
    data.action === 'create' && handleCreate(productionLine);
    data.action === 'edit' && handleEdit(productionLine);
  };

  const handleCancelForm = () => {
    setProductionLine({});
    toggle();
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle}>
        {data.action === 'create'
          ? 'Create'
          : data.action === 'edit'
          ? 'Edit'
          : ''}{' '}
        production line
      </ModalHeader>
      <ModalBody>
        <Form autoComplete="off">
          <FormGroup>
            <Label for="projectId">Project ID</Label>
            <Input
              type="text"
              id="projectId"
              name="productionLine[project_id]"
              value={productionLine.project_id}
              onChange={(e) =>
                setProductionLine({
                  ...productionLine,
                  project_id: e.target.value,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="Name">Name</Label>
            <Input
              type="text"
              id="Name"
              name="productionLine[project_name]"
              value={productionLine.project_name}
              onChange={(e) =>
                setProductionLine({
                  ...productionLine,
                  project_name: e.target.value,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="startup_date">Start up date</Label>
            <Input
              type="date"
              id="startup_date"
              name="productionLine[startup_date]"
              value={productionLine.startup_date}
              onChange={(e) =>
                setProductionLine({
                  ...productionLine,
                  startup_date: e.target.value,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="production_year">Production year</Label>
            <Input
              type="number"
              id="production_year"
              name="productionLine[production_year]"
              value={productionLine.production_year}
              onChange={(e) =>
                setProductionLine({
                  ...productionLine,
                  production_year: e.target.value,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="beginning_date_of_guarantee">Guarantee start</Label>
            <Input
              type="date"
              id="beginning_date_of_guarantee"
              name="productionLine[beginning_date_of_guarantee]"
              value={productionLine.beginning_date_of_guarantee}
              onChange={(e) =>
                setProductionLine({
                  ...productionLine,
                  beginning_date_of_guarantee: e.target.value,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="guarantee_valid_till">Guarantee end</Label>
            <Input
              type="date"
              id="guarantee_valid_till"
              name="productionLine[guarantee_valid_till]"
              value={productionLine.guarantee_valid_till}
              onChange={(e) =>
                setProductionLine({
                  ...productionLine,
                  guarantee_valid_till: e.target.value,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="GrafanaId">Grafana ID</Label>
            <Input
              type="number"
              id="GrafanaId"
              name="productionLine[grafana_id]"
              value={productionLine.grafana_id}
              onChange={(e) =>
                setProductionLine({
                  ...productionLine,
                  grafana_id: e.target.value,
                })
              }
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleCancelForm} color="light">
          Cancel
        </Button>
        <Button
          className="text-capitalize"
          onClick={handleSubmitForm}
          color="primary"
        >
          {data.action === 'edit' ? 'Confirm changes' : data.action}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ProductionLineForm;
