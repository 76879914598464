import React from 'react';
import { Route } from 'react-router-dom';
import { PrivateRoute } from '../../lib/helpers/PrivateRoute';
import settings from './settings';

export const MAS = () =>
  settings.children ? (
    settings.children.map((child) => (
      <PrivateRoute
        key={child.name}
        exact
        path={child.url}
        component={child.view}
      />
    ))
  ) : (
    <PrivateRoute
      key={settings.name}
      exact
      path={settings.url}
      component={settings.view}
    />
  );
export const MASSettings = settings;
