import React from 'react';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/legendScroll';
import 'echarts/lib/component/tooltip';
import { generateDurationString } from '../../../helpers';

const BarChartRoelandt = ({ data }) => {
  const setColor = (params, api) =>
    ({
      alarm: 'rgba(223, 57, 78,  1)',
      warning: 'rgba(255, 193, 7,  1)',
      info: 'rgba(23, 162, 184,  1)',
    }[params.data.type] || '#6E7074');

  const option = {
    title: {
      text: 'Durations',
      show: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (params) => {
        const duration = generateDurationString(params[0].value);
        const type = params[0].data.type.toUpperCase();

        return `
                        <strong> [${type}]</strong><br />
                        <strong> ${params[0].name}</strong>
                        <p>
                            Duration: ${duration}
                        </p>`;
      },
    },
    xAxis: {
      type: 'category',
      data: data.map((item) => item.name),
      axisTick: { show: false },
      axisLabel: { show: false },
    },
    yAxis: {
      name: 'Duration',
      nameLocation: 'middle',
      nameGap: 80,
      type: 'value',
      axisLabel: {
        formatter: (value) => generateDurationString(value),
      },
    },
    series: [
      {
        type: 'bar',
        itemStyle: {
          normal: {
            color: setColor,
          },
        },
        data: data, //.map(item => item.value)
      },
    ],
  };

  return (
    <ReactEchartsCore
      echarts={echarts}
      style={{ height: 350 }}
      option={option}
    />
  );
};

export default BarChartRoelandt;
