import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SegmentForm from './SegmentForm';
import {
  getSegments,
  getSegmentSequence,
  insertSegment,
  updateSegment,
  deleteSegment,
} from '../calls/segments';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import Skeleton from 'react-loading-skeleton';
import ActionButtons from '../ActionButtons';
import TableReact from '../TableTemplate';

const SegmentsTable = ({ data }) => {
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [formData, setFormData] = useState(null);
  const [deleteData, setDeleteData] = useState({});
  const [rowSelected, setRowSelected] = useState(null);
  const [segments, setSegments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rowIndex, setRowIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getSegments(data.selected.productionLine.project_id);
      setSegments(result.segments);
      setRowSelected(result.segments && result.segments[0]);
      setLoading(result.loading);
    };
    fetchData();
  }, []);

  const toggleForm = () => setIsOpenForm(!isOpenForm);
  const toggleDelete = () => setIsOpenDelete(!isOpenDelete);

  const handleCreate = async (item) => {
    const resultSeq = await getSegmentSequence();
    if (resultSeq.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }

    const itemWithId = {
      ...item,
      line_segment_id: resultSeq,
      project_id: data.selected.productionLine.project_id,
    };
    const result = await insertSegment(itemWithId);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }

    const isFirstCreated = segments.length === 0;
    isFirstCreated && setRowSelected(itemWithId);
    setSegments([...segments, itemWithId]);
    toggleForm();
    toast.success('Project created successfully.');
  };

  const handleEdit = async (item) => {
    const { line_segment_id } = item;
    const result = await updateSegment(item);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }
    const tmp = segments.filter(
      (segment) => segment.line_segment_id !== line_segment_id
    );
    setSegments([...tmp, item]);
    setRowSelected(item);
    toggleForm();
    toast.success('Project edited successfully.');
  };

  const handleDelete = async () => {
    const result = await deleteSegment(rowSelected);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }
    setSegments(segments.filter((item) => item.line_segment_id !== deleteData));
    toggleDelete();
    toast.success('Segment deleted.');
  };

  const handleOpenDelete = () => {
    setDeleteData(rowSelected.line_segment_id);
    toggleDelete();
  };

  const handleOpenForm = (action, row = rowSelected) => {
    const isNew = action === 'create';
    const { selected } = data;
    const nextOrdNo = segments.length
      ? Math.trunc(Math.max(...segments.map((segment) => segment.pos))) + 1
      : 1;
    const tmp = { ...row, action, selected, pos: isNew ? nextOrdNo : row.pos };
    setFormData(tmp);
    toggleForm();
  };

  const handleRowSelect = (info) => {
    setRowIndex(info.index);
    setRowSelected(info.original);
  };

  //const dataForChild = () => (!disabled && {...rowSelected, breadcrumb: [...data.breadcrumb, rowSelected.name], selected:{...data.selected, segment:rowSelected}})

  const columns = [
    /*{
        id: 'line_segment_id',
        Header: 'ID',
        accessor: "line_segment_id"
    }, */ {
      id: 'pos',
      Header: 'Ord.No.',
      width: 100,
      accessor: 'pos',
      style: { textAlign: 'right', width: 100 },
    },
    {
      id: 'name',
      Header: 'Name',
      accessor: 'name',
    },
  ];
  const disabled = segments.length === 0;
  return loading ? (
    <Skeleton height={200} />
  ) : (
    <React.Fragment>
      <ActionButtons
        isDisabled={disabled}
        handleCreate={handleOpenForm}
        handleEdit={handleOpenForm}
        handleDuplicate
        handleDelete={handleOpenDelete}
      />
      {/*<span className="ml-2">
                    {!disabled && <Machines data={dataForChild()} />}  
                </span>*/}
      <TableReact
        hover
        columns={columns}
        data={segments}
        rowIndex={rowIndex}
        handleCreate={handleOpenForm}
        handleRowSelect={handleRowSelect}
      />
      {formData && (
        <SegmentForm
          data={formData}
          isOpen={isOpenForm}
          handleCreate={handleCreate}
          handleEdit={handleEdit}
          toggle={toggleForm}
        />
      )}
      <DeleteConfirmationModal
        text={'segment'}
        handleDelete={handleDelete}
        isOpen={isOpenDelete}
        toggle={toggleDelete}
      />
    </React.Fragment>
  );
};

export default SegmentsTable;
