import React, { Component } from 'react';
import { Container } from 'reactstrap';
import jsQR from 'jsqr';
import { Redirect } from 'react-router-dom';

const video = document.createElement('video');
window.video = video;

class QrScanner extends Component {
  state = {
    redirect: false,
    qrData: '',
  };

  componentDidMount() {
    this.getCamera();
  }
  componentWillUnmount() {
    if (!video.srcObject.active) return;
    video.srcObject.getTracks()[0].stop();
  }

  getCamera = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: 'environment' } })
      .then((stream) => {
        video.srcObject = stream;
        video.setAttribute('playsinline', true); // required to tell iOS safari we don't want fullscreen
        video.play();
        requestAnimationFrame(this.tick);
      })
      .catch((err) => {
        this.setState({
          redirect: '/',
        });
      });
  };

  tick = () => {
    const canvasElement = document.getElementById('canvas');
    if (!canvasElement) {
      return;
    } // prevent app from crushing when exiting qr scanner component without scanned image
    const canvas = canvasElement.getContext('2d');
    if (video.readyState === video.HAVE_ENOUGH_DATA) {
      canvasElement.height = video.videoHeight;
      canvasElement.width = video.videoWidth;
      canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
      var imageData = canvas.getImageData(
        0,
        0,
        canvasElement.width,
        canvasElement.height
      );
      var code = jsQR(imageData.data, imageData.width, imageData.height);
      if (code) {
        video.srcObject.getTracks()[0].stop();
        /*this.setState({
                    redirect: "actions/",
                    qrData: code.data
                })*/
        document.location.href = code.data;
        return;
      }
    }
    requestAnimationFrame(this.tick);
  };

  render() {
    const { redirect, qrData } = this.state;
    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: redirect,
            state: { qrData },
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <Container>
          <canvas id="canvas" height="480" width="640"></canvas>
        </Container>
      </React.Fragment>
    );
  }
}

export default QrScanner;
