import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GroupForm from './GroupForm';
import {
  getGroups,
  getGroupSequence,
  insertGroup,
  updateGroup,
  deleteGroup,
} from '../calls/groups';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import Skeleton from 'react-loading-skeleton';
import ActionButtons from '../ActionButtons';
import TableReact from '../TableTemplate';

const GroupsTable = ({ data }) => {
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [formData, setFormData] = useState(null);
  const [deleteData, setDeleteData] = useState({});
  const [rowSelected, setRowSelected] = useState(null);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rowIndex, setRowIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getGroups();
      setGroups(result.groups);
      setRowSelected(result.groups && result.groups[0]);
      setLoading(result.loading);
    };
    fetchData();
  }, []);

  const toggleForm = () => setIsOpenForm(!isOpenForm);
  const toggleDelete = () => setIsOpenDelete(!isOpenDelete);

  const handleCreate = async (item) => {
    const resultSeq = await getGroupSequence();
    if (resultSeq.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }

    const itemWithId = { ...item, group_id: resultSeq };
    const result = await insertGroup(itemWithId);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }

    const isFirstCreated = groups.length === 0;
    isFirstCreated && setRowSelected(itemWithId);
    setGroups([...groups, itemWithId]);
    toggleForm();
    toast.success('Project created successfully.');
  };

  const handleEdit = async (item) => {
    const { group_id } = item;
    const result = await updateGroup(item);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }
    const tmp = groups.filter((group) => group.group_id !== group_id);
    setGroups([...tmp, item]);
    setRowSelected(item);
    toggleForm();
    toast.success('Project edited successfully.');
  };

  const handleDelete = async () => {
    const result = await deleteGroup(rowSelected);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }
    setGroups(groups.filter((item) => item.group_id !== deleteData));
    toggleDelete();
    toast.success('Group deleted.');
  };

  const handleOpenDelete = () => {
    setDeleteData(rowSelected.line_group_id);
    toggleDelete();
  };

  const handleOpenForm = (action, row = rowSelected) => {
    const isNew = action === 'create';
    const { selected } = data;
    const nextOrdNo = groups.length
      ? Math.trunc(Math.max(...groups.map((group) => group.pos))) + 1
      : 1;
    const tmp = { ...row, action, selected, pos: isNew ? nextOrdNo : row.pos };
    setFormData(tmp);
    toggleForm();
  };

  const handleRowSelect = (info) => {
    setRowIndex(info.index);
    setRowSelected(info.original);
  };

  //const dataForChild = () => (!disabled && {...rowSelected, breadcrumb: [...data.breadcrumb, rowSelected.name], selected:{...data.selected, group:rowSelected}})

  const columns = [
    /* {
        id: 'group_id',
        Header: 'id',
        accessor: "group_id"    
    }, */ {
      id: 'pos',
      Header: 'Ord.No.',
      width: 100,
      style: { textAlign: 'right', width: 100 },
      accessor: 'pos',
    },
    {
      id: 'group_name',
      Header: 'Name',
      accessor: 'group_name',
    },
  ];
  const disabled = groups.length === 0;
  return loading ? (
    <Skeleton height={200} />
  ) : (
    <React.Fragment>
      <ActionButtons
        isDisabled={disabled}
        handleCreate={handleOpenForm}
        handleEdit={handleOpenForm}
        handleDuplicate
        handleDelete={handleOpenDelete}
      />
      {/*<span className="ml-2">
                    {!disabled && <Machines data={dataForChild()} />}  
                </span>*/}
      <TableReact
        hover
        columns={columns}
        data={groups}
        rowIndex={rowIndex}
        handleCreate={handleOpenForm}
        handleRowSelect={handleRowSelect}
      />
      {formData && (
        <GroupForm
          data={formData}
          isOpen={isOpenForm}
          handleCreate={handleCreate}
          handleEdit={handleEdit}
          toggle={toggleForm}
        />
      )}
      <DeleteConfirmationModal
        text={'group'}
        handleDelete={handleDelete}
        isOpen={isOpenDelete}
        toggle={toggleDelete}
      />
    </React.Fragment>
  );
};

export default GroupsTable;
