import styled from 'styled-components';

export const Composer = styled.div`
  display: flex;
  gap: 1em;
  align-items: flex-end;
  padding: 0 1em;
`;
export const PreviewAndUpload = styled.div`
  flex: 1;
`;
export const Attachments = styled.div`
  background: ${({ theme }) => theme.colors.grayscale._10};
  height: 271px;
  overflow: auto;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
