import firebase from 'firebase';
import 'firebase/firestore';

export const createOrUpdateMaintenance = async (data) => {
  const fsdb = firebase.firestore();
  await fsdb
    .collection('maintenance_tasks_test')
    .doc(data.id)
    .set(data, { merge: true });
};

export const createAssembly = async (data) => {
  const fsdb = firebase.firestore();
  await fsdb
    .collection('test_assemblies')
    .doc()
    .set({ name: data }, { merge: true });
};
