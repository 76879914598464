import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Badge, Modal, ModalBody, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import NoContent from '../../../../../components/NoContent';
import TooltipWrapper from '../../../../../components/TooltipWrapper';
import { generateLogTimes, getIcon } from './helpers';
import LogItem from './LogItem';
import classnames from 'classnames';
import Pagination, { sliceDataForPage } from './Pagination';
const StyledLogModalGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10;
  padding: 0 1em;
  margin-bottom: 1em;
  .overdue-text {
    color: ${({ theme }) => theme.colors.main.danger._100};
  }
`;
const StyledLogGrid = styled.div`
  display: grid;
  grid-template-columns: 300px minmax(200px, auto) 160px;
  /* gap: 1em;
  padding: 0.5em 1em; */
  .grid-row {
    display: contents;
    cursor: pointer;
    &:hover > div {
      background-color: ${({ theme }) => theme.colors.grayscale._10};
    }
  }
  .grid-cell {
    padding: 0.5em 1em;
  }
  .item-description {
    display: flex;
    flex-direction: column;
    .task-name {
      max-height: 50px;
      overflow: hidden;
    }
  }
  .item-notes {
    max-height: 109px;
    overflow: hidden;
  }
  .item-times {
    display: flex;
    flex-direction: column;
    text-align: right;
  }
  .small {
    font-size: 0.8em;
    font-weight: 500;
  }
  .muted {
    color: ${({ theme }) => theme.colors.grayscale._40};
  }
  .muted-danger {
    color: ${({ theme }) => theme.colors.main.danger._40};
  }
`;
const Log = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLog, setSelectedLog] = useState(false);
  const [dataOnPage, setDataOnPage] = useState(sliceDataForPage(data, 0));

  useEffect(() => setDataOnPage(sliceDataForPage(data, 0)), [data]);

  const toggle = () => setIsOpen(!isOpen);

  const handleLogSelect = (data) => {
    setSelectedLog(data);
    toggle();
  };

  return (
    <div style={{ overflowY: 'auto', maxHeight: 750 }}>
      <Pagination data={data} handleChangePage={setDataOnPage} />
      {!data?.length ? (
        <NoContent title="Empty Log" />
      ) : (
        <StyledLogGrid>
          {dataOnPage?.map((item, index) => {
            return (
              <LogItem key={index} data={item} eventSelect={handleLogSelect} />
            );
          })}
        </StyledLogGrid>
      )}
      <LogDetailModal data={selectedLog} isOpen={isOpen} toggle={toggle} />
    </div>
  );
};

const LogDetailModal = ({ data, isOpen, toggle }) => {
  if (!data) return null;
  const logTimes = generateLogTimes(data);
  return (
    <Modal
      autoFocus={false}
      fade={false}
      isOpen={isOpen}
      toggle={toggle}
      contentClassName="shadow-lg"
      size={'lg'}
    >
      <ModalHeader toggle={toggle}>
        <div className="small text-muted">
          {data.machine.description} &gt; {data.assembly}
        </div>
        <div>{data.task}</div>

        {data?.method.map((method) => (
          <TooltipWrapper content={method} key={method}>
            <Badge color="light" className="mr-1" id={`tooltip-${method}`}>
              {getIcon(method)}
            </Badge>
          </TooltipWrapper>
        ))}
      </ModalHeader>
      <ModalBody>
        <StyledLogModalGrid>
          <div>
            <strong>Completed at</strong>
          </div>
          <div>
            {' '}
            {DateTime.fromMillis(logTimes.completedTimeTS).toFormat(
              'dd.LL.yyyy'
            )}
          </div>

          <div>
            <strong>Should be completed at</strong>
          </div>
          <div className={classnames(logTimes.isOverdue && 'overdue-text')}>
            {logTimes.dueDateTimeTS &&
              DateTime.fromMillis(logTimes.dueDateTimeTS).toFormat(
                'dd.LL.yyyy'
              )}{' '}
            {logTimes.isOverdue && `(${logTimes.overdue} days)`}
          </div>
          <div>
            <strong>Frequency</strong>
          </div>
          <div>
            Every {data.frequency[0].interval} {data.frequency[0].unit}
          </div>
          <div>
            <strong>Service notes</strong>
          </div>
          <div>{data.comment}</div>
        </StyledLogModalGrid>
      </ModalBody>
    </Modal>
  );
};

export { LogDetailModal };
export default Log;
