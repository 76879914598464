import React, { Component, useEffect, useState } from 'react';
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Badge,
  Modal,
  ModalBody,
} from 'reactstrap';
import _ from 'lodash';

const Comments = ({ comments }) => {
  const [modal, setModal] = useState(false);
  const [src, setSrc] = useState('');
  const [commentsData, setComments] = useState([]);

  const orderComments = () => {
    setComments(
      _.orderBy(comments, ['date.value', 'time.value'], ['asc', 'asc'])
    );
  };

  useEffect(() => {
    orderComments();
  }, [comments]);

  const getCardFooter = (comment) => {
    return (
      <CardFooter>
        {comment.user} gave this issue status:
        <Badge
          color={
            comment.gaveStatus === 'Draft'
              ? 'secondary'
              : comment.gaveStatus === 'In process'
              ? 'success'
              : comment.gaveStatus === 'On hold'
              ? 'warning'
              : comment.gaveStatus === 'Duplicate'
              ? 'danger'
              : comment.gaveStatus === 'Open'
              ? 'success'
              : comment.gaveStatus === 'Missing information'
              ? 'warning'
              : 'danger'
          }
          className="ml-2"
        >
          {comment.gaveStatus}
        </Badge>
      </CardFooter>
    );
  };

  const showImage = (string) => {
    if (string === '') return;
    const regex = /(http|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/g;
    const images = string.match(regex);
    const imageThumbnailStyle = {
      maxHeight: 120,
      marginBottom: '.50em',
      marginRight: '.50em',
    };
    return images.map((image, index) => (
      <img
        key={index}
        alt="attachment"
        src={image}
        style={imageThumbnailStyle}
        onClick={handleClick}
      />
    ));
  };

  const handleClick = (e) => {
    setModal(!modal);
    setSrc(e.target.src);
  };

  const toggle = () => {
    setModal(!modal);
  };

  const commentsContent = () =>
    commentsData.map((comment, index) => {
      if (comment.comment.value === '' && comment.file_upload.value === '')
        return false;
      return (
        <Col key={index} sm={12} className="mb-2">
          <Card>
            <CardHeader /*className={(comment.userType === "technicalSupport") ? "bg-dark text-light" : ""}*/
            >
              <div className="text-muted">
                {comment.date.value}, {comment.time.value}
              </div>
            </CardHeader>
            <CardBody>
              <p>{comment.comment.value}</p>
              <div>{showImage(comment.file_upload.value)}</div>
            </CardBody>
            {/*(comment.gaveStatus) ? this.getCardFooter(comment) : "" */}
          </Card>
        </Col>
      );
    });

  return (
    <React.Fragment>
      {commentsContent()}
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalBody>
          <img src={src} alt="preview" className="img-fluid" />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Comments;
