import React, { useState } from 'react';
import MachineState from './MachineState';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { StyledButton } from '../../../../../components/DateSelection/DateSelection';
import AlarmStatistics from './AlarmStatistics';
import { DocumentList } from './Documents';
import { IoIosWarning, IoIosDocument, IoMdHome } from 'react-icons/io';
import { FaExclamationCircle } from 'react-icons/fa';
import styled from 'styled-components';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import NoContent from '../../../../../components/NoContent';
import { useStore } from '../../../../../store';
import { OverviewContainer } from './MachineOverview.styles';
const GraphicIcons = styled.div`
  color: ${({ theme, color }) => {
    return theme.colors.main[color]._100;
  }};
  font-size: 1.3rem;
`;
const MachineOverview = ({
  selectedMachine,
  findMachineState,
  switchMachine,
  activeAlarms,
  dates,
  setDates,
}) => {
  const [docsModalOpen, setDocsModalOpen] = useState(false);
  const [alarmsModalOpen, setAlarmsModalOpen] = useState(false);

  const filteredActiveAlarms =
    selectedMachine.pos === 0
      ? activeAlarms
      : activeAlarms.filter(
          (item) =>
            item.serial_nr.toString() === selectedMachine.machine_id.toString()
        );
  const toggleDocsModal = () => setDocsModalOpen(!docsModalOpen);
  const toggleAlarmsModal = () => setAlarmsModalOpen(!alarmsModalOpen);

  return (
    <OverviewContainer>
      <img
        src={selectedMachine?.imageUrl}
        alt={selectedMachine?.description}
        style={{
          objectFit: 'cover',
          borderRadius: '50%',
          height: 140,
          width: 140,
          backgroundColor: '#ccc',
        }}
      />
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          paddingRight: '1em',
          borderRight: 'solid 1px #ccc',
          alignItems: 'flex-start',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '1em',
            fontSize: '.75em',
            alignItems: 'center',
            marginBottom: '1em',
          }}
        >
          <StyledButton
            small
            style={{ fontSize: '1rem' }}
            onClick={() => switchMachine(null)}
          >
            <IoMdHome />
          </StyledButton>
          <StyledButton onClick={() => switchMachine(-1)}>
            <FiChevronLeft style={{ fontSize: '1rem' }} /> Previous
          </StyledButton>
          <StyledButton onClick={() => switchMachine(1)}>
            Next
            <FiChevronRight style={{ fontSize: '1rem' }} />
          </StyledButton>
          <div style={{ borderRight: '1px solid #ccc', height: 20 }}></div>
          <StyledButton onClick={toggleDocsModal}>
            <IoIosDocument style={{ fontSize: '1rem' }} />
            Documentation
          </StyledButton>
        </div>
        <div>
          <MachineState
            data={findMachineState(
              selectedMachine?.machine_id.toString() === '12175'
                ? 3
                : selectedMachine?.machine_id
            )}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'flex-end',
            gap: '2.5em',
            fontSize: '.75em',
          }}
        >
          <div>
            <strong>{selectedMachine?.description}</strong>
            <div>SN: {selectedMachine?.machine_id}</div>
            <div>ID: {selectedMachine?.model_nr}</div>
            <div>Name: {selectedMachine?.machine_name}</div>
          </div>
          <div>
            {selectedMachine?.production_year && (
              <div>Production year: {selectedMachine?.production_year}</div>
            )}
            {selectedMachine?.beginning_date_of_guarantee && (
              <div>
                Beginning of guarantee:{' '}
                {selectedMachine?.beginning_date_of_guarantee}
              </div>
            )}
            {selectedMachine?.guarantee_valid_till && (
              <div>
                Guarantee valid till: {selectedMachine?.guarantee_valid_till}
              </div>
            )}
            {findMachineState(selectedMachine?.machine_id)?.work_time_hours && (
              <div>
                Working hours:{' '}
                {findMachineState(selectedMachine?.machine_id)
                  ?.work_time_hours || '-'}{' '}
                h
              </div>
            )}
            {findMachineState(selectedMachine?.machine_id)?.stop_time_hours && (
              <div>
                In stop hours:{' '}
                {findMachineState(selectedMachine?.machine_id)
                  ?.stop_time_hours || '-'}{' '}
                h
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '1em',
            fontSize: '.75em',
            alignItems: 'center',
          }}
        >
          <strong>Active Alarms</strong>
          <StyledButton onClick={toggleAlarmsModal}>
            <IoIosWarning style={{ fontSize: '1rem' }} />
            Alarms history
          </StyledButton>
        </div>
        <div style={{ fontSize: '.75em', maxHeight: 110, overflowY: 'auto' }}>
          {!filteredActiveAlarms.length ? (
            <NoContent
              title=" "
              style={{ height: '100%', fontSize: '1rem', alignItems: 'start' }}
            >
              No active alarms
            </NoContent>
          ) : (
            filteredActiveAlarms.map((alarm) => {
              const isAlarm = alarm.alarm_type.toLowerCase() === 'alarm';
              const isWarning = alarm.alarm_type.toLowerCase() === 'warning';
              const color = isAlarm ? 'danger' : isWarning ? 'warning' : 'info';
              return (
                <div
                  key={alarm.alarm_text}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1em',
                  }}
                >
                  <GraphicIcons color={color}>
                    <FaExclamationCircle />
                  </GraphicIcons>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '1em',
                    }}
                  >
                    <div>{alarm.alarm_text}</div>
                    <div>
                      <span style={{ fontWeight: 500, color: '#ccc' }}>
                        {alarm.machine_description}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      {/* <div>
        <StyledButton small>
          <IoIosDocument />
          <span>Documentation</span>
        </StyledButton>
        <StyledButton small color="primary" className="mt-2">
          <IoIosWarning /> <span>Alarms</span>
        </StyledButton>
        <AlarmStatistics
          data={data}
          dates={dates}
          toggleWithData={toggleWithData}
          setDates={setDates}
          datesLoading={datesLoading}
        />
        <Documents data={data} toggleWithData={toggleWithData} />
      </div> */}
      <Modal
        autoFocus={false}
        fade={false}
        isOpen={docsModalOpen}
        contentClassName="shadow-lg"
        size={'xl'}
      >
        <ModalHeader toggle={toggleDocsModal}>
          {`Documents - ${selectedMachine.description}`}
        </ModalHeader>
        <ModalBody>
          <DocumentList data={selectedMachine.manuals} />
        </ModalBody>
      </Modal>
      <AlarmStatistics
        ExternalToggle={toggleAlarmsModal}
        ExternalIsOpen={alarmsModalOpen}
        data={selectedMachine}
        setDates={setDates}
        dates={dates}
        datesLoading={false}
        modalOnly
      />
      {/* <Modal
        autoFocus={false}
        fade={false}
        isOpen={alarmsModalOpen}
        contentClassName="shadow-lg"
        size={'xl'}
      >
        <ModalHeader toggle={toggleAlarmsModal}>
          {`Alarms Statistics - ${selectedMachine.description}`}
        </ModalHeader>
        <ModalBody>
          <DocumentList data={selectedMachine.manuals} />
        </ModalBody>
      </Modal> */}
    </OverviewContainer>
  );
};

export default MachineOverview;
