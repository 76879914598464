import React, { useState, useEffect } from 'react';
import Breadcrumbs from '../../../../components/Breadcrumb/Breadcrumb';
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import Cards from './partials/Cards';
import AvailabilityTimeline2 from '../../../Dashboards/partials/AvailabilityTimeline2';
import { useStore } from '../../../../store';
import Performance from './partials/Performance';
import EnergyConsumption from './partials/EnergyConsumption';
import SteamConsumption from './partials/SteamConsumption';
import FinishedLotsTable2 from '../../../Dashboards/partials/FinishedLotsTable2';
import DateSelection from '../../../../components/DateSelection/DateSelection';
import { getLastXDays } from '../../../../lib/helpers';
import { generateDataForOEEPage } from '../../helpers';
import 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import ExportData from './partials/ExportData';
import energyConsumptionData from './partials/tmp-energy-consumptio.json';
import Tabs from '../../../../components/Tabs';
import GasConsumption from './partials/GasConsumption';
import CompressedAirConsumption from './partials/CompressedAirConsumption ';
import RecuperationConsumption from './partials/RecuperationConsumption';
import PiecesAndMass from './partials/PiecesAndMass';

const OEE = () => {
  const { state } = useStore();
  const [modalData, setModalData] = useState({ header: '', body: '' });
  const [isOpen, setIsOpen] = useState(false);
  const [dates, setDates] = useState([
    getLastXDays(1),
    new Date(getLastXDays(1).setHours(23, 59, 59, 999)),
  ]);

  const [allDatesSelected, setAllDatesSelected] = useState([
    getLastXDays(1),
    getLastXDays(0),
  ]);
  const [sums, setSums] = useState({});
  const [zoomSync, setZoomSync] = useState([0, 100]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const hasNull = dates.some((item) => item === null);
    !hasNull && setAllDatesSelected(dates);
  }, [dates]);

  const lotsRef = useFirestore()
    .collection('lot_history')
    .where('lot.start.ts', '>=', allDatesSelected[0].getTime())
    .where('lot.start.ts', '<=', allDatesSelected[1].getTime());
  const { status, data: cloudData } = useFirestoreCollectionData(lotsRef);

  const energyHistoryRef = useFirestore()
    .collection('energy_history')
    .where('date_ts', '>=', allDatesSelected[0].getTime())
    .where('date_ts', '<=', allDatesSelected[1].getTime());
  const {
    status: energyHistoryStatus,
    data: energyHistory,
  } = useFirestoreCollectionData(energyHistoryRef);

  useEffect(() => {
    if (!cloudData) return;
    const result = cloudData.filter(
      (item) => item.counters.divider.pieces.total >= 10
    );
    setData(result);
  }, [cloudData]);

  useEffect(() => {
    const sums = generateDataForOEEPage(data);
    setSums(sums);
  }, [data]);

  const toggleWithData = (modalData) => setModalData(modalData);
  const toggle = () => setIsOpen(!isOpen);

  const breadcrumbItems = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'OEE - Overall Equipment Effectiveness',
      link: '',
    },
  ];
  return (
    <React.Fragment>
      <Breadcrumbs items={breadcrumbItems} />
      <div style={{ display: 'flex', gap: '0.5em', padding: '0 1rem' }}>
        <DateSelection
          setDates={setDates}
          selectedDates={dates}
          loading={status === 'loading'}
          rangeWithTime
        />
        <ExportData data={data} />
      </div>
      <Container fluid>
        <Row className="mt-3">
          {/* <Col lg={12}>
            {
              <UncontrolledAlert color="warning">
                <h5 className="alert-heading">
                  Not all data is shown correctly
                </h5>
                <p>There was an error while getting some data from server.</p>
              </UncontrolledAlert>
            }
          </Col> */}
          <Cards
            sums={sums}
            dataNew={data}
            energy={[]}
            reports2={[]}
            avaTimeline={[]}
            summary={[]}
            stopsWithReasons={[]}
            reasonOptions={[]}
            getStopsWithReasons={[]}
            loading={false}
            setModalData={toggleWithData}
          />
          {/* <Col lg={12}>
                        <AvailabilityTimeline data={{ loading: loadingTimeline, timelineAVA, reports2 }} zoom />
    </Col>*/}
          <Col lg={12}>
            <AvailabilityTimeline2
              data={{
                loading: false,
                loadingTimeline: [],
                timelineAVA2: [],
                reports2: [],
              }}
              dataNew={data}
              zoom
              gridLeft={100}
              zoomSync={zoomSync}
              setZoomSync={setZoomSync}
            />
          </Col>
          <Col lg={12}>
            <Card className="mb-3">
              <CardHeader></CardHeader>
              <CardBody className="p-0">
                <Tabs
                  tabs={[
                    'OEE',
                    'Energy',
                    'Steam',
                    'Gas',
                    'Compressed Air',
                    'Recuperated Energy',
                    'Pieces and Mass',
                  ]}
                >
                  <Performance
                    data={[]}
                    dataNew={data}
                    loading={false}
                    gridLeft={100}
                    zoomSync={zoomSync}
                    setZoomSync={setZoomSync}
                  />
                  <EnergyConsumption
                    data={energyHistory}
                    dataNew={energyHistory}
                    loading={false}
                    gridLeft={100}
                    zoomSync={zoomSync}
                    setZoomSync={setZoomSync}
                  />
                  <SteamConsumption
                    data={energyHistory}
                    dataNew={energyHistory}
                    loading={false}
                    gridLeft={100}
                    zoomSync={zoomSync}
                    setZoomSync={setZoomSync}
                  />
                  <GasConsumption
                    data={energyHistory}
                    dataNew={energyHistory}
                    loading={false}
                    gridLeft={100}
                    zoomSync={zoomSync}
                    setZoomSync={setZoomSync}
                  />
                  <CompressedAirConsumption
                    data={energyHistory}
                    dataNew={energyHistory}
                    loading={false}
                    gridLeft={100}
                    zoomSync={zoomSync}
                    setZoomSync={setZoomSync}
                  />
                  <RecuperationConsumption
                    data={energyHistory}
                    dataNew={energyHistory}
                    loading={false}
                    gridLeft={100}
                    zoomSync={zoomSync}
                    setZoomSync={setZoomSync}
                  />
                  <PiecesAndMass
                    data={energyHistory}
                    dataNew={energyHistory}
                    loading={false}
                    gridLeft={100}
                    zoomSync={zoomSync}
                    setZoomSync={setZoomSync}
                  />
                </Tabs>
              </CardBody>
            </Card>
          </Col>

          <Col lg={12}>
            <FinishedLotsTable2
              data={[]}
              summary={[]}
              loading={false}
              cloudData={data}
            />
          </Col>
        </Row>
        <Modal
          autoFocus={false}
          fade={false}
          isOpen={isOpen}
          contentClassName="shadow-lg"
          size="xl"
        >
          <ModalHeader toggle={toggle}>{modalData.header}</ModalHeader>
          <ModalBody {...modalData.bodyProps} className="bg-light">
            {modalData.body}
          </ModalBody>
        </Modal>
      </Container>
    </React.Fragment>
  );
};

export default OEE;
