import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/chart/custom.js';
import { generateDurationString } from '../../Diagnostics/helpers';
import NoContent from '../../../components/NoContent/NoContent';
import { getColorScheme } from '../../../lib/helpers';

const LotTimelineNew = ({ loading, data }) => {
  const [events, setEvents] = useState({});
  useEffect(() => {
    setEvents({
      click: (params) => {
        //console.log({params})
      },
    });
  }, []);

  const colorScheme = getColorScheme();
  const colors = {
    run: colorScheme.main.success._100,
    stop: colorScheme.main.danger._100,
  };
  const getColor = (state) =>
    ({
      run: colorScheme.main.success._100,
      stop: colorScheme.main.danger._100,
    }[state.toLowerCase()]);

  const formattedData = data?.log?.divider?.state_changes.map((item) => {
    return {
      name: item.state,
      value: [Number(item.begin_at.ts), Number(item.end_at.ts)],
      //value: [+ new Date(item.time_from_txt), + new Date(item.time_to_txt)],
      dateString: [item, item],
      itemStyle: {
        normal: {
          color: getColor(item.state),
        },
      },
    };
  });
  const renderTooltip = ({ name, value }) => {
    const [start, end] = value;
    const duration = generateDurationString(end - start);
    const newDateStart = new Date(start);
    const newDateEnd = new Date(end);

    const startString =
      ('0' + newDateStart.getHours()).slice(-2) +
      ':' +
      ('0' + newDateStart.getMinutes()).slice(-2) +
      ':' +
      ('0' + newDateStart.getSeconds()).slice(-2);

    const endString =
      ('0' + newDateEnd.getHours()).slice(-2) +
      ':' +
      ('0' + newDateEnd.getMinutes()).slice(-2) +
      ':' +
      ('0' + newDateEnd.getSeconds()).slice(-2);

    return `
            <strong style="text-transform: capitalize"> ${name}</strong> <br />
            Duration: <strong>${duration}</strong> <br />
            ${startString} - ${endString} 
        `;
  };

  function renderItems(params, api) {
    var start = api.coord([api.value(0)]);
    var end = api.coord([api.value(1)]);
    var height = api.size([0, 1])[1];
    var rectShape = echarts.graphic.clipRectByRect(
      {
        x: start[0],
        y: start[1] / 30,
        width: end[0] - start[0],
        height: height,
      },
      {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height,
      }
    );

    return (
      rectShape && {
        type: 'rect',
        shape: { ...rectShape },
        style: api.style(),
      }
    );
  }

  const option = {
    tooltip: {
      formatter: (params) => renderTooltip(params),
    },
    legend: {
      data: [
        'Cleaning',
        'Minor stop',
        'Preparing',
        'Running',
        'Unplanned stop',
        'Waiting',
      ],
    },
    //  dataZoom: [{
    //      type: 'slider',
    //      filterMode: 'weakFilter',
    //      showDataShadow: false,
    //      top: 90,
    //      height: 10,
    //      handleIcon: 'M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z', // jshint ignore:line
    //      handleSize: 20,
    //      labelFormatter: ''
    //  }, {
    //      type: 'inside',
    //      filterMode: 'weakFilter'
    //  }],
    grid: {
      height: 50,
      top: 0,
      bottom: 0,
      left: 20,
      right: 20,
    },
    xAxis: {
      scale: true,
      show: true,
      type: 'time',
      max: function (value) {
        const max = formattedData?.length
          ? formattedData[formattedData.length - 1]?.value[1]
          : value.max;
        return max;
      },
      splitLine: {
        //show:false
      },
    },
    yAxis: {
      data: [''],
      show: false,
    },
    series: [
      {
        type: 'custom',
        renderItem: renderItems,
        data: formattedData,
        //silent: true
      },
    ],
  };
  return loading ? (
    <Skeleton height={148} />
  ) : (
    <Card className="mb-3">
      <CardHeader>Lot timeline</CardHeader>
      <CardBody className="p-0">
        {!data || data.length === 0 ? (
          <NoContent title="No data" style={{ height: 'auto' }} />
        ) : (
          <ReactEchartsCore
            echarts={echarts}
            style={{ height: 120 }}
            //onEvents={events}
            option={option}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default LotTimelineNew;
