import React from 'react';
import { MdDashboard } from 'react-icons/md';
import Dashboard from './views/Dashboard';

export default {
  title: 'Dashboard',
  icon: <MdDashboard />,
  iconText: 'Dashboard',
  url: '/',
  api: {},
  view: Dashboard,
};
