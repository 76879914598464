import styled from 'styled-components';

export const Chat = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Messages = styled.div`
  height: ${({ height }) => {
    const maxHeight = height <= 150 ? height : 150;
    return `${416 - maxHeight}px`;
  }};
  background: ${({ theme }) => theme.colors.grayscale._10};

  /* overflow-y: scroll; */
  overflow: auto;
  padding: 1em;
`;

export const Message = styled.div`
  display: flex;
  gap: 1em;
  background: white;
  padding: 0.7em;
  margin-bottom: 0.5em;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  .username {
    font-weight: 500;
  }
  .date {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.grayscale._40};
  }
`;

export const Avatar = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.main.primary._20};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
`;

export const Composer = styled.div`
  display: grid;
  grid-template-columns: fit-content(100px) 1fr fit-content(100px);
  grid-column-gap: 10px;
  padding: 1em 1em 0;
  align-items: end;
  /* display: flex;
  gap: 1em;
  align-items: flex-end;
  padding: 1em;
  border-top: solid 1px ${({ theme }) => theme.colors.grayscale._20};
  textarea {
    flex: 1;
  } */
`;
