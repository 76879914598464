import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Navbar from '../Navbar';
import QrResult from '../../components/QrScanner/QrResult';
import { Modules } from '../../modules';
import Settings from '../../pages/Settings';
import NewIssue from '../../modules/MAS/views/NewIssue';
import QrScanner from '../../components/QrScanner';
import docCookies from '../../lib/docCookies';

class Content extends Component {
  callbackFromContent = (dataFromNavbar) => {
    this.props.callbackFromLayout(dataFromNavbar);
  };

  isMachineChanged = (dataFromNavbar) => {
    this.setState({ machine: dataFromNavbar }, () => {
      localStorage.setItem('machine', JSON.stringify(dataFromNavbar));
    });
  };

  isLoggedIn = (token, projects) => {
    const isLoggedIn = token && projects;
    if (!isLoggedIn) {
      docCookies.getItem('userToken') && docCookies.removeItem('userToken');
      localStorage.removeItem('projects');
    }
    return isLoggedIn;
  };

  render() {
    const projects = localStorage.projects;
    const token = docCookies.getItem('userToken');
    return (
      <div id="content" className="bg-light">
        <Navbar
          isMachineChanged={this.isMachineChanged}
          callbackFromContent={this.callbackFromContent}
        />
        <div id="main">
          {Modules.map((module, index) => {
            //TODO: implement roles! something like: if (module.settings.acceptedRoles === localStorage.user.role) return module.component

            if (module.settings.gostolOnly) {
              const isGostol =
                docCookies.getItem('userToken') ===
                '1|cfb661bb-f43e-4108-8d1d-43ac80256494';

              return isGostol ? (
                <div key={index}>{module.component}</div>
              ) : null;
            } else {
              return <div key={index}>{module.component}</div>;
            }
          })}

          {/* DO NOT REMOVE/DELETE ROUTES BELOW */}
          <Route exact path="/Settings" component={Settings} />
        </div>
      </div>
    );
  }
}
export default Content;
