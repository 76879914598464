export const breadcrumbItems = [
  {
    text: 'Home',
    link: '/',
  },
  {
    text: 'Orders',
    link: '',
  },
];
