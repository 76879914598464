import React, { useRef, useState } from 'react';
import { FiFile, FiImage, FiUpload, FiVideo, FiX } from 'react-icons/fi';
import { StyledButton } from '../DateSelection/DateSelection';
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  FilePreviewContainer,
  PreviewList,
  InputLabel,
  StyledAttachmentPreview,
} from './file-upload-styles';

const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 2048000; // 2MB

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({
  label,
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  ...otherProps
}) => {
  const fileInputField = useRef(null);
  const [files, setFiles] = useState({});

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          return { file };
        }
        files[file.name] = file;
      }
    }
    return { ...files };
  };

  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };

  return (
    <>
      <FileUploadContainer>
        <InputLabel>{label}</InputLabel>
        <StyledButton type="button" onClick={handleUploadBtnClick}>
          <FiUpload />
          <span> Upload {otherProps.multiple ? 'files' : 'a file'}</span>
        </StyledButton>

        <DragDropText>
          Drag and drop your files <br />
          <small>
            Size limit: {Math.ceil(Number(maxFileSizeInBytes) / 1024)}KB
          </small>
        </DragDropText>
        <FormField
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          title=""
          value=""
          {...otherProps}
        />
      </FileUploadContainer>
      <FilePreviewContainer>
        <PreviewList>
          {Object.keys(files).map((fileName, index) => {
            let file = files[fileName];
            return (
              <StyledAttachmentPreview key={index}>
                <div className="icon">
                  {file.type.indexOf('image') > -1 ? (
                    <FiImage />
                  ) : file.type.indexOf('video') > -1 ? (
                    <FiVideo />
                  ) : (
                    <FiFile />
                  )}
                </div>
                <div className="name">{file.name}</div>
                <div className="size">
                  (
                  {Intl.NumberFormat(window.navigator.language).format(
                    Math.ceil(Number(file.size) / 1024)
                  )}
                  K)
                </div>
                <div className="remove" onClick={() => removeFile(fileName)}>
                  <FiX />
                </div>
              </StyledAttachmentPreview>
            );
          })}
        </PreviewList>
      </FilePreviewContainer>
    </>
  );
};

export default FileUpload;
