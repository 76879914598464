import React, { useEffect, useState } from 'react';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/component/visualMap';
import 'echarts/lib/chart/line.js';
import Skeleton from 'react-loading-skeleton';
import { getColorScheme } from '../../../../../lib/helpers';
import {
  concatAndSplitArraysFromJSON,
  generateArrayForChart,
} from '../../../helpers';

const PiecesAndMass = ({
  loading,
  gridLeft,
  zoomSync,
  setZoomSync,
  dataNew,
}) => {
  const [events, setEvents] = useState({});
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    zoomSync &&
      setEvents({
        dataZoom: (params) => {
          const { start, end } = params.batch ? params.batch[0] : params;
          setZoomSync([start, end]);
        },
      });
  }, []);

  useEffect(() => {
    if (!dataNew.length) {
      setChartData({ piecesData: [], massData: [] });
      return null;
    }
    const { date_ts, interval_ms } = dataNew[0];
    // equation to calculate pieces and mass per hour:
    // factor = 3600000(hour in ms) / interval_ms
    // piecesOrMassPerHour = piecesOrMass * factor
    const factor = 3600000 / interval_ms;

    const piecesArr = concatAndSplitArraysFromJSON(dataNew, 'pieces');
    const piecesData = generateArrayForChart(piecesArr, date_ts, interval_ms);
    const piecesPerHourData = piecesData.map((item) => [
      item[0],
      item[1] * factor,
    ]);
    const massArr = concatAndSplitArraysFromJSON(dataNew, 'mass_kg');
    const massDataInGrams = generateArrayForChart(
      massArr,
      date_ts,
      interval_ms
    );
    const massPerHourData = massDataInGrams.map((item) => [
      item[0],
      Math.floor(item[1] / 1000) * factor,
    ]);
    setChartData({ piecesData: piecesPerHourData, massData: massPerHourData });
  }, [dataNew]);

  const colorScheme = getColorScheme();
  const option = {
    grid: {
      // left: gridLeft || 50,
      // right: 20,
    },
    xAxis: {
      type: 'time',
      boundaryGap: false,
      // data: generatedDate,
      scale: true,
      //type:"time",
    },
    yAxis: [
      {
        type: 'value',
        name: 'Pieces',
        axisLabel: {
          formatter: (value) =>
            `${Intl.NumberFormat(window.navigator.language).format(
              value
            )} pcs/hr`,
        },
      },
      {
        type: 'value',
        name: 'Mass',
        axisLabel: {
          formatter: (value) =>
            `${Intl.NumberFormat(window.navigator.language).format(
              value
            )} kg/hr`,
        },
      },
    ],
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: ['Pieces', 'Mass'],
    },
    dataZoom: [
      {
        type: 'slider',
        filterMode: 'weakFilter',
        showDataShadow: false,
        top: 455,
        height: 10,
        handleIcon:
          'M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z', // jshint ignore:line
        handleSize: 20,
        labelFormatter: '',
        start: zoomSync ? zoomSync[0] : 0,
        end: zoomSync ? zoomSync[1] : 100,
      },
      {
        type: 'inside',
        filterMode: 'weakFilter',
      },
    ],
    series: [
      {
        type: 'line',
        sampling: 'average',
        name: 'Pieces',
        stack: '1',
        step: 'end',
        data: chartData.piecesData,
        symbol: 'none',
        areaStyle: {},
        itemStyle: {
          color: colorScheme.other[0]._100,
        },
        lineStyle: {
          width: 2,
        },
      },
      {
        type: 'line',
        sampling: 'average',
        name: 'Mass',
        stack: '2',
        yAxisIndex: 1,
        step: 'end',
        data: chartData.massData,
        symbol: 'none',
        areaStyle: {},
        itemStyle: {
          color: colorScheme.other[1]._100,
        },
        lineStyle: {
          width: 2,
        },
      },
    ],
  };
  return loading ? (
    <Skeleton height={256} />
  ) : (
    <ReactEchartsCore
      onEvents={events}
      echarts={echarts}
      style={{ height: 480 }}
      option={option}
    />
  );
};

export default PiecesAndMass;
