import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Tabs from '../../../../../components/Tabs';
import Agenda from './Agenda';
import Calendar from './Calendar';
import Log from './Log';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import 'firebase/firestore';
import firebase from 'firebase';
import { generateCalendarData } from './helpers';

const TodoCard = ({ selectedMachine }) => {
  const [allTasks, setAllTasks] = useState([]);
  const [allLogs, setAllLogs] = useState([]);
  const [generatedData, setGeneratedData] = useState([]);
  const [generatedDataDaily, setGeneratedDataDaily] = useState([]);
  const [logs, setLogs] = useState([]);

  const machineTasksRef = useFirestore()
    .collection('machines')
    // never put null into .doc, it will start generating massive Snapshot listeners
    // temporary set to '12086' -> divider ID
    .doc(selectedMachine ? selectedMachine?.machine_id.toString() : '12086')
    .collection('maintanance_tasks');
  const { status, data: tasksData } = useFirestoreCollectionData(
    machineTasksRef
  );

  const tasksLogRef = useFirestore()
    .collection('machines')
    // never put null into .doc, it will start generating massive Snapshot listeners
    // temporary set to '12086' -> divider ID
    .doc(selectedMachine ? selectedMachine?.machine_id.toString() : '12086')
    .collection('maintanance_log');
  const { status: logsStatus, data: logsData } = useFirestoreCollectionData(
    tasksLogRef
  );

  useEffect(() => {
    const fsdb = firebase.firestore();
    //Get all tasks
    fsdb
      .collectionGroup('maintanance_tasks')
      .limit(3) //remove limit when done testing
      .get()
      .then((querySnapshot) => {
        let tmp = [];
        querySnapshot.forEach((doc) => {
          tmp.push(doc.data());
        });
        setAllTasks(tmp);
      });

    // Get all logs
    fsdb
      .collectionGroup('maintanance_log')
      .limit(3) //remove limit when done testing
      .get()
      .then((querySnapshot) => {
        let tmp = [];
        querySnapshot.forEach((doc) => {
          tmp.push(doc.data());
        });
        setAllLogs(tmp);
      });
  }, []);

  useEffect(() => {
    const tasks = selectedMachine ? tasksData : allTasks;
    const todos = tasks?.filter((item) => item.group !== 'Daily');
    const dailyTasks = tasks?.filter((item) => item.group === 'Daily');
    setGeneratedData(generateCalendarData(todos));
    setGeneratedDataDaily(generateCalendarData(dailyTasks));
  }, [allTasks, selectedMachine, tasksData]);

  useEffect(() => {
    setLogs(selectedMachine ? logsData : allLogs);
  }, [allLogs, selectedMachine, logsData]);
  return (
    <Card>
      <CardHeader>Todos</CardHeader>
      <CardBody className="p-0">
        <Tabs tabs={['Agenda', 'Daily tasks', 'Calendar', 'Tasks Log']}>
          <Agenda data={generatedData} />
          <Agenda data={generatedDataDaily} hideOverdue />
          <Calendar data={generatedData} />
          <Log data={logs} />
        </Tabs>
      </CardBody>
    </Card>
  );
};

export default TodoCard;
