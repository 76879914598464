import React, { useState } from 'react';
import AssembliesTable from './AssembliesTable';
import { Button } from 'reactstrap';
import ModalTemplate from '../ModalTemplate';

const Assemblies = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <React.Fragment>
      <Button className="ml-2" outline size="sm" onClick={toggle}>
        Assemblies
      </Button>
      <ModalTemplate
        isOpen={isOpen}
        toggle={toggle}
        title="Assemblies"
        breadcrumbs={data.breadcrumb}
      >
        <AssembliesTable data={data} />
      </ModalTemplate>
    </React.Fragment>
  );
};

export default Assemblies;
