import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// AUTH
firebase.initializeApp(config);
const mainAuth = firebase.auth();
mainAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
// mainAuth.onAuthStateChanged(function (user) {
//   console.log({ user });
// });

async function handleLogout(history) {
  mainAuth.signOut();
}

//GSBL ADMIN CONFIG
const GSBLAdminConfig = {
  apiKey: process.env.REACT_APP_GSBLADMIN_API_KEY,
  authDomain: process.env.REACT_APP_GSBLADMIN_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_GSBLADMIN_DATABASE_URL,
  projectId: process.env.REACT_APP_GSBLADMIN_PROJECT_ID,
  storageBucket: process.env.REACT_APP_GSBLADMIN_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_GSBLADMIN_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_GSBLADMIN_APP_ID,
  measurementId: process.env.REACT_APP_GSBLADMIN_MEASUREMENT_ID,
};

const GSBLAdmin = firebase.initializeApp(GSBLAdminConfig, 'gsbl-firetest');
const GSBLAdminDB = GSBLAdmin.firestore();

//SUGAR-LAND CONFIG tmp database
const proj_sugarland = {
  apiKey: 'AIzaSyBFLXBaMFFbmzT0DpNaZtb9v_5hU0tvbII',
  appId: '1:889608137307:web:18e8855f05374f7eb36d7e',
  projectId: 'proj-sugarland',
  storageBucket: 'proj-sugarland.appspot.com',
};
const sugarLandProject = firebase.initializeApp(
  proj_sugarland,
  'proj_sugarland'
);
const sugarLandDB = sugarLandProject.firestore();

export default config;
export { config, GSBLAdminDB, sugarLandDB, mainAuth, handleLogout };
