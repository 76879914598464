import React from 'react';
import Camera from './views';
import { IoVideocam } from 'react-icons/io5';

export default {
  title: 'Cameras',
  icon: <IoVideocam />,
  iconText: 'Camera',
  url: '/cameras',
  gostolOnly: false,
  api: {},
  view: Camera,
};
