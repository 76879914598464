import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import {
  useFirestore,
  useFirestoreCollection,
  useFirestoreCollectionData,
} from 'reactfire';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
} from 'reactstrap';
import styled from 'styled-components';
import 'firebase/firestore';
import { StyledButton } from '../../../components/DateSelection/DateSelection';
import { createAssembly, createOrUpdateMaintenance } from './maintenanceCRUD';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { createOption } from './helpers';
import {
  METHOD_OPTIONS,
  FREQUENCY_UNITS,
  PERSONEL_LEVEL_OPTIONS,
} from './constants';
import FrequencyInput from './Form/FrequencyInput';
import MachineSelectionInput from './Form/MachineSelectionInput';
import PartsInput from './Form/PartsInput';
import { snakeCase } from '../../../lib/helpers';

const StyledAdminGrid = styled.div`
  display: grid;
  grid-template-columns: 450px auto;

  .head {
    border-bottom: solid 1px #ccc;
    font-weight: 500;
    padding: 0.5em 1em;
  }

  .item {
    padding: 1em 0;
    border-right: solid 1px #ccc;

    p {
      cursor: pointer;
      padding: 0.5em 1em;
      margin: 0;
      &:hover {
        background: ${({ theme }) => theme.colors.grayscale._10};
      }
      /* padding: 1em 0; */
    }
  }
  .selected {
    background: ${({ theme }) => theme.colors.grayscale._10};
  }
  .item .create-new {
    color: ${({ theme }) => theme.colors.main.success._100};
    font-weight: 500;

    &:hover {
      background: ${({ theme }) => theme.colors.main.success._10};
    }
  }
  .item + .item {
    border: none;
  }
`;

const EMPTY_TASK_STATE = {
  name: '',
  machine: '',
  assembly: '',
  frequency: [{}],
  method: '',
  personelLevel: '',
  forThisVariant: false,
  parts: [],
};

const AdminPage = () => {
  const [task, setTask] = useState(EMPTY_TASK_STATE);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [assembliesOptions, setAssembliesOptions] = useState([]);
  const [allMaintenances, setAllMaintenances] = useState([]);
  const [filteredMaintenances, setFilteredMaintenances] = useState([]);

  const handleMachineSelect = (machine) => {
    setSelectedMachine(machine);
    setTask({
      ...task,
      machine: {
        description: machine.description,
        id: machine.machine_id,
        model_nr: machine.model_nr,
      },
    });
  };
  const handlePartsChange = (parts) =>
    setTask({
      ...task,
      parts,
    });

  const handleTaskSelect = (selectedTask) => {
    const getSelectedMachine = machines.find(
      (item) =>
        item.machine_id?.toString() === selectedTask.machine?.id?.toString()
    );
    setSelectedMachine({ ...getSelectedMachine });
    const {
      task,
      machine,
      assembly,
      frequency,
      method,
      personelLevel,
      forThisVariant,
      parts,
      id,
    } = selectedTask;
    setTask({
      id,
      task,
      machine,
      assembly,
      frequency,
      method,
      personelLevel,
      forThisVariant,
      parts,
    });
  };

  useEffect(() => {
    const fsdb = firebase.firestore();
    //Get all tasks
    fsdb
      .collectionGroup('maintanance_tasks')
      .limit(3) //remove limit when done testing
      .get()
      .then((querySnapshot) => {
        let tmp = [];
        querySnapshot.forEach((doc) => {
          tmp.push(doc.data());
        });
        setAllMaintenances(tmp);
        setFilteredMaintenances(tmp);
      });
  }, []);
  const machineTasksRef = useFirestore().collection('maintenance_tasks_test');
  const { data: machineTasks } = useFirestoreCollectionData(machineTasksRef);

  const assembliesRef = useFirestore().collection('test_assemblies');
  const { data: assemblies } = useFirestoreCollectionData(assembliesRef);

  const machinesRef = useFirestore().collection('machines');
  const { data: machines } = useFirestoreCollectionData(machinesRef);

  const handleFileSelect = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();

    // file naming: machineID_machineDescription_MaintenanceID
    // e.g.: 12086_dough_divider_1084.pdf

    const fileName = snakeCase(
      `${task.machine.id} ${task.machine.description} Majkole`
    );
    // const storageRef = firebase.storage().ref(`tmp/${snakeCase(fileName)}`);

    // const file = evt.target.files[0];

    // const metadata = {
    //   cacheControl: 'public,max-age=300',
    //   contentType: 'application/pdf',
    // };

    // storageRef
    //   .put(file, metadata)
    //   .then((snapshot) => {
    //     console.log('Uploaded', snapshot.totalBytes, 'bytes.');
    //     console.log('File metadata:', snapshot.metadata);
    //     // Let's get a download URL for the file.
    //     snapshot.ref.getDownloadURL().then((url) => {
    //       console.log('File available at', url);
    //     });
    //   })
    //   .catch((error) => {
    //     console.error('Upload failed:', error);
    //   });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // 1. upload document to storage to get fileURL
    // 1.1. make this function async/await
    // 2. add fileUrl to task.document.fileURL
    // 3. run createOrUpdateMaintenance

    // createOrUpdateMaintenance(task);
  };

  const handleMethodSelect = (methods) => {
    setTask({ ...task, method: methods.map((item) => item.label) });
  };

  const handlePersonelLevelSelect = (personelLevel) => {
    setTask({ ...task, personelLevel });
  };
  const handleAssemblySelect = (assembly) => {
    setTask({ ...task, assembly: assembly.label });
  };
  const handleAssemblyCreate = (assembly) => createAssembly(assembly);

  const handleFrequencyChange = ({ newInterval, newUnit, index }) => {
    const { interval, unit } = task.frequency[index];
    const tmp = task.frequency.map((item, freqIndex) =>
      freqIndex === index
        ? { interval: newInterval || interval, unit: newUnit || unit }
        : item
    );
    setTask({
      ...task,
      frequency: tmp,
    });
  };

  const handleFrequencyAdd = () => {
    const tmp = [...task.frequency, { unit: FREQUENCY_UNITS[0] }];
    setTask({ ...task, frequency: tmp });
  };
  const handleFrequencyRemove = (index) => {
    const tmp = task.frequency;
    tmp.splice(index, 1);
    setTask({ ...task, frequency: tmp });
  };

  const getDefaultSelectedAssembly = () =>
    assembliesOptions?.find(
      (item) => item.value === task?.assembly?.toLowerCase()
    );
  const handleFilter = (text) =>
    setFilteredMaintenances(
      allMaintenances.filter(
        (item) =>
          item.machine.description.toLowerCase().indexOf(text.toLowerCase()) >
            -1 ||
          item.task.toString().toLowerCase().indexOf(text.toLowerCase()) > -1
      )
    );
  // const assembliesOptions = assemblies?.map((item) => createOption(item.name));
  useEffect(() => {
    setAssembliesOptions(assemblies?.map((item) => createOption(item.name)));
  }, [assemblies]);
  return (
    <Card>
      <CardHeader>Admin</CardHeader>
      <CardBody>
        <StyledAdminGrid>
          <div className="head">Maintenance</div>
          <div className="head">Details</div>
          <div className="item">
            <Input
              type="text"
              onChange={(e) => handleFilter(e.target.value)}
              placeholder="Search"
            />
            <p
              className="create-new"
              onClick={() => {
                setTask(EMPTY_TASK_STATE);
                setSelectedMachine(null);
              }}
            >
              + Create new
            </p>
            <div style={{ maxHeight: 580, overflowY: 'auto' }}>
              {filteredMaintenances?.map((item) => {
                return (
                  <p
                    className={item.id === task.id ? 'selected' : ''}
                    onClick={() => handleTaskSelect(item)}
                  >
                    <div className="small text-muted">
                      {item.machine.description} &gt; {item.assembly}
                    </div>
                    {item.task}
                  </p>
                );
              })}
            </div>
          </div>
          <div className="item px-3">
            <Form>
              <FormGroup row>
                <Label for="task-name" sm={2}>
                  Name
                </Label>
                <Col sm={10}>
                  <Input
                    type="text"
                    id="task-name"
                    placeholder="Task name"
                    name="data[task]"
                    value={task.task}
                    onChange={(e) => setTask({ ...task, task: e.target.value })}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="machine-name" sm={2}>
                  Machine
                </Label>
                <Col sm={10}>
                  <MachineSelectionInput
                    data={selectedMachine}
                    allMachines={machines}
                    handleMachineSelect={handleMachineSelect}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="assembly-name" sm={2}>
                  Assembly
                </Label>
                <Col sm={10}>
                  <CreatableSelect
                    value={getDefaultSelectedAssembly()}
                    name="data[assembly]"
                    options={assembliesOptions}
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={handleAssemblySelect}
                    onCreateOption={handleAssemblyCreate}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="checkbox2" sm={2}>
                  For this variant
                </Label>
                <Col sm={{ size: 10 }}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        id="checkbox2"
                        checked={task['forThisVariant']}
                        name="data[forThisVariant]"
                        onChange={(e) =>
                          setTask({ ...task, forThisVariant: e.target.checked })
                        }
                      />{' '}
                    </Label>
                  </FormGroup>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="frequency" sm={2}>
                  Frequency
                </Label>
                <Col sm={10}>
                  {/* <Input
                    type="text"
                    name="data[frequency]"
                    value={task.frequency}
                    onChange={(e) =>
                      setTask({ ...task, frequency: e.target.value })
                    }
                    id="frequency"
                    placeholder="frequency"
                  /> */}
                  {task.frequency.map((item, index) => (
                    <FrequencyInput
                      data={item}
                      index={index}
                      handleFrequencyRemove={handleFrequencyRemove}
                      handleFrequencyChange={handleFrequencyChange}
                      canDelete={task.frequency.length > 1}
                    />
                  ))}
                  <StyledButton type="button" onClick={handleFrequencyAdd}>
                    Add another frequency
                  </StyledButton>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="instructions" sm={2}>
                  Instructions
                </Label>
                <Col sm={10}>
                  <Input
                    type="file"
                    name="instructions"
                    id="instructions"
                    accept="application/pdf"
                    onChange={handleFileSelect}
                    multiple
                  />
                  <FormText color="muted">
                    Upload instructions in .PDF file
                  </FormText>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="method" sm={2}>
                  Method
                </Label>
                <Col sm={10}>
                  <Select
                    value={METHOD_OPTIONS.filter((item) =>
                      task.method?.includes(item.label)
                    )}
                    isMulti
                    name="data[method]"
                    options={METHOD_OPTIONS}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleMethodSelect}
                  />
                  {/* <Input
                    type="text"
                    value={task.method}
                   
                    id="method"
                    placeholder="method"
                  /> */}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="personelLevel" sm={2}>
                  Personel level
                </Label>
                <Col sm={10}>
                  <Select
                    value={PERSONEL_LEVEL_OPTIONS.find(
                      (item) => item.value === task.personelLevel
                    )}
                    name="data[personelLevel]"
                    options={PERSONEL_LEVEL_OPTIONS}
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={handlePersonelLevelSelect}
                  />
                  {/* <Input
                    type="text"
                    name="data[personelLevel]"
                    value={task.personelLevel}
                    onChange={(e) =>
                      setTask({ ...task, personelLevel: e.target.value })
                    }
                    id="personelLevel"
                    placeholder="personelLevel"
                  /> */}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="parts" sm={2}>
                  Parts
                </Label>
                <Col sm={10}>
                  <PartsInput
                    data={task.parts}
                    handlePartsChange={handlePartsChange}
                  />
                  {/* <Input
                    type="text"
                    name="data[parts]"
                    value={task.parts}
                    onChange={(e) =>
                      setTask({ ...task, parts: e.target.value })
                    }
                    id="parts"
                    placeholder="parts"
                  /> */}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={{ size: 10, offset: 2 }}>
                  <StyledButton
                    type="button"
                    color="success"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Apply changes
                  </StyledButton>
                </Col>
              </FormGroup>
            </Form>
          </div>
        </StyledAdminGrid>
      </CardBody>
    </Card>
  );
};

export default AdminPage;
