import React from 'react';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
const DeleteConfirmationModal = ({ text, handleDelete, toggle, isOpen }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} contentClassName="shadow-lg">
      <ModalBody className="text-center">
        <div className="font-weight-bold">Delete {text}?</div>
        <div className="text-muted">You will not be able to recover it.</div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggle}>
          Cancel
        </Button>
        <Button color="danger" onClick={handleDelete}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteConfirmationModal;
