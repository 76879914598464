import React, { useState } from 'react';
import { IoIosDocument } from 'react-icons/io';
//import tmpDocuments from './tmpDocuments.json'
import { FiEdit } from 'react-icons/fi';

const Documents = ({ data, toggleWithData }) => {
  const getContent = () => {
    const header = `Documents - ${data.description}`;
    const body = <DocumentList data={data.manuals} />;
    const bodyProps = { className: 'p-0' };
    const modalData = { header, body, bodyProps };
    toggleWithData(modalData);
  };

  return (
    <div
      style={{ cursor: 'pointer', padding: '0.5rem 1rem' }}
      onClick={getContent}
    >
      <IoIosDocument style={{ fontSize: '1.25rem' }} />
      <br />
      Docs
    </div>
  );
};

const DocumentList = ({ data }) => {
  // const categories = [...new Set(data.map((item) => item.category))]; // create array without duplicates
  const categories = Object.keys(data);
  return (
    <dl>
      {categories.map((category) => (
        <React.Fragment>
          <dt key={category} className="pl-3 mb-1 bg-light">
            {category.toUpperCase()}
          </dt>
          {data[category].map((document, index) => (
            <dd className="pl-3" key={index}>
              <a
                href={document.fileUrl}
                className="text-info"
                target="_blank"
                rel="noopener noreferrer"
              >
                {document.title}
              </a>
              {/* <a
                  className="pl-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`http://${process.env.REACT_APP_IP}/docs/wp-admin/post.php?post=${document.id}&action=edit`}
                >
                  | <FiEdit /> Edit
                </a> */}
            </dd>
          ))}
        </React.Fragment>
      ))}
    </dl>
  );
};

export default Documents;
export { DocumentList };
