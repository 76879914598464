import React from 'react';
import { Row, Col } from 'reactstrap';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import { getColorScheme } from '../../../../lib/helpers';

const Consumption = ({ data }) => {
  const {
    mixers_part_kwh,
    entry_part_kwh,
    main_part_kwh,
    oven_part_kwh,
    recuperation_kwh,
    oven_gas_m3,
    main_part_steam_kg,
    oven_steam_kg,
    compressed_air_m3,
    energy_weight_total_kg,
  } = data;

  const allWeightedMassInTonnes = Number(energy_weight_total_kg) / 1000;
  const perTonne = (value) =>
    (Number(value) / allWeightedMassInTonnes).toFixed(2);
  const valueStyle = {
    fontWeight: 'bold',
    textAlign: 'right',
    width: 'calc(100% / 3)',
  };

  const colorScheme = getColorScheme();
  const options = {
    legend: {
      orient: 'vertical',
      left: 0,
      top: 25,
      data: ['Mixers', 'Entry', 'Main', 'Oven'],
    },
    grid: {
      height: 150,
    },
    series: [
      {
        name: 'Mass',
        type: 'pie',
        data: [
          {
            value: Number(mixers_part_kwh),
            name: 'Mixers',
            itemStyle: { color: colorScheme.other[4]._100 },
          },
          {
            value: Number(entry_part_kwh),
            name: 'Entry',
            itemStyle: { color: colorScheme.other[4]._80 },
          },
          {
            value: Number(main_part_kwh),
            name: 'Main',
            itemStyle: { color: colorScheme.other[4]._60 },
          },
          {
            value: Number(oven_part_kwh),
            name: 'Oven',
            itemStyle: { color: colorScheme.other[4]._40 },
          },
        ],
        silent: true,
        height: 150,
        left: 100,
        labelLine: {
          show: false,
        },
        label: {
          show: false,
        },
      },
    ],
  };
  const cellsStyle = {
    boxSizing: 'border-box',
    flexGrow: 1,
    width: '33.33%',
    padding: '0',
    overflow: 'hidden', // Or flex might break
    listStyle: 'none',
  };
  return (
    <React.Fragment>
      <div className="mb-1 border-top font-weight-bold">Consumption</div>
      <div
        style={{
          display: 'flex',
          //justifyContent: "space-between",
          flexWrap: 'wrap',
        }}
      >
        <div style={{ minWidth: 250, maxWidth: 500, width: '100%' }}>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              margin: '0 0 1em 0',
              padding: 0,
            }}
          >
            <span style={cellsStyle}>Electricity</span>
            <span
              style={{ ...cellsStyle, textAlign: 'right', fontWeight: 500 }}
            >
              {(
                Number(mixers_part_kwh) +
                Number(entry_part_kwh) +
                Number(main_part_kwh) +
                Number(oven_part_kwh)
              ).toFixed(2) || '-'}{' '}
              kWh
            </span>
            <span
              style={{ ...cellsStyle, textAlign: 'right', fontWeight: 500 }}
            >
              {perTonne(
                Number(mixers_part_kwh) +
                  Number(entry_part_kwh) +
                  Number(main_part_kwh) +
                  Number(oven_part_kwh)
              ) || '-'}{' '}
              kWh/t
            </span>

            <span style={{ ...cellsStyle, paddingLeft: '1em' }}>Mixers</span>
            <span
              style={{ ...cellsStyle, textAlign: 'right', fontWeight: 500 }}
            >
              {Number(mixers_part_kwh).toFixed(2) || '-'} kWh
            </span>
            <span
              style={{ ...cellsStyle, textAlign: 'right', fontWeight: 500 }}
            >
              {perTonne(mixers_part_kwh) || '-'} kWh/t
            </span>

            <span style={{ ...cellsStyle, paddingLeft: '1em' }}>Entry</span>
            <span
              style={{ ...cellsStyle, textAlign: 'right', fontWeight: 500 }}
            >
              {Number(entry_part_kwh).toFixed(2) || '-'} kWh
            </span>
            <span
              style={{ ...cellsStyle, textAlign: 'right', fontWeight: 500 }}
            >
              {perTonne(entry_part_kwh) || '-'} kWh/t
            </span>

            <span style={{ ...cellsStyle, paddingLeft: '1em' }}>Main</span>
            <span
              style={{ ...cellsStyle, textAlign: 'right', fontWeight: 500 }}
            >
              {Number(main_part_kwh).toFixed(2) || '-'} kWh
            </span>
            <span
              style={{ ...cellsStyle, textAlign: 'right', fontWeight: 500 }}
            >
              {perTonne(main_part_kwh) || '-'} kWh/t
            </span>

            <span style={{ ...cellsStyle, paddingLeft: '1em' }}>Oven</span>
            <span
              style={{ ...cellsStyle, textAlign: 'right', fontWeight: 500 }}
            >
              {Number(oven_part_kwh).toFixed(2) || '-'} kWh
            </span>
            <span
              style={{ ...cellsStyle, textAlign: 'right', fontWeight: 500 }}
            >
              {perTonne(oven_part_kwh) || '-'} kWh/t
            </span>

            <span style={cellsStyle}>Recuperated Energy</span>
            <span
              style={{
                ...cellsStyle,
                textAlign: 'right',
                fontWeight: 500,
                color: colorScheme.main.success._100,
              }}
            >
              {Number(recuperation_kwh).toFixed(2) || '-'} kWh
            </span>
            <span
              style={{
                ...cellsStyle,
                textAlign: 'right',
                fontWeight: 500,
                color: colorScheme.main.success._100,
              }}
            >
              {perTonne(recuperation_kwh) || '-'} kWh/t
            </span>
          </div>
        </div>
        <div style={{ minWidth: 250, maxWidth: '25%', width: '100%' }}>
          <ReactEchartsCore
            style={{ height: 150, paddingLeft: '1em' }}
            echarts={echarts}
            option={options}
          />
        </div>
        <div style={{ minWidth: 250, maxWidth: 500, width: '100%' }}>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              margin: '0 0 1em 0',
              padding: 0,
            }}
          >
            <span style={cellsStyle}>Steam</span>
            <span
              style={{ ...cellsStyle, textAlign: 'right', fontWeight: 500 }}
            >
              {(Number(main_part_steam_kg) + Number(oven_steam_kg)).toFixed(
                2
              ) || '-'}{' '}
              kg
            </span>
            <span
              style={{ ...cellsStyle, textAlign: 'right', fontWeight: 500 }}
            >
              {perTonne(Number(main_part_steam_kg) + Number(oven_steam_kg)) ||
                '-'}{' '}
              kg/t{' '}
            </span>

            <span style={{ ...cellsStyle, paddingLeft: '1em' }}>Main</span>
            <span
              style={{ ...cellsStyle, textAlign: 'right', fontWeight: 500 }}
            >
              {Number(main_part_steam_kg)
                ? Number(main_part_steam_kg).toFixed(2)
                : '-'}{' '}
              kg
            </span>
            <span
              style={{ ...cellsStyle, textAlign: 'right', fontWeight: 500 }}
            >
              {perTonne(main_part_steam_kg) || '-'} kg/t{' '}
            </span>

            <span style={{ ...cellsStyle, paddingLeft: '1em' }}>Oven</span>
            <span
              style={{ ...cellsStyle, textAlign: 'right', fontWeight: 500 }}
            >
              {Number(oven_steam_kg) ? Number(oven_steam_kg).toFixed(2) : '-'}{' '}
              kg
            </span>
            <span
              style={{ ...cellsStyle, textAlign: 'right', fontWeight: 500 }}
            >
              {Number(oven_steam_kg) ? perTonne(oven_steam_kg) : '-'} kg/t{' '}
            </span>

            <span style={cellsStyle}>Gas</span>
            <span
              style={{ ...cellsStyle, textAlign: 'right', fontWeight: 500 }}
            >
              {Number(oven_gas_m3) ? Number(oven_gas_m3).toFixed(2) : '-'} m
              <sup>3</sup>
            </span>
            <span
              style={{ ...cellsStyle, textAlign: 'right', fontWeight: 500 }}
            >
              {Number(oven_gas_m3) ? perTonne(oven_gas_m3) : '-'} m<sup>3</sup>
              /t{' '}
            </span>

            <span style={cellsStyle}>Compressed air</span>
            <span
              style={{ ...cellsStyle, textAlign: 'right', fontWeight: 500 }}
            >
              {Number(compressed_air_m3)
                ? Number(compressed_air_m3).toFixed(2)
                : '-'}{' '}
              m<sup>3</sup>
            </span>
            <span
              style={{ ...cellsStyle, textAlign: 'right', fontWeight: 500 }}
            >
              {perTonne(compressed_air_m3) || '-'} m<sup>3</sup>/t{' '}
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Consumption;
