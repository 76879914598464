import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Button,
} from 'reactstrap';
import Select from 'react-select';
import { useStore } from '../../../store';
import { getColorScheme } from '../../../lib/helpers';

const AlarmForm = ({ toggle, isOpen, data, handleCreate, handleEdit }) => {
  const { state } = useStore();
  const [alarm, setAlarm] = useState(data);
  const [tmpProjectIds, setTmpProjectIds] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const colorScheme = getColorScheme();
  useEffect(() => {
    setAlarm(data);
    setProjectOptions(generateProjectOptions);
    data.action === 'create'
      ? setTmpProjectIds(generateProjectOptions)
      : setTmpProjectIds(generateTmpProjectIds);
  }, [data]);

  const generateProjectOptions = () => {
    return state.userProjects.map((project) => {
      return {
        value: project.project_id,
        label: `${project.project_id} - ${project.project_name}`,
      };
    });
  };

  const generateTmpProjectIds = () => {
    if (!data.project_ids) return [];
    const projectIdsArr = data.project_ids
      .slice(1, data.project_ids.length - 1)
      .split(',');
    return projectOptions.filter((project) =>
      projectIdsArr.includes(project.value)
    );
  };

  const handleSubmitForm = () => {
    data.action === 'create' && handleCreate(alarm);
    data.action === 'edit' && handleEdit(alarm);
  };

  const handleCancelForm = () => {
    setAlarm({});
    toggle();
  };

  const handleChange = (selectedOption) => {
    const project_ids = `{${selectedOption.map((item) => item.value)}}`;
    setAlarm({ ...alarm, project_ids });
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle}>
        {data.action === 'create'
          ? 'Create'
          : data.action === 'edit'
          ? 'Edit'
          : ''}{' '}
        Alarm
      </ModalHeader>
      <ModalBody>
        <Form autoComplete="off">
          <FormGroup>
            <Label for="Description">Description</Label>
            <Input
              name="alarm[description]"
              value={alarm.description}
              onChange={(e) =>
                setAlarm({ ...alarm, description: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="color">Color</Label>
            <Input
              id="color"
              type="color"
              list="presetColors"
              name="alarm[color]"
              value={alarm.color}
              onChange={(e) => setAlarm({ ...alarm, color: e.target.value })}
            />
            <datalist id="presetColors">
              {colorScheme.other.map((color) => (
                <option key={color._100}>{color._100}</option>
              ))}
              <option>{colorScheme.main.success._100}</option>
              <option>{colorScheme.main.warning._100}</option>
              <option>{colorScheme.main.danger._100}</option>
            </datalist>
          </FormGroup>
          <FormGroup>
            <Label for="Projects">Projects</Label>
            <Select
              isMulti
              defaultValue={tmpProjectIds}
              name="alarm[project_ids]"
              options={projectOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleChange}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleCancelForm} color="light">
          Cancel
        </Button>
        <Button
          className="text-capitalize"
          onClick={handleSubmitForm}
          color="primary"
        >
          {data.action === 'edit' ? 'Confirm changes' : data.action}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AlarmForm;
