import { DateTime } from 'luxon';
import React from 'react';
import { FiDownload, FiEye, FiFile, FiImage, FiVideo } from 'react-icons/fi';
import styled from 'styled-components';

const StyledAttachmentGrid = styled.div`
  overflow-y: auto;
  display: grid;
  padding: 1em;
  grid-template-columns:
    1fr
    fit-content(200px)
    /* fit-content(100px) */
    fit-content(100px);
  /* row-gap: 0.5em; */
  /* column-gap: 2em; */

  .combine {
    display: flex;
    gap: 0.2em;
    padding: 0.1em 1em;
    .size {
      color: ${({ theme }) => theme.colors.grayscale._40};
    }
  }
`;
const StyledAttachment = styled.a`
  cursor: pointer;
  display: contents;
  &:hover > div {
    background: ${({ theme }) => theme.colors.grayscale._20};
  }
`;

const AttachmentGrid = ({ data }) => {
  if (!data) return null;
  console.log('AttachmentGrid: ', { data });
  const data1 = [...data, ...data, ...data, ...data, ...data, ...data, ...data];
  return (
    <StyledAttachmentGrid>
      {data.map((attachment) => (
        <StyledAttachment
          href={attachment.fileURL}
          target="_blank"
          rel="noreferrer"
        >
          <div className="combine">
            <div className="icon">
              {attachment.type.indexOf('image') > -1 ? (
                <FiImage />
              ) : attachment.type.indexOf('video') > -1 ? (
                <FiVideo />
              ) : (
                <FiFile />
              )}
            </div>
            {attachment.name}
            <div className="size">
              (
              {Intl.NumberFormat(window.navigator.language).format(
                Math.ceil(Number(attachment.size) / 1024)
              )}
              K)
            </div>
          </div>
          <div className="px-3">{attachment.created_by}</div>
          <div className="px-3">
            {DateTime.fromSeconds(attachment.created_at.seconds).toFormat(
              'dd.LL.yyyy'
            )}
          </div>
          {/* <div className="combine">
            <a href={attachment.fileURL} target="_blank" rel="noreferrer">
              <FiEye />
            </a>

            <a
              href={attachment.fileURL}
              target="_blank"
              rel="noreferrer"
              download
            >
              <FiDownload />
            </a>
          </div> */}
        </StyledAttachment>
      ))}
    </StyledAttachmentGrid>
  );
};

export default AttachmentGrid;
