import React from 'react';
import EnergyRoelandt from './EnergyRoelandt';
import EnergyRest from './EnergyRest';
import { useStore } from '../../../store';

const Energy = (props) => {
  const { state } = useStore();
  return state.selectedProject.project_id === 'T02606' ? (
    <EnergyRoelandt />
  ) : (
    <EnergyRest />
  );
};

export default Energy;
