export const getMachineSequence = async () => {
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'sequence',
      funcName: 'project.project_machine_id_seq',
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => data.nextval);
};

export const getMachinesState = async (iproject_id) => {
  const params = { iproject_id };
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'select',
      funcName: 'project.mas_machine_state',
      inputParameters: params,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((response) => {
      const states = response || [];
      return {
        states,
        loading: false,
      };
    })
    .catch((error) => ({ error }));
};

export const getMachines = async (iproject_id) => {
  const params = { iproject_id };
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'select',
      funcName: 'project.project_machine_s',
      inputParameters: params,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((response) => {
      const machines = response || [];
      return {
        machines,
        loading: false,
      };
    });
};

export const insertMachine = async (data) => {
  const { action, selected, ...params } = data;
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'insert',
      funcName: 'project.project_machine_i',
      inputParameters: params,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => ({
      error: err,
    }));
};

export const updateMachine = async (data) => {
  const { action, selected, ...params } = data;
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'update',
      funcName: 'project.project_machine_u',
      inputParameters: params,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => ({
      error: err,
    }));
};

export const deleteMachine = async (project_machine_id) => {
  const params = { project_machine_id };
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'delete',
      funcName: 'project.project_machine_d',
      inputParameters: params,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => ({
      error: err,
    }));
};
