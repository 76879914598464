import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/chart/custom.js';
import { generateDurationString } from '../../Diagnostics/helpers';
import NoContent from '../../../components/NoContent/NoContent';
import { getColorScheme } from '../../../lib/helpers';
import styled from 'styled-components';

const StyledLegend = styled.div`
  display: flex;
  gap: 0.5em;
  font-size: 0.75em;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1em;
`;
const StyledLegendSquare = styled.div`
  width: 10px;
  height: 10px;
  background: ${({ theme, color }) => theme.colors.main[color]._100};
`;
const StyledLegendGrid = styled.div`
  display: grid;
  grid-template-columns:
    fit-content(200px) fit-content(100px) fit-content(100px)
    fit-content(100px);
  grid-column-gap: 10px;
  padding: 0 1em;
`;
const StyledGridItem = styled.div`
  display: flex;
  gap: 0.5em;
  font-size: 0.75em;
  align-items: center;
  justify-content: flex-start;
`;
const AvailabilityTimeline2 = ({
  data: { loading, timelineAVA2, reports2 },
  dataNew,
  zoom,
  gridLeft,
  zoomSync,
  setZoomSync,
}) => {
  const [events, setEvents] = useState({});
  useEffect(() => {
    zoomSync &&
      setEvents({
        dataZoom: (params) => {
          const { start, end } = params.batch ? params.batch[0] : params;
          setZoomSync([start, end]);
        },
      });
  }, []);
  const colorScheme = getColorScheme();
  const formattedTimelineDataStops =
    dataNew
      ?.map((item) =>
        item?.log?.divider?.state_changes?.map((stop) => ({
          name: stop.state,
          id: null,
          value: [Number(stop.begin_at.ts), Number(stop.end_at.ts)],
          itemStyle: {
            normal: {
              color:
                stop.state === 'STOP'
                  ? colorScheme.main.danger._100
                  : colorScheme.main.success._100,
            },
          },
        }))
      )
      .flat() || [];
  // const formattedTimelineDataStops = timelineAVA2
  //   .filter((item) => Number(item.series_nr) === 2)
  //   .map((item) => ({
  //     name: item.description,
  //     id: null,
  //     value: [Number(item.time_from_ms), Number(item.time_to_ms)],
  //     itemStyle: {
  //       normal: {
  //         color: item.color,
  //       },
  //     },
  //   }));

  const formattedTimelineDataAlarms =
    dataNew
      ?.map((item) =>
        item?.log?.alarms?.map((alarm) => ({
          name: alarm?.info?.alarm_text,
          id: null,
          value: [Number(alarm.start.ts), Number(alarm.end.ts)],
          itemStyle: {
            normal: {
              color:
                alarm.info.almtype === 'Alarm'
                  ? colorScheme.main.danger._100
                  : alarm.info.almtype === 'Warning'
                  ? colorScheme.main.warning._100
                  : colorScheme.main.info._100,
            },
          },
        }))
      )
      .flat() || [];
  // const formattedTimelineDataAlarms = timelineAVA2
  //   .filter((item) => Number(item.series_nr) === 3)
  //   .map((item) => ({
  //     name: item.description,
  //     id: null,
  //     value: [Number(item.time_from_ms), Number(item.time_to_ms)],
  //     itemStyle: {
  //       normal: {
  //         color: item.color, //+ "4d"
  //       },
  //     },
  //   }));

  const formattedLotData =
    dataNew?.map((item) => ({
      name: item.recipe.name,
      id: Number(item.lot.nr),
      value: [Number(item.lot.start.ts), Number(item.lot.end.ts)],
      itemStyle: {
        normal: {
          color:
            colorScheme.other[Number(item.lot.nr) % colorScheme.other.length]
              ._60,
        },
      },
    })) || [];
  // const formattedLotData = reports2.map((item) => ({
  //   name: item.rec_name,
  //   id: Number(item.rec_batch_nr),
  //   value: [Number(item.rec_batch_start), Number(item.rec_batch_end)],
  //   itemStyle: {
  //     normal: {
  //       color:
  //         colorScheme.other[
  //           Number(item.rec_batch_nr) % colorScheme.other.length
  //         ]._60,
  //     },
  //   },
  // }));

  const renderTooltip = ({ name, value, data }) => {
    if (!value) return '';

    const [start, end] = value;
    const duration = generateDurationString(end - start);
    const newDateStart = new Date(start);
    const newDateEnd = new Date(end);
    const lotId = data.id ? `#${data.id} ` : '';
    const startString =
      ('0' + newDateStart.getDate()).slice(-2) +
      '.' +
      ('0' + (newDateStart.getMonth() + 1)).slice(-2) +
      ', ' +
      ('0' + newDateStart.getHours()).slice(-2) +
      ':' +
      ('0' + newDateStart.getMinutes()).slice(-2) +
      ':' +
      ('0' + newDateStart.getSeconds()).slice(-2);

    const endString =
      ('0' + newDateEnd.getDate()).slice(-2) +
      '.' +
      ('0' + (newDateEnd.getMonth() + 1)).slice(-2) +
      ', ' +
      ('0' + newDateEnd.getHours()).slice(-2) +
      ':' +
      ('0' + newDateEnd.getMinutes()).slice(-2) +
      ':' +
      ('0' + newDateEnd.getSeconds()).slice(-2);

    return `
            <strong style="text-transform: capitalize"> ${lotId} ${name} </strong> <br />
            Duration: <strong>${duration}</strong> <br />
            ${startString} - ${endString} 
        `;
  };

  function renderItems(params, api) {
    var start = api.coord([api.value(0), 1]);
    var end = api.coord([api.value(1), 1]);
    var height = api.size([0, 1])[1] * 0.9;
    var rectShape = echarts.graphic.clipRectByRect(
      {
        x: start[0],
        y: start[1] - height / 2,
        width: end[0] - start[0],
        height: height,
      },
      {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height,
      }
    );

    return (
      rectShape && {
        type: 'rect',
        shape: { ...rectShape },
        style: api.style(),
      }
    );
  }

  function renderItemsAlarms(params, api) {
    var start = api.coord([api.value(0), 0]);
    var end = api.coord([api.value(1), 0]);
    var height = api.size([0, 1])[1] * 0.9;
    var rectShape = echarts.graphic.clipRectByRect(
      {
        x: start[0],
        y: start[1] - height / 2,
        width: end[0] - start[0],
        height: height,
      },
      {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height,
      }
    );

    return (
      rectShape && {
        type: 'rect',
        shape: { ...rectShape },
        style: api.style(),
      }
    );
  }

  function renderLotItems(params, api) {
    var start = api.coord([api.value(0), 2]);
    var end = api.coord([api.value(1), 2]);
    var height = api.size([0, 1])[1] * 0.9;
    var rectShape = echarts.graphic.clipRectByRect(
      {
        x: start[0],
        y: start[1] - height / 2,
        width: end[0] - start[0],
        height: height,
      },
      {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height,
      }
    );

    return (
      rectShape && {
        type: 'rect',
        shape: { ...rectShape },
        style: api.style(),
      }
    );
  }

  const option = {
    tooltip: {
      formatter: (params) => renderTooltip(params),
    },
    legend: {
      data: [
        'Cleaning',
        'Minor stop',
        'Preparing',
        'Running',
        'Unplanned stop',
        'Waiting',
      ],
    },
    dataZoom: zoom && [
      {
        type: 'slider',
        filterMode: 'weakFilter',
        showDataShadow: false,
        top: 105,
        height: 10,
        handleIcon:
          'M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z', // jshint ignore:line
        handleSize: 20,
        labelFormatter: '',
        start: zoomSync ? zoomSync[0] : 0,
        end: zoomSync ? zoomSync[1] : 100,
      },
      {
        type: 'inside',
        filterMode: 'weakFilter',
      },
    ],
    grid: {
      height: 70,
      top: 0,
      bottom: 0,
      left: gridLeft || 20,
      right: 20,
    },
    xAxis: {
      scale: true,
      show: true,
      type: 'time',
      max: function (value) {
        // console.log({ value, formattedTimelineDataStops });
        const max = formattedTimelineDataStops?.length
          ? formattedTimelineDataStops[formattedTimelineDataStops.length - 1]
              ?.value[1]
          : value.max;
        return max;
      },
      splitLine: {
        //show:false
      },
    },
    yAxis: {
      data: ['Alarm status', 'Process status', 'Lots'],
      show: true,
    },
    series: [
      {
        type: 'custom',
        renderItem: renderItems,
        data: formattedTimelineDataStops,
      },
      {
        type: 'custom',
        renderItem: renderLotItems,
        data: formattedLotData,
        //silent: true
      },
      {
        type: 'custom',
        renderItem: renderItemsAlarms,
        data: formattedTimelineDataAlarms,
      },
    ],
  };

  return loading ? (
    <Skeleton height={148} className="mb-3" />
  ) : (
    <Card className="mb-3">
      <CardHeader>Production timeline</CardHeader>
      <CardBody className="p-0 pb-3">
        {!dataNew || !dataNew.length ? (
          <NoContent title="No data" style={{ height: 'auto' }} />
        ) : (
          <>
            <ReactEchartsCore
              echarts={echarts}
              onEvents={events}
              style={{ height: 120 }}
              option={option}
            />

            <StyledLegendGrid>
              <StyledGridItem>Legend Process Status:</StyledGridItem>
              <StyledGridItem>
                <StyledLegendSquare color="danger" />
                Stop
              </StyledGridItem>
              <StyledGridItem>
                {' '}
                <StyledLegendSquare color="success" />
                Run
              </StyledGridItem>
              <StyledGridItem> </StyledGridItem>
              <StyledGridItem>Legend Alarms Status:</StyledGridItem>
              <StyledGridItem>
                <StyledLegendSquare color="danger" />
                Alarm
              </StyledGridItem>
              <StyledGridItem>
                {' '}
                <StyledLegendSquare color="warning" />
                Warning
              </StyledGridItem>
              <StyledGridItem>
                <StyledLegendSquare color="info" />
                Info
              </StyledGridItem>
            </StyledLegendGrid>
          </>
        )}
        {/* {timelineAVA2.length === 0 ? (
          <NoContent title="No data" style={{ height: 'auto' }} />
        ) : (
          <ReactEchartsCore
            echarts={echarts}
            onEvents={events}
            style={{ height: 120 }}
            option={option}
          />
        )} */}
      </CardBody>
    </Card>
  );
};

export default AvailabilityTimeline2;
