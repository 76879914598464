import React, { useEffect, useState } from 'react';
import { FiBookOpen, FiCheckSquare, FiShoppingCart } from 'react-icons/fi';
import TooltipWrapper from '../../../../../components/TooltipWrapper';
import { Badge, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import NoContent from '../../../../../components/NoContent';
import DateSelection, {
  StyledButton,
} from '../../../../../components/DateSelection/DateSelection';
import firebase from 'firebase';
import 'firebase/firestore';
import AgendaItem from './AgendaItem';
import { getIcon } from './helpers';
import Pagination, { sliceDataForPage } from './Pagination';
import { DateTime } from 'luxon';
import SingleStat, {
  SingleStatsContainer,
} from '../../../../../components/SingleStat';
import { useToggler } from '../../../../../lib/helpers/customHooks';
import OrderModal from './OrderModal';

const Agenda = ({ data, hideOverdue, isLog }) => {
  const [isTaskDetailOpen, toggleTaskDetail] = useToggler(false);
  const [taskSelected, setTaskSelected] = useState(null);
  const [dataOnPage, setDataOnPage] = useState(sliceDataForPage(data, 0));

  const handleTaskSelect = (data) => {
    setTaskSelected(data);
    toggleTaskDetail();
  };

  useEffect(() => setDataOnPage(sliceDataForPage(data, 0)), [data]);

  return (
    <>
      <div style={{ overflowY: 'auto', maxHeight: 750, minHeight: 409 }}>
        {!dataOnPage?.length ? (
          <NoContent title="No Tasks" />
        ) : (
          <>
            <SingleStatsContainer>
              {!hideOverdue && (
                <SingleStat
                  label="Overdue"
                  value={data.filter((item) => item.overdue > 0).length}
                  danger
                />
              )}
              <SingleStat label="Tasks" value={data.length} />
            </SingleStatsContainer>
            <Pagination data={data} handleChangePage={setDataOnPage} />
            {dataOnPage?.map((item, index) => {
              return (
                <AgendaItem
                  key={index}
                  data={item}
                  isLog={isLog}
                  hideOverdue={hideOverdue}
                  eventSelect={handleTaskSelect}
                />
              );
            })}
          </>
        )}
      </div>

      <TaskDetailsModal
        data={taskSelected}
        hideOverdue={hideOverdue}
        isOpen={isTaskDetailOpen}
        toggle={toggleTaskDetail}
      />
    </>
  );
};

const TaskDetailsModal = ({ data, hideOverdue, isOpen, toggle }) => {
  const [dates, setDates] = useState([new Date()]);
  const [comment, setComment] = useState('');
  const [commentValidation, setCommentValidation] = useState(false);
  const [isOrderOpen, toggleOrder] = useToggler(false);
  if (!data) return null;
  const handleMarkAsCompleted = async () => {
    if (!comment) {
      setCommentValidation(true);
      return;
    }

    if (commentValidation) setCommentValidation(false);

    const fsdb = firebase.firestore();
    await fsdb
      .collection('machines')
      .doc(data.machine.id.toString())
      .collection('maintanance_tasks')
      .doc(data.id.toString())
      .set(
        {
          lastCompleted: {
            completedTime: +new Date(dates[0]),
            comment: comment,
          },
        },
        { merge: true }
      );
    toggle();
  };

  return (
    <Modal
      autoFocus={false}
      fade={false}
      isOpen={isOpen}
      toggle={toggle}
      contentClassName="shadow-lg"
      size={'lg'}
    >
      <ModalHeader toggle={toggle}>
        <div className="small text-muted">
          {data.machine.description} &gt; {data.assembly}
        </div>
        <div>{data.title}</div>

        {data?.method.map((method) => (
          <TooltipWrapper content={method} key={method}>
            <Badge color="light" className="mr-1" id={`tooltip-${method}`}>
              {getIcon(method)}
            </Badge>
          </TooltipWrapper>
        ))}
      </ModalHeader>
      <ModalBody>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridColumnGap: 10,
            padding: '0 1em',
            marginBottom: '1em',
          }}
        >
          <div>
            <strong>Id</strong>
          </div>
          <div>{data.id}</div>
          <div>
            <strong>Due time</strong>
          </div>
          {data.start && data.overdue && !hideOverdue ? (
            <div style={{ color: 'red' }}>
              {' '}
              {typeof data.start === 'string'
                ? DateTime.fromISO(data.start).toFormat('dd.LL.yyyy, HH:mm')
                : DateTime.fromJSDate(data.start).toFormat(
                    'dd.LL.yyyy, HH:mm'
                  )}{' '}
              - Overdue ({Math.floor(data.overdue)} days)
            </div>
          ) : data.start ? (
            <div>
              {' '}
              {typeof data.start === 'string'
                ? DateTime.fromISO(data.start).toFormat('dd.LL.yyyy, HH:mm')
                : DateTime.fromJSDate(data.start).toFormat(
                    'dd.LL.yyyy, HH:mm'
                  )}{' '}
            </div>
          ) : (
            <div>/</div>
          )}
          <div>
            <strong>Last time completed</strong>
          </div>
          <div>
            {(data.lastCompletedAt &&
              DateTime.fromISO(data.lastCompletedAt).toFormat(
                'dd.LL.yyyy, HH:mm'
              )) ||
              '/'}
          </div>
          <div>
            <strong>Frequency</strong>
          </div>
          <div>
            {data.frequency.map((item, index) => (
              <div key={index}>
                Every {item.interval} {item.unit}
              </div>
            ))}
          </div>
          <div>
            <strong>Personel Level</strong>
          </div>
          <div>{data.personelLevel}</div>
          <div>
            <strong>Parts</strong>
          </div>
          <div>
            {!data.parts.length
              ? '/'
              : data.parts.map((item, index) => (
                  <div key={index}>
                    {item.qty}x {item.name}
                  </div>
                ))}
          </div>
          <div></div>
          <div
            className="mb-2"
            style={{ display: 'flex', flexWrap: 'wrap', gap: '1em' }}
          >
            <a
              style={{ marginTop: '1em' }}
              href={data.document.fileUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledButton>
                <FiBookOpen />
                Instructions
              </StyledButton>
            </a>
            {!!data.parts.length && (
              <>
                <StyledButton
                  onClick={toggleOrder}
                  style={{ marginTop: '1em' }}
                >
                  <FiShoppingCart />
                  Order Parts
                </StyledButton>
                <OrderModal
                  isOpen={isOrderOpen}
                  toggle={toggleOrder}
                  data={data}
                />
              </>
            )}
          </div>
          {/* <div></div>
          <div className="mb-2">
            <StyledButton>
              <FiBookOpen />
              Logs
            </StyledButton>
          </div> */}
        </div>
        <hr />
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridColumnGap: 10,
            padding: '0 1em',
            marginBottom: '1em',
          }}
        >
          <div>
            <strong>Time of completion</strong>
          </div>
          <div className="mb-2">
            <DateSelection
              setDates={setDates}
              selectedDates={dates}
              showTimeInput
            />
          </div>
          <div>
            <strong>Service notes</strong>
          </div>
          <div className="mb-2">
            <Input
              type="textarea"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Add notes"
              required
            />
            {commentValidation && (
              <small style={{ color: 'red' }}>Service notes are required</small>
            )}
          </div>
          <div></div>
          <div className="mb-2">
            <StyledButton color="success" onClick={handleMarkAsCompleted}>
              <FiCheckSquare /> Mark as completed
            </StyledButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default Agenda;
export { TaskDetailsModal };
