import React from 'react';
import Alarms from './views/Alarms';
import { IoWarning } from 'react-icons/io5';

export default {
  title: 'Diagnostics',
  icon: <IoWarning />,
  iconText: 'Diagnostics',
  url: '/diagnostics',
  api: {},
  view: Alarms,
};
