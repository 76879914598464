import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Button,
} from 'reactstrap';

const AssemblyForm = ({ toggle, isOpen, data, handleCreate, handleEdit }) => {
  const [assembly, setAssembly] = useState(data);

  useEffect(() => {
    setAssembly(data);
  }, [data]);

  const handleSubmitForm = () => {
    const {
      productionLine: { project_id },
      machine: { model_nr, serial_nr },
    } = data.selected;
    const tmp = { ...assembly, project_id, model_nr, serial_nr };
    data.action === 'create' && handleCreate(tmp);
    data.action === 'edit' && handleEdit(tmp);
  };

  const handleCancelForm = () => {
    setAssembly({});
    toggle();
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle}>
        {data.action === 'create'
          ? 'Create'
          : data.action === 'edit'
          ? 'Edit'
          : ''}{' '}
        assembly
      </ModalHeader>
      <ModalBody>
        <Form autoComplete="off">
          {/*<FormGroup>
                        <Label for="assemblyIs">ID</Label>
                        <Input
                            disabled
                            type="text"
                            id="assemblyIs"
                            name="assembly[assembly_id]"
                            value={assembly.assembly_id} />
                    </FormGroup>*/}
          <FormGroup>
            <Label for="pos">Ord.No.</Label>
            <Input
              type="text"
              id="pos"
              name="assembly[pos]"
              value={assembly.pos}
              onChange={(e) =>
                setAssembly({ ...assembly, pos: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="Name">Name</Label>
            <Input
              type="text"
              id="Name"
              name="assembly[name]"
              value={assembly.name}
              onChange={(e) =>
                setAssembly({ ...assembly, name: e.target.value })
              }
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleCancelForm} color="light">
          Cancel
        </Button>
        <Button
          className="text-capitalize"
          onClick={handleSubmitForm}
          color="primary"
        >
          {data.action === 'edit' ? 'Confirm changes' : data.action}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AssemblyForm;
