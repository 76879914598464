import React, { useEffect, useState } from 'react';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/component/visualMap';
import 'echarts/lib/chart/line.js';
import Skeleton from 'react-loading-skeleton';
import { getColorScheme } from '../../../../../lib/helpers';
import {
  concatAndSplitArraysFromJSON,
  generateArrayForChart,
} from '../../../helpers';

const GasConsumption = ({
  loading,
  gridLeft,
  zoomSync,
  setZoomSync,
  dataNew,
}) => {
  const [events, setEvents] = useState({});
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    zoomSync &&
      setEvents({
        dataZoom: (params) => {
          const { start, end } = params.batch ? params.batch[0] : params;
          setZoomSync([start, end]);
        },
      });
  }, []);

  useEffect(() => {
    if (!dataNew.length) {
      setChartData({
        gasData: [],
      });
      return null;
    }
    const { date_ts, interval_ms } = dataNew[0];

    const gasArr = concatAndSplitArraysFromJSON(dataNew, 'oven_gas_m3');
    const gasData = generateArrayForChart(gasArr, date_ts, interval_ms);

    setChartData({ gasData });
  }, [dataNew]);

  const colorScheme = getColorScheme();
  const option = {
    grid: {
      left: gridLeft || 50,
      right: 20,
    },
    xAxis: {
      type: 'time',
      boundaryGap: false,
      // data: generatedDate,
      scale: true,
      //type:"time",
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value}m^3',
      },
    },
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: ['Gas'],
    },
    dataZoom: [
      {
        type: 'slider',
        filterMode: 'weakFilter',
        showDataShadow: false,
        top: 455,
        height: 10,
        handleIcon:
          'M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z', // jshint ignore:line
        handleSize: 20,
        labelFormatter: '',
        start: zoomSync ? zoomSync[0] : 0,
        end: zoomSync ? zoomSync[1] : 100,
      },
      {
        type: 'inside',
        filterMode: 'weakFilter',
      },
    ],
    series: [
      {
        type: 'line',
        stack: 'energy',
        sampling: 'average',
        name: 'Gas',
        step: 'end',
        data: chartData.gasData,
        symbol: 'none',
        areaStyle: {},
        itemStyle: {
          color: colorScheme.other[2]._100,
        },
        lineStyle: {
          width: 2,
        },
      },
    ],
  };
  return loading ? (
    <Skeleton height={256} />
  ) : (
    <ReactEchartsCore
      onEvents={events}
      echarts={echarts}
      style={{ height: 480 }}
      option={option}
    />
  );
};

export default GasConsumption;
