export const getProjectUserSequence = async () => {
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'sequence',
      funcName: 'project.project_user_id_seq',
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => data.nextval);
};

export const getProjectUsers = async () => {
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'select',
      funcName: 'project.project_user_s',
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((response) => {
      const users = response || [];
      return {
        users,
        loading: false,
      };
    });
};

export const insertProjectUser = async (data) => {
  const { action, selected, ...params } = data;
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'insert',
      funcName: 'project.project_user_i',
      inputParameters: params,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => ({
      error: err,
    }));
};

export const updateProjectUser = async (data) => {
  const { action, selected, ...params } = data;
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'update',
      funcName: 'project.project_user_u',
      inputParameters: params,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => ({
      error: err,
    }));
};

export const deleteProjectUser = async (data) => {
  const { action, selected, ...params } = data;
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'delete',
      funcName: 'project.project_user_d',
      inputParameters: params,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => ({
      error: err,
    }));
};
