import moment from 'moment';
import React from 'react';
import { Badge } from 'reactstrap';
import styled from 'styled-components';
import TooltipWrapper from '../../../../../components/TooltipWrapper';
import { getIcon } from './helpers';

const StyledAgendaItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5em 1em;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.grayscale._10};
  }

  .item-description {
    display: flex;
    flex-direction: column;
  }
  .item-times {
    text-align: right;
  }
  .small {
    font-size: 0.8em;
    font-weight: 500;
  }
  .muted {
    color: ${({ theme }) => theme.colors.grayscale._40};
  }
  .muted-danger {
    color: ${({ theme }) => theme.colors.main.danger._40};
  }

  .item-overdue {
    color: ${({ theme }) => theme.colors.main.danger._100};
  }
`;

const AgendaItem = ({ data, hideOverdue, eventSelect, isLog }) => {
  return (
    <StyledAgendaItem key={data.id} onClick={() => eventSelect(data)}>
      <div className="item-description">
        <div className="small muted">
          {data.machine.description} &gt; {data.assembly}
        </div>
        <div>{data.title}</div>
        <div>
          <MethodBadges data={data.method} />
        </div>
      </div>
      <div className="item-times">
        {isLog ? (
          <LogItemTimes data={data} />
        ) : hideOverdue ? (
          <HiddenOverdueTimes data={data} />
        ) : (
          <ItemsTimes data={data} />
        )}
      </div>
    </StyledAgendaItem>
  );
};
const LogItemTimes = ({ data }) => (
  <>
    <div>{moment(data.start).format('DD.MM.YYYY')}</div>
    {data.lastCompletedAt && data.overdue && (
      <div className="small muted-danger">
        <div>
          {' '}
          {moment(data.lastCompletedAt).format('DD.MM.YYYY')} ({data.overdue}{' '}
          days)
        </div>
      </div>
    )}
    {data.lastCompletedAt && !data.overdue && (
      <div className="small muted">
        <div> {moment(data.lastCompletedAt).format('DD.MM.YYYY')}</div>
      </div>
    )}
    <div className="small muted">
      Every {data.frequency[0].interval} {data.frequency[0].unit}
    </div>
  </>
);

const HiddenOverdueTimes = ({ data }) => (
  <div className="small muted">
    Every {data.frequency[0].interval} {data.frequency[0].unit}
  </div>
);

const ItemsTimes = ({ data }) => {
  return (
    <>
      {data.overdue && (
        <div className="item-overdue">
          {data.overdue && `Overdue (${data.overdue} days)`}
        </div>
      )}
      {data.start && !data.overdue && (
        <div>{moment(data.start).format('DD.MM.YYYY')}</div>
      )}
      <div className="small muted">
        Every {data.frequency[0].interval} {data.frequency[0].unit}
      </div>
    </>
  );
};

const MethodBadges = ({ data }) => {
  return data?.map((method) => (
    <TooltipWrapper content={method} key={method}>
      <Badge color="light" className="mr-1" id={`tooltip-${method}`}>
        {getIcon(method)}
      </Badge>
    </TooltipWrapper>
  ));
};

export default AgendaItem;
export { MethodBadges };
