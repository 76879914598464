import React, { useState, useEffect } from 'react';
import {
  IoIosBuild,
  IoMdColorFilter,
  IoIosColorFill,
  IoIosFlask,
} from 'react-icons/io';
import TableReact from '../../../../../pages/Settings/TableTemplate';
import { getMaintenance } from '../../../../../pages/Settings/calls/maintenances';
import TooltipWrapper from '../../../../../components/TooltipWrapper';
import {
  Badge,
  Nav,
  NavItem,
  TabPane,
  TabContent,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Table,
  UncontrolledCollapse,
  Label,
  Input,
  FormGroup,
  Alert,
} from 'reactstrap';
import {
  FiEye,
  FiDroplet,
  FiCheckSquare,
  FiBookOpen,
  FiShoppingCart,
  FiChevronDown,
} from 'react-icons/fi';
import { getGroups } from '../../../../../pages/Settings/calls/groups';
import { getParts } from '../../../../../pages/Settings/calls/parts';
import { getInstructions } from '../../../../../pages/Settings/calls/instructions';
import { getColorScheme, getUserDetails } from '../../../../../lib/helpers';
import { toast } from 'react-toastify';
import NotificationBadge from '../../../../../components/NotificationBadge/NotificationBadge';

const Todos = ({ data, toggleWithData }) => {
  console.log('TODOS!!');
  const [isOpen, setIsOpen] = useState(false);
  const [isOrderOpen, setIsOrderOpen] = useState(false);
  const [parts, setParts] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [instructions, setInstructions] = useState([]);
  const [selectedMaintenance, setSelectedMaintenance] = useState('');
  const [comment, setComment] = useState('');

  useEffect(() => {
    getUserDetails().then((data) => setUserDetails(data));
  }, []);
  const colorScheme = getColorScheme();

  const toggle = () => setIsOpen(!isOpen);

  const toggleOrder = () => {
    setIsOrderOpen(!isOrderOpen);
  };

  const orderParts = () => {
    const { name, company_name } = userDetails;
    fetch(process.env.REACT_APP_MAIL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        parts: parts,
        comment: comment,
        user: { name, company_name },
        task: selectedMaintenance,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // if(data.errorCode) handleError(data.errorCode)
      });
    toast.success('Order sent');
    setIsOrderOpen(!isOrderOpen);
  };

  const getIcon = (method) =>
    ({
      Visually: <FiEye />,
      Tools: <IoIosBuild />,
      Water: <FiDroplet />,
      Grease: <IoMdColorFilter />,
      Oil: <IoIosColorFill />,
      Detergent: <IoIosFlask />,
    }[method]);

  const handleMarkAsComplete = (id) => '';
  const handleInstructions = (maintenance) => {
    getInstructionsData(
      maintenance.machine_maintanance_id,
      maintenance.task_id
    );
    setSelectedMaintenance(maintenance);
    toggle();
  };

  const getInstructionsData = async (imachine_maintanance_id, itask_id) => {
    const resultParts = await getParts({ imachine_maintanance_id });
    setParts(resultParts.parts);
    const resultInstructionsData = await getInstructions({
      imaintanance_id: imachine_maintanance_id,
      itask_id,
    });
    setInstructions(resultInstructionsData.instructions);
  };

  const getContent = async () => {
    const { project_id, model_nr, machine_id: serial_nr, description } = data;
    const result = await getMaintenance({
      project_id,
      model_nr,
      serial_nr,
      assembly_id: null,
    });
    const header = description;
    const columns = [
      {
        id: 'assembly_name',
        Header: 'Assembly',
        accessor: 'assembly_name',
      },
      {
        id: 'task_name',
        Header: 'Name',
        accessor: 'task_name',
      },
      {
        id: 'frequency',
        Header: 'Interval',
        accessor: 'frequency',
      },
      {
        id: 'personel_level_description',
        Header: 'Personel level',
        accessor: 'personel_level_description',
      },
      {
        id: 'estimated_task_duration',
        Header: 'Est. dur.',
        accessor: 'estimated_task_duration',
        width: 30,
        style: { textAlign: 'center' },
        Cell: (row) => (row.value ? row.value + ' min' : '-'),
      },
      {
        id: 'operation_method',
        Header: 'Op. method',
        style: { textAlign: 'center' },
        width: 170,
        accessor: 'operation_method',
        Cell: (row) =>
          row.value &&
          row.value.split(',').map((method) => (
            <TooltipWrapper content={method} key={method}>
              <Badge color="light" className="mr-1" id={`tooltip-${method}`}>
                {getIcon(method)}
              </Badge>
            </TooltipWrapper>
          )),
      },
      {
        id: 'action',
        Header: 'Action',
        accessor: 'task_id',
        style: { textAlign: 'center' },
        Cell: ({ row }) => (
          <React.Fragment>
            <TooltipWrapper content="Instructions">
              <Badge
                size="sm"
                className="mr-1"
                style={{ cursor: 'pointer' }}
                color="light"
                onClick={() => handleInstructions(row.original)}
              >
                <FiBookOpen />{' '}
              </Badge>
            </TooltipWrapper>
            <TooltipWrapper content="Mark as complete">
              <Badge
                size="sm"
                className="mr-1"
                style={{ cursor: 'pointer' }}
                color="light"
                onClick={() => handleMarkAsComplete(row.value)}
              >
                <FiCheckSquare />{' '}
              </Badge>
            </TooltipWrapper>
          </React.Fragment>
        ),
      },
    ];
    const body = (
      <MaintenanceTabs
        data={result.maintenances}
        isOpen={isOpen}
        toggle={toggle}
        columns={columns}
      />
    );
    const bodyProps = { className: 'p-0' };
    const modalData = { header, body, bodyProps };
    toggleWithData(modalData);
  };

  return (
    <React.Fragment>
      <div
        style={{ cursor: 'pointer', padding: '0.5rem 1rem' }}
        onClick={getContent}
      >
        <div style={{ position: 'relative' }}>
          <IoIosBuild style={{ fontSize: '1.25rem' }} />
          {/*<div style={{position:"absolute", top:"-10px", right:"-15px", height:"9px", width:"9px"}} className="blob red" />*/}
        </div>
        Todos
      </div>
      <Modal isOpen={isOpen} size="lg">
        <ModalHeader toggle={toggle}>
          Instructions: {selectedMaintenance.task_name}
        </ModalHeader>
        <ModalBody>
          {parts.length !== 0 && (
            <p
              className="p-3"
              style={{ background: colorScheme.main.primary._10 }}
            >
              Parts list:
              <ul>
                {parts.map((item) => (
                  <li key={item.ident_nr}>
                    {item.quantity}x {item.name}
                  </li>
                ))}
              </ul>
              <div className="text-right">
                <Button
                  size="sm"
                  color="primary"
                  onClick={toggleOrder}
                  className="ml-3"
                >
                  Order Parts <FiShoppingCart />
                </Button>
              </div>
              <Modal isOpen={isOrderOpen} size="md">
                <ModalHeader toggle={toggleOrder}>Order Parts</ModalHeader>
                <ModalBody>
                  <Alert color="warning">
                    <h5 className="alert-heading">🚧 Warning!</h5>
                    This feature is not implemnted yet. Do not send any orders
                    at the time.
                  </Alert>
                  <FormGroup tag="fieldset">
                    <Label for="comment">Add comment</Label>
                    <Input
                      type="textarea"
                      name="text"
                      id="comment"
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </FormGroup>
                  <span id="toggler" style={{ cursor: 'pointer' }}>
                    View parts list <FiChevronDown />
                  </span>
                  <UncontrolledCollapse toggler="#toggler">
                    <Table borderless size="sm">
                      <thead>
                        <tr>
                          <th>Ident</th>
                          <th>Part</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {parts.map((item) => (
                          <tr key={item.ident_nr}>
                            <td>{item.ident_nr}</td>
                            <td>{item.name}</td>
                            <td className="text-right">{item.quantity}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </UncontrolledCollapse>
                  <div className="text-right">
                    <Button
                      size="sm"
                      color="light"
                      onClick={toggleOrder}
                      className="ml-3"
                    >
                      Cancel
                    </Button>
                    <Button
                      size="sm"
                      color="primary"
                      onClick={orderParts}
                      className="ml-3"
                    >
                      Order Parts
                    </Button>
                  </div>
                </ModalBody>
              </Modal>
            </p>
          )}
          {instructions.length !== 0 && (
            <div
              dangerouslySetInnerHTML={{ __html: instructions[0].html_content }}
            />
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const MaintenanceTabs = ({ data, columns, isOpen, toggle }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [groups, setGroups] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const colorScheme = getColorScheme();

  useEffect(() => {
    const fetchData = async () => {
      const result = await getGroups();
      setGroups(result.groups);
      setFilteredData(
        data.filter(
          (todo) => todo.group_id && todo.group_id === result.groups[0].group_id
        )
      );
      setActiveTab(result.groups[0].group_id);
    };
    fetchData();
  }, []);

  const toggleTab = (group) => {
    if (activeTab !== group) setActiveTab(group);
    setFilteredData(data.filter((todo) => todo.group_id === group));
  };

  const navStyle = {
    borderBottom: 'solid 1px rgba(0,0,0,.03)',
  };

  const activeNavLinkStyle = {
    cursor: 'pointer',
    borderBottom: `solid 3px ${colorScheme.main.primary._100}`,
    fontWeight: 'bold',
  };

  return (
    <React.Fragment>
      <Nav style={navStyle} className="hover-primary">
        {groups.map((group) => (
          <NavItem key={group.group_id}>
            <NavLink
              style={
                activeTab === group.group_id
                  ? activeNavLinkStyle
                  : { cursor: 'pointer' }
              }
              onClick={() => {
                toggleTab(group.group_id);
              }}
            >
              {group.group_name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent>
        <TabPane>
          <TableReact hideCreateButton data={filteredData} columns={columns} />
        </TabPane>
      </TabContent>
    </React.Fragment>
  );
};

export default Todos;
