import React, { useState } from 'react';

import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  CardHeader,
} from 'reactstrap';
import {
  FaExclamationCircle,
  FaPlayCircle,
  FaStopCircle,
} from 'react-icons/fa';
import Breadcrumbs from '../../../../components/Breadcrumb';
import { Redirect } from 'react-router-dom';
import { useStore } from '../../../../store';
import MachineCard from './partials/MachineCard';
import { useEffect } from 'react';
import { getMachines } from '../../../../pages/Settings/calls/machines';
import Skeleton from 'react-loading-skeleton';
import { getColorScheme, getLastXDays } from '../../../../lib/helpers';
import { useNonInitialEffect } from '../../../../lib/helpers/customHooks';
import {
  generateAlarmsTimelineData,
  getAlarmHistory,
  sumDuration,
} from '../../../Diagnostics/helpers';
import MachineState from './partials/MachineState';
import 'firebase/firestore';
import 'firebase/database';
import {
  useDatabase,
  useDatabaseListData,
  useFirestore,
  useFirestoreCollection,
  useFirestoreCollectionData,
} from 'reactfire';
import styled from 'styled-components';
import Dot, { Home } from './Dot';
import _ from 'lodash';
import MachineOverview from './partials/MachineOverview';
import handGearIcon from '../../../../media/hand-gear-white.svg';
import Calendar from './partials/Calendar';
import TodoCard from './partials/TodoCard';
import IssuesCard from './partials/IssuesCard';
import Shop from './partials/Shop';

const GraphicContainer = styled.div`
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
`;
const StyledLegend = styled.div`
  display: flex;
  gap: 0.5em;
  font-size: 0.75em;
  align-items: center;
  justify-content: flex-start;
`;
const StyledProductionLineImage = styled.img`
  opacity: 1;
  transition: opacity 0.5s ease-out;
  border-radius: 0.25rem;
  max-height: 302px;
  object-fit: cover;
  background-color: ${({ theme }) => theme.colors.main.primary._20};
  /* &:hover {
    opacity: 0.3;
  } */
`;
const StyledManualIcon = styled.div`
  text-align: center;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.main.success._100};
`;
const Overview = () => {
  const [machineId, setmachineId] = useState('12084');
  const { state, dispatch } = useStore();
  const [isLoggedIn] = useState();
  const [modalData, setModalData] = useState({ header: '', body: '' });
  const [isOpen, setIsOpen] = useState(false);
  const [filteredMachines, setFilteredMachines] = useState([]);
  const colorScheme = getColorScheme();
  // state for diagnostics
  const [alarmsDurationSum, setAlarmsDurationSum] = useState(
    state.diagnostics.alarmsDurationSum || []
  );
  const [alarmsTimeline, setAlarmsTimeline] = useState(
    state.diagnostics.alarmsTimeline || []
  );
  const [activeAlarms, setActiveAlarms] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [machineHours, setmachineHours] = useState(null);
  const [isDatesLoading, setDatesLoading] = useState(false);
  const [isLoadingAlarmHistory, setLoadingAlarmHistory] = useState(false);
  const [isError, setError] = useState(false);
  const [data, setData] = useState(state.diagnostics.data || []);
  const [dates, setDates] = useState(
    state.diagnostics.dates || [getLastXDays(0), getLastXDays(0)]
  );
  const [intervalLoading, setIntervalLoading] = useState(false);

  useEffect(() => {
    fetchDiagnosticsData();
    fetchActiveAlarms();
    const interval = setInterval(() => {
      if (intervalLoading) return;
      setIntervalLoading(true);
      fetchActiveAlarms();
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const fetchActiveAlarms = async () => {
    const resultAlarms2 = await getAlarms2();
    setActiveAlarms(resultAlarms2);
    setIntervalLoading(false);
  };
  const getAlarms2 = async () => {
    const params = {
      iproject_id: state.selectedProject.project_id,
      imodel_nr: state.selectedProject.project_id,
      iserial_nr: state.selectedProject.project_id,
    };
    return fetch(process.env.REACT_APP_PSQL_PROC, {
      method: 'POST',
      body: JSON.stringify({
        reqType: 'select',
        funcName:
          params.iproject_id === 'T02606'
            ? 'project.mas_active_alarms'
            : 'project.mas_active_alarms_v2',
        inputParameters: params,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => data || [])
      .catch((err) => []);
  };
  const db = useDatabase();
  const machineStateRef = db.ref('machine_state');
  const machineStateList = useDatabaseListData(machineStateRef);
  const { data: machineState } = machineStateList;

  const machinesRef = useFirestore().collection('machines');
  const { status: machinesStatus, data: machines } = useFirestoreCollectionData(
    machinesRef
  );

  // const getTask = async () => {
  //   const tasksSnapshot = await useFirestore()
  //     .collection('machines')
  //     .doc('12084')
  //     .collection('maintanance_tasks')
  //     .get();
  //   tasksSnapshot.forEach((doc) => {
  //     let task_doc_id = doc.id;
  //     let task = doc.data();
  //     //console.log(task_doc_id, '=>', task)
  //     console.log(task_doc_id, task.frequency);
  //   });
  // };

  // const {
  //   status: machinesStatusDocs,
  //   data: machinesDocs,
  // } = useFirestoreCollection(machinesRef);
  // console.log({ machinesDocs: machinesDocs.docs.map((d) => d.data()) });

  useNonInitialEffect(() => {
    const hasNull = dates.some((item) => item === null);
    !hasNull && fetchDiagnosticsData();
  }, [dates, state.selectedProject.project_id]);

  const fetchDiagnosticsData = async () => {
    setDatesLoading(true);
    setLoadingAlarmHistory(!alarmsDurationSum.length);
    setError(false);
    const resultAlarmHistory = await getAlarmHistory({
      dates,
      projectId: state.selectedProject.project_id,
      setLoading: setDatesLoading,
      setLoadingAlarmHistory,
      setError,
    });
    setData(resultAlarmHistory);

    const sortByDuration = sumDuration(resultAlarmHistory);
    setAlarmsDurationSum(sortByDuration);
    const alarmsTimelineData = generateAlarmsTimelineData(resultAlarmHistory);
    setAlarmsTimeline(alarmsTimelineData);

    const res = {
      alarmsDurationSum: sortByDuration,
      alarmsTimeline: alarmsTimelineData,
      data: resultAlarmHistory,
      dates,
    };
    dispatch({ type: 'SET_DATA_DIAGNOSTICS_MODULE', payload: res });
  };

  ///////////////////////////
  ///////////////////////////
  ///////////////////////////

  useEffect(() => {
    //using return function skips first render
    return () => {
      setIsOpen(!isOpen);
    };
  }, [modalData]);

  useEffect(() => {
    dispatch({
      type: 'SET_PROJECT_MACHINES',
      payload: machines,
    });
    const sortByPosition = _.sortBy(machines, (i) => i.pos);
    setFilteredMachines(sortByPosition);
  }, [machines]);

  const toggleWithData = (modalData) => {
    setModalData(modalData);
  };
  const toggle = () => setIsOpen(!isOpen);

  const handleSearch = (text) => {
    const filteredData = machines?.filter(
      (machine) =>
        (machine.machine_id &&
          machine.machine_id.toLowerCase().indexOf(text) > -1) ||
        (machine.model_nr &&
          machine.model_nr.toLowerCase().indexOf(text) > -1) ||
        (machine.machine_name &&
          machine.machine_name.toLowerCase().indexOf(text) > -1) ||
        (machine.description &&
          machine.description.toLowerCase().indexOf(text) > -1)
    );
    setFilteredMachines(filteredData);
  };
  const findMachineState = (id) => {
    if (!id) return;
    const segmentstate = machineState?.find(
      (item) => item.machine_id?.toString() === id.toString()
    );
    return segmentstate;
  };
  const findMachine = (id) => {
    if (!id) return;
    const machine = machines?.find(
      (item) => item.machine_id?.toString() === id?.toString()
    );
    return machine;
  };

  const breadcrumbItems = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Maintenance and service',
      link: '',
    },
  ];
  const switchMachine = (indexDelta) => {
    const sortedMachines = machines.sort((a, b) => a.pos - b.pos);

    if (!indexDelta) {
      setSelectedMachine(null);
      return;
    }

    if (!selectedMachine && indexDelta > 0) {
      setSelectedMachine(sortedMachines[1]);
      return;
    }

    if (!selectedMachine && indexDelta < 0) {
      setSelectedMachine(sortedMachines[sortedMachines.length - 1]);
      return;
    }

    const selectedMachineIndex = sortedMachines.findIndex(
      (item) =>
        item.machine_id.toString() === selectedMachine.machine_id.toString()
    );
    const switchedMachine = sortedMachines[selectedMachineIndex + indexDelta];
    setSelectedMachine(switchedMachine);
  };

  const handleMachineMarkerClick = (machineId) => {
    if (selectedMachine?.machine_id?.toString() === machineId.toString()) {
      setSelectedMachine(null);
      return;
    }
    const selected = machines?.find(
      (machine) => machine.machine_id.toString() === machineId.toString()
    );
    setSelectedMachine(selected);
  };
  return (
    <React.Fragment>
      <Breadcrumbs items={breadcrumbItems} />{' '}
      <Container fluid>
        <Row className="my-4">
          <Col lg={12}>
            {machinesStatus === 'loading' ? (
              <Skeleton height={250} />
            ) : (
              <Card className="row flex-row flex-wrap no-gutters mb-3">
                {machines.length &&
                machines.find(
                  (item) => Number(item.pos) === 0 && item.imageUrl
                ) ? (
                  <GraphicContainer>
                    {/* Hopper */}
                    <Home handleClick={setSelectedMachine} top="20" left="20" />
                    <Dot
                      activeAlarms={activeAlarms}
                      handleClick={handleMachineMarkerClick}
                      selectedMachine={selectedMachine}
                      status={findMachineState(12084)}
                      machine={findMachine(12084)}
                      top="159"
                      left="90"
                    />
                    {/* Dough Divider */}
                    <Dot
                      activeAlarms={activeAlarms}
                      handleClick={handleMachineMarkerClick}
                      selectedMachine={selectedMachine}
                      status={findMachineState(12086)}
                      machine={findMachine(12086)}
                      top="206"
                      left="95"
                    />
                    {/* Transporter Left */}
                    <Dot
                      activeAlarms={activeAlarms}
                      handleClick={handleMachineMarkerClick}
                      selectedMachine={selectedMachine}
                      status={findMachineState(12088)}
                      machine={findMachine(12088)}
                      top="200"
                      left="159"
                    />
                    {/* Transporter Right */}
                    <Dot
                      activeAlarms={activeAlarms}
                      handleClick={handleMachineMarkerClick}
                      selectedMachine={selectedMachine}
                      status={findMachineState(12090)}
                      machine={findMachine(12090)}
                      top="214"
                      left="141"
                    />
                    {/* Conical Rounder Left */}
                    <Dot
                      activeAlarms={activeAlarms}
                      handleClick={handleMachineMarkerClick}
                      selectedMachine={selectedMachine}
                      status={findMachineState(12092)}
                      machine={findMachine(12092)}
                      top="197"
                      left="205"
                    />
                    {/* Conical Rounder Right */}
                    <Dot
                      activeAlarms={activeAlarms}
                      handleClick={handleMachineMarkerClick}
                      selectedMachine={selectedMachine}
                      status={findMachineState(12094)}
                      machine={findMachine(12094)}
                      top="216"
                      left="191"
                    />
                    {/* Intermediate Proofer */}
                    <Dot
                      activeAlarms={activeAlarms}
                      handleClick={handleMachineMarkerClick}
                      selectedMachine={selectedMachine}
                      status={findMachineState(12096)}
                      machine={findMachine(12096)}
                      top="179"
                      left="245"
                    />
                    {/* Hot Air Blower From Recuperation */}
                    <Dot
                      activeAlarms={activeAlarms}
                      handleClick={handleMachineMarkerClick}
                      selectedMachine={selectedMachine}
                      status={findMachineState(12171)}
                      machine={findMachine(12171)}
                      top="162"
                      left="213"
                    />
                    {/* Double Pieces Ejector Right */}
                    <Dot
                      activeAlarms={activeAlarms}
                      handleClick={handleMachineMarkerClick}
                      selectedMachine={selectedMachine}
                      status={findMachineState(12172)}
                      machine={findMachine(12172)}
                      top="190"
                      left="181"
                    />
                    {/* Double Pieces Ejector Left */}
                    <Dot
                      activeAlarms={activeAlarms}
                      handleClick={handleMachineMarkerClick}
                      selectedMachine={selectedMachine}
                      status={findMachineState(12173)}
                      machine={findMachine(12173)}
                      top="166"
                      left="187"
                    />
                    {/* Moulding Device Left */}
                    <Dot
                      activeAlarms={activeAlarms}
                      handleClick={handleMachineMarkerClick}
                      selectedMachine={selectedMachine}
                      status={findMachineState(12099)}
                      machine={findMachine(12099)}
                      top="176"
                      left="287"
                    />
                    {/* Moulding Device Right */}
                    <Dot
                      activeAlarms={activeAlarms}
                      handleClick={handleMachineMarkerClick}
                      selectedMachine={selectedMachine}
                      status={findMachineState(12101)}
                      machine={findMachine(12101)}
                      top="213"
                      left="261"
                    />
                    {/* Dredger Left */}
                    <Dot
                      activeAlarms={activeAlarms}
                      handleClick={handleMachineMarkerClick}
                      selectedMachine={selectedMachine}
                      status={findMachineState(12107)}
                      machine={findMachine(12107)}
                      top="179"
                      left="335"
                    />
                    {/* Dredger Right */}
                    <Dot
                      activeAlarms={activeAlarms}
                      handleClick={handleMachineMarkerClick}
                      selectedMachine={selectedMachine}
                      status={findMachineState(12108)}
                      machine={findMachine(12108)}
                      top="215"
                      left="323"
                    />
                    {/* Automatic Charger Left */}
                    <Dot
                      activeAlarms={activeAlarms}
                      handleClick={handleMachineMarkerClick}
                      selectedMachine={selectedMachine}
                      status={findMachineState(12103)}
                      machine={findMachine(12103)}
                      top="204"
                      left="363"
                    />
                    {/* Automatic Charger Right */}
                    <Dot
                      activeAlarms={activeAlarms}
                      handleClick={handleMachineMarkerClick}
                      selectedMachine={selectedMachine}
                      status={findMachineState(12105)}
                      machine={findMachine(12105)}
                      top="210"
                      left="385"
                    />
                    {/* Fermentation Chamber Cleaner */}
                    <Dot
                      activeAlarms={activeAlarms}
                      handleClick={handleMachineMarkerClick}
                      selectedMachine={selectedMachine}
                      status={findMachineState(12179)}
                      machine={findMachine(12179)}
                      top="215"
                      left="415"
                    />
                    {/* Fermentation Chamber */}
                    <Dot
                      activeAlarms={activeAlarms}
                      handleClick={handleMachineMarkerClick}
                      selectedMachine={selectedMachine}
                      status={findMachineState(12174)}
                      machine={findMachine(12174)}
                      top="185"
                      left="485"
                    />
                    {/* Air Conditioner */}
                    <Dot
                      activeAlarms={activeAlarms}
                      handleClick={handleMachineMarkerClick}
                      selectedMachine={selectedMachine}
                      status={findMachineState(393002)}
                      machine={findMachine(393002)}
                      top="145"
                      left="405"
                    />
                    {/* Cutting Device */}
                    <Dot
                      activeAlarms={activeAlarms}
                      handleClick={handleMachineMarkerClick}
                      selectedMachine={selectedMachine}
                      status={findMachineState(392883)}
                      machine={findMachine(392883)}
                      top="211"
                      left="655"
                    />
                    {/* Oven */}
                    <Dot
                      activeAlarms={activeAlarms}
                      handleClick={handleMachineMarkerClick}
                      selectedMachine={selectedMachine}
                      status={findMachineState(3)}
                      machine={findMachine(12175)}
                      top="211"
                      left="775"
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: '255px',
                        left: '60px',
                        zIndex: 10,
                      }}
                    >
                      <small>
                        <strong>Divider</strong>
                      </small>
                      <MachineState data={findMachineState('12086')} />
                    </div>
                    <div
                      style={{
                        position: 'absolute',
                        top: '255px',
                        left: '250px',
                        zIndex: 10,
                      }}
                    >
                      <div style={{ flexGrow: 1 }}>
                        <small>
                          <strong>Entry</strong>
                        </small>
                        <MachineState data={findMachineState('1')} />
                      </div>
                    </div>
                    <div
                      style={{
                        position: 'absolute',
                        top: '255px',
                        left: '480px',
                        zIndex: 10,
                      }}
                    >
                      <div style={{ flexGrow: 2 }}>
                        <small>
                          <strong>Main</strong>
                        </small>
                        <MachineState data={findMachineState('2')} />
                      </div>
                    </div>
                    <div
                      style={{
                        position: 'absolute',
                        top: '255px',
                        left: '780px',
                        zIndex: 10,
                      }}
                    >
                      <small>
                        <strong>Oven</strong>
                      </small>
                      <MachineState data={findMachineState('3')} />
                    </div>
                    <Col sm={12}>
                      <StyledProductionLineImage
                        src={findMachine('Q03607')?.imageUrl}
                        alt="production line"
                        className="rounded"
                      />
                    </Col>
                    <Col className="d-flex px-3 pb-3 justify-content-center flex-wrap"></Col>
                    <Col sm={12} className="mb-1">
                      <StyledLegend>
                        Machine in:
                        <FaPlayCircle
                          style={{
                            color: colorScheme.main.success._100,
                            width: 20,
                            height: 20,
                          }}
                        />
                        Automatic Mode
                        <FaStopCircle
                          style={{
                            color: colorScheme.main.warning._100,
                            width: 20,
                            height: 20,
                          }}
                        />
                        Stop Mode
                        <FaExclamationCircle
                          style={{
                            color: colorScheme.main.danger._100,
                            width: 20,
                            height: 20,
                          }}
                        />
                        Alarm State
                        <StyledManualIcon>
                          <img
                            src={handGearIcon}
                            width={16}
                            height={16}
                            alt="machine"
                            style={{ verticalAlign: 'text-top' }}
                          />
                        </StyledManualIcon>
                        Manual Mode
                      </StyledLegend>
                    </Col>
                  </GraphicContainer>
                ) : (
                  <Col
                    lg={12}
                    className="align-items-stretch"
                    style={{ background: colorScheme.main.primary._20 }}
                  ></Col>
                )}

                {/* {<Col lg={4} className="align-items-stretch d-flex flex-column">
                  <CardBody>
                    <strong>{state.selectedProject.project_name}</strong>
                  </CardBody>
                </Col>} */}
              </Card>
            )}
          </Col>
          <Col sm={12} className="mb-3 sticky-top">
            {machinesStatus === 'loading' ? (
              <Skeleton height={150} />
            ) : (
              <Card>
                <CardBody>
                  <MachineOverview
                    selectedMachine={
                      selectedMachine ||
                      machines.find((machine) => machine.pos === 0)
                    }
                    machineState={
                      selectedMachine?.machine_id &&
                      findMachineState(selectedMachine.machine_id)
                    }
                    dates={dates}
                    setDates={setDates}
                    switchMachine={switchMachine}
                    activeAlarms={activeAlarms}
                    findMachineState={findMachineState}
                  />
                </CardBody>
              </Card>
            )}
          </Col>
          {/* <Col sm={6} className="mb-3">
            <Card>
              <CardHeader>alarms</CardHeader>
              <CardBody>alarms</CardBody>
            </Card>
          </Col>
          <Col sm={6} className="mb-3">
            <Card>
              <CardHeader>Todo</CardHeader>
              <CardBody className="p-0">
                <div
                  className="bg-light py-2 pl-3"
                  style={{ display: 'flex', gap: '.5em' }}
                >
                  {' '}
                  <StyledButton small>Agenda</StyledButton>
                  <StyledButton small>Calendar</StyledButton>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm={6} className="mb-3">
            <Card>
              <CardHeader>issue</CardHeader>
              <CardBody>issue</CardBody>
            </Card>
          </Col>

          <Col sm={6} className="mb-3">
            <Card>
              <CardHeader>docs</CardHeader>
              <CardBody>docs</CardBody>
            </Card>
          </Col> */}
          <Col sm={12} lg={6} className="mb-3">
            <TodoCard selectedMachine={selectedMachine} />
          </Col>
          <Col sm={12} lg={6} className="mb-3">
            <IssuesCard selectedMachine={selectedMachine} machines={machines} />
          </Col>
          <Col sm={12} lg={12} className="mb-3">
            <Shop selectedMachine={selectedMachine} />
          </Col>
        </Row>
        {/* {machinesStatus !== 'loading' && (
          <React.Fragment>
            <hr />
            <Row className="mt-4">
              <Col lg={12} className="mb-3">
                <Input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </Col>
              {filteredMachines
                ?.filter((machine) => machine.machine_id)
                .map((machine) => {
                  return (
                    <Col
                      key={machine.machine_id}
                      xl={3}
                      lg={6}
                      sm={12}
                      className="mb-3"
                    >
                      <MachineCard
                        findMachineState={findMachineState}
                        setDates={setDates}
                        dates={dates}
                        datesLoading={isDatesLoading}
                        data={machine}
                        toggleWithData={toggleWithData}
                        state={findMachineState(
                          machine.machine_id.toString() === '12175'
                            ? 3
                            : machine.machine_id
                        )}
                      />
                    </Col>
                  );
                })}
            </Row>
          </React.Fragment>
        )}
        <Modal
          autoFocus={false}
          fade={false}
          isOpen={isOpen}
          contentClassName="shadow-lg"
          size={modalData.modalSize || 'xl'}
        >
          <ModalHeader toggle={toggle}>{modalData.header}</ModalHeader>
          <ModalBody {...modalData.bodyProps}>{modalData.body}</ModalBody>
        </Modal> */}
      </Container>
    </React.Fragment>
  );
};

export default Overview;
