import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30vh;
  color: ${({ theme }) => theme.colors.grayscale._30};
`;
const NoContent = (props) => {
  const title = props.title ? props.title : 'No Content';

  return (
    <StyledDiv style={{ ...props.style }}>
      <h1>{title}</h1>
      {props.children}
    </StyledDiv>
  );
};

export default NoContent;
