import React, { useState, useEffect } from 'react';
//import ReactTable from "react-table";
// import 'react-table/react-table.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CardBody, Card, CardHeader } from 'reactstrap';
import ProductionLineForm from './ProductionLineForm';
import {
  getProductionLines,
  updateProductionLine,
  deleteProductionLine,
  getProductionLineSequence,
  insertProductionLine,
} from '../calls/productionLines';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import ActionButtons from '../ActionButtons';
import Segments from '../Segments';
import Skeleton from 'react-loading-skeleton';
import Machines from '../Machines';
import TableReact from '../TableTemplate';

const ProductionLineTable = () => {
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [formData, setFormData] = useState({});
  const [rowSelected, setRowSelected] = useState(null);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rowIndex, setRowIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getProductionLines();
      setRowSelected(result.projects && result.projects[0]);
      setProjects(result.projects);
      setLoading(result.loading);
    };
    fetchData();
  }, []);

  const toggleForm = () => setIsOpenForm(!isOpenForm);
  const toggleDelete = () => setIsOpenDelete(!isOpenDelete);

  const handleOpenDelete = () => {
    setDeleteData(rowSelected.project_id);
    toggleDelete();
  };

  const dataForChild = () =>
    !disabled && {
      ...rowSelected,
      breadcrumb: [rowSelected.project_name],
      selected: { productionLine: rowSelected },
    };

  const handleOpenForm = (action, row = rowSelected) => {
    const data = { ...row, action };
    setFormData(data);
    toggleForm();
  };

  const handleCreate = async (item) => {
    const resultSeq = await getProductionLineSequence();
    if (resultSeq.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }

    const itemWithId = { ...item, mas_project_id: resultSeq };
    const result = await insertProductionLine(itemWithId);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }

    const isFirstCreated = projects.length === 0;
    isFirstCreated && setRowSelected(itemWithId);
    setProjects([...projects, itemWithId]);
    toggleForm();
    toast.success('Project created successfully.');
  };

  const handleEdit = async (item) => {
    const { mas_project_id } = item;
    const result = await updateProductionLine(item);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }
    const tmp = projects.filter(
      (project) => project.mas_project_id !== mas_project_id
    );
    setProjects([...tmp, item]);
    setRowSelected(item);
    toggleForm();
    toast.success('Project edited successfully.');
  };

  const handleDelete = async () => {
    const result = await deleteProductionLine(rowSelected.mas_project_id);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }
    setProjects(projects.filter((item) => item.project_id !== deleteData));
    toggleDelete();
    toast.success('Project deleted.');
  };

  const columns = [
    {
      id: 'id',
      Header: 'ID',
      accessor: 'project_id',
    },
    {
      id: 'project_name',
      Header: 'Name',
      accessor: 'project_name',
    },
    {
      id: 'startup_date',
      Header: 'Start up date',
      accessor: 'startup_date',
      style: { textAlign: 'center' },
    },
    {
      id: 'production_year',
      Header: 'Production year',
      accessor: 'production_year',
      style: { textAlign: 'center' },
    },
    {
      id: 'beginning_date_of_guarantee',
      Header: 'Guarantee start',
      accessor: 'beginning_date_of_guarantee',
      style: { textAlign: 'center' },
    },
    {
      id: 'guarantee_valid_till',
      Header: 'Guarantee end',
      accessor: 'guarantee_valid_till',
      style: { textAlign: 'center' },
    },
    {
      id: 'grafana_id',
      Header: 'Grafana ID',
      accessor: 'grafana_id',
      style: { textAlign: 'right' },
    },
  ];

  const handleRowSelect = (info) => {
    setRowIndex(info.index);
    setRowSelected(info.original);
  };

  const disabled = projects.length === 0;
  return (
    <Card>
      <CardHeader>Production lines</CardHeader>
      <CardBody className="p-0">
        {loading ? (
          <Skeleton height={200} />
        ) : (
          <React.Fragment>
            <ActionButtons
              isDisabled={disabled}
              handleCreate={handleOpenForm}
              handleEdit={handleOpenForm}
              handleDuplicate
              handleDelete={handleOpenDelete}
            >
              <Segments data={projects && dataForChild()} />
              <Machines data={projects && dataForChild()} />
            </ActionButtons>
            <TableReact
              hover
              columns={columns}
              data={projects}
              handleCreate={handleOpenForm}
              rowIndex={rowIndex}
              handleRowSelect={handleRowSelect}
            />
            {/* <ReactTable
                                filterable
                                columns={columns}
                                data={projects}
                                defaultPageSize={10}
                                style={{ border: "none" }}
                                getTrProps={(state, rowInfo) => highlightRow(rowInfo)}
                           className="-highlight -striped" />*/}
            {rowSelected && (
              <ProductionLineForm
                data={formData}
                handleCreate={handleCreate}
                handleEdit={handleEdit}
                isOpen={isOpenForm}
                toggle={toggleForm}
              />
            )}
            <DeleteConfirmationModal
              text={'project'}
              handleDelete={handleDelete}
              isOpen={isOpenDelete}
              toggle={toggleDelete}
            />
          </React.Fragment>
        )}
      </CardBody>
    </Card>
  );
};

export default ProductionLineTable;
