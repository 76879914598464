import React, { useContext, useEffect, useState } from 'react';
import TableReact from '../../../../../pages/Settings/TableTemplate';
import Tabs from '../../../../../components/Tabs';
import { StyledButton } from '../../../../../components/DateSelection/DateSelection';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { Card, CardBody, CardHeader } from 'reactstrap';
import {
  columns,
  filterRowsByStatus,
  findAndSetUnseenOrders,
  findRowIndex,
  handleNewOrder,
  sortOrdersByModifiedDate,
} from './OrdersTable.helpers';
import { AuthContext } from '../../../../../utils/firebaseAuth';
import { useStore } from '../../../../../store';
import { FiDownload, FiTrash2, FiUpload } from 'react-icons/fi';
import { StyledBadge } from './OrdersTable.styles';
import { ThemeContext } from 'styled-components';
import { useLocalStorage } from '../../../../../lib/helpers/customHooks';

export const useGetOrders = (status = false) => {
  const ordersRef = useFirestore().collection('orders');
  const filteredOrdersRef = useFirestore()
    .collection('orders')
    .where('details.status', '==', status);

  const { data: orders } = useFirestoreCollectionData(
    status ? filteredOrdersRef : ordersRef
  );
  return [orders];
};

const OrdersTable = () => {
  const [rows, setRows] = useState([]);
  const [unseenOrders, setUnseenOrders] = useState({});
  const { currentProject, currentUser } = useContext(AuthContext);
  const { state, dispatch } = useStore();
  const themeContext = useContext(ThemeContext);
  const { selectedOrder } = state;
  const [seenOrders, setSeenOrders] = useLocalStorage(
    'seenOrders',
    localStorage.seenOrders || []
  );
  // const [orders] = useGetOrders();
  const ordersRef = useFirestore().collection('orders');
  const { data: orders } = useFirestoreCollectionData(ordersRef);

  useEffect(() => {
    if (!rows) return;

    findAndSetUnseenOrders2();
  }, [rows, seenOrders]);

  function findAndSetUnseenOrders2() {
    const proposed = findNumberOfUnseenOrders('proposed');
    const accepted = findNumberOfUnseenOrders('accepted');
    const returned = findNumberOfUnseenOrders('returned');
    const unseenOrders = {
      proposed,
      accepted,
      returned,
      all: proposed + accepted + returned,
    };

    setUnseenOrders(unseenOrders);
  }

  useEffect(() => {
    if (!orders) return;

    const sortedByDate = sortOrdersByModifiedDate(orders);
    const data = findAndSetUnseenOrders({
      orders: sortedByDate,
      seenOrders,
      forStatuses: ['proposed', 'accepted', 'returned'],
    });

    setRows(data);
  }, [orders, seenOrders]);

  const setRowAsSeen = (row) => {
    const seenOrderIndex = seenOrders.findIndex(
      (i) => i.order_no === row.original.details.order_no
    );

    const newSeenOrder = {
      order_no: row.original.details.order_no,
      modifiedAt: row.original.modifiedAt.seconds,
    };

    if (seenOrderIndex < 0) {
      setSeenOrders([...seenOrders, newSeenOrder]);
    } else {
      let tmpSeenOrders = seenOrders;
      tmpSeenOrders[seenOrderIndex] = newSeenOrder;
      setSeenOrders(tmpSeenOrders);
    }
  };

  const handleRowSelect = (row) => {
    setRowAsSeen(row);
    // we dont want to store "notSeen" property into database, so we remove it at the beginning of the flow
    const { notSeen, ...payload } = row.original;
    dispatch({ type: 'SELECTED_ORDER', payload });
  };

  const handleNewOrderClick = () => {
    const payload = handleNewOrder({ currentProject, currentUser });
    dispatch({ type: 'SELECTED_ORDER', payload });
  };
  const findNumberOfUnseenOrders = (status) => {
    const filteredRows = filterRowsByStatus(rows, status, true);
    return filteredRows.filter((item) => item.notSeen).length;
  };

  const tabs = [
    {
      label: 'Open',
      tooltipText: 'Drafts',
    },
    {
      beforeLabel: <FiDownload color={themeContext.colors.main.success._100} />,
      // label: 'For revision',
      label: 'Proposed',
      afterLabel: unseenOrders.proposed ? (
        <StyledBadge status={'deleted'}>{unseenOrders.proposed}</StyledBadge>
      ) : (
        ''
      ),
      tooltipText: 'Orders prepared by Gostol and waiting for your revision',
    },
    {
      beforeLabel: <FiUpload color={themeContext.colors.main.danger._100} />,
      label: 'Released',
      tooltipText: 'Orders waiting for revision by Gostol',
    },
    {
      beforeLabel: <FiDownload color={themeContext.colors.main.success._100} />,
      afterLabel: unseenOrders.accepted ? (
        <StyledBadge status={'deleted'}>{unseenOrders.accepted}</StyledBadge>
      ) : (
        ''
      ),

      // label: 'For confirmation',
      label: 'Accepted',
      tooltipText: 'Orders accepted by Gostol and  need your confirmation',
    },
    {
      beforeLabel: <FiDownload color={themeContext.colors.main.success._100} />,
      afterLabel: unseenOrders.returned ? (
        <StyledBadge status={'deleted'}>{unseenOrders.returned}</StyledBadge>
      ) : (
        ''
      ),
      label: 'Returned',
      tooltipText: 'Orders waiting for your revision',
    },
    {
      beforeLabel: <FiUpload color={themeContext.colors.main.danger._100} />,
      label: 'Confirmed',
      tooltipText: 'Orders are being processed',
    },
    { label: 'Closed', tooltipText: 'Fulfilled orders' },
    'Deleted',
    {
      label: 'All',
      afterLabel: unseenOrders.all ? (
        <StyledBadge status={'deleted'}>{unseenOrders.all}</StyledBadge>
      ) : (
        ''
      ),
    },
  ];

  return (
    <Card className="mb-3">
      <CardHeader className="d-flex justify-content-between">
        Orders{' '}
        <StyledButton color="primary" onClick={handleNewOrderClick}>
          New order
        </StyledButton>
      </CardHeader>
      <CardBody className="p-0">
        <Tabs
          tabs={tabs}
          onSelectedTab={() =>
            dispatch({ type: 'SELECTED_ORDER', payload: null })
          }
        >
          <TableReact
            hover
            columns={columns}
            data={filterRowsByStatus(rows, 'open')}
            rowIndex={findRowIndex(rows, 'open', selectedOrder)}
            handleRowSelect={handleRowSelect}
          />
          <TableReact
            hover
            columns={columns}
            // data={filterRowsByStatus(rows, ['proposed', 'returned'])}
            data={filterRowsByStatus(rows, 'proposed')}
            rowIndex={findRowIndex(rows, 'proposed', selectedOrder)}
            handleRowSelect={handleRowSelect}
          />
          <TableReact
            hover
            columns={columns}
            data={filterRowsByStatus(rows, 'released')}
            rowIndex={findRowIndex(rows, 'released', selectedOrder)}
            handleRowSelect={handleRowSelect}
          />
          <TableReact
            hover
            columns={columns}
            data={filterRowsByStatus(rows, 'accepted')}
            rowIndex={findRowIndex(rows, 'accepted', selectedOrder)}
            handleRowSelect={handleRowSelect}
          />
          <TableReact
            hover
            columns={columns}
            data={filterRowsByStatus(rows, 'returned')}
            rowIndex={findRowIndex(rows, 'returned', selectedOrder)}
            handleRowSelect={handleRowSelect}
          />
          <TableReact
            hover
            columns={columns}
            data={filterRowsByStatus(rows, 'confirmed')}
            rowIndex={findRowIndex(rows, 'confirmed', selectedOrder)}
            handleRowSelect={handleRowSelect}
          />
          <TableReact
            hover
            columns={columns}
            data={filterRowsByStatus(rows, 'closed')}
            rowIndex={findRowIndex(rows, 'closed', selectedOrder)}
            handleRowSelect={handleRowSelect}
          />
          <TableReact
            hover
            columns={columns}
            data={filterRowsByStatus(rows, 'deleted')}
            rowIndex={findRowIndex(rows, 'deleted', selectedOrder)}
            handleRowSelect={handleRowSelect}
          />
          <TableReact
            hover
            columns={columns}
            data={rows}
            rowIndex={findRowIndex(rows, null, selectedOrder)}
            handleRowSelect={handleRowSelect}
          />
        </Tabs>
      </CardBody>
    </Card>
  );
};

export default OrdersTable;
