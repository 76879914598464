import React, { useRef, useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { FiPaperclip, FiSend } from 'react-icons/fi';
import firebase from 'firebase';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { Card, CardBody, Collapse, Input, Spinner } from 'reactstrap';
import { StyledButton } from '../../../../../components/DateSelection/DateSelection';
import AlwaysScrollToBottom from '../../../../../components/AlwaysScrollToBottom';
import { Chat, Messages, Message, Avatar, Composer } from './OrderChat.styles';
import NoContent from '../../../../../components/NoContent';
import { mainAuth } from '../../../../../utils/firebase';
import { getInitials } from './OrderChat.helpers';
import { useStore } from '../../../../../store';
import FileUpload from '../../../../../components/FileUpload';
import { useToggler } from '../../../../../lib/helpers/customHooks';

const OrderChat = () => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAttachmentCollapsed, toggleAttachmentCollapse] = useToggler(false);
  const [user, setUser] = useState(mainAuth.currentUser);
  const { state } = useStore();
  const { selectedOrder } = state;
  const [newMessageHeight, setNewMessageHeight] = useState(0);

  const projectRef = useFirestore()
    .collection('orders')
    .doc(selectedOrder.details.id);
  const { data: messages } = useFirestoreDocData(projectRef);

  useEffect(() => setMessage(''), [selectedOrder]);

  const handleKeyDown = (e) => {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;
    // In case you have a limitation
    e.target.style.height = `${Math.min(e.target.scrollHeight, 150)}px`;
    setNewMessageHeight(e.target.scrollHeight);
  };

  const handleSendMessage = () => {
    setLoading(true);
    const sender = user.displayName || user.email;
    const fsdb = firebase.firestore();
    fsdb
      .collection('orders')
      .doc(selectedOrder.details.id)
      .update({
        messages: firebase.firestore.FieldValue.arrayUnion({
          message,
          date: new Date(),
          sender,
        }),
      })
      .then(() => {
        setMessage('');
        setLoading(false);
      })
      .catch((res) => {
        return res;
      });
  };

  return state.selectedOrder?.details?.status === 'open' ? (
    <NoContent title="Chat disabled">Chat unavailable in open orders</NoContent>
  ) : (
    <Chat>
      <Messages height={newMessageHeight}>
        {!messages?.messages?.length ? (
          <NoContent title="No messages" />
        ) : (
          messages.messages.map((item, index) => (
            <Message key={index}>
              <Avatar>{getInitials(item.sender)}</Avatar>
              <div style={{ flex: '1' }}>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    marginBottom: '.5em',
                  }}
                >
                  <div className="username">{item.sender} </div>
                  <div className="date">
                    {DateTime.fromSeconds(item.date.seconds).toFormat(
                      'dd.LL.yyyy, HH:mm'
                    )}
                  </div>
                </div>
                <p style={{ whiteSpace: 'pre-line' }}>{item.message}</p>
              </div>
            </Message>
          ))
        )}
        {/* <AlwaysScrollToBottom /> */}
      </Messages>
      <Composer>
        <Avatar>{getInitials(user.displayName || user.email)}</Avatar>
        <Input
          type="textarea"
          onKeyDown={handleKeyDown}
          style={{ minHeight: 50 }}
          placeholder="Text message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />{' '}
        <StyledButton onClick={handleSendMessage} disabled={!message}>
          {loading ? (
            <>
              <Spinner size="sm" /> Send
            </>
          ) : (
            <>
              <FiSend />
              Send
            </>
          )}
        </StyledButton>
      </Composer>
    </Chat>
  );
};

export default OrderChat;
