import React, { useEffect, useState } from 'react';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/component/visualMap';
import 'echarts/lib/chart/line.js';
import { CardBody, Card, CardHeader } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import { getColorScheme } from '../../../../../lib/helpers';
import { DateTime } from 'luxon';

const Performance = ({ loading, gridLeft, zoomSync, setZoomSync, dataNew }) => {
  console.log({ dataNew });
  const [events, setEvents] = useState({});
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    zoomSync &&
      setEvents({
        dataZoom: (params) => {
          const { start, end } = params.batch ? params.batch[0] : params;
          setZoomSync([start, end]);
        },
      });
  }, []);

  useEffect(() => {
    const addedZeroValueAfterEachLotAndChangedMntToMs =
      dataNew
        ?.map((item) => {
          item?.log?.oee &&
            item?.log?.oee.length > 0 &&
            item.log.oee.push({
              ava: 0,
              mnt: item.log.oee[item.log.oee.length - 1].mnt + 1,
              oee: 0,
              prf: 0,
              qlt: 0,
            });
          return item?.log?.oee;
        })
        .flat()
        .map((item) => ({
          ...item,
          mnt: item.mnt * 60 * 1000,
        })) || [];

    setChartData(addedZeroValueAfterEachLotAndChangedMntToMs);
    // const tmp = dataNew
    //   .map((item) => item.log.oee)
    //   .flat()
    //   .map((item) => ({
    //     ...item,
    //     mnt: item.mnt * 60 * 1000,
    //   }));

    // setChartData(tmp);
  }, [dataNew]);

  const renderTooltip = (params) => {
    const out = params.map(
      (item) =>
        `<div style="display:flex; justify-content:space-between"><div class="mr-2">${
          item.marker
        } ${item.seriesName}:</div><div> ${Intl.NumberFormat(
          window.navigator.language
        ).format(item.value[1])}</div></div>`
    );
    return [
      `${DateTime.fromMillis(params[0].axisValue).toFormat(
        'dd.LL.yyyy, HH:mm'
      )}<br />`,
      ...out,
    ].join('');
  };

  const colorScheme = getColorScheme();
  const option = {
    grid: {
      left: gridLeft || 50,
      right: 20,
    },
    xAxis: {
      type: 'time',
      boundaryGap: false,
      // data: generatedDate,
      scale: true,
      //type:"time",
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value}%',
      },
    },
    //FIXME: tooltip shows american formatted date and if there is more days selected for this chart, there in no HH:mm:ss visible
    // TODO: create custom tooltip
    tooltip: {
      trigger: 'axis',
      formatter: (params) => renderTooltip(params),
    },
    legend: {
      data: [
        'Availability',
        'Performance',
        'Quality',
        'OEE',
        'Mixer Utilization',
      ],
      selected: {
        'Mixer Utilization': false,
      },
    },
    dataZoom: [
      {
        type: 'slider',
        filterMode: 'weakFilter',
        showDataShadow: false,
        top: 455,
        height: 10,
        handleIcon:
          'M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z', // jshint ignore:line
        handleSize: 20,
        labelFormatter: '',
        start: zoomSync ? zoomSync[0] : 0,
        end: zoomSync ? zoomSync[1] : 100,
      },
      {
        type: 'inside',
        filterMode: 'weakFilter',
      },
    ],
    series: [
      {
        type: 'line',
        sampling: 'average',
        name: 'Availability',
        step: 'end',
        data: chartData.map(({ mnt, ava }) => [Number(mnt), Number(ava)]),
        symbol: 'none',
        itemStyle: {
          color: colorScheme.other[0]._100,
        },
        lineStyle: {
          width: 2,
          type: 'dashed',
        },
      },
      {
        type: 'line',
        sampling: 'average',
        name: 'Performance',
        step: 'end',
        data: chartData.map(({ mnt, prf }) => [Number(mnt), Number(prf)]),
        symbol: 'none',
        itemStyle: {
          color: colorScheme.other[1]._100,
        },
        lineStyle: {
          width: 2,
          type: 'dashed',
        },
      },
      {
        type: 'line',
        sampling: 'average',
        name: 'Quality',
        step: 'end',
        data: chartData.map(({ mnt, qlt }) => [Number(mnt), Number(qlt)]),
        symbol: 'none',
        itemStyle: {
          color: colorScheme.other[2]._100,
        },
        lineStyle: {
          width: 2,
          type: 'dashed',
        },
      },
      {
        type: 'line',
        sampling: 'average',
        name: 'OEE',
        step: 'end',
        data: chartData.map(({ mnt, oee }) => [Number(mnt), Number(oee)]),
        symbol: 'none',
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: colorScheme.main.success._80,
            },
            {
              offset: 1,
              color: colorScheme.main.success._10,
            },
          ]),
        },
        itemStyle: {
          color: colorScheme.main.success._100,
        },
        lineStyle: {
          width: 2,
        },
      },
      // {
      //   type: 'line',
      //   sampling: 'average',
      //   name: 'Mixer Utilization',
      //   data: data.map(({ time_ms, mixer_utilization }) => [
      //     Number(time_ms),
      //     Number(mixer_utilization),
      //   ]),
      //   symbol: 'none',
      //   itemStyle: {
      //     color: colorScheme.other[3]._100,
      //   },
      //   lineStyle: {
      //     width: 2,
      //   },
      // },
    ],
  };
  return loading ? (
    <Skeleton height={256} />
  ) : (
    <ReactEchartsCore
      onEvents={events}
      echarts={echarts}
      style={{ height: 480 }}
      option={option}
    />
  );
};

export default Performance;
