import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Layout from './Layout';
import Login from './pages/Login';
import Page404 from './pages/Page404';
import Page500 from './pages/Page500';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { StoreProvider } from './store';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import { FirebaseAppProvider } from 'reactfire';
import { AuthProvider } from './utils/firebaseAuth';
import config from './utils/firebase';

require('dotenv').config();

const App = () => {
  // TODO: change configuration of firebase based on which project is selected
  // const [configuration, setConfiguration] = useState('default configuration')
  // useEffect(()=>{
  //   setConfiguration('based on projectSelected')
  // },[projectSelected])

  return (
    // <FirebaseAppProvider firebaseConfig={configuration}>
    <FirebaseAppProvider firebaseConfig={config}>
      <AuthProvider>
        <StoreProvider>
          <ThemeProvider theme={theme}>
            <HashRouter>
              <Switch>
                <Route path="/login" name="Login Page" component={Login} />
                <Route path="/404" name="Page 404" component={Page404} />
                <Route path="/500" name="Page 500" component={Page500} />
                <Route path="/" name="Home" component={Layout} />
              </Switch>
            </HashRouter>
          </ThemeProvider>
        </StoreProvider>
      </AuthProvider>
    </FirebaseAppProvider>
  );
};

export default App;
