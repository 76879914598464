import React from 'react';
import { Media, CardFooter, CardBody, Card } from 'reactstrap';
import Details from './Details';
import Todos from './Todos';
import Issues from './Issues';
import Documents from './Documents';
import handGearIcon from '../../../../../media/hand-gear-white.svg';
import monitorIcon from '../../../../../media/monitor-white.svg';
import offIcon from '../../../../../media/off.svg';
import TooltipWrapper from '../../../../../components/TooltipWrapper';
import {
  FaPlayCircle,
  FaStopCircle,
  FaExclamationCircle,
} from 'react-icons/fa';
import classnames from 'classnames';
import MachineState from './MachineState';
import { getColorScheme } from '../../../../../lib/helpers';
import AlarmStatistics from './AlarmStatistics';

const MachineCard = ({
  data,
  toggleWithData,
  state,
  dates,
  setDates,
  datesLoading,
  findMachineState,
}) => {
  const colorScheme = getColorScheme();
  const hasError = state?.has_error === 'true';
  const icon = () =>
    ({
      AUTO: monitorIcon,
      MANUAL: handGearIcon,
      STOP: offIcon,
    }[state?.machine_state]);

  const iconStyleBG = () =>
    ({
      AUTO: colorScheme.main.success._100,
      MANUAL: colorScheme.main.success._100,
      STOP: colorScheme.main.danger._100,
    }[state?.machine_state]);

  const iconStyle = () => ({
    padding: '.5em',
    background: iconStyleBG(),
    border:
      hasError === 'false'
        ? `solid 3px ${colorScheme.main.warning._100}`
        : 'none',
  });
  const statusData = findMachineState && findMachineState(data.serial_nr);
  return (
    <Card className="h-100">
      <CardBody>
        <Media style={{ alignItems: 'center', fontSize: '.75em' }}>
          {data.imageUrl ? (
            <Media left>
              <Media
                object
                src={data.imageUrl}
                alt={data.description}
                style={{
                  objectFit: 'cover',
                  borderRadius: '50%',
                  height: 70,
                  width: 70,
                  backgroundColor: colorScheme.main.primary._20,
                }}
              />
            </Media>
          ) : (
            <Media
              left
              style={{
                borderRadius: '50%',
                height: 70,
                width: 70,
                backgroundColor: colorScheme.main.primary._20,
              }}
            ></Media>
          )}

          <Media
            className="pl-3"
            body
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            <div className="d-flex justify-content-between flex-wrap-reverse">
              <span className="font-weight-bold">{data.description}</span>
              <span className="ml-auto">{<MachineState data={state} />}</span>
              {/*<div className="blob red/yellow m-0"></div>*/}
            </div>
            SN: {data.machine_id} <br />
            ID: {data.model_nr}
            <br />
            Name: {data.machine_name}
          </Media>
        </Media>
      </CardBody>
      {!!toggleWithData && (
        <CardFooter
          className="text-center bg-white text-muted hover-primary p-0"
          style={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '.75em',
          }}
        >
          <Details
            data={data}
            machineState={state}
            toggleWithData={toggleWithData}
          />
          <Todos data={data} toggleWithData={toggleWithData} />
          <Issues data={data} toggleWithData={toggleWithData} />
          <AlarmStatistics
            data={data}
            dates={dates}
            toggleWithData={toggleWithData}
            setDates={setDates}
            datesLoading={datesLoading}
          />
          <Documents data={data} toggleWithData={toggleWithData} />
        </CardFooter>
      )}
    </Card>
  );
};

export default MachineCard;
