import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FiDroplet, FiEye } from 'react-icons/fi';
import {
  IoIosBuild,
  IoIosColorFill,
  IoMdColorFilter,
  IoIosFlask,
} from 'react-icons/io';
import { Badge } from 'reactstrap';
import MaintenanceForm from './MaintenanceForm';
import Parts from '../Parts';
import Instructions from '../Instructions';
import {
  getMaintenanceSequence,
  getMaintenance,
  insertMaintenance,
  updateMaintenance,
  deleteMaintenance,
} from '../calls/maintenances';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import Skeleton from 'react-loading-skeleton';
import Frequencies from '../Groups';
import ActionButtons from '../ActionButtons';
import TooltipWrapper from '../../../components/TooltipWrapper';
import TableReact from '../TableTemplate';

const MaintenancesTable = ({ data }) => {
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [formData, setFormData] = useState(null);
  const [deleteData, setDeleteData] = useState({});
  const [maintenances, setMaintenances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rowIndex, setRowIndex] = useState(0);
  const [rowSelected, setRowSelected] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const {
        productionLine: { project_id },
        machine: { serial_nr, model_nr },
        assembly: { assembly_id },
      } = data.selected;
      const result = await getMaintenance({
        serial_nr,
        model_nr,
        project_id,
        assembly_id,
      });
      setMaintenances(result.maintenances);
      setRowSelected(result.maintenances && result.maintenances[0]);
      setLoading(result.loading);
    };
    fetchData();
  }, []);

  useEffect(() => {
    return () => toggleForm();
  }, [formData]);

  const toggleForm = () => setIsOpenForm(!isOpenForm);
  const toggleDelete = () => setIsOpenDelete(!isOpenDelete);

  const handleCreate = async (item) => {
    const resultSeq = await getMaintenanceSequence();
    if (resultSeq.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }

    const itemWithId = {
      ...item,
      machine_maintanance_id: Number(resultSeq),
      task_id: Number(resultSeq),
    };

    const result = await insertMaintenance(itemWithId);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }

    const isFirstCreated = maintenances.length === 0;
    isFirstCreated && setRowSelected(itemWithId);
    setMaintenances([...maintenances, itemWithId]);
    toggleForm();
    toast.success('Maintenance created successfully.');
  };

  const handleEdit = async (item) => {
    const { machine_maintanance_id } = item;
    const result = await updateMaintenance({
      ...item,
      task_id: item.machine_maintanance_id,
    });
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }
    const tmp = maintenances.filter(
      (maintenance) =>
        maintenance.machine_maintanance_id !== machine_maintanance_id
    );
    setMaintenances([...tmp, item]);
    setRowSelected(item);
    toggleForm();
    toast.success('Maintenance edited successfully.');
  };

  const handleDelete = async () => {
    const result = await deleteMaintenance({
      ...rowSelected,
      task_id: rowSelected.machine_maintanance_id,
    });
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }
    setMaintenances(
      maintenances.filter((item) => item.machine_maintanance_id !== deleteData)
    );
    toggleDelete();
    toast.success('Maintenance deleted.');
  };

  const handleOpenDelete = () => {
    setDeleteData(rowSelected.machine_maintanance_id);
    toggleDelete();
  };

  const handleOpenForm = (action, row = rowSelected) => {
    const isNew = action === 'create';
    const nextOrdNo = maintenances.length
      ? Math.trunc(
          Math.max(...maintenances.map((maintenance) => maintenance.task_order))
        ) + 1
      : 1;
    const { selected } = data;
    const tmp = {
      ...row,
      action,
      selected,
      task_order: isNew ? nextOrdNo : row.task_order,
    };
    setFormData(tmp);
    //toggleForm()
  };

  const handleRowSelect = (info) => {
    setRowIndex(info.index);
    setRowSelected(info.original);
  };

  const dataForChild = () =>
    !disabled && {
      ...rowSelected,
      breadcrumb: [...data.breadcrumb, rowSelected.task_name],
      selected: { ...data.selected, maintenance: rowSelected },
    };

  const getIcon = (method) =>
    ({
      Visually: <FiEye />,
      Tools: <IoIosBuild />,
      Water: <FiDroplet />,
      Grease: <IoMdColorFilter />,
      Oil: <IoIosColorFill />,
      Detergent: <IoIosFlask />,
    }[method]);

  const columns = [
    /*{
        id: 'machine_maintanance_id',
        Header: 'ID',
        accessor: "machine_maintanance_id"
    },*/ {
      id: 'task_order',
      Header: 'Ord.No.',
      width: 100,
      accessor: 'task_order',
      style: { textAlign: 'right' },
    },
    {
      id: 'task_id',
      Header: 'Task ID',
      accessor: 'task_id',
      style: { textAlign: 'right' },
    },
    {
      id: 'task_name',
      Header: 'Name',
      accessor: 'task_name',
    },
    {
      id: 'group_name',
      Header: 'Interval group',
      accessor: 'group_name',
    },
    {
      id: 'frequency',
      Header: 'Interval description',
      accessor: 'frequency',
    },
    {
      id: 'personel_level_description',
      Header: 'Personel level',
      accessor: 'personel_level_description',
    },
    {
      id: 'estimated_task_duration',
      Header: 'estimated task duration',
      accessor: 'estimated_task_duration',
    },
    {
      id: 'operation_method',
      Header: 'Operation method',
      style: { textAlign: 'center' },
      width: 170,
      accessor: 'operation_method',
      Cell: (row) =>
        row.value
          ? row.value.split(',').map((method) => (
              <React.Fragment key={method}>
                <TooltipWrapper content={method}>
                  <Badge
                    color="light"
                    className="mr-1"
                    id={`tooltip-${method}`}
                  >
                    {getIcon(method)}
                  </Badge>
                </TooltipWrapper>
              </React.Fragment>
            ))
          : '',
    },
  ];

  const disabled = maintenances.length === 0;
  return loading ? (
    <Skeleton height={200} />
  ) : (
    <React.Fragment>
      <ActionButtons
        isDisabled={disabled}
        handleCreate={handleOpenForm}
        handleEdit={handleOpenForm}
        handleDuplicate
        handleDelete={handleOpenDelete}
      >
        <Parts data={dataForChild()} />
        <Instructions data={dataForChild()} />
        <Frequencies data={dataForChild()} />
      </ActionButtons>
      <TableReact
        hover
        columns={columns}
        data={maintenances}
        rowIndex={rowIndex}
        handleCreate={handleOpenForm}
        handleRowSelect={handleRowSelect}
      />
      {formData && (
        <MaintenanceForm
          getIcon={getIcon}
          data={formData}
          isOpen={isOpenForm}
          handleCreate={handleCreate}
          handleEdit={handleEdit}
          toggle={toggleForm}
        />
      )}
      <DeleteConfirmationModal
        text={'maintenance'}
        handleDelete={handleDelete}
        isOpen={isOpenDelete}
        toggle={toggleDelete}
      />
    </React.Fragment>
  );
};

export default MaintenancesTable;
