import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Button,
  FormText,
} from 'reactstrap';

const PartsForm = ({ toggle, isOpen, data, handleCreate, handleEdit }) => {
  const [part, setPart] = useState(data);
  useEffect(() => {
    setPart(data);
  }, [data]);

  const handleSubmitForm = () => {
    data.action === 'create' && handleCreate(part);
    data.action === 'edit' && handleEdit(part);
  };

  const handleCancelForm = () => {
    setPart({});
    toggle();
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle}>
        {' '}
        {data.action === 'create'
          ? 'Create'
          : data.action === 'edit'
          ? 'Edit'
          : ''}{' '}
        part{' '}
      </ModalHeader>
      <ModalBody>
        <Form autoComplete="off">
          {/* <FormGroup>
                        <Label for="machine_maintanance_id">machine_maintanance_id</Label>
                        <Input             
                            disabled          
                            type="text"
                            id="machine_maintanance_id"
                            name="part[machine_maintanance_id]"
                            onChange={e=> setPart({...part, machine_maintanance_id:e.target.value})}
                            value={part.machine_maintanance_id} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="maintanance_parts_id">maintanance_parts_id</Label>
                        <Input           
                            disabled                 
                            type="text"
                            id="maintanance_parts_id"
                            name="part[maintanance_parts_id]"
                            onChange={e=> setPart({...part, maintanance_parts_id:e.target.value})}
                            value={part.maintanance_parts_id} />
                    </FormGroup>  */}
          <FormGroup>
            <Label for="_pos">Ord.No.</Label>
            <Input
              type="number"
              id="_pos"
              name="part[pos]"
              onChange={(e) => setPart({ ...part, pos: e.target.value })}
              value={part.pos}
            />
          </FormGroup>
          <FormGroup>
            <Label for="_ident_nr">Ident number</Label>
            <Input
              type="number"
              id="_ident_nr"
              name="part[ident_nr]"
              onChange={(e) => setPart({ ...part, ident_nr: e.target.value })}
              value={part.ident_nr}
            />
          </FormGroup>
          <FormGroup>
            <Label for="_name">Name</Label>
            <Input
              type="text"
              id="_name"
              name="part[name]"
              onChange={(e) => setPart({ ...part, name: e.target.value })}
              value={part.name}
            />
          </FormGroup>
          <FormGroup>
            <Label for="_quantity">Quantity</Label>
            <Input
              type="number"
              id="_quantity"
              name="part[quantity]"
              onChange={(e) => setPart({ ...part, quantity: e.target.value })}
              value={part.quantity || setPart({ ...part, quantity: 1 })}
            />
          </FormGroup>{' '}
          {/*
                    <FormGroup>
                        <Label for="_price">Price</Label>
                        <Input                       
                            type="text"
                            id="_price"
                            name="part[price]"
                            onChange={e=> setPart({...part, price:e.target.value})}
                            value={part.price} />
                    </FormGroup>      */}
          <FormGroup>
            <Label for="_minq">Minimal quantity</Label>
            <Input
              type="number"
              id="_minq"
              name="part[minq]"
              onChange={(e) => setPart({ ...part, minq: e.target.value })}
              value={part.minq}
            />
          </FormGroup>
          <FormGroup>
            <Label for="_maxq">Maximum quantity</Label>
            <Input
              type="number"
              id="_maxq"
              name="part[maxq]"
              onChange={(e) => setPart({ ...part, maxq: e.target.value })}
              value={part.maxq}
            />
          </FormGroup>
          {/* <FormGroup check>
                        <Label check for="_is_checked">
                        <Input 
                            id="_is_checked"
                            checked = {part["is_checked"] === undefined || part["is_checked"]}
                            type="checkbox" 
                            name="part[is_checked]"
                            onChange={e=> setPart({...part, is_checked:e.target.checked})}/>{' '}
                        Is checked
                        <FormText color="muted">
                            if checked 
                        </FormText>
                        </Label>
                   </FormGroup> */}
          <FormGroup check>
            <Label check for="_is_required">
              <Input
                id="_is_required"
                checked={part['is_required']}
                type="checkbox"
                name="part[is_required]"
                onChange={(e) =>
                  setPart({ ...part, is_required: e.target.checked })
                }
              />{' '}
              Is required
              <FormText color="muted">
                if checked, user cannot deselect this part from ordering.
              </FormText>
            </Label>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleCancelForm} color="light">
          Cancel
        </Button>
        <Button
          className="text-capitalize"
          onClick={handleSubmitForm}
          color="primary"
        >
          {data.action === 'edit' ? 'Confirm changes' : data.action}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PartsForm;
