import React from 'react';
import styled from 'styled-components';

const StyledNotification = styled.div`
  position: relative;
`;

const StyledBadge = styled.span`
  position: absolute;
  top: -5px;
  right: -7px;
  padding: 2px 5px;
  min-width: 16px;
  border-radius: 50%;
  background-color: ${({ color, theme }) =>
    theme.color.main[color || 'danger']._100};
  color: white;
  font-size: 0.7em;
`;

const NotificationBadge = ({ children, value, styles, color }) => {
  if (!value) return children;
  return (
    <StyledNotification>
      {children}
      <StyledBadge style={styles} color={color}>
        {value}
      </StyledBadge>
    </StyledNotification>
  );
};

export default NotificationBadge;
