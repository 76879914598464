import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import ResultPage from './ResultPage';
import { Redirect } from 'react-router-dom';
import { apiCalls } from '../../../api/dummy/globalSettings.json'; //TODO: Refactor apiCalls

class QrResult extends Component {
  state = {
    serialNumber: localStorage.qrContent || this.props.match.params.data,
    machine: {},
    data: null,
    image: null,
  };

  componentDidMount() {
    this.getMachine();
    // this.setLocalStorage();
  }

  getMachine = () => {
    const { serialNumber } = this.state;
    localStorage.setItem('serialNumberMachine', serialNumber);
    if (serialNumber) {
      fetch(process.env.REACT_APP_API_PREFIX + 'appapi/v1/machine', {
        method: 'POST',
        body: JSON.stringify({
          serialNumber,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((result) => result.json())
        .then((data) => {
          this.setState({ data });
          return fetch(
            process.env.REACT_APP_API_PREFIX + 'appapi/v1/machines-images/',
            {
              method: 'POST',
              body: JSON.stringify({
                modelNumbers: [data[0].model_nr],
              }),
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
        })
        .then((result) => result.json())
        //.then (result => this.setState({image: result[this.state.data[0].model_nr][0].guid}))
        .then((result) => this.setState({ image: result[0].image }));
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.data !== prevState.data) {
      // this.setLocalStorage();
    }
  }

  getAllMachines = (projectSelected, serialNumber) => {
    const { path, machines } = apiCalls;
    fetch(path + machines, {
      method: 'POST',
      body: JSON.stringify({
        productionLine: projectSelected,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((data) => data.json())
      .then((data) => {
        const allMachines = data
          .filter((item) => item.serial_nr)
          .map((item) => ({
            projectId: item.project_id,
            modelNumber: item.model_nr,
            machineName: item.machine_name,
            serialNumber: item.serial_nr,
            description: item.description,
            alarmTopic: item.alarm_topic,
            productionYear: item.production_year,
            guaranteeValidTill: item.guarantee_valid_till,
            beginningDateOfGuarantee: item.beginning_date_of_guarantee,
          }));
        const machine = allMachines.filter(
          (machine) => machine.serialNumber === serialNumber
        )[0];
        localStorage.setItem('allMachines', JSON.stringify(allMachines));
        localStorage.setItem('machine', JSON.stringify(machine));
        this.setState({ machine });
      });
  };

  setLocalStorage() {
    if (localStorage.getItem('projects')) {
      const qrContent = JSON.parse(localStorage.getItem('qrcontent'));
      //const productionLine = JSON.parse(localStorage.getItem("projects")).filter(project => project.project_id === qrContent.production_line_code);
      //localStorage.setItem("productionLine", JSON.stringify(productionLine[0]));
      this.getAllMachines(
        qrContent.production_line_code,
        qrContent.serial_number
      );
    }
  }

  render() {
    const { data, image } = this.state;
    //const { data } = this.state
    //localStorage.setItem("qrcontent", data)

    if (!data || !image) return null;
    return (
      <Row>
        <Col lg={12}>
          <ResultPage
            //model={this.props.location.state.qrData}
            data={data[0]}
            image={image}
          />
        </Col>
      </Row>
    );
  }
}

export default QrResult;
