import React, { useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupButtonDropdown,
} from 'reactstrap';
import { FREQUENCY_UNITS } from '../constants';

const FrequencyInput = ({
  data,
  index,
  canDelete,
  handleFrequencyChange,
  handleFrequencyRemove,
}) => {
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const toggleDropDown = (index) => setDropDownOpen(!dropdownOpen);
  return (
    <InputGroup key={index} className="mb-2">
      <Input
        type="number"
        value={data?.interval}
        onChange={(e) =>
          // setTask({ ...task, frequency: e.target.value })
          handleFrequencyChange({
            newInterval: Number(e.target.value),
            index,
          })
        }
        id="frequency"
        placeholder="interval"
      />
      <InputGroupButtonDropdown
        addonType="append"
        isOpen={dropdownOpen}
        toggle={toggleDropDown}
      >
        <DropdownToggle caret>
          {data?.unit || FREQUENCY_UNITS[0]}
        </DropdownToggle>
        <DropdownMenu>
          {FREQUENCY_UNITS.map((unit) => (
            <DropdownItem
              onClick={() => handleFrequencyChange({ newUnit: unit, index })}
            >
              {unit}
            </DropdownItem>
          ))}
        </DropdownMenu>
        {canDelete && (
          <span
            onClick={() => handleFrequencyRemove(index)}
            style={{
              marginLeft: '.5em',
              color: 'red',
              cursor: 'pointer',
              fontSize: '1.3em',
            }}
          >
            <FiTrash2 />
          </span>
        )}
      </InputGroupButtonDropdown>
    </InputGroup>
  );
};

export default FrequencyInput;
