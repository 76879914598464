import { GSBLAdminDB, sugarLandDB } from '../../../../../../utils/firebase';
import 'firebase/firestore';
import firebase from 'firebase';
import { toast } from 'react-toastify';

async function tmpGetCustomers() {
  let children = [];
  await GSBLAdminDB.collection('customers')
    .get()
    .then((querySnapshot) =>
      querySnapshot.forEach((child) => children.push({ data: child.data() }))
    )
    .catch((res) => console.log({ res }));
  return children;
}

const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

const generateTempOrderNumber = () =>
  `TMP-${Math.floor(Math.random() * 10)}-${Math.floor(Math.random() * 100000)}`;

const generateTempCustomerOrderNumber = () =>
  `TMP-${Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, 2)
    .toUpperCase()}${Math.floor(Math.random() * 1000)}`;

const structureDetailsData = ({
  description = '',
  date = new Date(),
  type = 'service',
  released = 0,
  status = 'open',
  project_id,
  project_name,
}) => {
  return {
    order_no: generateTempOrderNumber(),
    customer_order_no: generateTempCustomerOrderNumber(),
    description,
    date,
    type,
    released,
    status,
    // project: project_id,
    // location: project_name,
  };
};

const structureItemsData = (data) => {
  if (!data) return [];
  const tmp = data.map(({ data }) => {
    const {
      dno,
      formula,
      ni_vidno,
      level,
      not_last_value,
      not_last_level,
      prodaja_podrejeni,
      prodaja_pozicije,
      ...rest
    } = data;
    return rest;
  });
  return convertArrayToObject(tmp, 'material_code');
};

const generateMetaObject = (data) => {
  const { customer, ...project } = data;
  const { id, ...rest } = customer;
  const ref = `/customers/${id}`;
  return {
    project,
    customer: {
      _id_: id,
      _ref_: ref,
      data: rest,
    },
  };
};

async function lazyFetch(ref) {
  const fsdb = firebase.firestore();
  let children = [];
  await fsdb
    .collection(ref + '/bom')
    .get()
    .then((querySnapshot) =>
      querySnapshot.forEach((child) => children.push({ data: child.data() }))
    )
    .catch((res) => console.log({ res }));
  return children;
}

function process(obj, index, path) {
  const {
    Item, //podatki iz NAV šifranta kosov
    BomNode, //podatki iz NAV drevesa
    Translations, //podatki iz NAV šifranta prevodov
    data, //posebni podatki za aplikacijo (kljukice...)
    _ref_, //lokacija dokumenta v Firestore
  } = obj;
  // Spodnji data objekt je konstrukt,
  // pripravljen za delovanje PrimeReact komponente;
  // ne mešaj ga z 'data' gnezdenim objektom v Firestore kosovnici.
  const _data = {
    Description: BomNode.Description,
    Translations: {
      SVN: {
        Description: BomNode.Description,
        Language_Code: 'SVN',
      },
      ...Translations,
    },
    No: BomNode.No,
    Indentation: BomNode.Indentation,
    QtyperParent: BomNode.QtyperParent,
    Price: data.price || 0,
    IsLeaf: BomNode.IsLeaf,
    prodaja_pozicije: data.prodaja_pozicije,
    prodaja_podrejeni: data.prodaja_podrejeni,
    ni_vidno: data.ni_vidno,
    dno: data.dno,
    quick_select: data.quick_select || false,
    quick_select_quantity: data.quick_select_quantity || null,
    quick_select_price: data.quick_select_price || null,
  };
  //'key' - edinstven označevalec vrstice
  const _key = path ? path + '-' + index.toString() : index.toString();
  //'leaf' - ali ima kos potomce?
  const _leaf = BomNode.IsLeaf || data.dno;
  return {
    key: _key,
    leaf: _leaf,
    data: _data,
    ref: _ref_,
  };
}
//Funkcija za vstavitev otrok na pravo mesto v drevesu:
function insert(root, key, input, mode) {
  let regex = new RegExp('^' + root.key);
  if (key.match(regex)) {
    if (root.key == key) {
      //Ničen modus pomeni vstavitev otrok,
      //opredeljen pa vstavitev podatka:
      if (mode) {
        root.data[mode] = input;
      } else {
        root.children = input;
      }
      return root;
    } else {
      root.children = root.children
        ? root.children.map((child) => {
            return insert(child, key, input, mode);
          })
        : [];
      return root;
    }
  } else {
    return root;
  }
}

export {
  process,
  insert,
  lazyFetch,
  convertArrayToObject,
  structureItemsData,
  structureDetailsData,
  // releaseOrder,
};
