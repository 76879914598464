import React from 'react';
import styled, { css } from 'styled-components';

const StyledBadge = styled.span`
  font-size: 0.8em;
  padding: 0.2em 0.5em;
  font-weight: 500;
  background-repeat: 3px;

  ${({ value }) =>
    value < 0
      ? css`
          color: ${({ theme }) => theme.colors.main.danger._100};
          background: ${({ theme }) => theme.colors.main.danger._10};
        `
      : css`
          color: ${({ theme }) => theme.colors.main.success._100};
          background: ${({ theme }) => theme.colors.main.success._10};
        `}
`;
const TrendBadge = ({ inverted, value }) => {
  const tmp = inverted ? value * -1 : value;
  return (
    <StyledBadge value={tmp}>{value > 0 ? '+' + value : value}%</StyledBadge>
  );
};

export default TrendBadge;
