import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Input, FormGroup } from 'reactstrap';
import PartsForm from './PartsForm';
import {
  getPartsSequence,
  getParts,
  insertPart,
  updatePart,
  deletePart,
} from '../calls/parts';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import Skeleton from 'react-loading-skeleton';
import ActionButtons from '../ActionButtons';
import TableReact from '../TableTemplate';

const PartsTable = ({ data }) => {
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [parts, setParts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState(null);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [rowIndex, setRowIndex] = useState(0);
  const [rowSelected, setRowSelected] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getParts({
        imachine_maintanance_id:
          data.selected.maintenance.machine_maintanance_id,
      });
      setParts(result.parts);
      setRowSelected(result.parts && result.parts[0]);
      setLoading(result.loading);
    };
    fetchData();
  }, []);

  const toggleForm = () => setIsOpenForm(!isOpenForm);
  const toggleDelete = () => setIsOpenDelete(!isOpenDelete);

  const handleOpenDelete = () => {
    setDeleteData(rowSelected.maintanance_parts_id);
    toggleDelete();
  };

  const handleCreate = async (item) => {
    const resultSeq = await getPartsSequence();
    if (resultSeq.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }

    const itemWithId = {
      ...item,
      maintanance_parts_id: Number(resultSeq),
      machine_maintanance_id: data.selected.maintenance.machine_maintanance_id,
    };

    const result = await insertPart(itemWithId);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }

    const isFirstCreated = parts.length === 0;
    isFirstCreated && setRowSelected(itemWithId);
    setParts([...parts, itemWithId]);
    toggleForm();
    toast.success('Part created successfully.');
  };

  const handleEdit = async (item) => {
    const { maintanance_parts_id } = item;
    const result = await updatePart({
      ...item,
      task_id: item.maintanance_parts_id,
    });
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }
    const tmp = parts.filter(
      (part) => part.maintanance_parts_id !== maintanance_parts_id
    );
    setParts([...tmp, item]);
    setRowSelected(item);
    toggleForm();
    toast.success('Maintenance edited successfully.');
  };

  const handleDelete = async () => {
    const result = await deletePart({
      ...rowSelected,
      task_id: rowSelected.maintanance_parts_id,
    });
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }

    setParts(parts.filter((item) => item.maintanance_parts_id !== deleteData));
    toggleDelete();
    toast.success('Part deleted.');
  };

  const handleOpenForm = (action, row = rowSelected) => {
    const isNew = action === 'create';
    const { selected } = data;
    const nextOrdNo = parts.length
      ? Math.trunc(Math.max(...parts.map((part) => part.pos))) + 1
      : 1;
    const tmp = { ...row, action, selected, pos: isNew ? nextOrdNo : row.pos };
    setFormData(tmp);
    toggleForm();
  };

  const handleRowSelect = (info) => {
    setRowIndex(info.index);
    setRowSelected(info.original);
  };

  const columns = [
    /*{
        id: 'maintanance_parts_id',
        Header: 'maintanance_parts_id',
        accessor: "maintanance_parts_id"
    },{
        id: 'machine_maintanance_id',
        Header: 'machine_maintanance_id',
        accessor: "machine_maintanance_id"
    },*/ {
      id: 'pos',
      Header: 'Ord.No.',
      width: 100,
      accessor: 'pos',
      style: { textAlign: 'right' },
    },
    {
      id: 'ident_nr',
      Header: 'Ident number',
      accessor: 'ident_nr',
      style: { textAlign: 'right' },
    },
    {
      id: 'name',
      Header: 'Name',
      accessor: 'name',
    },
    {
      id: 'quantity',
      Header: 'Quantity',
      accessor: 'quantity',
      style: { textAlign: 'right' },
    },
    /* {
            id: 'price',
            Header: 'Price',
            accessor: "price",
            style: { textAlign: "right" }
        }, */ {
      id: 'minq',
      Header: 'Minimal quantity',
      accessor: 'minq',
      style: { textAlign: 'right' },
    },
    {
      id: 'maxq',
      Header: 'Maximum  quantity',
      accessor: 'maxq',
      style: { textAlign: 'right' },
    },
    /*{
            id: 'is_checked',
            Header: 'Is checked',
            accessor: "is_checked",
            style: { textAlign: "center" },
            Cell: (row) => (
                <FormGroup check>
                    <Input
                        
                        checked={row.original.is_checked || row.original.is_checked === 't'}
                        type="checkbox"
                        name="is_checked" />{' '}
                </FormGroup>
            )
        }, */ {
      id: 'is_required',
      Header: 'Is required',
      accessor: 'is_required',
      style: { textAlign: 'center' },
      Cell: ({ row }) => (
        <FormGroup check>
          <Input
            readOnly
            checked={
              row.original.is_required || row.original.is_required === 't'
            }
            type="checkbox"
            name="is_required"
          />{' '}
        </FormGroup>
      ),
    },
  ];
  const disabled = parts.length === 0;

  return loading ? (
    <Skeleton height={200} />
  ) : (
    <React.Fragment>
      <ActionButtons
        isDisabled={disabled}
        handleCreate={handleOpenForm}
        handleEdit={handleOpenForm}
        handleDuplicate
        handleDelete={handleOpenDelete}
      />
      <TableReact
        hover
        columns={columns}
        data={parts}
        handleCreate={handleOpenForm}
        rowIndex={rowIndex}
        handleRowSelect={handleRowSelect}
      />
      {formData && (
        <PartsForm
          data={formData}
          isOpen={isOpenForm}
          handleCreate={handleCreate}
          handleEdit={handleEdit}
          toggle={toggleForm}
        />
      )}
      <DeleteConfirmationModal
        text={'part'}
        handleDelete={handleDelete}
        isOpen={isOpenDelete}
        toggle={toggleDelete}
      />
    </React.Fragment>
  );
};

export default PartsTable;
