import React, { Fragment, useEffect, useState } from 'react';
import { Button, Modal, FormGroup, Label } from 'reactstrap';
import settings from '../../../settings';
import Select from 'react-select';
import ReplyModal from './ReplyModal';
import { getUserDetails } from '../../../../../lib/helpers';

const Reply = ({ uuid, refreshComments, submissionId, statusUpdated }) => {
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [canSetStatus, setCanSetStatus] = useState(false);

  useEffect(() => {
    getUserDetails().then((data) => {
      if (!data) return false;
      const rolesThatCanSetStatuses = ['site-admin'];
      const canSetStatus = rolesThatCanSetStatuses.includes(data.user_role);
      setCanSetStatus(canSetStatus);
    });
  }, []);

  const toggleModal = () => {
    refreshComments(uuid);
    setIsModalOpen(!isModalOpen);
  };

  const handleSelectChange = (e) => {
    if (!e || e.value !== 'duplicate') {
      setIsDuplicate(false);
    } else {
      setIsDuplicate(true);
    }
    const { url, method } = settings.api.updateIssue;
    const data = {
      submissionId,
      status: e.value,
    };
    fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        statusUpdated();
      })
      .catch((error) => console.error('Error:', error));
  };

  //const { isDuplicate } = this.state
  /* const showReferenceSelect = <FormGroup>
             <Label className="mr-2">Reference to</Label><Select
                 name="form-field-name"
                 // onChange={this.handleSelectChange}                    
                 isClearable
                 options={[
                     { value: '12345', label: "#12345" },
                     { value: '12346', label: "#12346" },
                     { value: '12347', label: "#12347" },
                     { value: '12348', label: "#12348" },
                     { value: '12349', label: "#12349" }
                 ]}
                 styles={{ control: styles => ({ ...styles, backgroundColor: 'white', width: 240 }), }}
             /></FormGroup>*/
  return (
    <Fragment>
      {/*
                    canSetStatus &&
                    <FormGroup className="mr-3">
                    <Label className="mr-2">Set status</Label>
                    <Select
                        name="form-field-name"
                        onChange={handleSelectChange}
                        isClearable
                        options={[
                            { value: 'open', label: "Open" },
                            { value: 'in-process', label: "In process" },
                            { value: 'on-hold', label: "On hold" },
                            { value: 'missing-info', label: "Missing information" },
                            { value: 'duplicate', label: "Duplicate" },
                            { value: 'closed', label: "Closed" }
                        ]}
                        styles={{ control: styles => ({ ...styles, backgroundColor: 'white', width: 240 }), }}/>
                </FormGroup>
                    */}
      <Button color="info mb-4 mt-4" onClick={toggleModal}>
        Add Reply
      </Button>
      <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
        <ReplyModal uuid={uuid} toggle={toggleModal} />
      </Modal>
    </Fragment>
  );
};
/* <Form inline className="mb-2">
                    <FormGroup className="mr-3">
                        <Label className="mr-2">Set status</Label>
                        <Select
                            name="form-field-name"
                            onChange={this.handleSelectChange}
                            isClearable
                            options={[
                                { value: 'open', label: "Open" },
                                { value: 'in-process', label: "In process" },
                                { value: 'on-hold', label: "On hold" },
                                { value: 'missing-info', label: "Missing information" },
                                { value: 'duplicate', label: "Duplicate" }
                            ]}
                            styles={{ control: styles => ({ ...styles, backgroundColor: 'white', width: 240 }), }}
                        />
                    </FormGroup>
                    {isDuplicate ? showReferenceSelect : ""}
                </Form>
                <Input type="textarea" name="text" id="exampleText" rows="5" placeholder="Click here to add reply" />*/
export default Reply;
