import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Col,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
} from 'reactstrap';
import { StyledButton } from '../../../../../components/DateSelection/DateSelection';
import FileUpload from '../../../../../components/FileUpload';
import { createOption } from '../../../../../pages/Settings/AdminPage/helpers';
import firebase from 'firebase';
import 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { getUserDetails, snakeCase } from '../../../../../lib/helpers';
import MachineSelectionInput from '../../../../../pages/Settings/AdminPage/Form/MachineSelectionInput';
import { useStore } from '../../../../../store';
import { StyledReactQuill } from './IssuesList';

const TYPES_OF_ISSUES = [
  createOption('Electrical'),
  createOption('Mechanical'),
  createOption('Software'),
];
const EMPTY_ISSUE_STATE = {
  machine: '',
  createdAt: '',
  changedAt: '',
  subject: '',
  type: '',
  user: '',
  priority: false,
  status: '',
  message: '',
  attachments: [],
};

const NewIssueModal = ({
  isOpen,
  toggle,
  selectedMachine,
  // machines,
  isEdit,
  data,
}) => {
  const [tmpSelectedMachine, setTmpSelectedMachine] = useState(selectedMachine);
  const [issue, setIssue] = useState(isEdit ? data : EMPTY_ISSUE_STATE);
  const [message, setNewMessage] = useState(
    isEdit ? data.message : EMPTY_ISSUE_STATE
  );
  const [validation, setValidation] = useState({
    machine: false,
    type: false,
    subject: false,
    message: false,
  });
  const { state } = useStore();

  // useEffect(() => {
  //   const editSelectedMachine =
  //     isEdit &&
  //     state.projectMachines.find(
  //       (item) => item.machine_id === data?.machine?.machine_id
  //     );
  //   console.log({ editSelectedMachine });
  //   setTmpSelectedMachine(editSelectedMachine);
  // }, [data, mode]);

  useEffect(() => {
    const editSelectedMachine =
      isEdit &&
      state.projectMachines.find(
        (item) => item.machine_id === data?.machine?.machine_id
      );
    setTmpSelectedMachine(isEdit ? editSelectedMachine : selectedMachine);
    putMachineDataInIssue(selectedMachine);
  }, [selectedMachine, data]);

  const putMachineDataInIssue = (machine) => {
    if (!machine) return;
    const {
      project_id,
      model_nr,
      machine_id,
      machine_name,
      description,
    } = machine;
    setIssue({
      ...issue,
      machine: { project_id, model_nr, machine_id, machine_name, description },
    });
  };

  const handleMachineSelect = (machine) => {
    putMachineDataInIssue(machine);
    setTmpSelectedMachine(machine);
  };

  const sendDataToFirestore = async ({ data, docId }) => {
    const fsdb = firebase.firestore();
    await fsdb
      .collection('machines')
      .doc(data.machine.machine_id.toString())
      .collection('issues')
      .doc(docId.toString())
      .set(data, { merge: true });
    // setIssue(EMPTY_ISSUE_STATE);
    // toggle();
  };
  const sendAttachmentsToStorage = ({ dataToDSend, docId }) => {
    const { attachments: files } = dataToDSend;
    const promises = files.map((file) => {
      let fileRef = firebase
        .storage()
        .ref()
        .child(`issue_att/${snakeCase(file.name)}`);
      return fileRef
        .put(file.File)
        .then(() => fileRef.getDownloadURL())
        .then((url) => {
          const { File, ...rest } = file;
          return { ...rest, fileURL: url };
        });
    });
    Promise.all(promises)
      .then((attachments) => {
        const newAttachments = data
          ? [...data?.attachments, ...attachments]
          : attachments;
        const dataWithAttachmentURLs = {
          ...dataToDSend,
          attachments: newAttachments,
        };
        sendDataToFirestore({
          data: dataWithAttachmentURLs,
          docId,
        });
        setIssue(EMPTY_ISSUE_STATE);
        toggle();
      })
      .catch((err) => {
        console.error({ err });
        alert(err.code);
      });
  };
  // files.forEach((file) => {
  //   const uploadTask = firebase
  //     .storage()
  //     .ref()
  //     .child(`issue_att/${snakeCase(file.name)}`)
  //     .put(file.File);
  //   promises.push(uploadTask);
  //   uploadTask.on(
  //     firebase.storage.TaskEvent.STATE_CHANGED,
  //     (snapshot) => {
  //       const progress =
  //         (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //       if (snapshot.state === firebase.storage.TaskState.RUNNING) {
  //         console.log(`Progress: ${progress}%`);
  //       }
  //     },
  //     (error) => {
  //       console.log(error.code);
  //     },

  //     (complete) => {
  //       uploadTask.snapshot.ref.getDownloadURL().then((url) => {
  //         // do something with the url
  //         const { File, ...rest } = file;
  //         downloadURLs.push({ downloadURL: url, ...rest });
  //         console.log({ downloadURLs, complete });
  //       });
  //     }
  //   );
  // });
  //   Promise.all(promises)
  //     .then(() => {
  //       console.log('completed');
  //       const dataWithAttachmentURLs = { ...data, attachments: downloadURLs };
  //       console.log({ dataWithAttachmentURLs });
  //       // sendDataToFirestore({
  //       //   data: dataWithAttachmentURLs,
  //       //   docId,
  //       // });
  //     })
  //     .catch((err) => console.log(err.code));
  // };
  const handleTypeSelect = (type) => setIssue({ ...issue, type: type.label });
  const handleSendIssue = async () => {
    const notValidated = handleFieldsValidation();
    if (notValidated) return;

    const createdAt = isEdit ? data.createdAt : +new Date();
    const userDetails = await getUserDetails();
    const { company_name, name, user_role, id } = isEdit
      ? data.user
      : userDetails;
    const user = { company_name, name, user_role, id };
    const status = isEdit ? data.status : 'Pending';
    const dataToDSend = { ...issue, message, createdAt, user, status };
    sendAttachmentsToStorage({
      dataToDSend,
      docId: createdAt,
    });
    // sendDataToFirestore({ data: dataWithAttachmentURLs, docId: createdAt });
  };
  const handleFieldsValidation = () => {
    const validationState = {
      machine: !Boolean(issue.machine) && !selectedMachine,
      type: !Boolean(issue.type),
      subject: !Boolean(issue.subject),
      message: !Boolean(message),
    };
    setValidation(validationState);
    return Object.values(validationState).indexOf(true) > -1;
  };

  const putAttachmentsIntoIssue = (files) => {
    const filesInArray = [...files];
    const attachments = filesInArray.map((item) => {
      const { type, name, size } = item;
      return {
        File: item,
        type,
        name,
        size,
      };
    });
    setIssue({ ...issue, attachments });
  };

  return (
    <Modal
      autoFocus={false}
      fade={false}
      isOpen={isOpen}
      toggle={toggle}
      contentClassName="shadow-lg"
      size={'lg'}
    >
      <ModalHeader>{selectedMachine?.description}</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label for="machine-name" sm={3}>
              Machine
            </Label>
            <Col sm={9}>
              <MachineSelectionInput
                data={tmpSelectedMachine}
                allMachines={state.projectMachines}
                handleMachineSelect={handleMachineSelect}
              />

              {validation.machine && (
                <small style={{ color: 'red' }}>Machine is required</small>
              )}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="type" sm={3}>
              Type of an issue
            </Label>
            <Col sm={9}>
              <Select
                defaultValue={
                  isEdit &&
                  TYPES_OF_ISSUES.find((item) => item.label === data.type)
                }
                name="colors"
                options={TYPES_OF_ISSUES}
                className="basic-single"
                classNamePrefix="select"
                onChange={handleTypeSelect}
              />
              {validation.type && (
                <small style={{ color: 'red' }}>
                  Type of an issue is required
                </small>
              )}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="priority" sm={3}>
              Priority
            </Label>
            <Col sm={{ size: 9 }}>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    id="priority"
                    checked={issue['priority']}
                    name="issue[priority]"
                    onChange={(e) =>
                      setIssue({ ...issue, priority: e.target.checked })
                    }
                  />{' '}
                </Label>
              </FormGroup>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="subject" sm={3}>
              Subject
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                id="subject"
                placeholder="Name subject"
                name="issue[subject]"
                value={issue.subject}
                onChange={(e) =>
                  setIssue({ ...issue, subject: e.target.value })
                }
              />
              {validation.subject && (
                <small style={{ color: 'red' }}>Subject is required</small>
              )}
            </Col>
          </FormGroup>
          <FormGroup>
            <Label for="message">Description</Label>
            <StyledReactQuill
              theme="snow"
              value={message}
              onChange={setNewMessage}
            />
            {/* <Input
              type="textarea"
              name="issue[message]"
              id="message"
              style={{ height: 150 }}
              value={issue.message}
              onChange={(e) => setIssue({ ...issue, message: e.target.value })}
            /> */}
            {validation.message && (
              <small style={{ color: 'red' }}>Description is required</small>
            )}
            <FileUpload
              accept="video/*, image/*, application/pdf"
              label="Attachments"
              multiple
              updateFilesCb={putAttachmentsIntoIssue}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <StyledButton color="primary" onClick={handleSendIssue}>
          {isEdit ? 'Update message' : 'Send message'}
        </StyledButton>
      </ModalFooter>
    </Modal>
  );
};

export default NewIssueModal;
