import docCookies from '../../lib/docCookies';

// old code below
export function statusBadge(value) {
  switch (value.toLowerCase()) {
    case 'draft':
      return {
        value: 'Draft',
        color: 'secondary',
      };
    case 'in-process':
      return {
        value: 'In process',
        color: 'success',
      };
    case 'on-hold':
      return {
        value: 'On hold',
        color: 'warning',
      };
    case 'duplicate':
      return {
        value: 'Duplicate',
        color: 'danger',
      };
    case 'open':
      return {
        value: 'Open',
        color: 'success',
      };
    case 'missing-info':
      return {
        value: 'Missing info',
        color: 'warning',
      };
    case 'closed':
      return {
        value: 'Closed',
        color: 'danger',
      };
    default:
      return {
        value,
        color: 'danger',
      };
  }
}

export function priorityBadge(value) {
  switch (value.toLowerCase()) {
    case 'low':
      return {
        value: 'Low',
        color: 'success',
      };
    case 'medium':
      return {
        value: 'Medium',
        color: 'warning',
      };
    case 'high':
      return {
        value: 'High',
        color: 'danger',
      };
    case 'urgent':
      return {
        value: 'Urgent',
        color: 'dark',
      };
    default:
      return {
        value,
        color: 'secondary',
      };
  }
}

export async function sendOrder(task, items) {
  const updateMaintenance = await newMaintenance(task);
  updateMaintenance.errorCode === 0 && sendMail(items, task);
}

async function newMaintenance(task) {
  const token = docCookies.getItem('userToken');
  const { project_id, model_nr, serial_nr, maintanance_id } = task;
  const maintanance_status_flag = 1000;
  const comment_text = '';

  const response = await fetch(
    process.env.REACT_APP_API_PREFIX_V2 + 'dbrequest.php',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token,
        node: '/machine/maintanance/insert',
        project_id,
        model_nr,
        serial_nr,
        maintanance_id,
        maintanance_status_flag,
        comment_text,
      }),
    }
  );

  return response.json();
}

async function sendMail(items, task) {
  const user = await findUser();
  const sendOrder = await fetch(
    process.env.REACT_APP_API_PREFIX + 'appapi/v1/send-mail',
    {
      method: 'POST',
      body: JSON.stringify({
        user: user[0],
        items,
        task,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  return sendOrder.json();
}

async function findUser() {
  const token = docCookies.getItem('userToken');
  const response = await fetch(
    process.env.REACT_APP_API_PREFIX_V2 + 'dbrequest.php',
    {
      method: 'POST',
      body: JSON.stringify({
        token,
        node: '/user/info',
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  return response.json();
}
