import React, { useContext, useEffect } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import styled from 'styled-components';
import {
  handleNewOrder,
  sortOrdersByModifiedDate,
} from '../../../../../Orders/views/partials/OrdersTable/OrdersTable.helpers';
import ItemsTable from '../../../../../Orders/views/partials/OrderItems';
import ShopItems from '../../../../../Orders/views/partials/ShopItems';
import QuickPick from '../../../../../Orders/views/partials/QuickPick';
import { AuthContext } from '../../../../../../utils/firebaseAuth';
import Tabs from '../../../../../../components/Tabs';
import { useStore } from '../../../../../../store';
import { useGetOrders } from '../../../../../Orders/views/partials/OrdersTable/OrdersTable';

const StylizedShopContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const Shop = ({ selectedMachine }) => {
  const { state, dispatch } = useStore();
  const { selectedOrder } = state;
  const { currentProject, currentUser } = useContext(AuthContext);

  const [orders] = useGetOrders('open');

  useEffect(() => {
    if (!orders) {
      const type = 'service';
      const payload = handleNewOrder({ currentProject, currentUser, type });
      dispatch({ type: 'SELECTED_ORDER', payload });
      return;
    }
    const sorted = sortOrdersByModifiedDate(orders);
    dispatch({ type: 'OPEN_ORDERS', payload: sorted });
    dispatch({ type: 'SELECTED_ORDER', payload: sorted[0] });
    return () => {
      dispatch({ type: 'OPEN_ORDERS', payload: null });
    };
  }, [orders]);

  // useEffect(() => {
  //   const type = 'service';
  //   const payload = handleNewOrder({ currentProject, currentUser, type });
  //    dispatch({ type: 'SELECTED_ORDER', payload });
  // }, [currentProject]);

  return (
    <Card>
      <CardHeader className="d-flex justify-content-between"></CardHeader>
      <CardBody className="p-0">
        <StylizedShopContainer>
          <Tabs tabs={['Quick Pick', 'By machine']}>
            <QuickPick />
            <ShopItems selectedMachine={selectedMachine} />
          </Tabs>
          {selectedOrder && (
            <>
              <hr />
              <ItemsTable hideAddButton />
            </>
          )}
        </StylizedShopContainer>
      </CardBody>
    </Card>
  );
};

export default Shop;
