import React, { useEffect, useState } from 'react';
import { FiFilter, FiX } from 'react-icons/fi';
import {
  UncontrolledCollapse,
  Button,
  FormGroup,
  Label,
  Row,
  Col,
  Form,
  Input,
} from 'reactstrap';
import styled, { css } from 'styled-components';

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  background: white;
  border-radius: 3px;
  border: none;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  padding: 0.5em 1em;
  color: ${({ theme }) => theme.colors.grayscale._100};

  span {
    padding-left: 0.5rem;
  }

  ${({ small }) =>
    small &&
    css`
      padding: 0.5em 0.35em;
    `}

  &:hover {
    background: ${({ theme }) => theme.colors.grayscale._10};
    color: ${({ theme }) => theme.colors.grayscale._100};
  }
`;

const Filter = ({ data, onChange, filterBy }) => {
  const [filteredList, setFilteredList] = useState(data);

  const [filter, setFilter] = useState([]);
  const [active, setActive] = useState({});

  const buildFilter = (filter) => {
    let query = {};
    for (let keys in filter) {
      if (filter[keys].constructor === Array && filter[keys].length > 0) {
        query[keys] = filter[keys];
      }
    }
    return query;
  };

  const filterData = (data, query) => {
    const filteredData = data.filter((item) => {
      for (let key in query) {
        if (item[key] === undefined || !query[key].includes(item[key])) {
          return false;
        }
      }
      return true;
    });
    return filteredData;
  };

  useEffect(() => {
    const lists = filterBy.map((filter) => ({
      ...filter,
      list: [...new Set(data.map((item) => item[filter.type]))],
    }));

    const active = {};
    for (const key of filterBy) {
      active[key.type] = key.active || [];
    }
    setActive(active);
    setFilter(lists);
  }, [data]);

  useEffect(() => {
    onChange(filteredList);
  }, [filteredList]);

  useEffect(() => {
    setFilteredList(getFilteredList());
  }, [active]);

  const getFilteredList = () => {
    const query = buildFilter(active);
    return filterData(data, query);
  };

  const clearFilters = () => {
    const active = {};
    for (const key of filterBy) {
      active[key.type] = [];
    }

    setActive(active);
  };

  const onChangeActive = (type, item) => {
    if (active[type].includes(item)) {
      const filterIndex = active[type].indexOf(item);
      const newFilter = [...active[type]];
      newFilter.splice(filterIndex, 1);
      setActive({ ...active, [type]: newFilter });
    } else {
      setActive({ ...active, [type]: [...active[type], item] });
    }
  };
  const checkForActiveFilter = () => {
    for (let keys in active) {
      if (active[keys].constructor === Array && active[keys].length > 0) {
        return true;
      }
    }
    return false;
  };
  return (
    <div className="bg-light py-2 pl-3">
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <StyledButton id="toggler" outline className="mr-2">
          <FiFilter /> <span>Filter</span>
        </StyledButton>

        {checkForActiveFilter() && (
          <small style={{ cursor: 'pointer' }} onClick={clearFilters}>
            Clear filters <FiX />
          </small>
        )}
      </div>
      <UncontrolledCollapse toggler="#toggler" className="p-2">
        <Form>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              columnGap: 10,
            }}
          >
            {filter.map(({ list, type }, index) => {
              const numberOfColumns = Math.floor(list.length / 5) || 1;

              return (
                <div key={index}>
                  <FormGroup>
                    <Label for="machine">
                      <strong>
                        {filterBy[index].label || filterBy[index].type}
                      </strong>
                    </Label>
                    <FormGroup tag="fieldset">
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {list.map((item) => (
                          <FormGroup check key={item}>
                            <Label
                              check
                              onClick={(e) => {
                                // if there is no stopPropagation, clicking on label will close modal (in MAS module)
                                e.stopPropagation();
                              }}
                            >
                              <Input
                                type="checkbox"
                                checked={active[type].includes(item)}
                                onChange={() => onChangeActive(type, item)}
                              />
                              {item}
                            </Label>
                          </FormGroup>
                        ))}
                      </div>
                    </FormGroup>
                  </FormGroup>
                </div>
              );
            })}
          </div>
        </Form>
      </UncontrolledCollapse>
    </div>
  );
};

export default Filter;
