import React from 'react';
import { Col, Card, CardHeader, CardBody, Row } from 'reactstrap';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/chart/custom.js';
import { getColorScheme } from '../../../../../lib/helpers';

const LossModalData = ({ data, setModalData, sums }) => {
  const colorScheme = getColorScheme();
  const bolderStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingTop: 0,
    fontSize: '1.5em',
    flexWrap: 'wrap',
    fontWeight: 'bold',
  };
  const optionPieces = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)',
    },
    legend: {
      orient: 'vertical',
      left: 10,
      data: ['Produced pieces', 'Ejected pieces'],
    },
    series: [
      {
        name: 'Pieces',
        type: 'pie',
        data: [
          {
            value: Number(sums?.counters?.pieces?.rejected),
            name: 'Ejected pieces',
            itemStyle: { color: '#DF394E' },
          },
          {
            value: Number(sums?.counters?.pieces?.out),
            name: 'Produced pieces',
            itemStyle: { color: '#24C48E' },
          },
        ],
      },
    ],
  };

  const optionMass = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c}kg ({d}%)',
    },
    legend: {
      orient: 'vertical',
      left: 10,
      data: ['Produced mass', 'Ejected mass'],
    },
    series: [
      {
        name: 'Pieces',
        type: 'pie',
        data: [
          {
            value: Math.floor(Number(sums?.counters?.mass_g?.rejected)),
            name: 'Ejected mass',
            itemStyle: { color: '#DF394E' },
          },
          {
            value: Math.floor(Number(sums?.counters?.mass_g?.out)),
            name: 'Produced mass',
            itemStyle: { color: '#24C48E' },
          },
        ],
      },
    ],
  };

  const header = 'Produced Pieces and Mass';
  return (
    <Row>
      <Col lg={6}>
        {' '}
        <Card className="mb-3">
          <CardHeader>Pieces</CardHeader>
          <CardBody
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              paddingTop: 0,
              flexWrap: 'wrap',
              paddingTop: '.25rem',
              paddingBottom: '.25rem',
              fontWeight: 'bold',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: colorScheme.grayscale._40 }}>Total</div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div style={{ fontSize: '1.5em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    sums?.counters?.pieces?.total
                  )}
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: colorScheme.grayscale._40 }}>Produced</div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div style={{ fontSize: '1.5em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Number(sums?.counters?.pieces?.out)
                  )}
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: colorScheme.grayscale._40 }}>Ejected</div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div style={{ fontSize: '1.5em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    sums?.counters?.pieces?.rejected
                  )}
                </div>
                <div style={{ paddingLeft: '1em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Number(
                      (
                        (Number(sums?.counters?.pieces?.rejected) /
                          Number(sums?.counters?.pieces?.out)) *
                        100
                      ).toFixed(2)
                    )
                  )}
                  %
                </div>
              </div>
            </div>
          </CardBody>
          <CardBody>
            <ReactEchartsCore echarts={echarts} option={optionPieces} />
          </CardBody>
        </Card>
      </Col>
      <Col lg={6}>
        {' '}
        <Card className="mb-3">
          <CardHeader>Mass</CardHeader>
          <CardBody
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              paddingTop: 0,
              flexWrap: 'wrap',
              paddingTop: '.25rem',
              paddingBottom: '.25rem',
              fontWeight: 'bold',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: colorScheme.grayscale._40 }}>Total</div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div style={{ fontSize: '1.5em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Math.round(sums?.counters?.mass_g?.total / 1000)
                  )}
                  kg
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: colorScheme.grayscale._40 }}>Produced</div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div style={{ fontSize: '1.5em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Math.round(Number(sums?.counters?.mass_g?.out / 1000))
                  )}
                  kg
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: colorScheme.grayscale._40 }}>Ejected</div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div style={{ fontSize: '1.5em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Math.round(sums?.counters?.mass_g?.rejected / 1000)
                  )}
                  kg
                </div>
                <div style={{ paddingLeft: '1em' }}>
                  {Intl.NumberFormat(window.navigator.language).format(
                    Number(
                      (
                        (Number(sums?.counters?.mass_g?.rejected / 1000) /
                          Number(sums?.counters?.mass_g?.total / 1000)) *
                        100
                      ).toFixed(2)
                    )
                  )}
                  %
                </div>
              </div>
            </div>
          </CardBody>
          <CardBody>
            <ReactEchartsCore echarts={echarts} option={optionMass} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );

  // setModalData({ header, body });
};

export default LossModalData;
