import React, { useState, useEffect } from 'react';
import Breadcrumbs from '../../../components/Breadcrumb/Breadcrumb';
import DateSelection from '../../../components/DateSelection';
import { Container, Col, Row, Card, CardHeader, CardBody } from 'reactstrap';
import { useStore } from '../../../store';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import moment from 'moment';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { getColorScheme, getLastXDays } from '../../../lib/helpers';
import NoContent from '../../../components/NoContent/NoContent';
import { useNonInitialEffect } from '../../../lib/helpers/customHooks';
import { useFirestore, useFirestoreDocDataOnce } from 'reactfire';

const EnergyRest = (props) => {
  const { state, dispatch } = useStore();
  const [dates, setDates] = useState(
    state.energy.dates || [getLastXDays(6), getLastXDays(0)]
  );
  const [data, setData] = useState(state.energy.data || []);
  const [, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDate, setLoadingDate] = useState(false);
  const [filtered, setFiltered] = useState(state.energy.filtered || []);
  //const [colorPalette, setColorPalette] = useState([])
  const [consumption, setConsumption] = useState(
    state.energy.consumption || {
      dates: [],
      entry: [],
      main: [],
      oven: [],
      gas: [],
      steam: [],
      steamOven: [],
      pieces: [],
      mass: [],
    }
  );
  const [piecesAndMass, setPiecesAndMass] = useState(
    state.energy.piecesAndMass || {
      piecesGood: [],
      piecesRejected: [],
      massGood: [],
      massRejected: [],
    }
  );
  const colorScheme = getColorScheme();

  const projectRef = useFirestore().collection('project').doc('0');
  const { data: projectData } = useFirestoreDocDataOnce(projectRef);
  /*
    const [oee, setOee] = useState(state.energy.oee || {
        availability: [],
        performance: [],
        quality: [],
        oee: [],
    })
    */
  useNonInitialEffect(() => {
    const hasNull = dates.some((item) => item === null);
    !hasNull && fetchData();
  }, [dates, projectData]);

  useEffect(() => {
    //if (!_.isEmpty(state.energy)) return
    const hasNull = dates.some((item) => item === null);
    !hasNull && dates.length && fetchData();
  }, []);

  const cumulativeSum = (sum) => (value) => (sum += Number(value));

  const fetchData = async () => {
    setLoading(_.isEmpty(state.energy));
    setLoadingDate(true);
    const resultReports = await getReports();
    setData(resultReports);

    const filtered = resultReports.sort((a, b) => a.time_ts - b.time_ts);
    setFiltered(filtered);

    const consumption = {
      view: 'total',
      // dates: filtered.map((item) => moment(item.time_dt).format('ddd, DD.MM')),
      dates: filtered.map((item) =>
        moment(Number(item.time_ts)).format('ddd, DD.MM')
      ),
      entry: filtered.map((item) => Number(item.entry_part_kwh).toFixed(2)),
      main: filtered.map((item) => Number(item.main_part_kwh).toFixed(2)),
      oven: filtered.map((item) => Number(item.oven_part_kwh).toFixed(2)),
      mixers: filtered.map((item) => Number(item.mixers_part_kwh).toFixed(2)),
      elCumulative: filtered
        .map((item) =>
          (
            Number(item.entry_part_kwh) +
            Number(item.main_part_kwh) +
            Number(item.oven_part_kwh) +
            Number(item.mixers_part_kwh)
          ).toFixed(2)
        )
        .map(cumulativeSum(0)),
      elTotalSum: filtered
        .map((item) =>
          (
            Number(item.entry_part_kwh) +
            Number(item.main_part_kwh) +
            Number(item.oven_part_kwh) +
            Number(item.mixers_part_kwh)
          ).toFixed(2)
        )
        .reduce((a, b) => Number(a) + Number(b), 0),
      gas: filtered.map((item) => Number(item.oven_gas_m3).toFixed(2)),
      gasCumulative: filtered
        .map((item) => Number(item.oven_gas_m3).toFixed(2))
        .map(cumulativeSum(0)),
      gasTotalSum: filtered
        .map((item) => Number(item.oven_gas_m3).toFixed(2))
        .reduce((a, b) => Number(a) + Number(b), 0),
      recuperation: filtered.map((item) =>
        Number(item.recuperation_kwh).toFixed(2)
      ),
      recuperationCumulative: filtered
        .map((item) => Number(item.recuperation_kwh).toFixed(2))
        .map(cumulativeSum(0)),
      recuperationTotalSum: filtered
        .map((item) => Number(item.recuperation_kwh).toFixed(2))
        .reduce((a, b) => Number(a) + Number(b), 0),
      steam: filtered.map((item) => Number(item.main_part_steam_kg).toFixed(2)),
      steamOven: filtered.map((item) => Number(item.oven_steam_kg).toFixed(2)),
      steamCumulative: filtered
        .map((item) =>
          (
            Number(item.main_part_steam_kg) + Number(item.oven_steam_kg)
          ).toFixed(2)
        )
        .map(cumulativeSum(0)),
      steamTotalSum: filtered
        .map((item) =>
          (
            Number(item.main_part_steam_kg) + Number(item.oven_steam_kg)
          ).toFixed(2)
        )
        .reduce((a, b) => Number(a) + Number(b), 0),
      compressedAir: filtered.map((item) =>
        Number(item.compressed_air_m3).toFixed(2)
      ),
      compressedAirCumulative: filtered
        .map((item) => Number(item.compressed_air_m3).toFixed(2))
        .map(cumulativeSum(0)),
      compressedAirSum: filtered
        .map((item) => Number(item.compressed_air_m3).toFixed(2))
        .reduce((a, b) => Number(a) + Number(b), 0),
    };
    setConsumption(consumption);
    const piecesAndMass = {
      pieces: filtered.map((item) => Number(item.pcs_total)),
      piecesTotalSum: filtered
        .map((item) => Number(item.pcs_total))
        .reduce((a, b) => Number(a) + Number(b), 0),
      mass: filtered.map((item) => Math.floor(Number(item.weight_total_kg))),
      massTotalSum: filtered
        .map((item) => Number(item.weight_total_kg))
        .reduce((a, b) => Number(a) + Number(b), 0),
    };
    setPiecesAndMass(piecesAndMass);
    const res = {
      data: resultReports,
      filtered,
      consumption,
      piecesAndMass,
      dates,
    };
    dispatch({ type: 'SET_DATA_ENERGY_MODULE', payload: res });
    setLoading(false);
    setLoadingDate(false);
  };

  const getReports = async () => {
    if (!projectData) return [];
    const params = {
      iproject_id: projectData.project_id,
      ts_from: +new Date(dates[0]),
      ts_to: +new Date(dates[1].setHours(23, 59, 59, 999)),
      tz_offset: new Date().getTimezoneOffset() * 60,
    };
    return fetch(process.env.REACT_APP_PSQL_PROC, {
      method: 'POST',
      body: JSON.stringify({
        reqType: 'select',
        funcName: 'project.mas_energy_daily',
        inputParameters: params,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        return data || [];
      })
      .catch((err) => {
        console.error(err);
        setError(true);
        return [];
      });
  };

  const breadcrumbItems = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Energy',
      link: '',
    },
  ];
  const renderTooltip = (params) => {
    const out = params.map(
      (item) =>
        `<div style="display:flex; justify-content:space-between"><div class="mr-2">${
          item.marker
        } ${item.seriesName}:</div><div> ${Intl.NumberFormat(
          window.navigator.language
        ).format(item.value)}</div></div>`
    );
    return [`${params[0].axisValue}<br />`, ...out].join('');
  };

  const optionElectricity = {
    title: {
      text: consumption.view,
      left: 'center',
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params) => renderTooltip(params),
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: [
      {
        type: 'category',
        data: consumption.dates,
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: (value) =>
            `${Intl.NumberFormat(window.navigator.language).format(value)} kWh`,
        },
      },
      {
        type: 'value',
        axisLabel: {
          formatter: (value) =>
            `${Intl.NumberFormat(window.navigator.language).format(value)} kWh`,
        },
      },
    ],
    legend: {
      data: ['Mixers', 'Entry', 'Main', 'Oven', 'Total', 'Cumulative'],
      top: 10,
    },
    series: [
      {
        name: 'Mixers',
        type: 'bar',
        stack: '1',
        itemStyle: {
          color: colorScheme.other[4]._80,
        },
        data: consumption.mixers,
      },
      {
        name: 'Entry',
        type: 'bar',
        stack: '1',
        itemStyle: {
          color: colorScheme.other[4]._60,
        },
        data: consumption.entry,
      },
      {
        name: 'Main',
        type: 'bar',
        stack: '1',
        itemStyle: {
          color: colorScheme.other[4]._40,
        },
        data: consumption.main,
      },
      {
        name: 'Oven',
        type: 'bar',
        stack: '1',
        itemStyle: {
          color: colorScheme.other[4]._20,
        },
        data: consumption.oven,
      },
      {
        name: 'Total',
        type: 'line',
        label: {
          show: true,
          position: 'top',
        },
        itemStyle: {
          color: colorScheme.other[4]._100,
        },
        lineStyle: {
          width: 0,
        },
        data: consumption.entry.map((item, index) =>
          (
            Number(item) +
            Number(consumption.mixers[index]) +
            Number(consumption.main[index]) +
            Number(consumption.oven[index])
          ).toFixed(2)
        ),
      },
      {
        name: 'Cumulative',
        areaStyle: {
          opacity: 0.2,
        },
        type: 'line',
        itemStyle: {
          color: colorScheme.other[2]._100,
        },
        yAxisIndex: 1,
        data:
          consumption.view === 'total' &&
          consumption.elCumulative &&
          consumption.elCumulative.map((item) => item.toFixed(2)),
      },
    ],
  };

  const optionGas = {
    tooltip: {
      trigger: 'axis',
      formatter: (params) => renderTooltip(params),
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: [
      {
        type: 'category',
        data: consumption.dates,
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: (value) =>
            `${Intl.NumberFormat(window.navigator.language).format(value)} m^3`,
        },
      },
      {
        type: 'value',
        axisLabel: {
          formatter: (value) =>
            `${Intl.NumberFormat(window.navigator.language).format(value)} m^3`,
        },
      },
    ],
    legend: {
      data: ['Gas', 'Cumulative'],
      top: 10,
    },
    series: [
      {
        name: 'Gas',
        type: 'bar',
        data: consumption.gas,
        itemStyle: {
          color: colorScheme.other[2]._100,
        },
        label: {
          show: true,
          position: 'top',
        },
      },
      {
        name: 'Cumulative',
        areaStyle: {
          opacity: 0.2,
        },
        type: 'line',
        itemStyle: {
          color: colorScheme.other[4]._100,
        },
        yAxisIndex: 1,
        data:
          consumption.view === 'total' &&
          consumption.gasCumulative &&
          consumption.gasCumulative.map((item) => item.toFixed(2)),
      },
    ],
  };
  const optionRecuperation = {
    tooltip: {
      trigger: 'axis',
      formatter: (params) => renderTooltip(params),
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: [
      {
        type: 'category',
        data: consumption.dates,
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: (value) =>
            `${Intl.NumberFormat(window.navigator.language).format(value)} kWh`,
        },
      },
      {
        type: 'value',
        axisLabel: {
          formatter: (value) =>
            `${Intl.NumberFormat(window.navigator.language).format(value)} kWh`,
        },
      },
    ],
    legend: {
      data: ['Recuperated Energy', 'Cumulative'],
      top: 10,
    },
    series: [
      {
        name: 'Recuperated Energy',
        type: 'bar',
        data: consumption.recuperation,
        itemStyle: {
          color: colorScheme.main.success._100,
        },
        label: {
          show: true,
          position: 'top',
        },
      },
      {
        name: 'Cumulative',
        areaStyle: {
          opacity: 0.2,
        },
        type: 'line',
        itemStyle: {
          color: colorScheme.other[2]._100,
        },
        yAxisIndex: 1,
        data:
          consumption.view === 'total' &&
          consumption.recuperationCumulative &&
          consumption.recuperationCumulative.map((item) => item.toFixed(2)),
      },
    ],
  };
  const optionSteam = {
    tooltip: {
      trigger: 'axis',
      formatter: (params) => renderTooltip(params),
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: [
      {
        type: 'category',
        data: consumption.dates,
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: (value) =>
            `${Intl.NumberFormat(window.navigator.language).format(value)} kg`,
        },
      },
      {
        type: 'value',
        axisLabel: {
          formatter: (value) =>
            `${Intl.NumberFormat(window.navigator.language).format(value)} kg`,
        },
      },
    ],
    legend: {
      data: ['Main', 'Oven', 'Total', 'Cumulative'],
      top: 10,
    },
    series: [
      {
        name: 'Main',
        type: 'bar',
        stack: '1',
        data: consumption.steam,
        itemStyle: {
          color: colorScheme.other[1]._80,
        },
      },
      {
        name: 'Oven',
        type: 'bar',
        stack: '1',
        data: consumption.steamOven,
        itemStyle: {
          color: colorScheme.other[1]._60,
        },
      },
      {
        name: 'Total',
        type: 'line',
        data: consumption.steam.map((item, index) =>
          (Number(item) + Number(consumption.steamOven[index])).toFixed(2)
        ),
        itemStyle: {
          color: colorScheme.other[1]._100,
        },
        lineStyle: {
          width: 0,
        },
        label: {
          show: true,
          position: 'top',
        },
      },
      {
        name: 'Cumulative',
        areaStyle: {
          opacity: 0.2,
        },
        type: 'line',
        itemStyle: {
          color: colorScheme.other[2]._100,
        },
        yAxisIndex: 1,
        data:
          consumption.view === 'total' &&
          consumption.steamCumulative &&
          consumption.steamCumulative.map((item) => item.toFixed(2)),
      },
    ],
  };
  const optionCompressedAir = {
    tooltip: {
      trigger: 'axis',
      formatter: (params) => renderTooltip(params),
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: [
      {
        type: 'category',
        data: consumption.dates,
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: (value) =>
            `${Intl.NumberFormat(window.navigator.language).format(value)} m^3`,
        },
      },
      {
        type: 'value',
        axisLabel: {
          formatter: (value) =>
            `${Intl.NumberFormat(window.navigator.language).format(value)} m^3`,
        },
      },
    ],
    legend: {
      data: ['Compressed Air', 'Cumulative'],
      top: 10,
    },
    series: [
      {
        name: 'Compressed Air',
        type: 'bar',
        data: consumption.compressedAir, //.map(value=>Intl.NumberFormat(window.navigator.language).format(value)),
        itemStyle: {
          color: colorScheme.other[0]._100,
        },
        label: {
          show: true,
          position: 'top',
        },
      },
      {
        name: 'Cumulative',
        areaStyle: {
          opacity: 0.2,
        },
        type: 'line',
        itemStyle: {
          color: colorScheme.other[2]._100,
        },
        yAxisIndex: 1,
        data:
          consumption.view === 'total' &&
          consumption.compressedAirCumulative &&
          consumption.compressedAirCumulative.map((item) => item.toFixed(2)),
      },
    ],
  };
  /*
    const optionOee = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        xAxis: [
            {
                type: 'category',
                data: consumption.dates
            }
        ],
        yAxis: [
            {
                type: 'value',
                axisLabel: {
                    formatter: '{value}%'
                }
            },
        ],
        legend: {
            data: ['Availability',
                'Performance',
                'Quality',
                'OEE']
                
        },
        series: [
            {
                name: 'Availability',
                type: 'bar',
                data: oee.availability,
                itemStyle: {
                    color: colorPalette[0]
                },
            },
            {
                name: 'Performance',
                type: 'bar',
                data: oee.performance,
                itemStyle: {
                    color: colorPalette[1]
                },
            },
            {
                name: 'Quality',
                type: 'bar',
                data: oee.quality,
                itemStyle: {
                    color: colorPalette[2]
                },
            },
            {
                name: 'OEE',
                type: 'line',
                data: oee.oee,
                itemStyle: {
                    color: colorPalette[3]
                },
                label: {
                    show: true,
                    position: 'top'
                },
            }
        ]
    }
*/
  const optionPiecesMass = {
    tooltip: {
      trigger: 'axis',
      formatter: (params) => renderTooltip(params),
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: [
      {
        type: 'category',
        data: consumption.dates,
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Pieces',
        axisLabel: {
          formatter: (value) =>
            `${Intl.NumberFormat(window.navigator.language).format(value)}`,
        },
      },
      {
        type: 'value',
        name: 'Mass',
        axisLabel: {
          formatter: (value) =>
            `${Intl.NumberFormat(window.navigator.language).format(value)} kg`,
        },
      },
    ],
    legend: {
      data: ['Pieces', 'Mass'],
    },
    series: [
      {
        name: 'Pieces',
        type: 'bar',
        stack: '1',
        data: piecesAndMass.pieces,
        itemStyle: {
          color: colorScheme.other[0]._100,
        },
      },
      {
        name: 'Mass',
        type: 'bar',
        stack: '2',
        yAxisIndex: 1,
        data: piecesAndMass.mass,
        itemStyle: {
          color: colorScheme.other[1]._100,
        },
      },
    ],
  };

  const handlePerPieces = () => {
    setConsumption({
      ...consumption,
      view: 'perPieces',
      entry: filtered.map((item) =>
        Number(item.pcs_total) && item.entry_part_kwh
          ? (
              Number(item.entry_part_kwh) /
              (Number(item.pcs_total) / 1000)
            ).toFixed(2)
          : 0
      ),
      main: filtered.map((item) =>
        Number(item.pcs_total) && item.main_part_kwh
          ? (
              Number(item.main_part_kwh) /
              (Number(item.pcs_total) / 1000)
            ).toFixed(2)
          : 0
      ),
      oven: filtered.map((item) =>
        Number(item.pcs_total) && item.oven_part_kwh
          ? (
              Number(item.oven_part_kwh) /
              (Number(item.pcs_total) / 1000)
            ).toFixed(2)
          : 0
      ),
      gas: filtered.map((item) =>
        Number(item.pcs_total) && item.oven_gas_m3
          ? (
              Number(item.oven_gas_m3) /
              (Number(item.pcs_total) / 1000)
            ).toFixed(2)
          : 0
      ),
      steam: filtered.map((item) =>
        Number(item.pcs_total) && item.main_part_steam_kg
          ? (
              Number(item.main_part_steam_kg) /
              (Number(item.pcs_total) / 1000)
            ).toFixed(2)
          : 0
      ),
      steamOven: filtered.map((item) =>
        Number(item.pcs_total) && item.oven_steam_kg
          ? (
              Number(item.oven_steam_kg) /
              (Number(item.pcs_total) / 1000)
            ).toFixed(2)
          : 0
      ),
      compressedAir: filtered.map((item) =>
        Number(item.pcs_total) && item.compressed_air_m3
          ? (
              Number(item.compressed_air_m3) /
              (Number(item.pcs_total) / 1000)
            ).toFixed(2)
          : 0
      ),
      recuperation: filtered.map((item) =>
        Number(item.pcs_total) && item.recuperation_kwh
          ? (
              Number(item.recuperation_kwh) /
              (Number(item.pcs_total) / 1000)
            ).toFixed(2)
          : 0
      ),
    });
  };

  const handlePerTonne = () => {
    setConsumption({
      ...consumption,
      view: 'perTonne',
      entry: filtered.map((item) =>
        Number(item.weight_total_kg) && item.entry_part_kwh
          ? (
              Number(item.entry_part_kwh) /
              (Number(item.weight_total_kg) / 1000)
            ).toFixed(2)
          : 0
      ),
      main: filtered.map((item) =>
        Number(item.weight_total_kg) && item.main_part_kwh
          ? (
              Number(item.main_part_kwh) /
              (Number(item.weight_total_kg) / 1000)
            ).toFixed(2)
          : 0
      ),
      oven: filtered.map((item) =>
        Number(item.weight_total_kg) && item.oven_part_kwh
          ? (
              Number(item.oven_part_kwh) /
              (Number(item.weight_total_kg) / 1000)
            ).toFixed(2)
          : 0
      ),
      mixers: filtered.map((item) =>
        Number(item.weight_total_kg) && item.mixers_part_kwh
          ? (
              Number(item.mixers_part_kwh) /
              (Number(item.weight_total_kg) / 1000)
            ).toFixed(2)
          : 0
      ),
      gas: filtered.map((item) =>
        Number(item.weight_total_kg) && item.oven_gas_m3
          ? (
              Number(item.oven_gas_m3) /
              (Number(item.weight_total_kg) / 1000)
            ).toFixed(2)
          : 0
      ),
      steam: filtered.map((item) =>
        Number(item.weight_total_kg) && item.main_part_steam_kg
          ? (
              Number(item.main_part_steam_kg) /
              (Number(item.weight_total_kg) / 1000)
            ).toFixed(2)
          : 0
      ),
      steamOven: filtered.map((item) =>
        Number(item.weight_total_kg) && item.oven_steam_kg
          ? (
              Number(item.oven_steam_kg) /
              (Number(item.weight_total_kg) / 1000)
            ).toFixed(2)
          : 0
      ),
      compressedAir: filtered.map((item) =>
        Number(item.weight_total_kg) && item.compressed_air_m3
          ? (
              Number(item.compressed_air_m3) /
              (Number(item.weight_total_kg) / 1000)
            ).toFixed(2)
          : 0
      ),
      recuperation: filtered.map((item) =>
        Number(item.weight_total_kg) && item.recuperation_kwh
          ? (
              Number(item.recuperation_kwh) /
              (Number(item.weight_total_kg) / 1000)
            ).toFixed(2)
          : 0
      ),
    });
  };
  const handleTotal = () => {
    let tmp = 0;
    setConsumption({
      ...consumption,
      view: 'total',
      entry: filtered.map((item) => Number(item.entry_part_kwh).toFixed(2)),
      main: filtered.map((item) => Number(item.main_part_kwh).toFixed(2)),
      oven: filtered.map((item) => Number(item.oven_part_kwh).toFixed(2)),
      mixers: filtered.map((item) => Number(item.mixers_part_kwh).toFixed(2)),
      gas: filtered.map((item) => Number(item.oven_gas_m3).toFixed(2)),
      gasCumulative: filtered.map((item) => {
        tmp += Number(item.oven_gas_m3);
        return tmp;
      }),
      steam: filtered.map((item) => Number(item.main_part_steam_kg).toFixed(2)),
      steamOven: filtered.map((item) => Number(item.oven_steam_kg).toFixed(2)),
      compressedAir: filtered.map((item) =>
        Number(item.compressed_air_m3).toFixed(2)
      ),
      recuperation: filtered.map((item) =>
        Number(item.recuperation_kwh).toFixed(2)
      ),
    });
  };

  const tabStyle = (name) =>
    name === consumption.view
      ? {
          borderBottom: `solid 3px ${colorScheme.main.primary._100}`,
          fontWeight: 500,
          cursor: 'pointer',
          padding: '.5rem 1rem',
        }
      : {
          cursor: 'pointer',
          padding: '.5rem 1rem',
        };
  return (
    <React.Fragment>
      <Breadcrumbs items={breadcrumbItems} />
      <div style={{ padding: '0 1rem' }}>
        <DateSelection
          range
          setDates={setDates}
          selectedDates={dates}
          loading={loadingDate}
        />
      </div>
      <Container fluid>
        <Row className="mt-3">
          <Col lg={4} className="mb-3">
            {loading ? (
              <Skeleton height={130} />
            ) : (
              <ProducedCard data={piecesAndMass} />
            )}
          </Col>
          <Col lg={8}></Col>
          <Col lg={8} className="mb-3">
            {loading ? (
              <Skeleton height={130} />
            ) : (
              <ConsumptionCard
                data={consumption}
                mass={piecesAndMass.massTotalSum}
              />
            )}
          </Col>
          <Col lg={4} className="mb-3">
            {loading ? (
              <Skeleton height={130} />
            ) : (
              <RecuperationCard
                data={consumption}
                mass={piecesAndMass.massTotalSum}
              />
            )}
          </Col>

          <Col sm={12}>
            {loading ? (
              <Skeleton height={380} />
            ) : (
              <Card className="mb-3">
                <CardHeader>Pieces And Mass</CardHeader>
                <CardBody className="p-0">
                  {!data.length ? (
                    <NoContent style={{ height: '250px' }} title="No data">
                      Try different date
                    </NoContent>
                  ) : (
                    <ReactEchartsCore
                      style={{ height: 480 }}
                      echarts={echarts}
                      option={optionPiecesMass}
                    />
                  )}
                </CardBody>
              </Card>
            )}
          </Col>
          <Col sm={12}>
            {loading ? (
              <Skeleton height={380} />
            ) : (
              <Card className="mb-3">
                <CardHeader>Electricity</CardHeader>
                <CardBody className="p-0">
                  <div className="d-flex justify-content-between mx-4 align-items-end border-bottom">
                    <div className="d-flex hover-primary">
                      <div onClick={handleTotal} style={tabStyle('total')}>
                        Total
                      </div>
                      <div
                        onClick={handlePerTonne}
                        style={tabStyle('perTonne')}
                      >
                        Per tonne
                      </div>
                      <div
                        onClick={handlePerPieces}
                        style={tabStyle('perPieces')}
                      >
                        Per 1000 pieces
                      </div>
                    </div>
                  </div>

                  {!data.length ? (
                    <NoContent style={{ height: '250px' }} title="No data">
                      Try different date
                    </NoContent>
                  ) : (
                    <ReactEchartsCore
                      style={{ height: 480 }}
                      echarts={echarts}
                      option={optionElectricity}
                    />
                  )}
                </CardBody>
              </Card>
            )}
          </Col>
          <Col sm={12}>
            {loading ? (
              <Skeleton height={380} />
            ) : (
              <Card className="mb-3">
                <CardHeader>Gas</CardHeader>
                <CardBody className="p-0">
                  <div className="d-flex justify-content-between mx-4 align-items-end border-bottom">
                    <div className="d-flex hover-primary">
                      <div onClick={handleTotal} style={tabStyle('total')}>
                        Total
                      </div>
                      <div
                        onClick={handlePerTonne}
                        style={tabStyle('perTonne')}
                      >
                        Per tonne
                      </div>
                      <div
                        onClick={handlePerPieces}
                        style={tabStyle('perPieces')}
                      >
                        Per 1000 pieces
                      </div>
                    </div>
                  </div>
                  {!data.length ? (
                    <NoContent style={{ height: '250px' }} title="No data">
                      Try different date
                    </NoContent>
                  ) : (
                    <ReactEchartsCore
                      style={{ height: 480 }}
                      echarts={echarts}
                      option={optionGas}
                    />
                  )}
                </CardBody>
              </Card>
            )}
          </Col>
          <Col sm={12}>
            {loading ? (
              <Skeleton height={380} />
            ) : (
              <Card className="mb-3">
                <CardHeader>Compressed Air</CardHeader>
                <CardBody className="p-0">
                  <div className="d-flex justify-content-between mx-4 align-items-end border-bottom">
                    <div className="d-flex hover-primary">
                      <div onClick={handleTotal} style={tabStyle('total')}>
                        Total
                      </div>
                      <div
                        onClick={handlePerTonne}
                        style={tabStyle('perTonne')}
                      >
                        Per tonne
                      </div>
                      <div
                        onClick={handlePerPieces}
                        style={tabStyle('perPieces')}
                      >
                        Per 1000 pieces
                      </div>
                    </div>
                  </div>
                  {!data.length ? (
                    <NoContent style={{ height: '250px' }} title="No data">
                      Try different date
                    </NoContent>
                  ) : (
                    <ReactEchartsCore
                      style={{ height: 480 }}
                      echarts={echarts}
                      option={optionCompressedAir}
                    />
                  )}
                </CardBody>
              </Card>
            )}
          </Col>
          <Col sm={12}>
            {loading ? (
              <Skeleton height={380} />
            ) : (
              <Card className="mb-3">
                <CardHeader>Steam</CardHeader>
                <CardBody className="p-0">
                  <div className="d-flex justify-content-between mx-4 align-items-end border-bottom">
                    <div className="d-flex hover-primary">
                      <div onClick={handleTotal} style={tabStyle('total')}>
                        Total
                      </div>
                      <div
                        onClick={handlePerTonne}
                        style={tabStyle('perTonne')}
                      >
                        Per tonne
                      </div>
                      <div
                        onClick={handlePerPieces}
                        style={tabStyle('perPieces')}
                      >
                        Per 1000 pieces
                      </div>
                    </div>
                  </div>
                  {!data.length ? (
                    <NoContent style={{ height: '250px' }} title="No data">
                      Try different date
                    </NoContent>
                  ) : (
                    <ReactEchartsCore
                      style={{ height: 480 }}
                      echarts={echarts}
                      option={optionSteam}
                    />
                  )}
                </CardBody>
              </Card>
            )}
          </Col>
          <Col sm={12}>
            {loading ? (
              <Skeleton height={380} />
            ) : (
              <Card className="mb-3">
                <CardHeader>Recuperated Energy</CardHeader>
                <CardBody className="p-0">
                  <div className="d-flex justify-content-between mx-4 align-items-end border-bottom">
                    <div className="d-flex hover-primary">
                      <div onClick={handleTotal} style={tabStyle('total')}>
                        Total
                      </div>
                      <div
                        onClick={handlePerTonne}
                        style={tabStyle('perTonne')}
                      >
                        Per tonne
                      </div>
                      <div
                        onClick={handlePerPieces}
                        style={tabStyle('perPieces')}
                      >
                        Per 1000 pieces
                      </div>
                    </div>
                  </div>
                  {!data.length ? (
                    <NoContent style={{ height: '250px' }} title="No data">
                      Try different date
                    </NoContent>
                  ) : (
                    <ReactEchartsCore
                      style={{ height: 480 }}
                      echarts={echarts}
                      option={optionRecuperation}
                    />
                  )}
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const CardComponent = ({ title, children }) => {
  return (
    <Card className="h-100">
      <CardHeader className="d-flex justify-content-between pb-1">
        <div>{title}</div>
      </CardHeader>
      <CardBody
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          flexWrap: 'wrap',
          paddingTop: '.25rem',
          paddingBottom: '.25rem',
          fontWeight: 'bold',
        }}
      >
        {children}
      </CardBody>
    </Card>
  );
};

const CardBodyComponent = ({ data: { label, primary, secondary } }) => {
  const colorScheme = getColorScheme();
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ color: colorScheme.grayscale._40 }}>{label}</div>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <div style={{ fontSize: '1.5em', color: primary.color }}>
          {primary.value || '-'}
          {primary.unit}
        </div>
        {secondary && (
          <div style={{ paddingLeft: '1em', color: secondary.color }}>
            {secondary.value || '-'}
            {secondary.unit}
          </div>
        )}
      </div>
    </div>
  );
};

const ProducedCard = ({ data }) => {
  const formatValue = (value) =>
    Intl.NumberFormat(window.navigator.language).format(
      Math.floor(Number(value))
    );
  return (
    <CardComponent title="Pieces And Mass">
      <CardBodyComponent
        data={{
          label: 'Pieces',
          primary: {
            value: formatValue(data.piecesTotalSum),
            unit: 'pcs',
          },
        }}
      />
      <CardBodyComponent
        data={{
          label: 'Mass',
          primary: {
            value: formatValue(data.massTotalSum),
            unit: 'kg',
          },
        }}
      />
    </CardComponent>
  );
};

const ConsumptionCard = ({ data, mass }) => {
  const formatValue = (value) =>
    Intl.NumberFormat(window.navigator.language).format(
      Math.floor(Number(value))
    );
  return (
    <CardComponent title="Consumption">
      <CardBodyComponent
        data={{
          label: 'Electricity',
          primary: {
            value: formatValue(data.elTotalSum),
            unit: 'kWh',
          },
          secondary: {
            value: formatValue(data.elTotalSum / (Number(mass) / 1000)),
            unit: 'kWh/t',
          },
        }}
      />
      <CardBodyComponent
        data={{
          label: 'Gas',
          primary: {
            value: formatValue(data.gasTotalSum),
            unit: (
              <span>
                m<sup>3</sup>
              </span>
            ),
          },
          secondary: {
            value: formatValue(data.gasTotalSum / (Number(mass) / 1000)),
            unit: (
              <span>
                m<sup>3</sup>/t
              </span>
            ),
          },
        }}
      />
      <CardBodyComponent
        data={{
          label: 'Compressed air',
          primary: {
            value: formatValue(data.compressedAirSum),
            unit: (
              <span>
                m<sup>3</sup>
              </span>
            ),
          },
          secondary: {
            value: formatValue(data.compressedAirSum / (Number(mass) / 1000)),
            unit: (
              <span>
                m<sup>3</sup>/t
              </span>
            ),
          },
        }}
      />
      <CardBodyComponent
        data={{
          label: 'Steam',
          primary: {
            value: formatValue(data.steamTotalSum),
            unit: <span>kg</span>,
          },
          secondary: {
            value: formatValue(data.steamTotalSum / (Number(mass) / 1000)),
            unit: <span>kg/t</span>,
          },
        }}
      />
    </CardComponent>
  );
};

const RecuperationCard = ({ data, mass }) => {
  const colorScheme = getColorScheme();
  const formatValue = (value) =>
    Intl.NumberFormat(window.navigator.language).format(
      Math.floor(Number(value))
    );
  return (
    <CardComponent title="Recuperated Energy">
      <CardBodyComponent
        data={{
          label: '',
          primary: {
            value: formatValue(data.recuperationTotalSum),
            unit: 'kWh',
            color: colorScheme.main.success._100,
          },
          secondary: {
            value: formatValue(
              data.recuperationTotalSum / (Number(mass) / 1000)
            ),
            unit: 'kWh/t',
            color: colorScheme.main.success._100,
          },
        }}
      />
    </CardComponent>
  );
};

export default EnergyRest;
