export const theme = {
  colors: {
    main: {
      primary: {
        _100: '#d22630',
        _90: '#d73c45',
        _80: '#db5159',
        _70: '#e0676e',
        _60: '#e47d83',
        _50: '#e99398',
        _40: '#eda8ac',
        _30: '#f2bec1',
        _20: '#f6d4d6',
        _10: '#fbe9ea',
      },
      secondary: {
        _100: '#d0b4a0',
        _90: '#d5bba9',
        _80: '#d9c3b3',
        _70: '#decbbd',
        _60: '#e3d2c6',
        _50: '#e7d9cf',
        _40: '#ece1d9',
        _30: '#f1e9e3',
        _20: '#f6f0ec',
        _10: '#faf7f5',
      },
      success: {
        _100: '#24c48e',
        _90: '#3aca99',
        _80: '#50d0a5',
        _70: '#66d6b0',
        _60: '#7cdcbb',
        _50: '#91e1c7',
        _40: '#a7e7d2',
        _30: '#bdeddd',
        _20: '#d3f3e8',
        _10: '#e9f9f4',
      },
      danger: {
        _100: '#df394e',
        _90: '#e24d60',
        _80: '#e56171',
        _70: '#e97483',
        _60: '#ec8895',
        _50: '#ef9ca7',
        _40: '#f2b0b8',
        _30: '#f5c4ca',
        _20: '#f9d7dc',
        _10: '#fcebed',
      },
      warning: {
        _100: '#ffc107',
        _90: '#ffc720',
        _80: '#ffcd39',
        _70: '#ffd451',
        _60: '#ffda6a',
        _50: '#ffe083',
        _40: '#ffe69c',
        _30: '#ffecb5',
        _20: '#fff3cd',
        _10: '#fff9e6',
      },
      info: {
        _100: '#17a2b8',
        _90: '#2eabbf',
        _80: '#45b5c6',
        _70: '#5dbecd',
        _60: '#74c7d4',
        _50: '#8bd0db',
        _40: '#a2dae3',
        _30: '#b9e3ea',
        _20: '#d1ecf1',
        _10: '#e8f6f8',
      },
    },
    grayscale: {
      _100: '#495057',
      _90: '#5a6067',
      _80: '#6c7177',
      _70: '#7d8287',
      _60: '#8f9398',
      _50: '#a0a4a8',
      _40: '#b2b5b8',
      _30: '#d5d7d9',
      _20: '#e6e8e9',
      _10: '#f8f9fa',
    },
    other: [
      {
        _100: '#44b7c2',
        _90: '#57bec8',
        _80: '#69c5ce',
        _70: '#7ccdd4',
        _60: '#8fd4da',
        _50: '#a1dbe0',
        _40: '#b4e2e7',
        _30: '#c7e9ed',
        _20: '#daf1f3',
        _10: '#ecf8f9',
      },
      {
        _100: '#024b79',
        _90: '#1b5d86',
        _80: '#356f94',
        _70: '#4e81a1',
        _60: '#6793af',
        _50: '#80a5bc',
        _40: '#9ab7c9',
        _30: '#b3c9d7',
        _20: '#ccdbe4',
        _10: '#e6edf2',
      },
      {
        _100: '#ffad48',
        _90: '#ffb55a',
        _80: '#ffbd6d',
        _70: '#ffc67f',
        _60: '#ffce91',
        _50: '#ffd6a3',
        _40: '#ffdeb6',
        _30: '#ffe6c8',
        _20: '#ffefda',
        _10: '#fff7ed',
      },
      {
        _100: '#ba5800',
        _90: '#c1691a',
        _80: '#c87933',
        _70: '#cf8a4d',
        _60: '#d69b66',
        _50: '#dcab80',
        _40: '#e3bc99',
        _30: '#eacdb3',
        _20: '#f1decc',
        _10: '#f8eee5',
      },
      {
        _100: '#dbc500',
        _90: '#decb1a',
        _80: '#e2d133',
        _70: '#e6d64d',
        _60: '#e9dc66',
        _50: '#ede280',
        _40: '#f1e899',
        _30: '#f4eeb3',
        _20: '#f8f3cc',
        _10: '#fbf9e5',
      },
    ],
  },
};
