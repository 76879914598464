import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  ModalBody,
  ModalHeader,
  Modal,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  ModalFooter,
} from 'reactstrap';
import Select from 'react-select';
import 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import TableReact from '../../../../pages/Settings/TableTemplate';
import { useStore } from '../../../../store';
import { StyledButton } from '../../../../components/DateSelection/DateSelection';
import {
  columns,
  getTranslation,
  prepareItemForOrder,
} from './OrderItems.helpers';
import { ButtonContainer, Container, RoundButton } from './OrderItems.styles';
import { useToggler } from '../../../../lib/helpers/customHooks';
import MachineSelectionInput from '../../../../pages/Settings/AdminPage/Form/MachineSelectionInput';
import ShopItems from './ShopItems';
import QuickPick from './QuickPick';
import { saveAsOpenOrder } from './ShopItems.helpers';
import { editOrder } from './OrderDetails.helpers';
import Tabs from '../../../../components/Tabs';
import TooltipWrapper from '../../../../components/TooltipWrapper';
import { handleNewOrder } from './OrdersTable/OrdersTable.helpers';
import { AuthContext } from '../../../../utils/firebaseAuth';

const ItemsTable = ({ hideAddButton }) => {
  const [isAddItemOpen, toggleAddItem] = useToggler(false);
  const [isEditItemOpen, toggleEditItem] = useToggler(false);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const machinesRef = useFirestore().collection('machines');
  const { data: machines } = useFirestoreCollectionData(machinesRef);

  const { state, dispatch } = useStore();
  const { selectedOrder } = state;
  const items = selectedOrder?.items || [];
  const status = selectedOrder?.details?.status;
  const canEdit =
    status === 'open' || status === 'returned' || status === 'proposed';
  const isStatusOpen = status === 'open';

  const handleItemSelect = (data) => {
    setSelectedItem(data);
    toggleEditItem();
  };
  const handleRemoveItem = (itemToRemove) => {
    const newItems = removeSelectedItemFromItems({ itemToRemove, items });
    const payload = {
      details: state.selectedOrder.details,
      messages: state.selectedOrder.messages,
      items: newItems,
    };
    editOrder(payload, 'open');
    dispatch({
      type: 'SELECTED_ORDER',
      payload,
    });
  };

  const removeSelectedItemFromItems = ({ itemToRemove, items }) =>
    items.filter(
      (item) => item.material_code !== itemToRemove.original.material_code
    );

  const addEditColumn = () => {
    const editColumn = {
      id: 'actions',
      Header: '',
      accessor: '',
      Cell: ({ row }) => (
        <>
          <ButtonContainer>
            <TooltipWrapper content="Edit Item">
              <RoundButton onClick={() => handleItemSelect(row)}>
                <FiEdit />
              </RoundButton>
            </TooltipWrapper>
            {isStatusOpen && (
              <TooltipWrapper content="Remove Item">
                <RoundButton onClick={() => handleRemoveItem(row)}>
                  <FiTrash2 />
                </RoundButton>
              </TooltipWrapper>
            )}
          </ButtonContainer>
        </>
      ),
    };
    return [...columns(isStatusOpen), editColumn];
  };

  const handleOpenAddItem = (e) => {
    toggleAddItem();
  };
  const handleMachineSelect = (machine) => {
    setSelectedMachine(machine);
  };
  return (
    <Card className="mb-3">
      <CardBody className="p-0">
        {!hideAddButton && canEdit && (
          <Container>
            <StyledButton color="primary" onClick={handleOpenAddItem}>
              Add item
            </StyledButton>
          </Container>
        )}
        <TableReact
          columns={canEdit ? addEditColumn() : columns()}
          // data={prepareItemsForTable(items)}
          data={items}
        />
        <OrderItemDetailsModal
          isOpen={isEditItemOpen}
          toggle={toggleEditItem}
          data={selectedItem?.original}
          isEdit
        />
        <Modal isOpen={isAddItemOpen} toggle={toggleAddItem} size="xl">
          <ModalHeader toggle={toggleAddItem}>Add items</ModalHeader>
          <Tabs tabs={['Quick pick', 'By machine']}>
            <ModalBody className="p-0 ">
              <QuickPick />
            </ModalBody>
            <ModalBody className="p-0 ">
              <div className="bg-light p-3">
                <MachineSelectionInput
                  data={selectedMachine}
                  allMachines={machines}
                  handleMachineSelect={handleMachineSelect}
                  buttonPosition="top"
                />
              </div>
              <ShopItems
                selectedMachine={selectedMachine}
                styles={{ padding: 0 }}
              />
            </ModalBody>
          </Tabs>
        </Modal>
      </CardBody>
    </Card>
  );
};

export const OrderItemDetailsModal = ({
  isOpen,
  toggle,
  data,
  isEdit = false,
  isQuickPick = false,
}) => {
  const { state, dispatch } = useStore();
  const { currentProject, currentUser } = useContext(AuthContext);
  const { selectedOrder, openOrders } = state;
  const isOpenOrder = selectedOrder?.details?.status === 'open';
  const [price, setPrice] = useState(
    isQuickPick
      ? data.quick_select_price
      : isEdit
      ? data?.price
      : data?.data?.Price
  );

  const [quantity, setQuantity] = useState(
    isQuickPick
      ? data.quick_select_quantity
      : isEdit
      ? data?.quantity
      : data?.data?.QtyperParent
  );

  const [note, setNote] = useState(data?.note || '');

  useEffect(() => {
    setPrice(
      isQuickPick
        ? data.quick_select_price
        : isEdit
        ? data?.price
        : data?.data?.Price
    );

    setQuantity(
      isQuickPick
        ? data.quick_select_quantity
        : isEdit
        ? data?.quantity
        : data?.data?.QtyperParent
    );
  }, [isOpen]);

  if (!data || Object.keys(data).length === 0) return null;

  const modifyQuicPickData = () => ({
    price: data.quick_select_price,
    ...data,
  });

  const saveToFirebaseAndDispatchPayload = async (payload) => {
    sendToFirebase(payload);

    dispatch({
      type: 'SELECTED_ORDER',
      payload,
    });
  };

  const saveAndDispatchResult = async (payload) => {
    const result = await saveAsOpenOrder(payload);
    dispatch({
      type: 'SELECTED_ORDER',
      payload: result,
    });
  };

  const handleAddItem = () => {
    const dataToPush = isQuickPick ? modifyQuicPickData() : data.data;
    const dataWithQty = { ...dataToPush, QtyperParent: Number(quantity), note };
    const modifiedForOrder = prepareItemForOrder({
      item: dataWithQty,
      selectedOrder,
    });

    // modifiedForOrder.quantity = quantity;
    const newItems = state.selectedOrder?.items || [];
    newItems.push(modifiedForOrder);
    const payload = {
      details: state.selectedOrder?.details || {},
      messages: state.selectedOrder?.messages || [],
      items: newItems,
    };

    payload.details.status === 'open'
      ? saveAndDispatchResult(payload)
      : saveToFirebaseAndDispatchPayload(payload);

    toggle();
  };

  const sendToFirebase = (payload) => {
    const status = payload.details.status;
    editOrder(payload, status);
  };

  const handleEditItem = () => {
    const status = selectedOrder.details?.status || 'open';
    const isOpenOrder = status === 'open';
    const { items } = state.selectedOrder;
    const dataWithQty = {
      ...data,
      orig_quantity: isOpenOrder ? Number(quantity) : data.orig_quantity,
      quantity: Number(quantity),
      note,
    };

    const modifiedForOrder = items.map((item) => {
      if (item.material_code === data.material_code) return dataWithQty;
      return item;
    });

    const payload = {
      details: state.selectedOrder.details,
      messages: state.selectedOrder.messages,
      items: modifiedForOrder,
    };

    sendToFirebase(payload);

    dispatch({
      type: 'SELECTED_ORDER',
      payload,
    });

    toggle();
  };
  const openOrdersOptions =
    openOrders &&
    openOrders.map((order) => ({
      value: order,
      label: order.details.order_no,
    }));

  const findSelectedOrderInOptions = () =>
    openOrdersOptions &&
    openOrdersOptions.find(
      (order) =>
        order.value?.details?.order_no === selectedOrder?.details?.order_no
    );

  const groupedOptions = [
    {
      label: 'New',
      options: [{ value: 'new', label: 'Create new order' }],
    },
    {
      label: 'Open orders',
      options: openOrdersOptions,
    },
  ];

  const handleChangeOrder = ({ value }) => {
    if (value === 'new') {
      const type = 'service';
      const payload = handleNewOrder({ currentProject, currentUser, type });
      dispatch({ type: 'SELECTED_ORDER', payload });
      return;
    }

    dispatch({
      type: 'SELECTED_ORDER',
      payload: value,
    });
  };

  return (
    <Modal isOpen={isOpen} size="sm" toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {getTranslation(
          isEdit || isQuickPick ? data.name : data.data.Translations
        )}
      </ModalHeader>
      <ModalBody>
        <div>
          <Form>
            <Row form>
              {openOrders && (
                <Col md={12}>
                  <FormGroup>
                    <Label for="select_open_order">Select open order</Label>
                    <Select
                      defaultValue={findSelectedOrderInOptions()}
                      options={groupedOptions}
                      onChange={handleChangeOrder}
                    />
                  </FormGroup>
                </Col>
              )}
              <Col md={12}>
                <FormGroup>
                  <Label for="quantity">Quantity</Label>
                  <Input
                    type="number"
                    name="quantity"
                    id="quantity"
                    min={isOpenOrder ? 1 : 0}
                    onChange={(e) => {
                      setQuantity(
                        Number(e.target.value) > 0
                          ? e.target.value
                          : isOpenOrder
                          ? 1
                          : 0
                      );
                    }}
                    value={quantity}
                    // max={data?.original?.quantity}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                Price: <span>{price}</span>
              </Col>
              <Col md={12} className="mb-4">
                Value:{' '}
                <span style={{ fontSize: '1.5em' }}>
                  {(Number(price) * Number(quantity)).toFixed(2) || '-'}
                </span>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for="note">Note</Label>
                  <Input
                    type="textarea"
                    name="note"
                    id="note"
                    placeholder="(Optional)"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </div>
      </ModalBody>
      <ModalFooter>
        <StyledButton
          color="success"
          onClick={() => (isEdit ? handleEditItem() : handleAddItem())}
        >
          {isEdit ? 'Edit' : 'Add'}
        </StyledButton>
      </ModalFooter>
    </Modal>
  );
};
export default ItemsTable;
