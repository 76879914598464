import React, { useState } from 'react';
import GroupsTable from './GroupsTable';
import { Button } from 'reactstrap';
import ModalTemplate from '../ModalTemplate';

const Groups = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <React.Fragment>
      <Button
        disabled={!data}
        className="ml-2"
        outline
        size="sm"
        onClick={toggle}
      >
        Groups
      </Button>
      {!!data && (
        <ModalTemplate
          isOpen={isOpen}
          toggle={toggle}
          title="Groups"
          breadcrumbs={data.breadcrumb}
        >
          <GroupsTable data={data} />
        </ModalTemplate>
      )}
    </React.Fragment>
  );
};

export default Groups;
