import React from 'react';
import Orders from './views/Orders';
import { IoDocumentText } from 'react-icons/io5';

const config = {
  title: 'Orders',
  icon: <IoDocumentText />,
  iconText: 'Orders',
  url: '/orders',
  api: {},
  view: Orders,
};

export default config;
