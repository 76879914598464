import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MachineForm from './MachineForm';
import Assemblies from '../Assemblies';
import {
  getMachineSequence,
  getMachines,
  insertMachine,
  updateMachine,
  deleteMachine,
} from '../calls/machines';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import Skeleton from 'react-loading-skeleton';
import ActionButtons from '../ActionButtons';
import TableReact from '../TableTemplate';
import TooltipWrapper from '../../../components/TooltipWrapper';
import MachineCard from '../../../modules/MAS/views/Overview/partials/MachineCard';
import { useStore } from '../../../store';

const MachinesTable = ({ data }) => {
  const { dispatch } = useStore();
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [machines, setMachines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteData, setDeleteData] = useState({});
  const [formData, setFormData] = useState(null);
  const [rowIndex, setRowIndex] = useState(0);
  const [rowSelected, setRowSelected] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const result = await getMachines(data.selected.productionLine.project_id);
      setMachines(result.machines);
      setRowSelected(result.machines && result.machines[0]);
      setLoading(result.loading);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const sorted = machines.sort((a, b) => a.order_nr - b.order_nr);
    setMachines(sorted);
  }, [machines]);

  const toggleForm = () => setIsOpenForm(!isOpenForm);
  const toggleDelete = () => setIsOpenDelete(!isOpenDelete);

  const handleOpenDelete = () => {
    setDeleteData(rowSelected.project_machine_id);
    toggleDelete();
  };

  const handleCreate = async (item) => {
    const resultSeq = await getMachineSequence();
    if (resultSeq.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }

    const itemWithId = { ...item, project_machine_id: Number(resultSeq) };
    const result = await insertMachine(itemWithId);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }

    const isFirstCreated = machines.length === 0;
    isFirstCreated && setRowSelected(itemWithId);
    setMachines([...machines, itemWithId]);
    toggleForm();
    toast.success('Project created successfully.');
  };

  const handleEdit = async (item, showToast = true) => {
    const { project_machine_id } = item;
    const result = await updateMachine(item);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }
    const tmp = machines.filter(
      (machine) => machine.project_machine_id !== project_machine_id
    );
    const reordered = [...tmp, item].sort((a, b) => {
      return Number(a.order_nr) - Number(b.order_nr);
    });
    setMachines(reordered);
    setRowSelected(item);
    dispatch({ type: 'SET_PROJECT_MACHINES', payload: reordered });
    localStorage.setItem('allMachines', JSON.stringify(reordered));
    toggleForm();
    showToast && toast.success('Machine edited successfully.');
  };

  const handleDelete = async () => {
    const result = await deleteMachine(Number(rowSelected.project_machine_id));
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }
    setMachines(
      machines.filter((item) => item.project_machine_id !== deleteData)
    );
    toggleDelete();
    toast.success('Machine deleted.');
  };

  const handleOpenForm = (action, row = rowSelected) => {
    const isNew = action === 'create';
    const { selected } = data;
    const nextOrdNo = machines.length
      ? Math.trunc(Math.max(...machines.map((machine) => machine.order_nr))) + 1
      : 1;
    const tmp = {
      ...row,
      action,
      selected,
      order_nr: isNew ? nextOrdNo : row.order_nr,
    };
    setFormData(tmp);
    toggleForm();
  };

  const handleRowSelect = (info) => {
    setRowIndex(info.index);
    setRowSelected(info.original);
  };

  const handleReorder = (machines) => {
    const indexToOrdNr = machines.map((item, index) => ({
      ...item,
      order_nr: index,
    }));
    indexToOrdNr.map(async (machine) => {
      await handleEdit(machine, false);
    });
    toast.success('Machines edited successfully.');
  };

  const dataForChild = () =>
    !disabled && {
      ...rowSelected,
      breadcrumb: [...data.breadcrumb, rowSelected.description],
      selected: { ...data.selected, machine: rowSelected },
    };

  const columns = [
    {
      id: 'image',
      Header: 'Preview',
      accessor: 'machine_image_b64',
      width: 70,
      style: { padding: 0 },
      Cell: ({ row, value }) => {
        if (!value) return '';
        return (
          <TooltipWrapper content={<MachineCard data={row.original} />}>
            <img
              src={value}
              alt="machine"
              style={{
                objectFit: 'cover',
                width: '100%',
              }}
            />
          </TooltipWrapper>
        );
      },
    },
    /* {
        id: 'order_nr',
        Header: 'Ord.No.',
        width: 100,
        style: { textAlign: "right", minWidth: 100 },
        accessor: 'order_nr'
    },*/ {
      id: 'serial_nr',
      Header: 'SN',
      accessor: 'serial_nr',
      style: { textAlign: 'right' },
    },
    {
      id: 'model_nr',
      Header: 'ID',
      accessor: 'model_nr',
      style: { textAlign: 'right' },
    },
    {
      id: 'line_segment_name',
      Header: 'Segment',
      style: { minWidth: 200 },
      accessor: 'line_segment_name',
    },
    {
      id: 'machine_name',
      Header: 'Name',
      accessor: 'machine_name',
      style: { minWidth: 200 },
      width: 200,
    },
    /*{
        id: 'project_machine_id',
        Header: 'ID',
        accessor: "project_machine_id"
    },*/ {
      id: 'description',
      Header: 'Screen name',
      accessor: 'description',
      style: { minWidth: 200 },
      width: 200,
    },
    {
      id: 'topic_root',
      Header: 'Topic root',
      accessor: 'topic_root',
    },
    {
      id: 'startup_date',
      Header: 'Startup',
      accessor: 'startup_date',
      style: { textAlign: 'center' },
      Cell: (row) => row.value || data.selected.productionLine.startup_date,
    },
    {
      id: 'production_year',
      Header: 'Production year',
      accessor: 'production_year',
      style: { textAlign: 'right' },
      Cell: (row) => row.value || data.selected.productionLine.production_year,
    },
    {
      id: 'beginning_date_of_guarantee',
      Header: 'Guarantee start',
      accessor: 'beginning_date_of_guarantee',
      style: { textAlign: 'center' },
      Cell: (row) =>
        row.value || data.selected.productionLine.beginning_date_of_guarantee,
    },
    {
      id: 'guarantee_valid_till',
      Header: 'Guarantee end',
      accessor: 'guarantee_valid_till',
      style: { textAlign: 'center' },
      Cell: (row) =>
        row.value || data.selected.productionLine.guarantee_valid_till,
    },
  ];
  const disabled = machines.length === 0;
  return loading ? (
    <Skeleton height={200} />
  ) : (
    <React.Fragment>
      <ActionButtons
        isDisabled={disabled}
        handleCreate={handleOpenForm}
        handleEdit={handleOpenForm}
        handleDuplicate
        handleDelete={handleOpenDelete}
      >
        <Assemblies data={dataForChild()} />
      </ActionButtons>
      <TableReact
        hover
        movable
        showAllRows
        columns={columns}
        data={machines}
        rowIndex={rowIndex}
        handleReorder={handleReorder}
        handleCreate={handleOpenForm}
        handleRowSelect={handleRowSelect}
      />
      {formData && (
        <MachineForm
          data={formData}
          isOpen={isOpenForm}
          handleCreate={handleCreate}
          handleEdit={handleEdit}
          toggle={toggleForm}
        />
      )}
      <DeleteConfirmationModal
        text={'machine'}
        handleDelete={handleDelete}
        isOpen={isOpenDelete}
        toggle={toggleDelete}
      />
    </React.Fragment>
  );
};

export default MachinesTable;
