import React from 'react';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/legendScroll';
import 'echarts/lib/component/tooltip';
import { generateDurationString } from '../../../helpers';
import { getColorScheme } from '../../../../../lib/helpers';

const BarChart = ({ data }) => {
  const colorScheme = getColorScheme();
  const setColor = (params, api) =>
    ({
      alarm: colorScheme.main.danger._100,
      warning: colorScheme.main.warning._100,
      info: colorScheme.main.info._100,
    }[params.data.type] || '#6E7074');

  const option = {
    title: {
      text: 'Durations',
      show: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (params) => {
        const duration = generateDurationString(params[0].value);
        const type = params[0].data.type.toUpperCase();
        return `
                        <strong> [${type}]</strong><br />
                        ${params[0].data.machine || ''}
                        <div><strong> ${params[0].name}</strong></div>
                        <p>
                            Duration: ${duration} <br />
                        </p>`;
      },
    },
    legend: {},
    xAxis: {
      type: 'category',
      data: data.map((item) => item.name),
      axisTick: { show: false },
      axisLabel: { show: false },
      //axisLabel: {
      //    show: true,
      //    rotate: 45,
      //    formatter: function (value, index) {
      //        let stripped = value.substring(0,10)
      //        stripped += value.length > 10 ? "..." : ""
      //        return stripped
      //
      //    }
      //},
    },
    yAxis: {
      name: 'Duration',
      nameLocation: 'middle',
      nameGap: 80,
      type: 'value',
      axisLabel: {
        formatter: (value) => generateDurationString(value),
      },
    },
    series: [
      {
        type: 'bar',
        itemStyle: {
          normal: {
            color: setColor,
          },
        },
        data: data, //.map(item => item.value)
      },
    ],
  };

  return (
    <ReactEchartsCore
      echarts={echarts}
      style={{ height: 480 }}
      option={option}
    />
  );
};

export default BarChart;
