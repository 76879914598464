import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import calendarEventsData from './calendarEvents.json';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { dataZoom } from 'echarts/lib/theme/dark';
import { TaskDetailsModal } from './Agenda';
import { generateEventModalDataFromCalendar } from './helpers';

const Calendar = ({ data, original }) => {
  const [calendarEvents, setCalendarEvents] = useState(calendarEventsData);
  const [isOpen, setIsOpen] = useState(false);
  const [eventSelected, setEventSelected] = useState(null);
  const [events, setEvents] = useState([
    { title: 'Maintenance 1', id: '1', userModified: false, machineId: 120480 },
    { title: 'Maintenance 2', id: '2', userModified: false, machineId: 120480 },
  ]);
  const calendarRef = useRef(null);

  const toggle = () => setIsOpen(!isOpen);
  const onEventAdded = (event) => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.addEvent(event);
  };

  const handleDrop = (dropInfo) => {
    const eventId = dropInfo.draggedEl.attributes.data.value;
    const dropedEvent = events.find((item) => item.id === eventId);
    // onEventAdded();
  };

  const handleEventDrop = (eventDropInfo) => {
    console.log({ eventDropInfo });
  };
  const handleEventChange = (changeInfo) => {
    console.log({ changeInfo });
  };
  const handleEventReceive = (info) => {
    console.log({ info });
  };
  const handleEventClick = (eventClickInfo) => {
    setEventSelected(eventClickInfo.event);
    toggle();
  };
  // useEffect(() => {}, [eventSelected]);
  return (
    // <>
    //   <div
    //     style={{
    //       display: 'grid',
    //       gridTemplateColumns: '1fr 3fr',
    //       gridColumnGap: 10,
    //       padding: '0 1em',
    //       marginBottom: '1em',
    //       gridTemplateRows: 500,
    //     }}
    //   >
    //     <div id="external-events">
    //       <p align="center">
    //         <strong> Events</strong>
    //       </p>
    //       {events.map((event) => (
    //         <div
    //           className="fc-event"
    //           title={event.title}
    //           data={event.id}
    //           key={event.id}
    //         >
    //           {event.title}
    //         </div>
    //       ))}
    //     </div>
    //     <FullCalendar
    //       ref={calendarRef}
    //       plugins={[dayGridPlugin, interactionPlugin]}
    //       initialView="dayGridMonth"
    //       droppable
    //       dayMaxEvents
    //       editable
    //       // drop={handleDrop}
    //       // eventDrop={(data) => handleEventDrop(data)}
    //       eventChange={handleEventChange}
    //       eventReceive={handleEventReceive}
    //       events={calendarEvents}
    //     />
    //   </div>
    // </>
    <div style={{ padding: '0 1em' }}>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        droppable
        firstDay={1}
        eventTimeFormat={{
          // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }}
        dayMaxEvents
        editable
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek',
        }}
        // drop={handleDrop}
        // eventDrop={(data) => handleEventDrop(data)}
        eventChange={handleEventChange}
        eventReceive={handleEventReceive}
        eventClick={handleEventClick}
        events={data}
      />

      <TaskDetailsModal
        data={generateEventModalDataFromCalendar(eventSelected)}
        original={original}
        isOpen={isOpen}
        toggle={toggle}
      />
      {/* <ModalHeader>{eventSelected && eventSelected.title}</ModalHeader>
        <ModalBody>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gridColumnGap: 10,
              padding: '0 1em',
              marginBottom: '1em',
            }}
          >
            <div>
              <strong>Start</strong>
            </div>
            <div>{eventSelected?.startStr || '/'}</div>
            {eventSelected?.end && (
              <>
                <div>
                  <strong>End</strong>
                </div>
                <div>{eventSelected.endStr || '/'}</div>
              </>
            )}
          </div>
        </ModalBody> */}
    </div>
  );
};

export default Calendar;
