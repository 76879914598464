import React, { Component } from 'react';
import Iframe from 'react-iframe';
import { Card, CardBody } from 'reactstrap';

import $ from 'jquery';
class ModalReply extends Component {
  // componentDidMount() {
  //     this.loadSurvey();
  // }
  //
  // componentDidUpdate(prevProps) {
  //     if (this.props.uuid !== prevProps.uuid) {
  //         this.loadSurvey();
  //     }
  // }
  //
  // loadSurvey() {
  //     $("#showform").load( process.env.REACT_APP_NINJA_FORMS_SHOW_FORM_URL + process.env.REACT_APP_NINJA_FORM_ATTACHMENT_POST_ID + "&uuid=" + this.props.uuid);
  // }

  render() {
    return (
      <Card style={{ height: ' 87vh' }}>
        {/*<div id="showform"></div>*/}
        <CardBody>
          <Iframe
            url={
              process.env.REACT_APP_NINJA_FORMS_SHOW_FORM_URL +
              process.env.REACT_APP_NINJA_FORM_ATTACHMENT_POST_ID +
              '&uuid=' +
              this.props.uuid
            }
            display="initial"
            position="relative"
            width="100%"
            height="100%"
            styles={{ border: 'none' }}
          />
        </CardBody>
      </Card>
    );
  }
}

export default ModalReply;
