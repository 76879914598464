import styled from 'styled-components';

export const DetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  gap: 0.5em;
  .title {
    font-size: 1.25rem;
    .muted {
      color: ${({ theme }) => theme.colors.grayscale._40};
    }
    .badge {
      margin-left: 1em;
    }
  }
  .actions {
    display: flex;
    gap: 1em;
  }
`;

export const Value = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.25em 1em;
  background-color: ${({ theme, muted }) =>
    muted && theme.colors.grayscale._10};
  div {
    flex: 1;
  }
  div.big {
    font-size: 1.75em;
    font-weight: 500;
  }
`;
