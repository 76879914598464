import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Button,
  FormText,
} from 'reactstrap';
import { getSegments } from '../calls/segments';

const MachineForm = ({ toggle, isOpen, data, handleCreate, handleEdit }) => {
  const [machine, setMachine] = useState(data);
  const [segments, setSegments] = useState([]);

  const initData = () => {
    return segments.length !== 0 && data.action === 'create'
      ? {
          ...data,
          project_id: data.selected.productionLine.project_id,
          line_segment_id: segments[0].line_segment_id,
          line_segment_name: segments[0].name,
        }
      : data;
  };

  useEffect(() => {
    const fetchData = async () => {
      const { project_id } = data.selected.productionLine;
      const result = await getSegments(project_id);
      setSegments(result.segments);
      result.segments.length !== 0 &&
        setMachine({
          ...machine,
          project_id,
          line_segment_id:
            Number(data.line_segment_id) ||
            Number(result.segments[0].line_segment_id),
          line_segment_name: data.line_segment_name || result.segments[0].name,
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    const init = initData();
    setMachine({
      ...init,
      project_id: data.selected.productionLine.project_id,
    });
  }, [data]);

  const handleSubmitForm = () => {
    const tmp = {
      ...machine,
      project_id: data.selected.productionLine.project_id,
    };
    data.action === 'create' && handleCreate(tmp);
    data.action === 'edit' && handleEdit(tmp);
  };

  const handleCancelForm = () => {
    setMachine({});
    toggle();
  };

  const previewFile = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setMachine({ ...machine, machine_image_b64: reader.result });
    });

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle}>
        {data.action === 'create'
          ? 'Create'
          : data.action === 'edit'
          ? 'Edit'
          : ''}{' '}
        machine
      </ModalHeader>
      <ModalBody>
        <Form autoComplete="off">
          {/*<FormGroup>
                        <Label for="machineId">ID</Label>
                        <Input
                            disabled
                            type="text"
                            id="machineId"
                            name="machine[project_machine_id]"
                            value={machine.project_machine_id} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="projectId">Project ID</Label>
                        <Input
                            disabled
                            type="text"
                            id="projectId"
                            name="machine[project_id]"
                            value={data.selected.productionLine.project_id} />
                        </FormGroup>*/}
          <FormGroup>
            <Label for="ordinalNumber">Ord.No.</Label>
            <Input
              max="999"
              type="number"
              id="ordinalNumber"
              name="machine[order_nr]"
              onChange={(e) =>
                setMachine({ ...machine, order_nr: Number(e.target.value) })
              }
              value={machine.order_nr}
            />
          </FormGroup>
          <FormGroup>
            <Label for="line_segment">Segment</Label>
            <Input
              type="select"
              name="select"
              id="line_segment"
              onChange={(e) => {
                setMachine({
                  ...machine,
                  line_segment_id: Number(e.target.value),
                  line_segment_name:
                    e.target.options[e.target.options.selectedIndex].attributes
                      .label.value,
                });
              }}
            >
              {data.action === 'edit' && !data.line_segment_id && (
                <option></option>
              )}
              {segments.map((segment) => (
                <option
                  key={segment.line_segment_id}
                  value={segment.line_segment_id}
                  label={segment.name}
                  selected={
                    Number(segment.line_segment_id) ===
                    Number(data.line_segment_id)
                  }
                >
                  {segment.name}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="SN">SN</Label>
            <Input
              type="text"
              id="SN"
              name="machine[serial_nr]"
              value={machine.serial_nr}
              onChange={(e) =>
                setMachine({ ...machine, serial_nr: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="MN">ID</Label>
            <Input
              type="text"
              id="MN"
              name="machine[model_nr]"
              value={machine.model_nr}
              onChange={(e) =>
                setMachine({ ...machine, model_nr: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="Name">Name</Label>
            <Input
              type="text"
              id="Name"
              name="machine[machine_name]"
              value={machine.machine_name}
              onChange={(e) =>
                setMachine({ ...machine, machine_name: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="description">Screen name</Label>
            <Input
              type="text"
              id="description"
              name="machine[description]"
              value={machine.description}
              onChange={(e) =>
                setMachine({ ...machine, description: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="topic_root">Topic root</Label>
            <Input
              type="text"
              id="topic_root"
              name="machine[topic_root]"
              value={machine.topic_root}
              onChange={(e) =>
                setMachine({ ...machine, topic_root: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="productionYear">Production year</Label>
            <Input
              type="number"
              id="productionYear"
              name="machine[production_year]"
              value={machine.production_year}
              placeholder={data.selected.productionLine.production_year}
              onChange={(e) =>
                setMachine({
                  ...machine,
                  production_year: Number(e.target.value),
                })
              }
            />
            <FormText color="muted">Keep blank for default values</FormText>
          </FormGroup>
          <FormGroup>
            <Label for="startupDate">Startup date</Label>
            <Input
              type="text"
              onFocus={(e) => (e.target.type = 'date')}
              id="startupDate"
              name="machine[startup_date]"
              value={machine.startup_date}
              placeholder={data.selected.productionLine.startup_date}
              onChange={(e) =>
                setMachine({ ...machine, startup_date: e.target.value })
              }
            />
            <FormText color="muted">Keep blank for default values</FormText>
          </FormGroup>
          <FormGroup>
            <Label for="beginningDateOfGuarantee">Guarantee start</Label>
            <Input
              type="text"
              onFocus={(e) => (e.target.type = 'date')}
              id="beginningDateOfGuarantee"
              name="machine[beginning_date_of_guarantee]"
              value={machine.beginning_date_of_guarantee}
              placeholder={
                data.selected.productionLine.beginning_date_of_guarantee
              }
              onChange={(e) =>
                setMachine({
                  ...machine,
                  beginning_date_of_guarantee: e.target.value,
                })
              }
            />
            <FormText color="muted">Keep blank for default values</FormText>
          </FormGroup>
          <FormGroup>
            <Label for="guaranteeValidTill">Guarantee end</Label>
            <Input
              type="text"
              onFocus={(e) => (e.target.type = 'date')}
              id="guaranteeValidTill"
              name="machine[guarantee_valid_till]"
              placeholder={data.selected.productionLine.guarantee_valid_till}
              value={machine.guarantee_valid_till}
              onChange={(e) =>
                setMachine({ ...machine, guarantee_valid_till: e.target.value })
              }
            />
            <FormText color="muted">Keep blank for default values</FormText>
          </FormGroup>
          <FormGroup>
            <Label for="image">Machine image</Label>
            <Input type="file" onChange={previewFile} accept="image/*" />
            <br />
            {machine.machine_image_b64 && (
              <Button
                className="text-red"
                color="light"
                size="sm"
                onClick={() =>
                  setMachine({ ...machine, machine_image_b64: '' })
                }
              >
                Remove Image
              </Button>
            )}
            <br />
            {!!machine.machine_image_b64 && (
              <img
                src={machine.machine_image_b64 || ''}
                height="200"
                alt="machine"
              />
            )}
          </FormGroup>
          {/*<FormGroup>
                        <Label for="alarmTopic">Alarm topic</Label>
                        <Input
                            type="text"
                            id="alarmTopic"
                            name="machine[alarm_topic]"
                            value={machine.alarm_topic}
                            onChange={e => setMachine({ ...machine, alarm_topic: e.target.value })} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="bs_wth_topic">WTH topic</Label>
                        <Input
                            type="text"
                            id="bs_wth_topic"
                            name="machine[bs_wth_topic]"
                            value={machine.bs_wth_topic}
                            onChange={e => setMachine({ ...machine, bs_wth_topic: e.target.value })} />
                    </FormGroup>*/}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleCancelForm} color="light">
          Cancel
        </Button>
        <Button
          className="text-capitalize"
          onClick={handleSubmitForm}
          color="primary"
        >
          {data.action === 'edit' ? 'Confirm changes' : data.action}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MachineForm;
