import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
class Page404 extends Component {
  render() {
    return (
      <Container>
        <Row className="text-center">
          <Col>
            <span className="display-1 mr-4" style={{ color: '#de384d' }}>
              404
            </span>
            <h4 className="pt-3">Page not found.</h4>
            <p className="text-muted">
              The page you are looking for doesn't exists or other error
              occurred.
              <br />
              <Link
                to={'/'}
                style={{ color: '#de384d', textDecoration: 'none' }}
              >
                Go back
              </Link>{' '}
              to app to choose new direction.
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Page404;
