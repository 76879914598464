import { DateTime } from 'luxon';
import { displayCurrency, getOrderQuote } from '../OrderDetails.helpers';
import { StyledBadge } from './OrdersTable.styles';

export const getColor = ({ theme, status, opacity }) =>
  ({
    //status
    open: theme.colors.main.success[`_${opacity}`],
    proposed: theme.colors.other[0][`_${opacity}`],
    released: theme.colors.other[1][`_${opacity}`],
    accepted: theme.colors.other[0][`_${opacity}`],
    returned: theme.colors.other[3][`_${opacity}`],
    confirmed: theme.colors.other[4][`_${opacity}`],
    closed: theme.colors.other[2][`_${opacity}`],
    deleted: theme.colors.main.danger[`_${opacity}`],
    //type
    service: theme.colors.other[2][`_${opacity}`],
    normal: theme.colors.other[0][`_${opacity}`],
    none: 'none',
  }[status?.toLowerCase() || 'none']);

export const columns = [
  {
    id: 'order_no',
    Header: 'Order Nr.',
    accessor: 'details.order_no',
    Cell: ({ row, value }) =>
      row.original.notSeen ? <strong>{value || ''}</strong> : value || '',
  },
  {
    id: 'customer_order_no',
    Header: 'Cust. order Nr.',
    accessor: 'details.customer_order_no',
    Cell: ({ row, value }) =>
      row.original.notSeen ? <strong>{value || ''}</strong> : value || '',
  },
  {
    id: 'quote',
    Header: 'Quote',

    Cell: (row) => {
      //NOTE: This column can't bo sorted, because row.value = undefined. To fix this, quote should be calculated in rows data

      const quote =
        row.value || getOrderQuote(row.row.original.items, 'orders table');
      const currency = displayCurrency(row.row.original?.details?.currency);
      const value = quote.toFixed(2) + currency;
      return row.row.original.notSeen ? (
        <strong>{value || ''}</strong>
      ) : (
        value || ''
      );
    },
    accessor: 'details.quote',
    style: { textAlign: 'right' },
  },
  {
    id: 'type',
    Header: 'Type',

    accessor: 'details.type',
    Cell: (row) => (
      <StyledBadge status={row.value}>{row.value.toUpperCase()}</StyledBadge>
    ),
    style: { textAlign: 'center' },
  },
  {
    id: 'status',
    Header: 'Status',

    accessor: 'details.status',
    Cell: (row) => (
      <StyledBadge status={row.value}>{row.value.toUpperCase()}</StyledBadge>
    ),
    style: { textAlign: 'center' },
  },
  {
    id: 'created',
    Header: 'Created',

    accessor: 'details.date.seconds',
    style: { textAlign: 'center' },

    Cell: (row) => {
      if (!row?.value) return '';
      const value = DateTime.fromSeconds(row.value).toFormat('dd.LL.yyyy');
      return row.row.original.notSeen ? (
        <strong>{value || ''}</strong>
      ) : (
        value || ''
      );
    },
  },
  {
    id: 'modified',
    Header: 'Last modified',

    accessor: 'modifiedAt.seconds',
    style: { textAlign: 'center' },

    Cell: (row) => {
      if (!row?.value) return '';
      const date = DateTime.fromSeconds(row.value);
      const isToday = checkIfToday(date);
      const value = date.toFormat(isToday ? 'HH:mm' : 'dd.LL.yyyy, HH:mm');
      return row.row.original.notSeen ? (
        <strong>{value || ''}</strong>
      ) : (
        value || ''
      );
    },
  },
];

export const sortOrdersByModifiedDate = (orders) =>
  orders.sort((a, b) => b?.modifiedAt?.seconds - a?.modifiedAt?.seconds);

export const findAndSetUnseenOrders = ({ orders, seenOrders, forStatuses }) => {
  if (!orders || !orders.length) return [];

  const addedSeenProperty = orders.map((item) => {
    if (
      forStatuses &&
      !forStatuses.includes(item?.details?.status?.toLowerCase())
    )
      return item;
    const seenOrder = seenOrders.find(
      (order) => order.order_no === item.details.order_no
    );
    const isSeen = seenOrder
      ? seenOrder.modifiedAt === item.modifiedAt.seconds
      : false;
    return {
      ...item,
      notSeen: !isSeen,
    };
  });
  return addedSeenProperty;
};

/**
 * Filter rows by status. Parameter "status" can be array of statuses.
 */
export const filterRowsByStatus = (rows, status) => {
  if (!rows || !rows.length) return [];

  const statuses =
    typeof status === 'string'
      ? [status]
      : Array.isArray(status)
      ? status
      : null;

  if (!statuses) return [];

  const data = rows.filter((item) =>
    statuses.includes(item?.details?.status?.toLowerCase())
  );

  return data;
};

export const findRowIndex = (rows, status, selectedOrder) => {
  const tableRows = status ? filterRowsByStatus(rows, status) : rows;
  return tableRows.findIndex(
    (item) => item?.details?.id === selectedOrder?.details?.id
  );
};

const checkIfToday = (date) => {
  const dateISO = date.toISODate();
  const dateISOLocal = DateTime.local().toISODate();
  const isToday = dateISO === dateISOLocal;
  return isToday;
};

export const handleNewOrder = ({
  currentProject,
  currentUser,
  type = 'normal',
}) => {
  if (!currentProject) return;

  const { customer, ...project } = currentProject;
  const modifiedAt = new Date();
  const items = [];
  const messages = [];
  const projectId = currentProject.project_id;
  const currency = currentProject.customer.locations.find((item) =>
    item.loc_projects.includes(projectId)
  ).loc_currency;
  const details = {
    createdBy: currentUser.email,
    location: currentProject.title,
    project: currentProject.project_id,
    customer: currentProject.customer.name,
    currency,
    status: 'open',
    type,
  };

  return {
    customer,
    details,
    project,
    modifiedAt,
    items,
    messages,
  };
};
