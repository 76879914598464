import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import {
  Card,
  CardBody,
  Table,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import NoContent from '../../../components/NoContent/NoContent';
import Skeleton from 'react-loading-skeleton';
import Report from './ReportRest/index';
import moment, { duration } from 'moment';
import { useStore } from '../../../store';
import { generateDurationString } from '../../Diagnostics/helpers';
import { getColorScheme } from '../../../lib/helpers';
import { generateReportTableData } from './dataGenerators';

const FinishedLotsTable2 = ({ loading, cloudData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [isReportDaily, setIsReportDaily] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [summary, setSummary] = useState({});
  const { state } = useStore();

  // useEffect(() => {
  //   setSummary(calculateSummary(data));
  // }, [data]);

  useEffect(() => {
    setSummary(calculateSummary2(tableData));
  }, [tableData]);

  useEffect(() => {
    setTableData(generateReportTableData(cloudData));
  }, [cloudData]);

  const toggle = () => setIsOpen(!isOpen);
  const colorScheme = getColorScheme();

  const calculateSummary2 = (data) => {
    const rec_batch_start = Math.min(
      ...data.map((item) => Number(item.rec_batch_start))
    );
    const rec_batch_end = Math.max(
      ...data.map((item) => Number(item.rec_batch_end))
    );
    const batch_duration = data
      ?.map((i) => Number(i.batch_duration))
      .reduce((a, b) => a + b, 0);

    const pcs_produced = data
      ?.map((i) => Number(i.pcs_produced))
      .reduce((a, b) => a + b, 0);
    const mass_produced_kg = data
      ?.map((i) => Number(i.mass_produced_kg))
      .reduce((a, b) => a + b, 0);
    const lotsDurations = data?.map((i) => Number(i.batch_duration));
    const availability = calculateOEE(data, lotsDurations, 'availability');
    const performance = calculateOEE(data, lotsDurations, 'performance');
    const quality = calculateOEE(data, lotsDurations, 'quality');
    const oee = calculateOEE(data, lotsDurations, 'oee');

    return {
      rec_batch_start,
      rec_batch_end,
      batch_duration,
      pcs_produced,
      mass_produced_kg,
      availability,
      performance,
      quality,
      oee,
    };
  };
  const calculateSummary = (data) => {
    const rec_batch_start = Math.min(
      ...data.map((item) => Number(item.rec_batch_start))
    );
    const rec_batch_end = Math.max(
      ...data.map((item) => Number(item.rec_batch_end))
    );
    const batch_duration = data
      ?.map((i) => Number(i.batch_duration))
      .reduce((a, b) => a + b, 0);
    const lotsDurations = data?.map((i) => Number(i.batch_duration));
    const pcs_total = data
      ?.map((i) => Number(i.pcs_total))
      .reduce((a, b) => a + b, 0);
    const pcs_rejected = data
      ?.map((i) => Number(i.pcs_rejected))
      .reduce((a, b) => a + b, 0);
    const pcs_produced = data
      ?.map((i) => Number(i.pcs_produced))
      .reduce((a, b) => a + b, 0);
    const mass_total_kg = data
      ?.map((i) => Number(i.mass_total_kg))
      .reduce((a, b) => a + b, 0);
    const mass_rejected_kg = data
      ?.map((i) => Number(i.mass_rejected_kg))
      .reduce((a, b) => a + b, 0);
    const mass_produced_kg = data
      ?.map((i) => Number(i.mass_produced_kg))
      .reduce((a, b) => a + b, 0);
    const divider_run_time = data
      ?.map((i) => Number(i.divider_run_time))
      .reduce((a, b) => a + b, 0);
    const divider_stop_time = data
      ?.map((i) => Number(i.divider_stop_time))
      .reduce((a, b) => a + b, 0);

    const availability = calculateOEE(data, lotsDurations, 'availability');
    const performance = calculateOEE(data, lotsDurations, 'performance');
    const quality = calculateOEE(data, lotsDurations, 'quality');
    const oee = calculateOEE(data, lotsDurations, 'oee');
    return {
      rec_batch_start,
      rec_batch_end,
      batch_duration,
      lotsDurations,
      pcs_total,
      pcs_rejected,
      pcs_produced,
      mass_total_kg,
      mass_produced_kg,
      mass_rejected_kg,
      divider_run_time,
      divider_stop_time,
      availability,
      performance,
      quality,
      oee,
    };
  };

  const calculateOEE = (data, durations, key) => {
    const values = data.map((i) => i[key]);
    const durationSum = durations.reduce((a, b) => a + b, 0);
    const result = values
      .map((value, index) => (durations[index] / durationSum) * Number(value))
      .reduce((a, b) => a + b, 0);

    // const up = values.map((value, index) => durations[index]*Number(value)).reduce((a, b) => a + b, 0)
    // const down = durationSum * values.length
    //console.log({durationSum, durations, values, result})
    // return Math.floor(up / down) + "%"
    return Math.floor(result);
  };

  const handleShowReport = (data) => {
    setIsReportDaily(false);
    const { rec_batch_nr } = data;

    const result = cloudData.find((item) => item.lot.nr === rec_batch_nr);
    setReportData(result);
    toggle();
  };
  // const handleShowReport = async (data) => {
  //   setIsReportDaily(false);

  //   const { rec_batch_nr } = data;

  //   const result = await getReportPromise(rec_batch_nr).then(
  //     (data) => data || []
  //   );
  //   setReportData(result);

  //   toggle();
  // };
  const handleShowDailyReport = () => {
    setIsReportDaily(true);
    toggle();

    // getAllReports();
  };

  const getReportPromise = async (rec_batch_nr) => {
    const params = {
      iproject_id: state.selectedProject.project_id,
      ibatch_nr: Number(rec_batch_nr),
      itz_offset_s: new Date().getTimezoneOffset() * 60,
    };
    return fetch(process.env.REACT_APP_PSQL_PROC, {
      method: 'POST',
      body: JSON.stringify({
        reqType: 'select',
        funcName: 'project.mas_lot_details_s',
        inputParameters: params,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => res.json());
  };
  const colorValue = (value) => {
    const color =
      value < 60
        ? colorScheme.main.danger._100
        : value < 75
        ? colorScheme.main.warning._100
        : value >= 75
        ? colorScheme.main.success._100
        : colorScheme.grayscale._100;
    return <span style={{ color }}>{value}%</span>;
  };

  const columns = [
    {
      Header: 'Recipe',
      columns: [
        {
          id: 'recipe',
          Header: 'Lot',
          accessor: 'rec_batch_nr',
          footer: 'footer',
          width: 75,
          style: { textAlign: 'center' },
        },
        {
          id: 'recipe_name',
          Header: () => (
            <div
              style={{
                textAlign: 'left',
              }}
            >
              Name
            </div>
          ),
          accessor: 'rec_name',
          footer: 'footer',
        },
      ],
    },
    {
      Header: 'Time',
      columns: [
        {
          id: 'start',
          Header: 'Start',
          accessor: 'rec_batch_start',
          footer: 'footer',
          style: { textAlign: 'center' },
          Cell: ({ cell }) => (
            <>
              <small
                style={{ color: colorScheme.grayscale._50, fontWeight: 500 }}
              >
                {moment(Number(cell.value)).format('DD.MM ')}
              </small>
              <div>{moment(Number(cell.value)).format('HH:mm:ss')}</div>
            </>
          ),
        },
        {
          id: 'end',
          Header: 'Completed at',
          accessor: 'rec_batch_end',
          footer: 'footer',
          style: { textAlign: 'center' },
          Cell: ({ cell }) => (
            <>
              <small
                style={{ color: colorScheme.grayscale._50, fontWeight: 500 }}
              >
                {moment(Number(cell.value)).format('DD.MM ')}
              </small>
              <div>{moment(Number(cell.value)).format('HH:mm:ss')}</div>
            </>
          ),
        },
        {
          id: 'batch_duration',
          Header: 'Duration',
          accessor: 'batch_duration',
          Cell: ({ cell }) => {
            const minutes = moment
              .duration(cell.value, 'milliseconds')
              .asMinutes();
            return (
              <>
                <small
                  style={{ color: colorScheme.grayscale._50, fontWeight: 500 }}
                >
                  {generateDurationString(cell.value)}
                </small>
                <div>{Math.round(Number(minutes))}min</div>
              </>
            );
          },
          footer: 'footer',
          style: { textAlign: 'center' },
        },
      ],
    },
    {
      Header: 'Produced',
      columns: [
        {
          id: 'pcs_produced',
          Header: () => (
            <div
              style={{
                textAlign: 'right',
              }}
            >
              Pieces
            </div>
          ),
          accessor: 'pcs_produced',
          footer: 'footer',
          style: { textAlign: 'right' },
          Cell: ({ cell }) =>
            Intl.NumberFormat(window.navigator.language).format(cell.value),
        },
        {
          id: 'mass_produced_kg',
          Header: () => (
            <div
              style={{
                textAlign: 'right',
              }}
            >
              Mass
            </div>
          ),
          accessor: 'mass_produced_kg',
          footer: 'footer',
          style: { textAlign: 'right' },
          Cell: ({ cell }) =>
            Intl.NumberFormat(window.navigator.language).format(cell.value) +
            ' kg',
        },
      ],
    },
    {
      Header: 'OEE',
      columns: [
        {
          id: 'oee',
          Header: 'OEE',
          accessor: 'oee',
          footer: 'footer',
          width: 75,
          style: { textAlign: 'center', fontWeight: 'bold' },
          Cell: ({ value }) => colorValue(Math.floor(Number(value))),
        },
        {
          id: 'availability',
          Header: 'AVA',
          accessor: 'availability',
          footer: 'footer',
          width: 75,
          style: { textAlign: 'center' },
          Cell: ({ value }) => colorValue(Math.floor(Number(value))),
        },
        {
          id: 'performance',
          Header: 'PER',
          accessor: 'performance',
          footer: 'footer',
          width: 75,
          style: { textAlign: 'center' },
          Cell: ({ value }) => colorValue(Math.floor(Number(value))),
        },
        {
          id: 'quality',
          Header: 'QUA',
          accessor: 'quality',
          footer: 'footer',
          width: 75,
          style: { textAlign: 'center' },
          Cell: ({ value }) => colorValue(Math.floor(Number(value))),
        },
      ],
    },
    {
      id: 'action',
      Header: 'Action',
      Cell: ({ row }) => (
        <div
          className="text-center"
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => handleShowReport(row.original)}
        >
          Report
        </div>
      ) /* <Report data={row.original}/>*/,
    },
  ];

  //const data = [{ id: 0, name: "round 800g", duration: "1:00:00", completedAt: "12:33:12", produced: 10000, plan: 11000, completed: 110, ava: 45, per: 65, qua: 95, oee: 71 },{ id: 0, name: "round 800g", duration: "1:00:00", completedAt: "12:33:12", produced: 10000, plan: 11000, completed: 110, ava: 45, per: 65, qua: 95, oee: 71 },{ id: 0, name: "round 800g", duration: "1:00:00", completedAt: "12:33:12", produced: 10000, plan: 11000, completed: 110, ava: 45, per: 65, qua: 95, oee: 71 },{ id: 0, name: "round 800g", duration: "1:00:00", completedAt: "12:33:12", produced: 10000, plan: 11000, completed: 110, ava: 45, per: 65, qua: 95, oee: 71 }]

  return loading ? (
    <Skeleton height={250} />
  ) : (
    <React.Fragment>
      <Card className="mb-3" style={{ overflow: 'auto' }}>
        <CardHeader>Finished lots</CardHeader>
        <CardBody className="p-0">
          {tableData.length === 0 ? (
            <NoContent title="No data" style={{ height: 'auto' }} />
          ) : (
            <TableReact
              columns={columns}
              // data={data}
              data={tableData}
              dataSum={summary}
              handleShowDailyReport={handleShowDailyReport}
            />
          )}
        </CardBody>
      </Card>
      <Modal
        toggle={toggle}
        fade={false}
        isOpen={isOpen}
        contentClassName="shadow-lg"
        size="xl"
      >
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
          <Report
            data={reportData}
            isReportDaily={isReportDaily}
            allLots={cloudData}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

function TableReact({ columns, data, dataSum, handleShowDailyReport }) {
  // Use the state and functions returned from useTable to build your UI
  const colorScheme = getColorScheme();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  const color = (value) => {
    return value < 60
      ? colorScheme.main.danger._100
      : value < 75
      ? colorScheme.main.warning._100
      : value >= 75
      ? colorScheme.main.success._100
      : colorScheme.grayscale._100;
  };
  const headerCellsStyle = {
    border: `1px solid ${colorScheme.grayscale._20}`,
    textAlign: 'center',
  };
  const cellsStyle = {
    border: `1px solid ${colorScheme.grayscale._20}`,
    padding: '.5em',
  };
  // Render the UI for your table
  return (
    <Table striped bordered {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} style={headerCellsStyle}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                  style={{
                    ...cell.column.style,
                    ...cellsStyle,
                    verticalAlign: 'middle',
                    lineHeight: '1em',
                  }}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr style={{ borderTop: '2px solid #ccc' }}>
          <td
            style={{
              border: `1px solid ${colorScheme.grayscale._20}`,
              fontWeight: 'bold',
              textAlign: 'center',

              verticalAlign: 'middle',
              lineHeight: '1em',
            }}
            colSpan={2}
          >
            Summary
          </td>
          <td
            style={{
              border: `1px solid ${colorScheme.grayscale._20}`,
              fontWeight: 'bold',
              textAlign: 'center',

              verticalAlign: 'middle',
              lineHeight: '1em',
            }}
          >
            <small
              style={{ color: colorScheme.grayscale._50, fontWeight: 500 }}
            >
              {moment(Number(dataSum?.rec_batch_start)).format('DD.MM ')}
            </small>
            <div>
              {moment(Number(dataSum?.rec_batch_start)).format('HH:mm:ss')}
            </div>

            {/* {moment(Number(dataSum?.rec_batch_start)).format('HH:mm:ss')} */}
          </td>
          <td
            style={{
              border: `1px solid ${colorScheme.grayscale._20}`,
              fontWeight: 'bold',
              textAlign: 'center',

              verticalAlign: 'middle',
              lineHeight: '1em',
            }}
          >
            <small
              style={{ color: colorScheme.grayscale._50, fontWeight: 500 }}
            >
              {moment(Number(dataSum?.rec_batch_end)).format('DD.MM ')}
            </small>
            <div>
              {moment(Number(dataSum?.rec_batch_end)).format('HH:mm:ss')}
            </div>
            {/* {moment(Number(dataSum?.rec_batch_end)).format('HH:mm:ss')} */}
          </td>
          <td
            style={{
              border: `1px solid ${colorScheme.grayscale._20}`,
              fontWeight: 'bold',
              textAlign: 'center',

              verticalAlign: 'middle',
              lineHeight: '1em',
            }}
          >
            <small
              style={{ color: colorScheme.grayscale._50, fontWeight: 500 }}
            >
              {generateDurationString(Number(dataSum?.batch_duration))}
            </small>
            <div>
              {Math.round(
                Number(
                  moment
                    .duration(dataSum?.batch_duration, 'milliseconds')
                    .asMinutes()
                )
              )}
              min
            </div>
          </td>
          <td
            style={{
              border: `1px solid ${colorScheme.grayscale._20}`,
              fontWeight: 'bold',
              textAlign: 'right',

              verticalAlign: 'middle',
            }}
          >
            {Intl.NumberFormat(window.navigator.language).format(
              dataSum?.pcs_produced
            )}
          </td>
          <td
            style={{
              border: `1px solid ${colorScheme.grayscale._20}`,
              fontWeight: 'bold',
              textAlign: 'right',
              verticalAlign: 'middle',
            }}
          >
            {Intl.NumberFormat(window.navigator.language).format(
              dataSum?.mass_produced_kg
            )}{' '}
            kg
          </td>
          <td
            style={{
              border: `1px solid ${colorScheme.grayscale._20}`,
              fontWeight: 'bold',
              textAlign: 'center',

              verticalAlign: 'middle',
              lineHeight: '1em',
              color: color(dataSum?.oee),
            }}
          >
            {dataSum?.oee}%
          </td>
          <td
            style={{
              border: `1px solid ${colorScheme.grayscale._20}`,
              fontWeight: 'bold',
              textAlign: 'center',

              verticalAlign: 'middle',
              lineHeight: '1em',
              color: color(dataSum?.availability),
            }}
          >
            {dataSum?.availability}%
          </td>
          <td
            style={{
              border: `1px solid ${colorScheme.grayscale._20}`,
              fontWeight: 'bold',
              textAlign: 'center',

              verticalAlign: 'middle',
              lineHeight: '1em',
              color: color(dataSum?.performance),
            }}
          >
            {dataSum?.performance}%
          </td>
          <td
            style={{
              border: `1px solid ${colorScheme.grayscale._20}`,
              fontWeight: 'bold',
              textAlign: 'center',

              verticalAlign: 'middle',
              lineHeight: '1em',
              color: color(dataSum?.quality),
            }}
          >
            {dataSum?.quality}%
          </td>
          <td
            style={{
              border: `1px solid ${colorScheme.grayscale._20}`,
              fontWeight: 'bold',
              textAlign: 'center',

              verticalAlign: 'middle',
              lineHeight: '1em',
            }}
          >
            <div
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={handleShowDailyReport}
            >
              Report
            </div>
          </td>
        </tr>
      </tfoot>
    </Table>
  );
}

export default FinishedLotsTable2;
