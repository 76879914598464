import React, { useState, useEffect } from 'react';
import NoContent from '../NoContent/NoContent';
import BasicTab from './BasicTab';
import CustomTab from './CustomTab';
import { StyledTab, StyledTabs } from './Tabs.styles';

const Tabs = ({ children, tabs, onSelectedTab = false }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  useEffect(() => {
    if (!onSelectedTab) return;
    onSelectedTab();
  }, [selectedTab]);

  return (
    <>
      <StyledTabs>
        <div>
          {tabs.map((tab, index) => {
            const isBasicTab = typeof tab === 'string';
            return isBasicTab ? (
              <BasicTab
                index={index}
                tab={tab}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
            ) : (
              <CustomTab
                index={index}
                tab={tab}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
            );
          })}
        </div>
      </StyledTabs>
      {children[selectedTab] || <NoContent />}
    </>
  );
};

export default Tabs;
