//  LEV: AddEditItemDialog
export function prepareItemForOrder({ item, selectedOrder }) {
  const status = selectedOrder?.details?.status || 'open';
  const isOpenOrder = status === 'open';

  let _obj = {
    material_code: item.No || item.material_code,
    name: item.Translations || item.name,
    note: item.note || '',
    quantity: item.quantity || item.QtyperParent,
    orig_quantity: isOpenOrder
      ? item.quantity || item.QtyperParent
      : item.orig_quantity || 0,
    price: item.Price || item.price || null,
    // Position not in use anymore. now we use "pos" just for display in table as index
    // pos: item.pos || selectedOrder?.items.length + 1,
    value: item.value || null,
  };
  return _obj;
}
// doesn't work anymore
export function prepareItemsForTable(items) {
  let keyArr = Object.keys(items);
  let arr = [];
  for (let i = 0; i < keyArr.length; i++) {
    let item = items[keyArr[i]];
    item.id = i + 1;
    arr.push(item);
  }
  return arr;
}

export const columns = (isStatusOpen = false) => {
  const columns = [
    {
      id: 'pos',
      Header: 'Pos.',
      style: { textAlign: 'right' },
      accessor: 'pos',
      Cell: ({ row }) => row.index + 1,
    },
    {
      id: 'material_code',
      Header: 'Code',
      accessor: 'material_code',
      style: { textAlign: 'right' },
    },
    {
      id: 'name',
      Header: 'Material',
      accessor: 'name',
      Cell: (row) => getTranslation(row.value),
    },
    {
      id: 'price',
      Header: 'Price',
      accessor: 'price',
      style: { textAlign: 'right' },
    },
    {
      id: 'orig_quantity',
      Header: 'Orig. quantity',
      accessor: 'orig_quantity',
      style: { textAlign: 'right' },
    },
    {
      id: 'quantity',
      Header: 'Quantity',
      accessor: 'quantity',
      style: { textAlign: 'right' },
    },
    {
      id: 'value',
      Header: 'Value',
      accessor: 'value',
      style: { textAlign: 'right' },
      Cell: ({ row }) => {
        const { price, quantity } = row.original;
        const value = (price * quantity).toFixed(2);
        return value;
      },
    },
    {
      id: 'note',
      Header: 'Note',
      accessor: 'note',
    },
  ];

  const removedOriginalQuantity = columns.filter(
    (item) => item.id !== 'orig_quantity'
  );

  return isStatusOpen ? removedOriginalQuantity : columns;
};

// izbere jezik, ki ga je nastavil uporabnik.
export function userLanguageFor(translationObj) {
  if (!translationObj) return '';
  /* Koda jezika za materiale
    se zaenkrat razlikuje od Firebase kode;
    ko bosta obe usklajeni, bo Firebase koda določala tudi,
    kateri jezik se bo uporabil pri imenu materialov;
    zaenkrat pa hard-coded slovenščina za materiale: */
  const selectedLanguage = 'SVN'; //mainAuth.languageCode;
  if (translationObj[selectedLanguage]?.Description) {
    return translationObj[selectedLanguage]['Description'];
  }
  //Angleščina je rezervna opcija:
  else if (Object.keys(translationObj).includes('ENG')) {
    return translationObj['ENG']['Description'];
  } else {
    return translationObj['SVN']['Description'];
  }
}

export function getTranslation(translations) {
  return userLanguageFor(translations);
}
