import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../../utils/firebaseAuth';

export function PrivateRoute({ children, ...rest }) {
  const { currentUser } = useContext(AuthContext);

  if (!currentUser) return <Redirect to="/login" />;

  return <Route {...rest} component={rest.component} />;
}
