import React, { useEffect, useState } from 'react';
import { IoIosWarning } from 'react-icons/io';
import { ModalHeader, Modal, ModalBody } from 'reactstrap';
import DateSelection from '../../../../../components/DateSelection';
import Filter from '../../../../../components/Filter';
import NoContent from '../../../../../components/NoContent/NoContent';
import { useStore } from '../../../../../store';
import { sumDuration } from '../../../../Diagnostics/helpers';
import BarChart from '../../../../Diagnostics/views/Alarms/partials/Barchart';
import Table from '../../../../Diagnostics/views/Alarms/partials/Table';

const AlarmStatistics = ({
  data,
  setDates,
  dates,
  datesLoading,
  modalOnly,
  ExternalToggle,
  ExternalIsOpen,
}) => {
  const { state } = useStore();
  const [isOpen, setIsOpen] = useState(ExternalIsOpen || false);
  const [machineAlarmsForChart, setMachineAlarmsForChart] = useState([]);
  const [machineAlarmsForTable, setMachineAlarmsForTable] = useState([]);
  useEffect(() => {
    const alarms = state.diagnostics;
    const machineAlarmsForChart = alarms?.alarmsDurationSum
      ? alarms.alarmsDurationSum.filter(
          (alarm) =>
            alarm.machineSerialNumber.toString() === data.machine_id.toString()
        )
      : [];
    const machineAlarmsForTable = alarms?.data
      ? alarms.data.filter(
          (alarm) => alarm.serial_nr.toString() === data.machine_id.toString()
        )
      : [];
    setMachineAlarmsForChart(machineAlarmsForChart);
    setMachineAlarmsForTable(machineAlarmsForTable);
  }, [state.diagnostics, ExternalIsOpen, dates]);

  const [filteredData, setFilteredData] = useState(null);
  const [filteredAlarmsDurationSum, setFilteredAlarmsDurationSum] = useState(
    null
  );

  const toggle = () => (ExternalToggle ? ExternalToggle() : setIsOpen(!isOpen));

  const filterAlarms = (data) => {
    setFilteredData(data);
    const sortByDuration = data && sumDuration(data);
    setFilteredAlarmsDurationSum(sortByDuration);
  };

  const filterBy = [
    {
      label: 'Alarms type',
      type: 'alarm_type',
    },
  ];

  return (
    <>
      {!modalOnly && (
        <div
          style={{ cursor: 'pointer', padding: '0.5rem 1rem' }}
          onClick={toggle}
        >
          <IoIosWarning style={{ fontSize: '1.25rem' }} />
          <br />
          Alarms
        </div>
      )}
      <Modal
        autoFocus={false}
        fade={false}
        isOpen={ExternalIsOpen || isOpen}
        contentClassName="shadow-lg"
        size={'xl'}
      >
        <ModalHeader toggle={toggle}>Alarms Statistics</ModalHeader>
        <ModalBody>
          <div style={{ padding: '0 1rem' }}>
            <DateSelection
              range
              setDates={setDates}
              selectedDates={dates}
              loading={datesLoading}
            />
          </div>
          {!machineAlarmsForChart.length ? (
            <NoContent style={{ height: '250px' }} title="No data">
              Try different date
            </NoContent>
          ) : (
            <React.Fragment>
              <BarChart
                data={filteredAlarmsDurationSum || machineAlarmsForChart}
              />
              <Filter
                data={machineAlarmsForTable}
                filterBy={filterBy}
                onChange={filterAlarms}
              />
              <Table
                data={filteredData || machineAlarmsForTable}
                durations={filteredAlarmsDurationSum || machineAlarmsForChart}
              />
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default AlarmStatistics;
