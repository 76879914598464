export function getInitials(string) {
  if (!string) return;
  const isEmail = string.includes('@');
  const displayName = isEmail ? generateDisplayName(string) : string;

  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

  let initials = [...displayName.matchAll(rgx)] || [];
  initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase();

  return initials;
}

function generateDisplayName(email) {
  if (!email) {
    return;
  }
  return email
    .match('.+(?=@)')[0]
    .split('.')
    .map((item) => item[0].toUpperCase() + item.slice(1))
    .slice(0, 2)
    .join(' ');
}
