import React, { useState } from 'react';
import {
  Col,
  Card,
  CardBody,
  CardHeader,
  Progress,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { FiArrowRight } from 'react-icons/fi';
import Skeleton from 'react-loading-skeleton';
import StopModalData from './StopModalData';
import LossModalData from './LossModalData';
import ConsumptionModalData from './ConsumptionModalData';
import { getColorScheme } from '../../../../../lib/helpers';
import { generateDurationString } from '../../../../Diagnostics/helpers';
import styled from 'styled-components';

const StyledProgressBar = styled(Progress)`
  .progress-bar {
    background-color: ${({ theme, themeColor }) =>
      theme.colors.other[themeColor]._100};
  }
`;
const Cards = ({
  sums,
  dataNew,
  energy,
  loading,
  summary,
  setModalData,
  avaTimeline,
  reasonOptions,
  stopsWithReasons,
  getStopsWithReasons,
  reports2,
}) => {
  const [isOpenProduced, setIsOpenProduced] = useState(false);
  const [isOpenTimes, setIsOpenTimes] = useState(false);
  const [isOpenConsumption, setIsOpenConsumption] = useState(false);

  const toggleProduced = () => setIsOpenProduced(!isOpenProduced);
  const toggleTimes = () => setIsOpenTimes(!isOpenTimes);
  const toggleConsumption = () => setIsOpenConsumption(!isOpenConsumption);

  const piecesAndMass = {
    pieces: energy && energy.map((item) => Number(item.pcs_total)),
    piecesTotalSum:
      energy &&
      energy
        .map((item) => Number(item.pcs_total))
        .reduce((a, b) => Number(a) + Number(b), 0),
    mass:
      energy && energy.map((item) => Math.floor(Number(item.weight_total_kg))),
    massTotalSum:
      energy &&
      energy
        .map((item) => Number(item.weight_total_kg))
        .reduce((a, b) => Number(a) + Number(b), 0),
  };

  const consumption = {
    elEntry:
      energy &&
      energy
        .map((item) => Number(item.entry_part_kwh).toFixed(2))
        .reduce((a, b) => Number(a) + Number(b), 0),
    elEntryPerTonne:
      energy &&
      energy
        .map((item) => Number(item.entry_part_kwh).toFixed(2))
        .reduce((a, b) => Number(a) + Number(b), 0) /
        (piecesAndMass.massTotalSum / 1000),
    elMain:
      energy &&
      energy
        .map((item) => Number(item.main_part_kwh).toFixed(2))
        .reduce((a, b) => Number(a) + Number(b), 0),
    elMainPerTonne:
      energy &&
      energy
        .map((item) => Number(item.main_part_kwh).toFixed(2))
        .reduce((a, b) => Number(a) + Number(b), 0) /
        (piecesAndMass.massTotalSum / 1000),
    elOven:
      energy &&
      energy
        .map((item) => Number(item.oven_part_kwh).toFixed(2))
        .reduce((a, b) => Number(a) + Number(b), 0),
    elOvenPerTonne:
      energy &&
      energy
        .map((item) => Number(item.oven_part_kwh).toFixed(2))
        .reduce((a, b) => Number(a) + Number(b), 0) /
        (piecesAndMass.massTotalSum / 1000),
    elMixers:
      energy &&
      energy
        .map((item) => Number(item.mixers_part_kwh).toFixed(2))
        .reduce((a, b) => Number(a) + Number(b), 0),
    elMixersPerTonne:
      energy &&
      energy
        .map((item) => Number(item.mixers_part_kwh).toFixed(2))
        .reduce((a, b) => Number(a) + Number(b), 0) /
        (piecesAndMass.massTotalSum / 1000),
    elTotalSum:
      energy &&
      energy
        .map((item) =>
          (
            Number(item.mixers_part_kwh) +
            Number(item.entry_part_kwh) +
            Number(item.main_part_kwh) +
            Number(item.oven_part_kwh)
          ).toFixed(2)
        )
        .reduce((a, b) => Number(a) + Number(b), 0),
    elTotalPerTonne:
      energy &&
      energy
        .map((item) =>
          (
            Number(item.mixers_part_kwh) +
            Number(item.entry_part_kwh) +
            Number(item.main_part_kwh) +
            Number(item.oven_part_kwh)
          ).toFixed(2)
        )
        .reduce((a, b) => Number(a) + Number(b), 0) /
        (piecesAndMass.massTotalSum / 1000),
    gasTotalSum:
      energy &&
      energy
        .map((item) => Number(item.oven_gas_m3).toFixed(2))
        .reduce((a, b) => Number(a) + Number(b), 0),
    gasTotalPerTonne:
      energy &&
      energy
        .map((item) => Number(item.oven_gas_m3).toFixed(2))
        .reduce((a, b) => Number(a) + Number(b), 0) /
        (piecesAndMass.massTotalSum / 1000),
    compressedAirSum:
      energy &&
      energy
        .map((item) => Number(item.compressed_air_m3).toFixed(2))
        .reduce((a, b) => Number(a) + Number(b), 0),
    compressedAirPerTonne:
      energy &&
      energy
        .map((item) => Number(item.compressed_air_m3).toFixed(2))
        .reduce((a, b) => Number(a) + Number(b), 0) /
        (piecesAndMass.massTotalSum / 1000),
    steamMain:
      energy &&
      energy
        .map((item) => Number(item.main_part_steam_kg).toFixed(2))
        .reduce((a, b) => Number(a) + Number(b), 0),
    steamMainPerTonne:
      energy &&
      energy
        .map((item) => Number(item.main_part_steam_kg).toFixed(2))
        .reduce((a, b) => Number(a) + Number(b), 0) /
        (piecesAndMass.massTotalSum / 1000),
    steamOven:
      energy &&
      energy
        .map((item) => Number(item.oven_steam_kg).toFixed(2))
        .reduce((a, b) => Number(a) + Number(b), 0),
    steamOvenPerTonne:
      energy &&
      energy
        .map((item) => Number(item.oven_steam_kg).toFixed(2))
        .reduce((a, b) => Number(a) + Number(b), 0) /
        (piecesAndMass.massTotalSum / 1000),
    steamTotalSum:
      energy &&
      energy
        .map((item) =>
          (
            Number(item.main_part_steam_kg) + Number(item.oven_steam_kg)
          ).toFixed(2)
        )
        .reduce((a, b) => Number(a) + Number(b), 0),
    steamTotalPerTonne:
      energy &&
      energy
        .map((item) =>
          (
            Number(item.main_part_steam_kg) + Number(item.oven_steam_kg)
          ).toFixed(2)
        )
        .reduce((a, b) => Number(a) + Number(b), 0) /
        (piecesAndMass.massTotalSum / 1000),
    recuperationTotalSum:
      energy &&
      energy
        .map((item) => Number(item.recuperation_kwh).toFixed(2))
        .reduce((a, b) => Number(a) + Number(b), 0),
    recuperationTotalPerTonne:
      energy &&
      energy
        .map((item) => Number(item.recuperation_kwh).toFixed(2))
        .reduce((a, b) => Number(a) + Number(b), 0) /
        (piecesAndMass.massTotalSum / 1000),
  };

  return (
    <React.Fragment>
      <Col lg={4} className="mb-3">
        {loading ? (
          <Skeleton height={130} />
        ) : (
          <OEECard data={summary} sums={sums} />
        )}
      </Col>
      <Col lg={4} className="mb-3">
        {loading ? (
          <Skeleton height={130} />
        ) : (
          <ProducedCard data={summary} toggle={toggleProduced} sums={sums}>
            <ModalComponent
              title="Produced Pieces And Mass"
              isOpen={isOpenProduced}
              toggle={toggleProduced}
            >
              <ModalBody className="bg-light">
                <LossModalData data={summary} sums={sums} />
              </ModalBody>
            </ModalComponent>
          </ProducedCard>
        )}
      </Col>
      <Col lg={4} className="mb-3">
        {loading ? (
          <Skeleton height={130} />
        ) : (
          <TimesCard data={summary} toggle={toggleTimes} sums={sums}>
            <ModalComponent
              title="Production Times"
              isOpen={isOpenTimes}
              toggle={toggleTimes}
            >
              <ModalBody className="bg-light">
                <StopModalData
                  sums={sums}
                  dataNew={dataNew}
                  data={{
                    summary,
                    setModalData,
                    avaTimeline,
                    reports2,
                    reasonOptions,
                    stopsWithReasons,
                    getStopsWithReasons,
                  }}
                />
              </ModalBody>
            </ModalComponent>
          </TimesCard>
        )}
      </Col>
      <Col lg={8} className="mb-3">
        {loading ? (
          <Skeleton height={130} />
        ) : (
          <ConsumptionCard
            data={consumption}
            toggle={toggleConsumption}
            sums={sums}
          >
            <ModalComponent
              title="Consumption"
              isOpen={isOpenConsumption}
              toggle={toggleConsumption}
            >
              <ModalBody className="bg-light">
                <ConsumptionModalData data={consumption} sums={sums} />
              </ModalBody>
            </ModalComponent>
          </ConsumptionCard>
        )}
      </Col>
      <Col lg={4} className="mb-3">
        {loading ? (
          <Skeleton height={130} />
        ) : (
          <RecuperationCard data={consumption} sums={sums} />
        )}
      </Col>
    </React.Fragment>
  );
};

const CardComponent = ({ title, event, children }) => {
  const colorScheme = getColorScheme();
  return (
    <Card
      className="h-100"
      onClick={event}
      style={{ cursor: event && 'pointer' }}
    >
      <CardHeader className="d-flex justify-content-between pb-1">
        <div>{title}</div>
        {event && (
          <div style={{ color: colorScheme.grayscale._40 }}>
            view more <FiArrowRight />
          </div>
        )}
      </CardHeader>
      <CardBody
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          flexWrap: 'wrap',
          paddingTop: '.25rem',
          paddingBottom: '.25rem',
          fontWeight: 'bold',
        }}
      >
        {children}
      </CardBody>
    </Card>
  );
};

const CardBodyComponent = ({ data: { label, primary, secondary } }) => {
  const colorScheme = getColorScheme();
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ color: colorScheme.grayscale._40 }}>{label}</div>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <div style={{ fontSize: '1.5em', color: primary.color }}>
          {primary.value || '-'}
          {primary.unit}
        </div>
        {secondary && (
          <div style={{ paddingLeft: '1em', color: secondary.color }}>
            {secondary.value || '-'}
            {secondary.unit}
          </div>
        )}
      </div>
    </div>
  );
};

const OEECard = ({ data, sums }) => {
  return (
    <CardComponent title="OEE">
      <div style={{ flex: 1, fontSize: '1.5em' }}>
        {sums?.oee?.oee ? sums.oee.oee : '-'}%
      </div>
      <div
        style={{
          flex: 2,
          fontSize: '1rem',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <StyledProgressBar
          value={sums?.oee?.availability ? sums?.oee?.availability : 0}
          className="mb-1"
          themeColor={0}
        >
          AVA: {sums?.oee?.availability ? sums?.oee?.availability : '-'}%
        </StyledProgressBar>
        <StyledProgressBar
          value={sums?.oee?.performance ? sums?.oee?.performance : 0}
          className="mb-1"
          themeColor={1}
        >
          PER: {sums?.oee?.performance ? sums?.oee?.performance : '-'}%
        </StyledProgressBar>
        <StyledProgressBar
          value={sums?.oee?.quality ? sums?.oee?.quality : 0}
          className="mb-1"
          themeColor={2}
        >
          QUA: {sums?.oee?.quality ? sums?.oee?.quality : '-'}%
        </StyledProgressBar>
      </div>
    </CardComponent>
  );
};

const ProducedCard = ({ data, toggle, children, sums }) => {
  const formatValue = (value) => {
    if (!value) return '- ';
    return Intl.NumberFormat(window.navigator.language).format(
      Math.floor(Number(value))
    );
  };
  return (
    <CardComponent title="Produced Pieces And Mass" event={toggle}>
      <CardBodyComponent
        data={{
          label: 'Pieces',
          primary: {
            value: formatValue(sums?.counters?.pieces?.out),
            // value: formatValue(data.pcs_produced),
            unit: 'pcs',
          },
        }}
      />
      <CardBodyComponent
        data={{
          label: 'Mass',
          primary: {
            value: formatValue(sums?.counters?.mass_g?.out / 1000),
            // value: formatValue(data.mass_produced_kg),
            unit: 'kg',
          },
        }}
      />
      {children}
    </CardComponent>
  );
};

const TimesCard = ({ data, toggle, children, sums }) => {
  return (
    <CardComponent title="Production Times" event={toggle}>
      <CardBodyComponent
        data={{
          label: '',
          primary: {
            value: generateDurationString(
              // ? Number(data.divider_run_time) + Number(data.divider_stop_time)
              data ? Number(sums.durations) : 0
            ),
            unit: '',
          },
        }}
      />
      {children}
    </CardComponent>
  );
};

const ConsumptionCard = ({ data, toggle, children, sums }) => {
  const formatValue = (value) => {
    if (!value) return '- ';
    return Intl.NumberFormat(window.navigator.language).format(
      Math.round(Number(value))
    );
  };
  // if (!sums?.energy) return <></>;
  const electricity = sums?.energy
    ? sums.energy.mixers_kwh +
      sums.energy.entry_kwh +
      sums.energy.main_kwh +
      sums.energy.oven_kwh
    : null;

  return (
    <CardComponent title="Consumption" event={toggle}>
      <CardBodyComponent
        data={{
          label: 'Electricity',
          primary: {
            value: formatValue(electricity),
            unit: 'kWh',
          },
          secondary: {
            value: formatValue(
              Number(electricity) /
                (Number(sums?.counters?.mass_g?.total) / 1000000)
            ),
            unit: 'kWh/t',
          },
        }}
      />
      <CardBodyComponent
        data={{
          label: 'Gas',
          primary: {
            value: formatValue(sums?.energy?.oven_gas_m3),
            unit: (
              <span>
                m<sup>3</sup>
              </span>
            ),
          },
          secondary: {
            value: formatValue(
              Number(sums?.energy?.oven_gas_m3) /
                (Number(sums?.counters?.mass_g?.total) / 1000000)
            ),
            unit: (
              <span>
                m<sup>3</sup>/t
              </span>
            ),
          },
        }}
      />
      <CardBodyComponent
        data={{
          label: 'Compressed air',
          primary: {
            value: formatValue(sums?.energy?.compr_air_m3),
            unit: (
              <span>
                m<sup>3</sup>
              </span>
            ),
          },
          secondary: {
            value: formatValue(
              Number(sums?.energy?.compr_air_m3) /
                (Number(sums?.counters?.mass_g?.total) / 1000000)
            ),
            unit: (
              <span>
                m<sup>3</sup>/t
              </span>
            ),
          },
        }}
      />
      <CardBodyComponent
        data={{
          label: 'Steam',
          primary: {
            value: formatValue(
              sums?.energy?.steam_main_m3 + sums?.energy?.steam_oven_m3
            ),
            unit: <span>kg</span>,
          },
          secondary: {
            value: formatValue(
              Number(
                sums?.energy?.steam_main_m3 + sums?.energy?.steam_oven_m3
              ) /
                (Number(sums?.counters?.mass_g?.total) / 1000000)
            ),
            unit: <span>kg/t</span>,
          },
        }}
      />
      {children}
    </CardComponent>
  );
};

const RecuperationCard = ({ data, sums }) => {
  const colorScheme = getColorScheme();
  const formatValue = (value) => {
    if (!value) return '- ';
    return Intl.NumberFormat(window.navigator.language).format(
      Math.floor(Number(value))
    );
  };
  return (
    <CardComponent title="Recuperated Energy">
      <CardBodyComponent
        data={{
          label: '',
          primary: {
            value: formatValue(sums?.energy?.recuperation_kwh),
            unit: 'kWh',
            color: colorScheme.main.success._100,
          },
          secondary: {
            value: formatValue(
              Number(sums?.energy?.recuperation_kwh) /
                (Number(sums?.counters?.mass_g?.total) / 1000000)
            ),
            unit: 'kWh/t',
            color: colorScheme.main.success._100,
          },
        }}
      />
    </CardComponent>
  );
};

const ModalComponent = ({ toggle, isOpen, title, children }) => {
  return (
    <Modal
      autoFocus={false}
      fade={false}
      isOpen={isOpen}
      contentClassName="shadow-lg"
      size="xl"
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      {children}
    </Modal>
  );
};

export default Cards;
