import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * This hook gets called only when the dependencies change but not during initial render.
 *
 * @example
 * ```
 *  useNonInitialEffect(()=>{
 *      alert("Dependency changed!");
 * },[dependency]);
 * ```
 */
export const useNonInitialEffect = (effect, deps) => {
  const initialRender = useRef(true);
  useEffect(() => {
    let effectReturns = () => {};
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      effectReturns = effect();
    }
    if (effectReturns && typeof effectReturns === 'function') {
      return effectReturns;
    }
  }, deps);
};

/**
 * Provides a boolean state variable that can be toggled between its two states.
 * -> Use the useState() hook to create the value state variable and its setter.
 * -> Create a function that toggles the value of the value state variable and memoize it, using the useCallback() hook.
 * -> Return the value state variable and the memoized toggler function.
 * @example
 * ```
 *  const Switch = () => {
 *    const [val, toggleVal] = useToggler(false);
 *    return <button onClick={toggleVal}>{val ? 'ON' : 'OFF'}</button>;
 *  };
 * ```
 */
export const useToggler = (initialState) => {
  const [value, setValue] = useState(initialState);

  const toggleValue = useCallback(() => setValue((prev) => !prev), []);

  return [value, toggleValue];
};

/**
 *
 * Sync state to local storage so that it persists through a page refresh.
 * Usage is similar to useState except we pass in a local storage key so that we
 * can default to that value on page load instead of the specified initial value.
 *
 *  @example
 * ```
 * function App() {
 *   // Similar to useState but first arg is key to the value in local storage.
 *   const [name, setName] = useLocalStorage("name", "Bob");
 *   return (
 *     <div>
 *       <input
 *         type="text"
 *         placeholder="Enter your name"
 *         value={name}
 *         onChange={(e) => setName(e.target.value)}
 *       />
 *     </div>
 *   );
 * }
 * ```
 */
export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}
