import styled, { css } from 'styled-components';

export const StyledTabs = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 1em;
  align-items: flex-end;
  border-bottom: solid ${({ theme }) => theme.colors.grayscale._30} 1px;
  overflow-y: auto;

  div {
    display: flex;
    gap: 0.2em;
    align-items: baseline;
  }
`;

export const StyledTab = styled.div`
  cursor: pointer;
  padding: 0.5rem 1rem;

  ${({ active }) =>
    active &&
    css`
      border-bottom: solid 3px ${({ theme }) => theme.colors.main.primary._100};
      font-weight: 500;
    `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.grayscale._10};
  }
`;
