import styled from 'styled-components';

export const FileUploadContainer = styled.section`
  position: relative;
  margin: 2em 0 0;
  border: 2px dotted lightgray;
  padding: 1em;
  border-radius: 6px;
  background-color: white;
`;

export const FormField = styled.input`
  font-size: 18px;
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;

  &:focus {
    outline: none;
  }
`;

export const DragDropText = styled.p`
  margin-top: 1em;
  text-align: center;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grayscale._40};
`;

export const FilePreviewContainer = styled.article`
  margin-bottom: 35px;

  span {
    font-size: 14px;
  }
`;

export const PreviewList = styled.section`
  display: flex;
  flex-direction: column;
`;

export const InputLabel = styled.label`
  top: -21px;
  font-size: 13px;
  color: black;
  left: 0;
  position: absolute;
`;

export const StyledAttachmentPreview = styled.div`
  display: flex;
  gap: 0.5em;
  max-width: 500px;
  padding: 3px;
  border-radius: 5px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grayscale._10};
  }
  .icon {
  }
  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 350px;
  }
  .size {
    color: ${({ theme }) => theme.colors.grayscale._40};
  }
  .remove {
    flex: 1;
    text-align: end;
    &:hover {
      cursor: pointer;
    }
  }
`;
