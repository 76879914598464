import moment from 'moment';
import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';

const generateDurationString = (milliseconds) => {
  const duration = moment.duration(Number(milliseconds));
  let durationString = '';
  durationString += duration.years() > 0 ? `${duration.years()} years ` : '';
  durationString += duration.months() > 0 ? `${duration.months()} months ` : '';
  durationString += duration.days() > 0 ? `${duration.days()} days ` : '';
  durationString += `${duration.hours()}`;
  durationString += `:${('0' + duration.minutes()).slice(-2)}`;
  durationString += `:${('0' + duration.seconds()).slice(-2)}`;
  return durationString;
};

const getAlarmHistory = async ({
  dates,
  projectId,
  setLoading,
  setLoadingAlarmHistory,
  setError,
}) => {
  const [start, end] = dates;
  if (!start) return [];
  const istart_ts = Math.floor(start.getTime() / 1000);
  const iend_ts = Math.floor(end.setHours(23, 59, 59, 999) / 1000);
  const params = {
    iproject_id: projectId,
    istart_ts,
    iend_ts,
    tz_offset: new Date().getTimezoneOffset() * 60,
  };
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'select',
      funcName:
        params.iproject_id === 'T02606'
          ? 'project.mas_alarm_hist'
          : 'project.mas_alarm_hist_v2',
      inputParameters: params,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((data) => {
      setLoading(false);
      setLoadingAlarmHistory(false);
      if (data.ERROR) {
        setError(true);
        console.error(data.ERROR);
        return [];
      }
      return data || [];
    })
    .catch((err) => {
      setError(true);
      setLoading(false);
      console.error(err);
      return [];
    });
};

const sumDuration = (data) => {
  const categories = uniq(data.map((item) => item.measurement_name));
  const durations = categories.map((category) => {
    const filtered = data.filter((item) => item.measurement_name === category);
    const name =
      data.find((item) => item.measurement_name === category).description ||
      data.find((item) => item.measurement_name === category).alarm_text;
    const machine = data.find((item) => item.measurement_name === category)
      .machine_description;
    const machineSerialNumber = data.find(
      (item) => item.measurement_name === category
    ).serial_nr;
    const value = sum(
      filtered.map((item) => (item.duration = Number(item.duration)))
    );
    const typeSrc = data.find((item) => item.measurement_name === category)
      .alarm_type;
    const type = typeSrc ? typeSrc.toLowerCase() : 'info';
    return { name, value, type, machine, machineSerialNumber };
  });
  const durationsSorted = reverse(sortBy(durations, 'value'));
  return durationsSorted;
};

const generateAlarmsTimelineData = (data) => {
  const sortByDuration = sumDuration(data);
  const timelineData = data.map((item) => [
    item.description || item.alarm_text,
    Number(item.begin_time),
    Number(item.end_time),
    Number(item.duration),
    item.alarm_type ? item.alarm_type.toLowerCase() : 'info',
    item.machine_description ? item.machine_description : '-',
  ]);
  const sortedByNames = reverse(
    sortByDuration
      .map((i) => timelineData.filter((j) => j[0] === i.name))
      .flat()
  );
  return sortedByNames;
};

export {
  generateDurationString,
  getAlarmHistory,
  sumDuration,
  generateAlarmsTimelineData,
};
