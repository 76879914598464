import React from 'react';
import {
  FaExclamationCircle,
  FaPlayCircle,
  FaStopCircle,
} from 'react-icons/fa';
import TooltipWrapper from '../../../../../components/TooltipWrapper';
import { getColorScheme } from '../../../../../lib/helpers';
import handGearIcon from '../../../../../media/hand-gear-white.svg';

const MachineState = ({ data, large, styles, small }) => {
  if (!data) return null;
  if (!data?.machine_state) return null;
  const hasError = data?.has_error === true;
  const colorScheme = getColorScheme();
  return (
    <TooltipWrapper
      content={'Status: ' + (data?.machine_state || 'AUTO')}
      className="ml-auto"
    >
      <div
        style={{
          fontSize: large ? '2em' : small ? '1.3em' : '1.3rem',
          display: 'flex',
          ...styles,
        }}
      >
        <FaPlayCircle
          className="mr-1"
          style={{
            color:
              data?.machine_state === 'AUTO'
                ? colorScheme.main.success._100
                : data?.machine_state !== 'AUTO' && data?.machine_id === 12175 // TODO: remove this condition after oven get some data in database
                ? colorScheme.main.success._100
                : 'rgba(0,0,0,0.1)',
          }}
        />
        <FaStopCircle
          className="mr-1"
          style={{
            color:
              data?.machine_state === 'STOP'
                ? colorScheme.main.warning._100
                : 'rgba(0,0,0,0.1)',
          }}
        />
        <FaExclamationCircle
          className="mr-1"
          style={{
            color: hasError ? colorScheme.main.danger._100 : 'rgba(0,0,0,0.1)',
          }}
        />
        <div
          className="text-center"
          style={{
            borderRadius: 50,
            width: large ? 32 : small ? 15 : 20,
            height: large ? 32 : small ? 15 : 20,
            backgroundColor:
              data?.machine_state === 'MANUAL'
                ? colorScheme.main.success._100
                : 'rgba(0,0,0,0.1)',
          }}
        >
          <img
            src={handGearIcon}
            width={large ? 24 : small ? 12 : 16}
            height={large ? 24 : small ? 12 : 16}
            alt="machine"
            style={{ verticalAlign: 'text-top' }}
          />
        </div>
      </div>
      {/*<div style={iconStyle()}>
                                        <img src={icon()} width="16" height="16" alt="machine" />
                            </div>*/}
    </TooltipWrapper>
  );
};

export default MachineState;
