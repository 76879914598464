import React, { useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import {
  Alert,
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
  UncontrolledCollapse,
} from 'reactstrap';
import { useToggler } from '../../../../../lib/helpers/customHooks';
import { useStore } from '../../../../../store';
import { orderParts } from './OrderModal.helpers';
const OrderModal = ({ isOpen, toggle, data }) => {
  const [isWarningOpen, toggleWarning] = useToggler(false);
  const [comment, setComment] = useState('');
  const { state } = useStore();
  const handleOrderParts = () => {
    toggleWarning();
    // orderParts({
    //   data,
    //   comment,
    //   project_id: state.selectedProject.project_id,
    // });
    // toggle();
  };
  return (
    <>
      <Modal isOpen={isOpen} size="md">
        <ModalHeader toggle={toggle}>Order Parts</ModalHeader>
        <ModalBody>
          <FormGroup tag="fieldset">
            <Label for="comment">Add comment</Label>
            <Input
              type="textarea"
              name="text"
              id="comment"
              onChange={(e) => setComment(e.target.value)}
            />
          </FormGroup>
          <span id="toggler" style={{ cursor: 'pointer' }}>
            View parts list <FiChevronDown />
          </span>
          <UncontrolledCollapse toggler="#toggler">
            <Table borderless size="sm">
              <thead>
                <tr>
                  <th>Ident</th>
                  <th>Part</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {data?.parts?.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td className="text-right">{item.qty}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </UncontrolledCollapse>
          <div className="text-right">
            <Button size="sm" color="light" onClick={toggle} className="ml-3">
              Cancel
            </Button>
            <Button
              size="sm"
              color="primary"
              onClick={handleOrderParts}
              className="ml-3"
            >
              Order Parts
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={isWarningOpen} size="md" toggle={toggleWarning}>
        <ModalBody>
          {' '}
          <Alert color="warning">
            <h5 className="alert-heading">🚧 Warning!</h5>
            This feature is not implemented yet. Do not send any orders at the
            time.
          </Alert>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrderModal;
