export const getMaintenanceSequence = async () => {
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'sequence',
      funcName: 'project.machine_maintanance_id_seq',
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => data.nextval);
};

export const getMaintenance = (data) => {
  const { action, selected, ...tmp } = data;
  const params = {
    iproject_id: tmp.project_id,
    imodel_nr: tmp.model_nr,
    iserial_nr: tmp.serial_nr,
    iassembly_id: tmp.assembly_id,
  };
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'select',
      funcName: 'project.machine_maintanance_s',
      inputParameters: params,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((response) => {
      const maintenances = response || [];
      return {
        maintenances,
        loading: false,
      };
    })
    .catch((err) => ({
      error: err,
    }));
};

export const insertMaintenance = async (data) => {
  const { action, selected, ...params } = data;
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'insert',
      funcName: 'project.machine_maintanance_i',
      inputParameters: params,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => ({
      error: err,
    }));
};

export const updateMaintenance = async (data) => {
  const { action, selected, ...params } = data;
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'update',
      funcName: 'project.machine_maintanance_u',
      inputParameters: params,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => ({
      error: err,
    }));
};

export const deleteMaintenance = async (data) => {
  const { action, selected, ...params } = data;
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'delete',
      funcName: 'project.machine_maintanance_d',
      inputParameters: params,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => ({
      error: err,
    }));
};
