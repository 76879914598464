import React from 'react';
import styled, { css } from 'styled-components';
import { FaExclamationCircle, FaStopCircle } from 'react-icons/fa';
import { IoIosBuild, IoMdHome } from 'react-icons/io';
import { useStore } from '../../../../store';
import { Card, CardBody } from 'reactstrap';
import handGearIcon from '../../../../media/hand-gear-white.svg';
import MachineState from './partials/MachineState';
const GraphicMarker = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  text-align: center;
  cursor: pointer;
  background-color: ${({ theme, color }) =>
    theme.colors.main[color]._100 + '80'};
  border-radius: 50px;
  padding: 2px;
  z-index: 10;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  display: flex;
  align-content: center;
  justify-content: center;

  .middle-dot {
    margin-top: 4px;
    background-color: ${({ theme, color }) => theme.colors.main[color]._100};
    height: 4px;
    width: 4px;
    border-radius: 50px;
  }
  &:hover .middle-dot {
    margin-top: 6px;
  }
  .line {
    content: '';
    height: 141px;
    width: 1px;
    position: absolute;
    top: -132px;
    right: 7px;
    background-color: ${({ theme, color }) => theme.colors.main[color]._40};
  }
  &:hover .line {
    right: 9px;
  }
  .machine-tooltip {
    opacity: 0;
    /* transform: translate(100px, -200px);    */
    position: absolute;
    top: -155px;
    left: 25px;
    font-size: 0.75em;

    pointer-events: none;
  }
  &:hover .machine-tooltip {
    opacity: 1;
    transition: opacity 0.15s ease-in 0.3s;
  }

  &:hover {
    background-color: ${({ theme, color }) =>
      theme.colors.main[color]._100 + 'CC'};
    width: 20px;
    height: 20px;
    top: ${({ top }) => top - 2}px;
    left: ${({ left }) => left - 2}px;
    z-index: 11;
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme, color }) =>
        theme.colors.main[color]._100 + 'CC'};
      width: 20px;
      height: 20px;
      top: ${({ top }) => top - 2}px;
      left: ${({ left }) => left - 2}px;
      .middle-dot {
        margin-top: 6px;
      }
      .line {
        right: 9px;
      }
    `}

  ${({ selectedForIcons }) =>
    selectedForIcons &&
    css`
      .line {
        background-color: ${({ theme, color }) =>
          theme.colors.main[color]._100};
      }
    `}
`;
const GraphicIcons = styled.div`
  color: ${({ theme, color }) => {
    return theme.colors.main[color]._40;
  }};
  font-size: 1.3rem;
  display: flex;
  position: absolute;
  top: ${({ position }) => -153 - position * 20}px;

  ${({ selected }) =>
    selected &&
    css`
      color: ${({ theme, color }) => {
        return theme.colors.main[color]._100;
      }};
    `}
`;
const StyledManualIcon = styled.div`
  text-align: center;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.main.success._100};
`;

const TooltipIcon = styled.div`
  color: ${({ theme, color }) => {
    return theme.colors.main[color]._100;
  }};
`;
const HomeButton = styled.div`
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  border-radius: 50%;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
  justify-content: center;
  align-items: center;
  padding: 1.5em;
  position: absolute;
  cursor: pointer;
  z-index: 10;
  display: flex;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grayscale._20};
  }
`;
const HomeIcon = styled.div`
  font-size: 1.5rem;
  display: flex;
  position: absolute;
`;
const Home = ({ top, left, handleClick }) => {
  return (
    <HomeButton top={top} left={left} onClick={() => handleClick(null)}>
      <HomeIcon>
        <IoMdHome />
      </HomeIcon>
    </HomeButton>
  );
};
const Dot = ({
  status,
  top,
  left,
  machine,
  handleClick,
  selectedMachine,
  activeAlarms,
}) => {
  const { machine_id } = machine;
  const findAlarmsForMchine = activeAlarms.filter(
    (item) => item.serial_nr.toString() === machine?.machine_id.toString()
  );
  const filteredActiveAlarms = findAlarmsForMchine.length
    ? findAlarmsForMchine
    : null;

  const isAlarm = filteredActiveAlarms
    ? !!filteredActiveAlarms.find(
        (item) => item.alarm_type.toLowerCase() === 'alarm'
      ) || status?.has_error
    : status?.has_error;
  const isWarning =
    filteredActiveAlarms &&
    !!filteredActiveAlarms.find(
      (item) => item.alarm_type.toLowerCase() === 'warning'
    );
  const isStop = status?.machine_state === 'STOP';
  const isManual = status?.machine_state === 'MANUAL';
  const isRunning = status?.machine_state === 'AUTO';
  const selected =
    selectedMachine?.machine_id.toString() === machine_id.toString();
  const selectedForIcons =
    selectedMachine?.machine_id.toString() === machine_id.toString() ||
    !selectedMachine;
  const color = isAlarm
    ? 'danger'
    : isStop || isWarning
    ? 'warning'
    : isRunning
    ? 'success'
    : isManual
    ? 'success'
    : 'info';
  return (
    <>
      <GraphicMarker
        color={color}
        top={top}
        left={left}
        isAlarm
        isWarning
        selected={selected}
        selectedForIcons={selectedForIcons}
        onClick={() => handleClick(machine_id)}
      >
        <div className="middle-dot"></div>
        {(isAlarm || isWarning || isStop || isManual) && (
          <div className="machine-tooltip">
            <Card>
              <CardBody>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'max-content 1fr',
                    justifyItems: 'end',
                    columnGap: '1em',
                    textAlign: 'left',
                  }}
                >
                  <strong>{machine?.description}</strong>
                  <MachineState
                    small
                    data={status}
                    styles={{ justifySelf: 'end' }}
                  />
                </div>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'max-content',
                    textAlign: 'left',
                  }}
                >
                  {filteredActiveAlarms &&
                    filteredActiveAlarms
                      .filter((item) => item.alarm_type === 'Alarm')
                      .map((alarm) => (
                        <div
                          key={alarm.alarm_text}
                          style={{
                            display: 'flex',
                            gap: '0.25em',
                            alignItems: 'center',
                          }}
                        >
                          <TooltipIcon color="danger">
                            <FaExclamationCircle />
                          </TooltipIcon>
                          <span>{alarm.alarm_text}</span>
                        </div>
                      ))}
                  {filteredActiveAlarms &&
                    filteredActiveAlarms
                      .filter((item) => item.alarm_type === 'Warning')
                      .map((warning) => (
                        <div
                          key={warning.alarm_text}
                          style={{
                            display: 'flex',
                            gap: '0.25em',
                            alignItems: 'center',
                          }}
                        >
                          <TooltipIcon color="warning">
                            <FaExclamationCircle />
                          </TooltipIcon>
                          <span>{warning.alarm_text}</span>
                        </div>
                      ))}
                </div>
              </CardBody>
            </Card>
          </div>
        )}
        {isManual && (
          <>
            <div className="line"></div>
            <GraphicIcons
              color={'warning'}
              selected={selectedForIcons}
              position={0}
            >
              <StyledManualIcon>
                <img
                  src={handGearIcon}
                  width={16}
                  height={16}
                  alt="machine"
                  style={{ verticalAlign: 'text-top' }}
                />
              </StyledManualIcon>
              {/* <IoIosBuild /> */}
            </GraphicIcons>
          </>
        )}
        {isStop && (
          <>
            <div className="line"></div>
            <GraphicIcons
              color={'warning'}
              selected={selectedForIcons}
              position={0}
            >
              <FaStopCircle />
              {/* <IoIosBuild /> */}
            </GraphicIcons>
          </>
        )}
        {(isAlarm || isWarning) && (
          <>
            <div className="line"></div>

            <GraphicIcons
              color={color}
              selected={selectedForIcons}
              position={isStop ? 1 : 0}
            >
              <FaExclamationCircle />
              {/* <IoIosBuild /> */}
            </GraphicIcons>
          </>
        )}
      </GraphicMarker>
    </>
  );
};

export default Dot;
export { Home };
