import React, { useEffect, useState } from 'react';
import {
  useTable,
  usePagination,
  useResizeColumns,
  useSortBy,
  useExpanded,
} from 'react-table';
import {
  FiChevronLeft,
  FiChevronsLeft,
  FiChevronRight,
  FiChevronsRight,
  FiChevronUp,
  FiChevronDown,
  FiMove,
  FiCheck,
  FiX,
} from 'react-icons/fi';
import {
  Table,
  ButtonGroup,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import classnames from 'classnames';
import NoContent from '../../components/NoContent/NoContent';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import TooltipWrapper from '../../components/TooltipWrapper';
import { getColorScheme } from '../../lib/helpers';

const TableReact = ({
  columns,
  data,
  rowIndex,
  handleRowSelect,
  handleCreate,
  hover,
  movable,
  subComponent,
  handleReorder,
  showAllRows,
}) => {
  const [records, setRecords] = useState(data);
  const [movableRows, setMovableRows] = useState(false);
  const colorScheme = getColorScheme();

  useEffect(() => {
    setRecords(data);
  }, [data]);

  const toggleMovableRows = () => setMovableRows(!movableRows);

  const handleReorderRows = () => {
    handleReorder(records);
    toggleMovableRows();
  };

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    rows,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data: movable ? records : data,
      columns,
      //getRowId,
      initialState: {
        selectedRowIds: { 0: true },
        pageSize: showAllRows ? data.length : 10,
      },
    },
    useSortBy,
    useExpanded,
    usePagination,
    useResizeColumns
  );
  const tableData = showAllRows ? rows : page;
  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex];
    setRecords(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    );
  };

  const headerCellsStyle = {
    cursor: 'pointer',
    border: '1px solid rgba(0,0,0,0.02)',
    // textAlign: 'center',
    padding: '.75em',
  };
  const cellsStyle = {
    border: '1px solid rgba(0,0,0,0.02)',
    padding: '.5em',
  };
  // Render the UI for your table

  /*<p className="w-100 text-center">
        <span>Table is empty. </span>
        <Button size="lg" color="light" className="ml-2 shadow-sm" onClick={() => handleCreate("create", {})}>
          <span className="text-green"><FiPlusCircle /></span> Create new
      </Button>
  </p>*/
  return !page.length ? (
    <React.Fragment>
      <NoContent style={{ height: '250px' }} title="Table is empty">
        {!!handleCreate && (
          <p>
            Click{' '}
            <span
              onClick={() => handleCreate('create', {})}
              style={{
                cursor: 'pointer',
                fontWeight: 500,
                color: colorScheme.main.primary._50,
              }}
            >
              here
            </span>{' '}
            to create new record
          </p>
        )}
      </NoContent>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <DndProvider backend={HTML5Backend}>
        <Table responsive bordered className="text-nowrap" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="shadow-sm">
                {movable && !movableRows && (
                  <th
                    style={{
                      ...headerCellsStyle,
                      color: colorScheme.main.primary._100,
                      textDecoration: 'underline',
                    }}
                    onClick={toggleMovableRows}
                  >
                    Reorder
                  </th>
                )}
                {movable && movableRows && (
                  <th style={{ ...headerCellsStyle }}>
                    <TooltipWrapper content="Save">
                      <FiCheck
                        onClick={handleReorderRows}
                        style={{
                          color: colorScheme.main.success._100,
                          marginRight: '.5em',
                        }}
                      />
                    </TooltipWrapper>
                    <TooltipWrapper content="Cancel">
                      <FiX
                        onClick={toggleMovableRows}
                        style={{ color: colorScheme.main.danger._100 }}
                      />
                    </TooltipWrapper>
                  </th>
                )}
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ ...column.style, ...headerCellsStyle }}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FiChevronDown />
                        ) : (
                          <FiChevronUp />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className={classnames('shadow-sm', hover ? 'hover-primary' : '')}
          >
            {tableData.map((row, i) => {
              prepareRow(row);

              if (movableRows) {
                return (
                  <MovableRow
                    index={i}
                    row={row}
                    moveRow={moveRow}
                    {...row.getRowProps()}
                  />
                );
              }

              return (
                <React.Fragment key={i}>
                  <tr
                    {...row.getRowProps()}
                    onClick={() => handleRowSelect && handleRowSelect(row)}
                    className={row.index === rowIndex ? 'selected-primary' : ''}
                  >
                    {movable && !movableRows && (
                      <td style={{ ...cellsStyle }}></td>
                    )}
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        style={{ ...cellsStyle, ...cell.column.style }}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                  {row.isExpanded ? (
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        {subComponent({ row })}
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      </DndProvider>
      <div className="text-right">
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <ButtonGroup size="sm" className="px-3 pb-3">
          <Button
            color="light"
            className={classnames(canPreviousPage && 'shadow-sm', 'ml-2')}
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <FiChevronsLeft />
          </Button>
          <Button
            color="light"
            className={classnames(canPreviousPage && 'shadow-sm', 'ml-2')}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <FiChevronLeft />
          </Button>
          <Button
            color="light"
            className={classnames(canNextPage && 'shadow-sm', 'ml-2')}
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <FiChevronRight />
          </Button>
          <Button
            color="light"
            className={classnames(canNextPage && 'shadow-sm', 'ml-2')}
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <FiChevronsRight />
          </Button>
          <UncontrolledButtonDropdown>
            <DropdownToggle caret color="light" className="shadow-sm ml-2">
              Show {pageSize === 9999 ? 'All' : pageSize}
              {/* Uncomment code bellow when show all rows code is fixed */}
              {/* Show {pageSize}   */}
            </DropdownToggle>
            <DropdownMenu>
              {['All', 10, 20, 30, 40, 50].map((pageSize, index) =>
                index === 0 ? (
                  // TODO: temporary set pageSize to 9999 to show all rows in table. If set as commented code below, table will put newly created rows in new page
                  // <React.Fragment key={pageSize}>
                  //   <DropdownItem
                  //     value={data.length}
                  //     onClick={() => setPageSize(Number(data.length))}
                  //   >
                  <React.Fragment key={pageSize}>
                    <DropdownItem
                      value={9999}
                      onClick={() => setPageSize(9999)}
                    >
                      Show All
                    </DropdownItem>
                    <hr />
                  </React.Fragment>
                ) : (
                  <DropdownItem
                    key={pageSize}
                    value={pageSize}
                    onClick={() => setPageSize(Number(pageSize))}
                  >
                    Show {pageSize}
                  </DropdownItem>
                )
              )}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </ButtonGroup>
      </div>
    </React.Fragment>
  );
};

const DND_ITEM_TYPE = 'row';

const MovableRow = ({ row, index, moveRow }) => {
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_ITEM_TYPE, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <tr ref={dropRef} style={{ opacity }}>
      <td ref={dragRef} className="text-center">
        <FiMove />
      </td>
      {row.cells.map((cell) => {
        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
      })}
    </tr>
  );
};

export default TableReact;
