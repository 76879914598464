import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import LoadingPage from '../pages/LoadingPage';
import { useFirestore, useFirestoreDocDataOnce } from 'reactfire';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  const projectRef = useFirestore().collection('project').doc('0');
  const { data: currentProject } = useFirestoreDocDataOnce(projectRef);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
      setPending(false);
    });
  }, []);

  if (pending) {
    return <LoadingPage />;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        currentProject,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
