import React from 'react';
import moment from 'moment';
import { Table } from 'reactstrap';
//import { generateDurationString } from '../../../Diagnostics/helpers';

const HeadDaily = ({ data, lots }) => {
  const valueStyle = { paddingRight: '2em', fontWeight: 'bold' };
  const keyStyle = { paddingRight: '1em' };
  return (
    <React.Fragment>
      <h4>Report</h4>
      <span style={keyStyle}>Start</span>
      <span style={valueStyle}>
        {moment(Number(data.rec_batch_start)).format('DD.MM.YYYY, HH:mm:ss')}
      </span>
      <span style={keyStyle}>End</span>
      <span style={valueStyle}>
        {moment(Number(data.rec_batch_end)).format('DD.MM.YYYY, HH:mm:ss')}
      </span>
      <div className="mb-1 border-top font-weight-bold">Lots</div>
      <Table size="sm" borderless>
        <thead>
          <tr>
            <th className="p-0">Batch ID</th>
            <th className="p-0">Recipe Name</th>
            <th className="p-0">Start</th>
            <th className="p-0">End</th>
            <th className="p-0">OEE</th>
            <th className="p-0">AVA</th>
            <th className="p-0">PER</th>
            <th className="p-0">QUA</th>
          </tr>
        </thead>
        <tbody>
          {lots.map(({ lot, oee, recipe }) => (
            <tr key={lot.nr}>
              <td className="p-0">{lot.nr}</td>
              <td className="p-0">{recipe.name}</td>
              <td className="p-0">
                {moment(Number(lot.start.ts)).format('DD.MM.YYYY, HH:mm:ss')}
              </td>
              <td className="p-0">
                {moment(Number(lot.end.ts)).format('DD.MM.YYYY, HH:mm:ss')}
              </td>
              <td className="p-0">{oee.divider.oee}</td>
              <td className="p-0">{oee.divider.availability}</td>
              <td className="p-0">{oee.divider.performance}</td>
              <td className="p-0">{oee.divider.quality}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
};

export default HeadDaily;
