export const getProductionLineSequence = async () => {
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'sequence',
      funcName: 'project.project_id_seq',
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => data.nextval);
};

export const getProductionLines = async () => {
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'select',
      funcName: 'project.project_s',
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((response) => {
      const projects = response || [];
      return {
        projects,
        loading: false,
      };
    });
};

export const insertProductionLine = async (data) => {
  const {
    mas_project_id,
    project_id,
    project_name,
    startup_date,
    grafana_id,
  } = data;
  const params = {
    mas_project_id,
    project_id,
    project_name,
    startup_date,
    grafana_id,
  };
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'insert',
      funcName: 'project.project_i',
      inputParameters: params,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => ({
      error: err,
    }));
};

export const updateProductionLine = async (data) => {
  const { actions, ...params } = data;
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'update',
      funcName: 'project.project_u',
      inputParameters: params,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => ({
      error: err,
    }));
};

export const deleteProductionLine = async (mas_project_id) => {
  const params = {
    mas_project_id,
  };
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'delete',
      funcName: 'project.project_d',
      inputParameters: params,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => ({
      error: err,
    }));
};
