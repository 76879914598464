import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import { useTable } from 'react-table';
import {
  Modal,
  ModalBody,
  Input,
  ModalFooter,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import styled from 'styled-components';
import firebase from 'firebase';
import 'firebase/firestore';
import Pagination, { sliceDataForPage } from './Pagination';
import { StyledButton } from '../../../../../components/DateSelection/DateSelection';
import NoContent from '../../../../../components/NoContent';
import { getUserDetails, snakeCase } from '../../../../../lib/helpers';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import FileUpload from '../../../../../components/FileUpload';
import { StyledAttachmentPreview } from '../../../../../components/FileUpload/file-upload-styles';
import { FiFile, FiImage, FiVideo } from 'react-icons/fi';
import { useStore } from '../../../../../store';
import NewIssueModal from './NewIssueModal';
import { useToggler } from '../../../../../lib/helpers/customHooks';
import docCookies from '../../../../../lib/docCookies';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Issues = ({ data }) => {
  const [dataOnPage, setDataOnPage] = useState(sliceDataForPage(data, 0, 13));
  useEffect(() => setDataOnPage(sliceDataForPage(data, 0, 13)), [data]);
  const columns = useMemo(
    () => [
      {
        Header: 'priority',
        accessor: 'priority',
        className: 'priority',
        Cell: ({ row }) => (row.values.priority ? <FaExclamationCircle /> : ''),
      },
      {
        Header: 'Name',
        accessor: 'user.name',
      },
      {
        Header: 'Machine',
        accessor: 'machine.description',
      },
      {
        Header: 'Status',
        accessor: 'status',
        style: { textAlign: 'center' },
        Cell: ({ row }) => (
          <StyledBadge status={row.values.status}>
            {row.values.status}
          </StyledBadge>
        ),
      },
      {
        Header: 'subject',
        accessor: 'subject',
        className: 'subject',
      },
      {
        Header: 'Type',
        accessor: 'type',
        style: { textAlign: 'center' },
        Cell: ({ row }) => (
          <StyledBadge type={row.values.type}>{row.values.type}</StyledBadge>
        ),
      },
      {
        Header: 'createdAt',
        accessor: 'createdAt',
        Cell: ({ row }) =>
          DateTime.fromMillis(row.values.createdAt).toFormat('dd.LL.yyyy'),
      },
    ],
    []
  );

  return (
    <>
      <Pagination data={data} handleChangePage={setDataOnPage} pageSize={13} />
      {!data?.length ? (
        <NoContent title="No issues" />
      ) : (
        <IssuesTable columns={columns} data={dataOnPage} />
      )}
    </>
  );
};

const getColor = ({ theme, status, type, opacity }) =>
  status
    ? {
        pending: theme.colors.other[0][`_${opacity}`],
        solving: theme.colors.other[1][`_${opacity}`],
        received: theme.colors.other[2][`_${opacity}`],
        closed: theme.colors.main.danger[`_${opacity}`],
        extra: theme.colors.other[4][`_${opacity}`],
      }[status.toLowerCase()]
    : type
    ? {
        mechanical: theme.colors.other[2][`_${opacity}`],
        electrical: theme.colors.other[4][`_${opacity}`],
        software: theme.colors.other[0][`_${opacity}`],
      }[type.toLowerCase()]
    : 'none';

const StyledBadge = styled.span`
  padding: 0.2em 0.5em;
  font-weight: 500;
  border-radius: 3px;
  color: white;

  font-size: 0.8em;
  text-align: center;
  color: ${({ theme, status, type }) =>
    getColor({ theme, status, type, opacity: 100 })};
  background-color: ${({ theme, status, type }) =>
    getColor({ theme, status, type, opacity: 10 })};
`;
const StyledDropdownItem = styled(DropdownItem)`
  color: ${({ theme, status, type }) =>
    getColor({ theme, status, type, opacity: 100 })};

  &:hover {
    color: ${({ theme, status, type }) =>
      getColor({ theme, status, type, opacity: 100 })};
    background-color: ${({ theme, status, type }) =>
      getColor({ theme, status, type, opacity: 10 })} !important;
  }
`;
const StyledDropdownBadge = styled(UncontrolledButtonDropdown)`
  .dropdown-toggle {
    color: ${({ theme, status, type }) =>
      getColor({ theme, status, type, opacity: 100 })};
    background-color: ${({ theme, status, type }) =>
      getColor({ theme, status, type, opacity: 10 })};
    border: none;

    &:hover,
    &:active,
    &:focus {
      color: ${({ theme, status, type }) =>
        getColor({ theme, status, type, opacity: 100 })} !important;
      background-color: ${({ theme, status, type }) =>
        getColor({ theme, status, type, opacity: 10 })} !important;
    }
  }
`;

const StyledTableContainer = styled.div`
  display: block;
  overflow-x: auto;
  width: 100%;
  margin-bottom: 1rem;
  table {
    width: 100%;
  }
  tr {
    &:hover {
      background-color: ${({ theme }) => theme.colors.grayscale._10};
      cursor: pointer;
    }
  }
  td {
    /* border: 1px solid rgba(0, 0, 0, 0.02); */
    padding: 0.5em;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .priority {
    text-align: center;
    color: ${({ theme }) => theme.colors.main.danger._100};
  }
`;

const IssuesTable = ({ data, columns }) => {
  const [selectedRow, setSelectedRow] = useState([]);
  const [isOpen, toggle] = useToggler(false);
  const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const handleRowSelect = (row) => {
    setSelectedRow(row);
    toggle();
  };

  return !data ? (
    <NoContent />
  ) : (
    <StyledTableContainer {...getTableProps()}>
      <table>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => handleRowSelect(row.original)}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      style={{ ...cell.column.style }}
                      className={cell.column.className}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {
          <IssueDetailsModal
            data={selectedRow}
            isOpen={isOpen}
            toggle={toggle}
          />
        }
      </table>
    </StyledTableContainer>
  );
};

const StyledIssueHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 1em;
  .priority {
    color: ${({ theme }) => theme.colors.main.danger._100};
    font-size: 1.5rem;
    margin-right: 0.5rem;
    vertical-align: text-bottom;
  }
  .subject {
    font-size: 2em;
  }
  .opened-on {
    margin: 0;
    color: ${({ theme }) => theme.colors.grayscale._40};
  }
`;

const StyledIssueBody = styled.div`
  display: flex;
  flex-direction: column;
  .head {
    display: flex;
    justify-content: space-between;
    padding: 1em 0;
  }
  .attachments {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0.25em;
  }
  .messages {
    /* border-bottom: solid 1px ${({ theme }) => theme.colors.grayscale._30}; */
    margin-bottom: 3em;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  .message {
    border: solid 1px ${({ theme }) => theme.colors.grayscale._10};
    border-radius: 5px;
    .head {
      background-color: ${({ theme }) => theme.colors.grayscale._10};
      padding: 0.5em;
    }
    p {
      padding: 0.5em;
    }
  }

  .status-change-info {
    font-size: 0.75em;
    color: ${({ theme }) => theme.colors.grayscale._40};
    padding: 0 1em;
  }

  textarea {
    width: 100%;
    height: 150px;
  }
`;
export const StyledReactQuill = styled(ReactQuill)`
  .ql-editor {
    height: 200px;
  }
`;
const IssueDetailsModal = ({ data, isOpen, toggle }) => {
  const [message, setNewMessage] = useState('');
  const [status, setStatus] = useState(data?.status);
  const [attachments, setAttachments] = useState([]);
  const [editModalIsOpen, toggleEditModal] = useToggler(false);
  const [canEdit, setCanEdit] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    setStatus(data.status);
    findUserIfCanEditOrIsAdmin(data);
  }, [data]);

  const findUserIfCanEditOrIsAdmin = async (data) => {
    const userDetails = await getUserDetails();
    const canEdit = data?.user?.id === userDetails.id;
    const isAdmin = userDetails.user_role === 'site-admin';

    setCanEdit(canEdit);
    setIsAdmin(isAdmin);
  };
  const issueMessagesRef = useFirestore()
    .collection('machines')
    .doc(data?.machine?.machine_id?.toString() || '12086')
    .collection('issues')
    .doc(data?.createdAt?.toString() || '1')
    .collection('messages');
  // .where('status', '!=', 'closed');
  const { data: messagesData } = useFirestoreCollectionData(issueMessagesRef);

  const handleSendMessage = async () => {
    const { company_name, name, user_role, id } = await getUserDetails();
    const user = { company_name, name, user_role, id };
    const type = 'comment';
    const createdAt = +new Date();
    const dataTmp = {
      value: message,
      type,
      user,
      createdAt,
      issueData: data,
      attachments,
    };
    sendAttachmentsToStorage({ data: dataTmp, docId: '12' });
  };
  const sendAttachmentsToStorage = ({ data, docId }) => {
    const { attachments: files } = data;
    const promises = files.map((file) => {
      let fileRef = firebase
        .storage()
        .ref()
        .child(`issue_att/${snakeCase(file.name)}`);
      return fileRef
        .put(file.File)
        .then(() => fileRef.getDownloadURL())
        .then((url) => {
          const { File, ...rest } = file;
          return { ...rest, fileURL: url };
        });
    });
    Promise.all(promises)
      .then((attachments) => {
        const dataWithAttachmentURLs = { ...data, attachments };
        sendMessageToFirestore({
          data: dataWithAttachmentURLs,
          docId,
        });
        setNewMessage('');
        setAttachments([]);
        toggle();
      })
      .catch((err) => alert(err.code));
  };
  const sendMessageToFirestore = async (allData) => {
    const { data, ...rest } = allData;
    const { issueData, ...message } = data;
    const fsdb = firebase.firestore();
    await fsdb
      .collection('machines')
      .doc(issueData.machine.machine_id.toString())
      .collection('issues')
      .doc(issueData.createdAt.toString())
      .collection('messages')
      .doc(data.createdAt.toString())
      .set(message, { merge: true });
  };
  const putAttachmentsIntoMessage = (files) => {
    const filesInArray = [...files];
    const attachments = filesInArray.map((item) => {
      const { type, name, size } = item;
      return {
        File: item,
        type,
        name,
        size,
      };
    });
    setAttachments(attachments);
  };
  const handleStatusChange = async ({ status, data }) => {
    const currentTS = +new Date();
    const { company_name, name, user_role, id } = await getUserDetails();
    const user = { company_name, name, user_role, id };
    const fsdb = firebase.firestore();

    await fsdb
      .collection('machines')
      .doc(data.machine.machine_id.toString())
      .collection('issues')
      .doc(data.createdAt.toString())
      .set({ status }, { merge: true });

    const message = {
      createdAt: currentTS,
      type: 'statusChange',
      value: status,
      user,
    };

    await fsdb
      .collection('machines')
      .doc(data.machine.machine_id.toString())
      .collection('issues')
      .doc(data.createdAt.toString())
      .collection('messages')
      .doc(currentTS.toString())
      .set(message, { merge: true });

    setStatus(status);
  };
  return (
    <Modal
      autoFocus={false}
      fade={false}
      isOpen={isOpen}
      toggle={toggle}
      contentClassName="shadow-lg"
      size={'lg'}
    >
      <ModalBody>
        <StyledIssueHeader>
          <div>
            {data?.priority && (
              <span className="priority">
                <FaExclamationCircle />
              </span>
            )}
            <span className="subject">{data?.subject}</span>
            <p className="opened-on">
              <strong>{data?.user?.name}</strong> opened this issue on{' '}
              {DateTime.fromMillis(data?.createdAt || 0).toFormat(
                'dd.LL.yyyy, HH:mm:ss'
              )}
            </p>
            <p>
              <StyledBadge type={data?.type}>{data?.type}</StyledBadge>{' '}
              <strong>{data?.machine?.description}</strong>
            </p>
          </div>
          <div style={{ display: 'flex', gap: '1em' }}>
            {canEdit && (
              <>
                <StyledButton onClick={toggleEditModal}>Edit</StyledButton>
                <NewIssueModal
                  isOpen={editModalIsOpen}
                  toggle={toggleEditModal}
                  data={data}
                  isEdit
                />
              </>
            )}
            {isAdmin ? (
              <StyledDropdownBadge status={status}>
                <DropdownToggle caret>{status}</DropdownToggle>
                <DropdownMenu>
                  <StyledDropdownItem
                    status="Pending"
                    onClick={() =>
                      handleStatusChange({ status: 'Pending', data })
                    }
                  >
                    Pending
                  </StyledDropdownItem>
                  <StyledDropdownItem
                    status="Received"
                    onClick={() =>
                      handleStatusChange({ status: 'Received', data })
                    }
                  >
                    Received
                  </StyledDropdownItem>
                  <StyledDropdownItem
                    status="Solving"
                    onClick={() =>
                      handleStatusChange({ status: 'Solving', data })
                    }
                  >
                    Solving
                  </StyledDropdownItem>
                  <StyledDropdownItem
                    status="Closed"
                    onClick={() =>
                      handleStatusChange({ status: 'Closed', data })
                    }
                  >
                    Closed
                  </StyledDropdownItem>
                </DropdownMenu>
              </StyledDropdownBadge>
            ) : (
              <StyledBadge status={data?.status}>{data?.status}</StyledBadge>
            )}
          </div>
        </StyledIssueHeader>
        <StyledIssueBody>
          <p>
            {/* {data?.message} */}
            <div
              dangerouslySetInnerHTML={{
                __html: data?.message,
              }}
            />
            <div className="attachments">
              {data?.attachments?.map((item, index) => (
                <a
                  href={item.fileURL}
                  key={index}
                  target="_blank"
                  rel="noreferrer"
                >
                  <StyledAttachmentPreview key={index}>
                    <div className="icon">
                      {item.type.indexOf('image') > -1 ? (
                        <FiImage />
                      ) : item.type.indexOf('video') > -1 ? (
                        <FiVideo />
                      ) : (
                        <FiFile />
                      )}
                    </div>
                    <div className="name">{item.name}</div>
                    <div className="size">
                      (
                      {Intl.NumberFormat(window.navigator.language).format(
                        Math.ceil(Number(item.size) / 1024)
                      )}
                      K)
                    </div>
                  </StyledAttachmentPreview>
                </a>
              ))}
            </div>
          </p>
          <div className="messages">
            {messagesData?.map((item, index) =>
              item.type === 'statusChange' ? (
                <StatusChange data={item} key={index} />
              ) : (
                <Message data={item} key={index} />
              )
            )}
            {/* <Spinner color="dark" size="s" /> */}
          </div>
          {/* <Input
            type="textarea"
            value={message.value}
            placeholder="Click here to reply"
            onChange={(e) => setNewMessage(e.target.value)}
          /> */}
          <StyledReactQuill
            theme="snow"
            value={message}
            onChange={setNewMessage}
          />
          <FileUpload
            accept="video/*, image/*, application/pdf"
            label="Attachments"
            multiple
            updateFilesCb={putAttachmentsIntoMessage}
          />
        </StyledIssueBody>
      </ModalBody>
      <ModalFooter>
        <StyledButton color="primary" onClick={handleSendMessage}>
          Send message
        </StyledButton>
      </ModalFooter>
    </Modal>
  );
};

const StatusChange = ({ data }) => (
  <div className="status-change">
    <StyledBadge status={data.value}>{data.value}</StyledBadge>
    <span className="status-change-info">
      status set by <strong>{data.user.name}</strong> on{' '}
      {DateTime.fromMillis(data?.createdAt || 0).toFormat(
        'dd.LL.yyyy, HH:mm:ss'
      )}
    </span>
  </div>
);

const Message = ({ data }) => (
  <div className="message">
    <div className="head">
      <div>{data?.user?.name}</div>
      <div>
        {DateTime.fromMillis(data?.createdAt || 0).toFormat(
          'dd.LL.yyyy, HH:mm:ss'
        )}
      </div>
    </div>
    <p>
      {/* {data.value} */}
      <div
        dangerouslySetInnerHTML={{
          __html: data?.value,
        }}
      />
      {data?.attachments && (
        <div className="attachments">
          {data?.attachments?.map((item, index) => (
            <a href={item.fileURL} key={index} target="_blank" rel="noreferrer">
              <StyledAttachmentPreview key={index}>
                <div className="icon">
                  {item.type.indexOf('image') > -1 ? (
                    <FiImage />
                  ) : item.type.indexOf('video') > -1 ? (
                    <FiVideo />
                  ) : (
                    <FiFile />
                  )}
                </div>
                <div className="name">{item.name}</div>
                <div className="size">
                  (
                  {Intl.NumberFormat(window.navigator.language).format(
                    Math.ceil(Number(item.size) / 1024)
                  )}
                  K)
                </div>
              </StyledAttachmentPreview>
            </a>
          ))}
        </div>
      )}
    </p>
  </div>
);

export default Issues;
