import React, { createContext, useContext, useReducer } from 'react';

const StoreContext = createContext();
const initialState = {
  isSidebarOpen: false,
  isIntervalSet: true,
  issueMachineSerialNr: '',
  isFromReportIssue: false,
  userProjects: localStorage.projects ? JSON.parse(localStorage.projects) : [],
  selectedProject: localStorage.productionLine
    ? JSON.parse(localStorage.productionLine)
    : {},
  projectMachines: localStorage.allMachines
    ? JSON.parse(localStorage.allMachines)
    : [],
  machinesState: [],
  oee: {},
  energy: {},
  diagnostics: {},
  user: {},
  selectedOrder: null,
  openOrders: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'SET_INTERVAL':
      return {
        ...state,
        isIntervalSet: action.payload,
      };
    case 'SET_SELECTED_ISSUE_MACHINE_SERIAL_NR':
      return {
        ...state,
        issueMachineSerialNr: action.payload,
      };
    case 'SET_IS_FROM_REPORT_ISSUE':
      return {
        ...state,
        isFromReportIssue: action.payload,
      };
    case 'IS_SIDEBAR_OPEN':
      return {
        ...state,
        isSidebarOpen: action.payload,
      };
    case 'USER_PROJECTS':
      return {
        ...state,
        userProjects: action.payload,
      };
    case 'SET_SELECTED_PROJECT':
      return {
        ...state,
        selectedProject: action.payload,
        oee: {},
        energy: {},
        diagnostics: {},
      };
    case 'SET_PROJECT_MACHINES':
      return {
        ...state,
        projectMachines: action.payload,
      };
    case 'SET_MACHINES_STATE':
      return {
        ...state,
        machinesState: action.payload,
      };
    case 'SET_DATA_OEE_MODULE':
      return {
        ...state,
        oee: { ...state.oee, ...action.payload },
      };
    case 'SET_DATA_ENERGY_MODULE':
      return {
        ...state,
        energy: action.payload,
      };
    case 'SET_DATA_DIAGNOSTICS_MODULE': // Alarms statistics
      return {
        ...state,
        diagnostics: action.payload,
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'SELECTED_ORDER':
      return {
        ...state,
        selectedOrder: action.payload,
      };
    case 'OPEN_ORDERS':
      return {
        ...state,
        openOrders: action.payload,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);
