import React, { useState, useEffect } from 'react';
import ProductionLines from './ProductionLines';
import Users from './Users';
import Alarms from './Alarms';
import { Container, Row, Col } from 'reactstrap';
import { getColorScheme, getUserDetails } from '../../lib/helpers';
import AdminPage from './AdminPage';
import { mainAuth } from '../../utils/firebase';

const Settings = () => {
  const [user, setUser] = useState(mainAuth.currentUser);
  const [tabSelected, setTabSelected] = useState('alarms');
  const colorScheme = getColorScheme();
  useEffect(() => {
    const fetchData = async () => {
      const userDetails = await getUserDetails();
      setTabSelected(
        userDetails.user_role === 'site-admin' ? 'projects' : 'alarms'
      );
      setUser(userDetails);
    };
    fetchData();
  }, []);

  const tabStyle = (name) =>
    name === tabSelected
      ? {
          borderBottom: `solid 3px ${colorScheme.main.primary._100}`,
          fontWeight: 500,
          cursor: 'pointer',
          padding: '.5rem 1rem',
        }
      : {
          cursor: 'pointer',
          padding: '.5rem 1rem',
        };

  return (
    <Container fluid>
      <div className="d-flex justify-content-between mt-3 align-items-end border-bottom">
        <div className="d-flex hover-primary">
          {user?.email === 'gostol@example.com' && (
            <div
              onClick={() => setTabSelected('projects')}
              style={tabStyle('projects')}
            >
              Projects
            </div>
          )}
          <div
            onClick={() => setTabSelected('alarms')}
            style={tabStyle('alarms')}
          >
            Alarms
          </div>
          {(user?.email === 'gostol@example.com' ||
            user?.user_role === 'admin') && (
            <div
              onClick={() => setTabSelected('users')}
              style={tabStyle('users')}
            >
              Users
            </div>
          )}
          {user?.email === 'gostol@example.com' && (
            <div
              onClick={() => setTabSelected('adminPage')}
              style={tabStyle('adminPage')}
            >
              Admin
            </div>
          )}
        </div>
      </div>
      <Row className="mt-4" width="100%">
        <Col>
          {tabSelected === 'projects' && <ProductionLines />}
          {tabSelected === 'alarms' && <Alarms />}
          {tabSelected === 'users' && <Users />}
          {tabSelected === 'adminPage' && <AdminPage />}
        </Col>
      </Row>
    </Container>
  );
};

export default Settings;
