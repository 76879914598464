import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 1em;
  justify-content: flex-end;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 3px;
`;
export const RoundButton = styled.div`
  color: ${({ theme }) => theme.colors.main.primary._100};
  padding: 0 6px;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    background: ${({ theme }) => theme.colors.grayscale._10};
  }
`;
