import React from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { generateDurationString } from '../../../helpers';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import styled, { css } from 'styled-components';
const StyledDropdownToggle = styled(DropdownToggle)`
  display: flex;
  align-items: center;
  background: ${({ theme, color }) =>
    color ? theme.colors.main[color]._100 : 'white'};
  color: ${({ theme, color }) => (color ? 'white' : 'inherit')};
  border-radius: 3px;
  border: none;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  padding: 0.5em 1em;
  input {
    border: none;
  }
  span {
    padding-left: 0.5rem;
  }

  ${({ small }) =>
    small &&
    css`
      padding: 0.5em 0.35em;
    `}

  ${({ smaller }) =>
    smaller &&
    css`
      padding: 0.35em 1em;
    `}

  &:hover {
    background: ${({ theme, color }) =>
      color ? theme.colors.main[color]._80 : theme.colors.grayscale._10};
  }
  &:active {
    background: ${({ theme, color }) =>
      color ? theme.colors.main[color]._80 : theme.colors.grayscale._10};
  }
`;
const ExportData = ({ data }) => {
  const generateXLSX = () => {
    const workbook = new ExcelJS.Workbook();
    const ROWS_OF_HEADER = 3;

    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.calcProperties.fullCalcOnLoad = true;

    const sheet = workbook.addWorksheet('Sheet1', {
      views: [{ state: 'frozen', xSplit: 4, ySplit: ROWS_OF_HEADER }],
    });

    sheet.columns = [
      { header: '', key: 'date', width: 15 },
      { header: '', key: 'time', width: 20 },
      { header: '', key: 'lotId', width: 15 },
      { header: '', key: 'lotName', width: 15 },
      { header: '', key: 'oee', width: 15 },
      { header: '', key: 'ava', width: 15 },
      { header: '', key: 'per', width: 15 },
      { header: '', key: 'qua', width: 15 },
      { header: '', key: 'totalTime', width: 15 },
      { header: '', key: 'totalTimeMinutes', width: 15 },
      { header: '', key: 'production', width: 15 },
      { header: '', key: 'productionMinutes', width: 15 },
      { header: '', key: 'operatorStop', width: 15 },
      { header: '', key: 'operatorStopMinutes', width: 15 },
      { header: '', key: 'alarmStop', width: 15 },
      { header: '', key: 'alarmStopMinutes', width: 15 },
      { header: 'piecesTotal', key: 'piecesTotal', width: 15 },
      { header: 'piecesProdTotal', key: 'piecesProdTotal', width: 15 },
      { header: 'piecesProdOK', key: 'piecesProdOK', width: 15 },
      { header: 'piecesProdNOK', key: 'piecesProdNOK', width: 15 },
      { header: 'piecesLow', key: 'piecesLow', width: 15 },
      { header: 'piecesHigh', key: 'piecesHigh', width: 15 },
      { header: 'piecesLastCounter', key: 'piecesLastCounter', width: 15 },
      { header: 'massTotal', key: 'massTotal', width: 15 },
      { header: 'massProdTotal', key: 'massProdTotal', width: 15 },
      { header: 'massProdOK', key: 'massProdOK', width: 15 },
      { header: 'massProdNOK', key: 'massProdNOK', width: 15 },
      { header: 'massLow', key: 'massLow', width: 15 },
      { header: 'massHigh', key: 'massHigh', width: 15 },
      { header: 'elMixers', key: 'elMixers', width: 15 },
      { header: 'elEntry', key: 'elEntry', width: 15 },
      { header: 'elMain', key: 'elMain', width: 15 },
      { header: 'elOven', key: 'elOven', width: 15 },
      { header: 'elRecuperated', key: 'elRecuperated', width: 15 },
      { header: 'steamMain', key: 'steamMain', width: 15 },
      { header: 'steamOven', key: 'steamOven', width: 15 },
      { header: 'gas', key: 'gas', width: 15 },
      { header: 'compressedAir', key: 'compressedAir', width: 15 },
    ];

    sheet.mergeCells('A1:D1');
    sheet.getCell('A1').value = 'Lot';
    sheet.getCell('A2').value = 'Date';
    sheet.mergeCells('A2:A3');
    sheet.getCell('B2').value = 'Time';
    sheet.mergeCells('B2:B3');
    sheet.getCell('C2').value = 'ID';
    sheet.mergeCells('C2:C3');
    sheet.getCell('D2').value = 'Name';
    sheet.mergeCells('D2:D3');
    sheet.mergeCells('E1:H1');

    sheet.getCell('E1').value = 'Indicators';
    sheet.getCell('E2').value = 'Oee';
    sheet.mergeCells('E2:E3');
    sheet.getCell('F2').value = 'Availability';
    sheet.mergeCells('F2:F3');
    sheet.getCell('G2').value = 'Performance';
    sheet.mergeCells('G2:G3');
    sheet.getCell('H2').value = 'Quality';
    sheet.mergeCells('H2:H3');

    sheet.getCell('I1').value = 'Time';
    sheet.mergeCells('I1:N1');
    sheet.getCell('I2').value = 'Total';
    sheet.mergeCells('I2:I3');
    sheet.getCell('J2').value = 'Total(min)';
    sheet.mergeCells('J2:J3');
    sheet.getCell('K2').value = 'Production';
    sheet.mergeCells('K2:K3');
    sheet.getCell('L2').value = 'Production(min)';
    sheet.mergeCells('L2:L3');
    sheet.getCell('M2').value = 'Operator';
    sheet.mergeCells('M2:M3');
    sheet.getCell('N2').value = 'Operator(min)';
    sheet.mergeCells('N2:N3');
    sheet.getCell('O2').value = 'Stops';
    sheet.mergeCells('O2:O3');
    sheet.getCell('P2').value = 'Stops(min)';
    sheet.mergeCells('P2:P3');

    sheet.getCell('Q1').value = 'Pieces';
    sheet.mergeCells('Q1:W1');
    sheet.getCell('Q2').value = 'Pieces Total';
    sheet.mergeCells('Q2:Q3');
    sheet.getCell('R2').value = 'Pieces Produced';
    sheet.mergeCells('R2:T2');
    sheet.getCell('R3').value = 'Total';
    sheet.getCell('S3').value = 'Pieces OK';
    sheet.getCell('T3').value = 'Pieces NOK';
    sheet.getCell('U2').value = 'Low Pieces';
    sheet.mergeCells('U2:U3');
    sheet.getCell('V2').value = 'Heavy Pieces';
    sheet.mergeCells('V2:V3');
    sheet.getCell('W2').value = 'Produced Bread Counter';
    sheet.mergeCells('W2:W3');

    sheet.getCell('X1').value = 'Mass (kg)';
    sheet.mergeCells('X1:AC1');
    sheet.getCell('X2').value = 'Mass Total';
    sheet.mergeCells('X2:X3');
    sheet.getCell('Y2').value = 'Mass Produced';
    sheet.mergeCells('Y2:AA2');
    sheet.getCell('Y3').value = 'Total';
    sheet.getCell('Z3').value = 'Mass OK';
    sheet.getCell('AA3').value = 'Mass NOK';
    sheet.getCell('AB2').value = 'Low Mass';
    sheet.mergeCells('AB2:AB3');
    sheet.getCell('AC2').value = 'Heavy Mass';
    sheet.mergeCells('AC2:AC3');

    sheet.getCell('AD1').value = 'Consumption';
    sheet.mergeCells('AD1:AL1');
    sheet.getCell('AD2').value = 'Electricity';
    sheet.mergeCells('AD2:AG2');
    sheet.getCell('AD3').value = 'Mixers';
    sheet.getCell('AE3').value = 'Entry';
    sheet.getCell('AF3').value = 'Main';
    sheet.getCell('AG3').value = 'Oven';
    sheet.getCell('AH2').value = 'Recuperated Energy (kWh)';
    sheet.mergeCells('AH2:AH3');
    sheet.getCell('AI2').value = 'Steam (kg)';
    sheet.mergeCells('AI2:AJ2');
    sheet.getCell('AI3').value = 'Main';
    sheet.getCell('AJ3').value = 'Oven';
    sheet.getCell('AK2').value = 'Gas (m3)';
    sheet.mergeCells('AK2:AK3');
    sheet.getCell('AL2').value = 'Compressed Air (m3)';

    [
      'A1',
      'A2',
      'B2',
      'C2',
      'D2',
      'B1',
      'C1',
      'D1',
      'E1',
      'E2',
      'F2',
      'G2',
      'H2',
      'I1',
      'I2',
      'J1',
      'J2',
      'K2',
      'L2',
      'M2',
      'N2',
      'O2',
      'P2',
      'Q1',
      'Q2',
      'R2',
      'R3',
      'S3',
      'T3',
      'U2',
      'V2',
      'W2',
      'X1',
      'X2',
      'Y2',
      'Y3',
      'Z3',
      'AA3',
      'AB2',
      'AC2',
      'AD1',
      'AD2',
      'AD3',
      'AE3',
      'AF3',
      'AG3',
      'AH2',
      'AI2',
      'AI3',
      'AJ3',
      'AK2',
      'AL2',
    ].map((cell) => {
      sheet.getCell(cell).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
      sheet.getCell(cell).font = { bold: true };
      return null;
    });

    const rows = data.map((item) => ({
      date: moment(item.lot.start.ts).format('DD.MM.YYYY'),
      time:
        moment(item.lot.start.ts).format('HH:mm:ss') +
        ' - ' +
        moment(item.lot.end.ts).format('HH:mm:ss'),
      lotId: item.lot.nr,
      lotName: item.recipe.name,
      oee: item.oee.divider.oee,
      ava: item.oee.divider.availability,
      per: item.oee.divider.performance,
      qua: item.oee.divider.quality,
      piecesTotal: item.counters.divider.pieces.total,
      piecesProdTotal: item.counters.divider.pieces.out,
      piecesProdOK: item.counters.divider.pieces.ok,
      piecesProdNOK:
        item.counters.divider.pieces.out - item.counters.divider.pieces.ok,
      piecesLow: item.counters.divider.pieces.low,
      piecesHigh: item.counters.divider.pieces.high,
      piecesLastCounter: item.counters.charger.pieces.total,
      massTotal: Math.floor(item.counters.divider.mass_g.total / 1000),
      massProdTotal: Math.floor(item.counters.divider.mass_g.out / 1000),
      massProdOK: Math.floor(item.counters.divider.mass_g.ok / 1000),
      massProdNOK: Math.floor(
        item.counters.divider.mass_g.out / 1000 -
          item.counters.divider.mass_g.ok / 1000
      ),
      massLow: Math.floor(item.counters.divider.mass_g.low / 1000),
      massHigh: Math.floor(item.counters.divider.mass_g.high / 1000),
      totalTime: generateDurationString(
        (Number(item.run_times.run.ms) + Number(item.run_times.stop.ms)) / 1000
      ),
      totalTimeMinutes: Math.round(
        Number(
          moment
            .duration(
              Number(item.run_times.run.ms) + Number(item.run_times.stop.ms),
              'milliseconds'
            )
            .asMinutes()
        )
      ),
      production: generateDurationString(Number(item.run_times.run.ms) / 1000),
      productionMinutes: Math.round(
        Number(
          moment
            .duration(Number(item.run_times.run.ms), 'milliseconds')
            .asMinutes()
        )
      ),
      operatorStop: generateDurationString(
        Number(item.run_times.stop_planned.ms) / 1000
      ),
      operatorStopMinutes: Math.round(
        Number(
          moment
            .duration(Number(item.run_times.stop_planned.ms), 'milliseconds')
            .asMinutes()
        )
      ),
      alarmStop: generateDurationString(
        Number(item.run_times.stop_unplanned.ms) / 1000
      ),
      alarmStopMinutes: Math.round(
        Number(
          moment
            .duration(Number(item.run_times.stop_unplanned.ms), 'milliseconds')
            .asMinutes()
        )
      ),
      elMixers: item.energy.mixers_kwh,
      elEntry: item.energy.entry_kwh,
      elMain: item.energy.main_kwh,
      elOven: item.energy.oven_kwh,
      elRecuperated: item.energy.recuperation_kwh,
      steamMain: item.energy.steam_main_m3,
      steamOven: item.energy.steam_oven_m3,
      gas: item.energy.oven_gas_m3,
      compressedAir: item.energy.compr_air_m3,
    }));

    sheet.addRows(rows);
    // Formulas must be after adding rows
    const COLUMNS_TO_SUM = [
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
      'AA',
      'AB',
      'AC',
    ];
    const rowsLength = rows.length;
    const dataRowEnds = rowsLength + ROWS_OF_HEADER;
    COLUMNS_TO_SUM.forEach((column) => {
      const cell = sheet.getCell(`${column}${rowsLength + ROWS_OF_HEADER + 1}`);
      cell.value = {
        formula: `SUM(${column}${ROWS_OF_HEADER + 1}:${column}${dataRowEnds})`,
      };
      cell.font = { bold: true };
    });
    // sheet.getCell(`K${rowsLength + ROWS_OF_HEADER}`).value = {
    //   formula: `SUM(K3:K${dataRowEnds})`,
    // };
    // sheet.getCell(`M${rowsLength + ROWS_OF_HEADER}`).value = {
    //   formula: `SUM(M3:M${dataRowEnds})`,
    // };
    // sheet.getCell(`O${rowsLength + ROWS_OF_HEADER}`).value = {
    //   formula: `SUM(O3:O${dataRowEnds})`,
    // };
    // sheet.getCell(`Q${rowsLength + ROWS_OF_HEADER}`).value = {
    //   formula: `SUM(Q3:Q${dataRowEnds})`,
    // };

    //download file
    workbook.xlsx.writeBuffer().then(function (data) {
      var blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, 'Lot report.xlsx');
    });
  };
  const generateJSON = () => {
    const stringifiedData = JSON.stringify(data);
    const blob = new Blob([stringifiedData], {
      type: 'application/json',
    });
    saveAs(blob, 'Lot report.json');
  };
  // return <StyledButton onClick={downloadFile}>export</StyledButton>;
  return (
    <UncontrolledDropdown>
      <StyledDropdownToggle>Export</StyledDropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={generateXLSX} disabled={!data.length}>
          Lot report .xlsx
        </DropdownItem>
        <DropdownItem onClick={generateJSON} disabled={!data.length}>
          Lot report .JSON
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ExportData;
