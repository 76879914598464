import React from 'react';
import Overview from './views/Dashboard';
import { IoSpeedometer } from 'react-icons/io5';

export default {
  title: 'Overall Equipment Effectiveness',
  icon: <IoSpeedometer />,
  iconText: 'OEE',
  url: '/oee',
  api: {},
  view: Overview,
};
