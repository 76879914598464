import React from 'react';
import styled from 'styled-components';

const StyledOverlayContainer = styled.div`
  position: relative;

  div:first-child {
    filter: blur(4px);
  }
`;

const StyledOverlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  top: 0;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  width: 100%;
`;

const BlurOverlay = ({ children, activate, text, styles }) => {
  if (!activate) return children;
  return (
    <StyledOverlayContainer>
      <div>{children}</div>
      <StyledOverlay style={{ ...styles }}>{text}</StyledOverlay>
    </StyledOverlayContainer>
  );
};

export default BlurOverlay;
