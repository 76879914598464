import { createOption } from './helpers';
export const METHOD_OPTIONS = [
  createOption('Visually'),
  createOption('Tools'),
  createOption('Water'),
  createOption('Detergent'),
  createOption('Grease'),
  createOption('Oil'),
];

export const FREQUENCY_UNITS = ['Working hours', 'Days', 'Weeks', 'Months'];

export const PERSONEL_LEVEL_OPTIONS = [
  createOption('Trained'),
  createOption('Qualified'),
  createOption('Gostol'),
];
