import React, { useContext, useState } from 'react';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { useToggler } from '../../../../lib/helpers/customHooks';
import TableReact from '../../../../pages/Settings/TableTemplate';
import { useStore } from '../../../../store';
import {
  AddItemButton,
  StyledItem,
} from '../../../MAS/views/Overview/partials/Shop/ItemsTreeTable';
import { OrderItemDetailsModal } from './OrderItems';
import { getTranslation } from './OrderItems.helpers';
import { ThemeContext } from 'styled-components';

const QuickPick = () => {
  const [isItemDetailsOpen, toggleItemDetails] = useToggler(false);
  const [selectedItem, setSelectedItem] = useState({});
  const { state } = useStore();
  const themeContext = useContext(ThemeContext);
  const isItemAlreadyInOrder = (item) => {
    const items = state.selectedOrder?.items;
    if (!item || !items?.length) return [false, null];
    const isInOrder = items.find(
      (itemInOrder) => itemInOrder.material_code === item.material_code
    );

    return [!!isInOrder, isInOrder?.quantity || null];
  };
  const columns = [
    {
      id: 'name',
      Header: 'Material',
      accessor: 'name',
      style: { verticalAlign: 'middle' },
      Cell: (row) => {
        const name = getTranslation(row.value);
        const materialCode = row.row.original.material_code;
        return (
          <>
            <div
              className="small font-weight-bolder"
              style={{ color: themeContext.colors.grayscale._40 }}
            >
              {materialCode}
            </div>
            <div>{name}</div>
          </>
        );
      },
    },
    {
      id: 'quantity',
      Header: 'Quantity',

      accessor: 'quick_select_quantity',
      style: { textAlign: 'right', verticalAlign: 'middle' },
    },
    {
      id: 'price',
      Header: 'Price',

      accessor: 'quick_select_price',
      style: { textAlign: 'right', verticalAlign: 'middle' },
    },
    {
      id: 'value',
      Header: 'Value',

      accessor: 'value',
      style: { textAlign: 'right', verticalAlign: 'middle' },
      Cell: ({ row }) => {
        const {
          quick_select_price: price,
          quick_select_quantity: quantity,
        } = row.original;
        return (price * quantity).toFixed(2);
      },
    },
    {
      id: 'add',
      Header: 'Added',
      style: { textAlign: 'center', verticalAlign: 'middle' },
      Cell: ({ row }) => {
        const [isAdded, quantity] = isItemAlreadyInOrder(row.original);

        return isAdded ? (
          <>
            <StyledItem>
              <span className="icon">
                <IoCheckmarkCircleSharp />
              </span>
              <span>{quantity}x</span>
            </StyledItem>
          </>
        ) : (
          <AddItemButton
            data={row.original}
            setItem={setSelectedItem}
            toggle={toggleItemDetails}
            isAdded={isAdded}
          />
        );
      },
    },
  ];

  const quickPickRef = useFirestore().collection('quick_select');
  const { data: quickPickItems } = useFirestoreCollectionData(quickPickRef);

  return (
    <>
      <TableReact columns={columns} data={quickPickItems || []} />{' '}
      <OrderItemDetailsModal
        isOpen={isItemDetailsOpen}
        toggle={toggleItemDetails}
        data={selectedItem}
        isQuickPick
      />
    </>
  );
};

export default QuickPick;
