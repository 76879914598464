import React from 'react';
import { FiChevronRight, FiChevronDown } from 'react-icons/fi';
import { generateDurationString } from '../../../helpers';
import moment from 'moment';
import TableReact from '../../../../../pages/Settings/TableTemplate';

const TableRoelandt = ({ data, durations }) => {
  const badgeStyle = (type) => {
    const color = {
      alarm: '--main-danger',
      warning: '--main-warning',
      info: '--main-info',
    }[type];
    return {
      fontSize: '0.8em',
      color: `var(${color})`,
      background: `var(${color}-10)`,
      padding: '0.2em 0.5em',
      fontWeight: 500,
      borderRadius: '3px',
    };
  };

  const renderRowSubComponent = ({ row }) => {
    const filtered = data.filter(
      (item) => item.description === row.original.name
    );
    const columns = [
      {
        id: 'start',
        Header: 'Start',
        accessor: 'begin_time',
        Cell: ({ value }) =>
          moment(Number(value)).format('DD.MM.YYYY, HH:mm:ss'),
      },
      {
        id: 'end',
        Header: 'End',
        accessor: 'end_time',
        Cell: ({ value }) =>
          moment(Number(value)).format('DD.MM.YYYY, HH:mm:ss'),
      },
      {
        id: 'duration',
        Header: 'Duration',
        accessor: 'duration',
        Cell: ({ value }) => generateDurationString(value),
      },
    ];
    return (
      <React.Fragment>
        <strong>Alarm log</strong>
        <p>Alarm occurred {filtered.length} times</p>
        <TableReact columns={columns} data={filtered} />
      </React.Fragment>
    );
  };

  const columns = [
    {
      // Build our expander column
      id: 'expander', // Make sure it has an ID
      Header: '',
      width: 35,
      Cell: ({ row }) => (
        <div className="text-center" {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <FiChevronDown /> : <FiChevronRight />}
        </div>
      ),
    },
    {
      id: 'type',
      Header: 'Type',
      accessor: 'type',
      style: { textAlign: 'center' },
      Cell: ({ row }) => {
        const { type } = row.values;
        return <span style={badgeStyle(type)}>{type.toUpperCase()}</span>;
      },
    },
    {
      id: 'duration',
      Header: 'Duration',
      accessor: 'value',
      Cell: ({ value }) => generateDurationString(value),
    },
    {
      id: 'description',
      Header: 'Description',
      accessor: 'name',
    },
  ];

  return (
    <TableReact
      columns={columns}
      data={durations}
      subComponent={renderRowSubComponent}
    />
  );
};

export default TableRoelandt;
