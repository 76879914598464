import firebase from 'firebase';
import { toast } from 'react-toastify';

export const deleteOrder = async (data) => {
  const fsdb = firebase.firestore();
  const OrderRef = fsdb.collection('orders').doc(data.details.id);

  const modifiedAt = firebase.firestore.FieldValue.serverTimestamp();
  const status = 'deleted';

  const modifiedData = {
    ...data,
    details: {
      ...data.details,
      status,
    },
    modifiedAt,
  };
  await OrderRef.set({ ...modifiedData }, { merge: true });
};

export const confirmOrder = async (data) => {
  const fsdb = firebase.firestore();
  const OrderRef = fsdb.collection('orders').doc(data.details.id);

  const modifiedAt = firebase.firestore.FieldValue.serverTimestamp();
  const status = 'confirmed';

  const modifiedData = {
    ...data,
    details: {
      ...data.details,
      status,
    },
    modifiedAt,
  };
  await OrderRef.set({ ...modifiedData }, { merge: true });
};

export const displayCurrency = (currency) =>
  ({
    EUR: ' €',
    RUB: ' ₽',
    USD: ' $',
    none: '',
  }[currency || 'none']);

export function getOrderQuote(orderItems) {
  if (!orderItems) return '';
  let quote = 0;
  for (let i = 0; i < orderItems.length; i++) {
    quote += orderItems[i].price * orderItems[i].quantity;
  }
  return Number(quote.toFixed(2));
}

export const editOrder = async (data, status) => {
  const fsdb = firebase.firestore();
  const OrderRef = fsdb.collection('orders').doc(data.details.id);

  const modifiedAt = firebase.firestore.FieldValue.serverTimestamp();

  const modifiedData = {
    ...data,
    details: {
      ...data.details,
      status,
    },
    modifiedAt,
  };
  await OrderRef.set({ ...modifiedData }, { merge: true });
};

export const returnOrder = async (data) => {
  const fsdb = firebase.firestore();
  const OrderRef = fsdb.collection('orders').doc(data.details.id);

  const modifiedAt = firebase.firestore.FieldValue.serverTimestamp();
  const status = 'returned';

  const modifiedData = {
    ...data,
    details: {
      ...data.details,
      status,
    },
    modifiedAt,
  };
  await OrderRef.set({ ...modifiedData }, { merge: true });
};

export const discardOrder = async (data) => {
  const fsdb = firebase.firestore();
  fsdb
    .collection('orders')
    .doc(data.details.id)
    .delete()
    .then(() => {
      toast.success('Order discharged successfully');
    })
    .catch((error) => {
      toast.error('There was an error, try again');
      console.error('Error removing Order: ', error);
    });
};
