import React from 'react';
import moment from 'moment';
import { generateReportData } from '../dataGenerators';
//import { generateDurationString } from '../../../Diagnostics/helpers';

const Head = ({ data }) => {
  const { mass_g, capacity, name } = data.recipe;
  const {
    start: { ts: start },
    end: { ts: end },
    nr,
  } = data.lot;
  const valueStyle = { paddingRight: '2em', fontWeight: 'bold' };
  const keyStyle = { paddingRight: '1em' };
  return (
    <React.Fragment>
      {
        <React.Fragment>
          {name && <h4>{name}</h4>}
          <span style={keyStyle}>ID</span>
          <span style={valueStyle}>{nr}</span>
          <span style={keyStyle}>Weight</span>
          <span style={valueStyle}>{mass_g}g</span>
          <span style={keyStyle}>Capacity</span>
          <span style={valueStyle}>{capacity} pcs/h</span>
          <hr className="m-2" />
        </React.Fragment>
      }
      <span style={keyStyle}>Date</span>
      <span style={valueStyle}>
        {moment(Number(start)).format('DD.MM.YYYY')}
      </span>
      <span style={keyStyle}>Lot start</span>
      <span style={valueStyle}>{moment(Number(start)).format('HH:mm:ss')}</span>
      <span style={keyStyle}>Lot end</span>
      <span style={valueStyle}>{moment(Number(end)).format('HH:mm:ss')}</span>
    </React.Fragment>
  );
};

export default Head;
