import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Input,
  Spinner,
} from 'reactstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import uniq from 'lodash/uniq';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/chart/pie';
import 'echarts/lib/chart/bar';
import TableReact from '../../../../../pages/Settings/TableTemplate';
import { getColorScheme } from '../../../../../lib/helpers';
import { generateDurationString } from '../../../../Diagnostics/helpers';
import AvailabilityTimeline2 from '../../../../Dashboards/partials/AvailabilityTimeline2';

const StopModalData = ({
  data: {
    summary,
    avaTimeline,
    reasonOptions,
    stopsWithReasons,
    getStopsWithReasons,
    reports2,
  },
  dataNew,
  sums,
}) => {
  const [reasons, setReasons] = useState(
    stopsWithReasons.filter((item) => item.is_minor_stop === 'f') || []
  );
  const [loadingReasons, setLoadingReasons] = useState(false);

  const colorScheme = getColorScheme();
  useEffect(() => {
    setReasons(stopsWithReasons);
    setLoadingReasons(false);
  }, [stopsWithReasons]);

  const stopsWithReasonBarChartData = () => {
    const removedMinorStops = reasons.filter(
      (item) => item.is_minor_stop === 'f'
    );
    const reasonsCategories = uniq(
      removedMinorStops.map((item) => item.stop_reason_name)
    );
    const calculatedStops = reasonsCategories.map((category) => {
      let duration = 0;
      removedMinorStops.map((item) => {
        if (item.stop_reason_name === category) {
          duration += Number(item.stop_duration);
        }
        return duration;
      });
      return [category || 'Undefined', duration];
    });

    return calculatedStops.sort((a, b) => b[1] - a[1]);
  };

  const handleChange = async ({ row, stop_reason_id, stop_reason_name }) => {
    const { state_start, state_end, stop_duration, ...rest } = row.original;

    const item = {
      state_start: Number(state_start),
      state_end: Number(state_end),
      stop_duration: Number(stop_duration),
      ...rest,
      stop_reason_id,
      stop_reason_name,
    };
    const result = await updateStopWithReason(item);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }
    setLoadingReasons(true);
    getStopsWithReasons();
  };

  const updateStopWithReason = async (data) => {
    return fetch(process.env.REACT_APP_PSQL_PROC, {
      method: 'POST',
      body: JSON.stringify({
        reqType: 'update',
        funcName: 'project.mas_stops_reason_u',
        inputParameters: data,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((err) => ({
        error: err,
      }));
  };

  const handleEditComment = async (data, newComment, oldComment) => {
    if (newComment === oldComment) return;

    const item = {
      ...data,
      stop_reason_comment: newComment,
    };

    const result = await updateStopWithReason(item);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }
    setLoadingReasons(true);
    getStopsWithReasons();
  };

  const cardStyle = {
    marginBottom: '1rem',
  };

  const setColor = (params, api) => {
    const findColor =
      reasons.find((item) => item.stop_reason_name === params.data[0])
        ?.stop_reason_color || colorScheme.main.danger._100;
    return findColor;
  };

  const optionReason = {
    xAxis: {
      type: 'category',
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value) => generateDurationString(value),
      },
    },
    tooltip: {
      formatter: (params) => {
        const [name, duration] = params.value;
        return `
                <strong style="text-transform: capitalize"> ${name}</strong> <br />
                Duration: <strong>${generateDurationString(duration)}</strong> 
            `;
      },
    },
    series: [
      {
        data: stopsWithReasonBarChartData(),
        type: 'bar',
        itemStyle: {
          color: setColor,
        },
      },
    ],
  };

  const columns = [
    {
      id: 'start',
      Header: 'Start',
      accessor: 'state_start',
      Cell: ({ row }) => {
        return moment(Number(row.values.start)).format('HH:mm:ss');
      },
    },
    {
      id: 'end',
      Header: 'End',
      accessor: 'state_end',
      Cell: ({ row }) => moment(Number(row.values.end)).format('HH:mm:ss'),
    },
    {
      id: 'duration',
      Header: 'Duration',
      accessor: 'stop_duration',
      Cell: ({ row }) => generateDurationString(Number(row.values.duration)),
    },
    {
      id: 'reason',
      Header: 'Reason',
      accessor: 'reason',
      Cell: ({ row }) => {
        return (
          <Input
            type="select"
            name="reason"
            defaultValue={row.original.stop_reason_id}
            onChange={(e) =>
              handleChange({
                row,
                stop_reason_id: Number(e.target.value),
                stop_reason_name:
                  e.target.options[e.target.options.selectedIndex].attributes
                    .label.value,
              })
            }
          >
            {reasonOptions.map((reason) => (
              <option
                key={reason.id}
                value={reason.id}
                label={reason.description}
                style={{ color: reason.color || colorScheme.main.danger._100 }}
                //defaultValue={reason.id}
                //selected={reason.id === row.original.stop_reason_id}
              >
                {reason.stop_reason_name}
              </option>
            ))}
          </Input>
        );
      },
    },
    {
      id: 'comment',
      Header: 'Comment',
      accessor: 'stop_reason_comment',
      Cell: (row) => (
        <Input
          type="textarea"
          defaultValue={row.cell.value}
          onBlur={(e) =>
            handleEditComment(
              row.row.original,
              e.target.value,
              row.cell.value || ''
            )
          }
        />
      ),
    },
  ];

  return (
    <Row>
      <Col lg={6}>
        <Card className="mb-3">
          <CardHeader className="d-flex justify-content-between pb-1">
            <div>Times</div>
          </CardHeader>
          <CardBody
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              paddingTop: 0,
              flexWrap: 'wrap',
              paddingBottom: '.25rem',
              fontWeight: 'bold',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: colorScheme.grayscale._40 }}>Total</div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div style={{ fontSize: '1.5em' }}>
                  {sums
                    ? generateDurationString(
                        Number(sums.times.stop) + Number(sums.times.run)
                      )
                    : '-'}
                  {/* {summary
                    ? generateDurationString(
                        Number(summary.divider_stop_time) +
                          Number(summary.divider_run_time)
                      )
                    : '-'} */}
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: colorScheme.grayscale._40 }}>Running</div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  color: colorScheme.main.success._100,
                }}
              >
                <div style={{ fontSize: '1.5em' }}>
                  {sums ? generateDurationString(sums.times.run) : '-'}
                  {/* {summary
                    ? generateDurationString(summary.divider_run_time)
                    : '-'} */}
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: colorScheme.grayscale._40 }}>Unplanned</div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  color: colorScheme.main.danger._100,
                }}
              >
                <div style={{ fontSize: '1.5em' }}>
                  {sums ? generateDurationString(sums.times.stop) : '-'}
                  {/* {summary
                    ? generateDurationString(summary.divider_stop_time)
                    : '-'} */}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col lg={6}>
        <Card className="mb-3">
          <CardHeader className="d-flex justify-content-between pb-1">
            <div>Unplanned Stops</div>
          </CardHeader>
          <CardBody
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              paddingTop: 0,
              flexWrap: 'wrap',
              paddingBottom: '.25rem',
              fontWeight: 'bold',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: colorScheme.grayscale._40 }}>Total</div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div style={{ fontSize: '1.5em' }}>
                  {sums ? generateDurationString(sums.times.stop) : '-'}
                  {/* {summary
                    ? generateDurationString(summary.divider_stop_time)
                    : '-'} */}
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: colorScheme.grayscale._40 }}>
                Operator Stops
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  color: colorScheme.main.warning._100,
                }}
              >
                <div style={{ fontSize: '1.5em' }}>
                  {sums ? generateDurationString(sums.times.stop_planned) : '-'}
                  {/* {summary
                    ? generateDurationString(summary.stop_time_operator_ms)
                    : '-'} */}
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: colorScheme.grayscale._40 }}>
                Alarm Stops
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  color: colorScheme.main.danger._100,
                }}
              >
                <div style={{ fontSize: '1.5em' }}>
                  {sums
                    ? generateDurationString(sums.times.stop_unplanned || 0)
                    : '-'}
                  {/* {summary
                    ? generateDurationString(summary.stop_time_other_ms || 0)
                    : '-'} */}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col lg={12}>
        <AvailabilityTimeline2
          zoom
          data={{ loading: false, timelineAVA2: avaTimeline, reports2 }}
          dataNew={dataNew}
          gridLeft={100}
        />
      </Col>
      {/* <Col lg={12}>
        <Card style={cardStyle}>
          <CardHeader>
            Reasons{' '}
            {loadingReasons && (
              <Spinner color="dark" size="sm" className="ml-1" />
            )}
          </CardHeader>
          <CardBody className="p-0">
            <ReactEchartsCore echarts={echarts} option={optionReason} />
            <TableReact columns={columns} data={reasons} />
          </CardBody>
        </Card>
      </Col> */}
    </Row>
  );
};

export default StopModalData;
