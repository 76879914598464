import React from 'react';
import { Row, Col } from 'reactstrap';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import { getColorScheme } from '../../../../lib/helpers';
const PiecesAndMass = ({ data }) => {
  const {
    pcs_total,
    pcs_out,
    pcs_rejected,
    pcs_total_ok,
    pcs_total_hi,
    pcs_total_lo,
    pcs_last_counter,
    mass_total,
    mass_out,
    mass_total_ok,
    mass_total_hi,
    mass_total_lo,
  } = data;
  const colorScheme = getColorScheme();
  const valueStyle = {
    paddingLeft: '2em',
    fontWeight: 'bold',
    textAlign: 'right',
  };
  const massOptionsGood = {
    legend: {
      orient: 'vertical',
      left: 0,
      top: 50,
      data: ['Mass OK', 'Produced Not OK', 'Light Mass', 'Heavy Mass'],
    },
    grid: {
      height: 150,
    },
    series: [
      {
        name: 'Mass',
        type: 'pie',
        radius: ['45%', '60%'],
        data: [
          //{ value: Number(mass_rejected), name: 'Rejected', itemStyle: { color: colors[1] } },
          {
            value: Number(mass_total_ok),
            name: 'Mass OK',
            itemStyle: { color: colorScheme.main.success._100 },
          },
          {
            value: Number(mass_out) - Number(mass_total_ok),
            name: 'Produced Not OK',
            itemStyle: { color: colorScheme.main.warning._100 },
          },
        ],
        silent: true,
        height: 150,
        left: 100,
        labelLine: {
          show: false,
        },
        label: {
          show: false,
        },
      },
      {
        name: 'Mass',
        type: 'pie',
        radius: [0, '40%'],
        data: [
          //{ value: Number(mass_rejected), name: 'Rejected', itemStyle: { color: colors[1] } },
          {
            value: Number(mass_total_lo),
            name: 'Light Mass',
            itemStyle: { color: colorScheme.other[0]._100 },
          },
          {
            value: Number(mass_total_hi),
            name: 'Heavy Mass',
            itemStyle: { color: colorScheme.other[1]._100 },
          },
          {
            value: Number(mass_out) - Number(mass_total_ok),
            name: 'Produced Not OK',
            itemStyle: { color: colorScheme.other[2]._100 },
          },
        ],

        silent: true,
        height: 150,
        left: 100,
        labelLine: {
          show: false,
        },
        label: {
          show: false,
        },
      },
    ],
  };
  const massOptionsBad = {
    legend: {
      orient: 'vertical',
      left: 0,
      data: ['Produced Not OK Pieces', 'Light Mass', 'Heavy Mass', 'Rejected'],
    },
    grid: {
      height: 150,
    },
    series: [
      {
        name: 'Mass',
        type: 'pie',
        data: [
          //{ value: Number(mass_rejected), name: 'Rejected', itemStyle: { color: colors[1] } },
          {
            value: Number(mass_total_lo),
            name: 'Light Mass',
            itemStyle: { color: colorScheme.other[0]._100 },
          },
          {
            value: Number(mass_total_hi),
            name: 'Heavy Mass',
            itemStyle: { color: colorScheme.other[1]._100 },
          },
          {
            value: Number(mass_out) - Number(mass_total_ok),
            name: 'Produced Not OK Pieces',
            itemStyle: { color: colorScheme.other[2]._100 },
          },
        ],

        silent: true,
        height: 150,
        left: 100,
        labelLine: {
          show: false,
        },
        label: {
          show: false,
        },
      },
    ],
  };
  const piecesOptionsBad = {
    legend: {
      orient: 'vertical',
      left: 0,
      data: [
        'Produced Not OK Pieces',
        'Light Pieces',
        'Heavy Pieces',
        'Rejected',
      ],
    },
    grid: {
      height: 150,
    },
    series: [
      {
        name: 'Pieces',
        type: 'pie',
        data: [
          //   { value: Number(pcs_rejected), name: 'Rejected', itemStyle: { color: colors[1] } },
          {
            value: Number(pcs_total_lo),
            name: 'Light Pieces',
            itemStyle: { color: colorScheme.other[0]._100 },
          },
          {
            value: Number(pcs_total_hi),
            name: 'Heavy Pieces',
            itemStyle: { color: colorScheme.other[1]._100 },
          },
          {
            value: Number(pcs_out) - Number(pcs_total_ok),
            name: 'Produced Not OK Pieces',
            itemStyle: { color: colorScheme.other[2]._100 },
          },
        ],
        left: 100,
        silent: true,
        height: 150,
        labelLine: {
          show: false,
        },
        label: {
          show: false,
        },
      },
    ],
  };

  const piecesOptionsGood = {
    legend: {
      orient: 'vertical',
      left: 0,
      top: 50,
      data: ['Pieces OK', 'Produced Not OK', 'Light Pieces', 'Heavy Pieces'],
    },
    grid: {
      height: 150,
    },
    series: [
      {
        name: 'Pieces',
        type: 'pie',
        radius: ['45%', '60%'],
        data: [
          //   { value: Number(pcs_rejected), name: 'Rejected', itemStyle: { color: colors[1] } },
          {
            value: Number(pcs_total_ok),
            name: 'Pieces OK',
            itemStyle: { color: colorScheme.main.success._100 },
          },
          {
            value: Number(pcs_out) - Number(pcs_total_ok),
            name: 'Produced Not OK',
            itemStyle: { color: colorScheme.main.warning._100 },
          },
        ],
        left: 100,
        silent: true,
        height: 150,
        labelLine: {
          show: false,
        },
        label: {
          show: false,
        },
      },
      {
        name: 'Pieces',
        type: 'pie',
        radius: [0, '40%'],
        data: [
          //   { value: Number(pcs_rejected), name: 'Rejected', itemStyle: { color: colors[1] } },
          {
            value: Number(pcs_total_lo),
            name: 'Light Pieces',
            itemStyle: { color: colorScheme.other[0]._100 },
          },
          {
            value: Number(pcs_total_hi),
            name: 'Heavy Pieces',
            itemStyle: { color: colorScheme.other[1]._100 },
          },
          {
            value: Number(pcs_out) - Number(pcs_total_ok),
            name: 'Produced Not OK',
            itemStyle: { color: colorScheme.other[2]._100 },
          },
        ],
        left: 100,
        silent: true,
        height: 150,
        labelLine: {
          show: false,
        },
        label: {
          show: false,
        },
      },
    ],
  };
  return (
    <React.Fragment>
      <div className="mb-1 border-top font-weight-bold">Pieces and mass</div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <div style={{ minWidth: 200, maxWidth: '25%', width: '100%' }}>
          <div className="d-flex justify-content-between">
            <span>Pieces Total</span>
            <span style={valueStyle}> {pcs_total}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span>Pieces Produced</span>
            <span style={valueStyle}>{pcs_out}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span style={{ paddingLeft: '1em' }}>Pieces OK</span>
            <span style={valueStyle}>{pcs_total_ok}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span style={{ paddingLeft: '1em' }}>Produced Not OK Pieces</span>
            <span style={valueStyle}>{pcs_out - pcs_total_ok}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span>Low Pieces</span>
            <span style={valueStyle}>{pcs_total_lo}</span>
          </div>
          <div className="d-flex justify-content-between ">
            <span>Heavy Pieces</span>
            <span style={valueStyle}>{pcs_total_hi}</span>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <span>Produced Bread Counter</span>
            <span style={valueStyle}>{pcs_last_counter}</span>
          </div>
        </div>

        <div style={{ minWidth: 200, maxWidth: '25%', width: '100%' }}>
          <ReactEchartsCore
            style={{ height: 150, paddingLeft: '1em' }}
            echarts={echarts}
            option={piecesOptionsGood}
          />
        </div>
        {/* <Col lg={6}>
                            <div className="d-flex justify-content-between">
                                <span>Produced Not OK Pieces</span>
                                <span style={valueStyle}>{pcs_out - pcs_total_ok}</span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span>Low Pieces</span>
                                <span style={valueStyle}>{pcs_total_lo}</span>
                            </div>
                            <div className="d-flex justify-content-between ">
                                <span>High Pieces</span>
                                <span style={valueStyle}>{pcs_total_hi}</span>
                            </div> 
                            </Col>
                       <Col lg={6}>
                            <ReactEchartsCore
                                style={{ height: 150, paddingLeft: "1em" }}
                                echarts={echarts}
                                option={piecesOptionsBad} />
                        </Col>*/}

        <div style={{ minWidth: 200, maxWidth: '25%', width: '100%' }}>
          <div className="d-flex justify-content-between">
            <span>Mass Total</span>
            <span style={valueStyle}> {Math.floor(Number(mass_total))} kg</span>
          </div>
          <div className="d-flex justify-content-between">
            <span>Mass Produced</span>
            <span style={valueStyle}>{Math.floor(Number(mass_out))} kg</span>
          </div>
          <div className="d-flex justify-content-between">
            <span style={{ paddingLeft: '1em' }}>Mass OK</span>
            <span style={valueStyle}>
              {Math.floor(Number(mass_total_ok))} kg
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span style={{ paddingLeft: '1em' }}>Produced Not OK Mass</span>
            <span style={valueStyle}>
              {Math.floor(Number(mass_out) - Number(mass_total_ok))} kg
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span>Light Mass</span>
            <span style={valueStyle}>
              {Math.floor(Number(mass_total_lo))} kg
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span>Heavy Mass</span>
            <span style={valueStyle}>
              {Math.floor(Number(mass_total_hi))} kg
            </span>
          </div>
        </div>

        <div style={{ minWidth: 200, maxWidth: '25%', width: '100%' }}>
          <ReactEchartsCore
            style={{ height: 150, paddingLeft: '1em' }}
            echarts={echarts}
            option={massOptionsGood}
          />
        </div>

        {/*<Col lg={6}>
                            <div className="d-flex justify-content-between">
                                <span>Produced Not OK Mass</span>
                                <span style={valueStyle}>{Math.floor((Number(mass_out)-Number(mass_total_ok)) / 1000)} kg</span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span>Light Mass</span>
                                <span style={valueStyle}>{Math.floor(Number(mass_total_lo) / 1000)} kg</span>
                            </div>
                            <div className="d-flex justify-content-between ">
                                <span>Heavy Mass</span>
                                <span style={valueStyle}>{Math.floor(Number(mass_total_hi) / 1000)} kg</span>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <ReactEchartsCore
                                style={{ height: 150, paddingLeft: "1em" }}
                                echarts={echarts}
                                option={massOptionsBad} />
                        </Col>*/}
      </div>
    </React.Fragment>
  );
};

export default PiecesAndMass;
