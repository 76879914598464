import React from 'react';
import { OEE, OEESettings } from './OEE';
import { MAS, MASSettings } from './MAS';
import { Energy, EnergySettings } from './Energy';
import { Diagnostics, DiagnosticsSettings } from './Diagnostics';
import { Dashboards, DashboardsSettings } from './Dashboards';
import { Camera, CameraSettings } from './Camera';
import { Orders, OrdersSettings } from './Orders';

export const Modules = [
  { component: <Dashboards />, settings: DashboardsSettings },
  { component: <OEE />, settings: OEESettings },
  { component: <Energy />, settings: EnergySettings },
  { component: <Diagnostics />, settings: DiagnosticsSettings },
  { component: <MAS />, settings: MASSettings },
  { component: <Camera />, settings: CameraSettings },
  { component: <Orders />, settings: OrdersSettings },
];
