import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { StyledButton } from '../../../components/DateSelection/DateSelection';
import { getUserDetails } from '../../../lib/helpers';

const Camera = (props) => {
  const [userDetails, setUserDetails] = useState({});
  const [ip, setIp] = useState('');

  const fetchData = async () => {
    const resultUserData = await getUserDetails();
    setUserDetails(resultUserData);
    const tmpIp = {
      gostol: 'http://10.0.117.99/',
      velpitar: 'http://192.168.94.196/',
      other: '',
    }[resultUserData.usr_name || 'other'];
    setIp(tmpIp);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (ip === '') return null;
  return (
    <Container fluid>
      <a href={ip} target="_BLANK" rel="noreferrer">
        <StyledButton className="mt-4"> Open in new window </StyledButton>
      </a>
      <Row className="mt-4">
        <Col lg={4} className="mb-3">
          <Card className="mb-3">
            <CardBody className="p-0">
              <ReactPlayer
                width="100%"
                height="100%"
                playing
                //controls
                url={`${ip}dash/cam5.mpd`}
              />
            </CardBody>
          </Card>
        </Col>
        <Col lg={4} className="mb-3">
          <Card className="mb-3">
            <CardBody className="p-0">
              <ReactPlayer
                width="100%"
                height="100%"
                playing
                //controls
                url={`${ip}dash/cam1.mpd`}
              />
            </CardBody>
          </Card>
        </Col>
        <Col lg={4} className="mb-3">
          <Card className="mb-3">
            <CardBody className="p-0">
              <ReactPlayer
                width="100%"
                height="100%"
                playing
                //controls
                url={`${ip}dash/cam6.mpd`}
              />
            </CardBody>
          </Card>
        </Col>
        <Col lg={4} className="mb-3">
          <Card className="mb-3">
            <CardBody className="p-0">
              <ReactPlayer
                width="100%"
                height="100%"
                playing
                //controls
                url={`${ip}dash/cam2.mpd`}
              />
            </CardBody>
          </Card>
        </Col>
        <Col lg={4} className="mb-3">
          <Card className="mb-3">
            <CardBody className="p-0">
              <ReactPlayer
                width="100%"
                height="100%"
                playing
                //controls
                url={`${ip}dash/cam3.mpd`}
              />
            </CardBody>
          </Card>
        </Col>
        <Col lg={4} className="mb-3">
          <Card className="mb-3">
            <CardBody className="p-0">
              <ReactPlayer
                width="100%"
                height="100%"
                playing
                //controls
                url={`${ip}dash/cam4.mpd`}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Camera;
