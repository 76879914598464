import React, { Component } from 'react';
import { Row, Col, Badge, Card, CardBody } from 'reactstrap';
import { statusBadge, priorityBadge } from '../../../helpers';

class Description extends Component {
  bodyPreview = (el) => {
    let content = [];
    Object.entries(el).forEach(([key, values]) => {
      const { value, label } = values;
      if (value === '') return;
      if (key.indexOf('_') === 0) return;

      content.push(
        <Row key={key}>
          <Col sm={4} className="text-right">
            {label}
          </Col>
          <Col sm={8}>
            <strong>{value}</strong>
          </Col>
        </Row>
      );
    });

    return content;
  };

  render() {
    const {
      head: {
        subject: { value: subject },
        priority: { value: priority },
        status: { value: status },
        uuid: { value: uuid },
        contact_name: { value: contact_name },
        date_of_submission: { value: date_of_submission },
        time_of_submission: { value: time_of_submission },
      },
      body,
    } = this.props;

    const statusBadgeResult = statusBadge(status);
    const priorityBadgeResult = priorityBadge(priority);
    return (
      <Card>
        <CardBody>
          <div style={{ fontSize: 18, fontWeight: 500 }}>
            {subject} <span className="text-muted">#{uuid}</span>
          </div>
          <div className="mb-4">
            <Badge color={priorityBadgeResult.color} className="mr-2">
              {priorityBadgeResult.value}
            </Badge>
            <Badge color={statusBadgeResult.color} className="mr-2">
              {statusBadgeResult.value}
            </Badge>
            <strong>{contact_name}</strong> opened this issue on{' '}
            {date_of_submission}, {time_of_submission}
          </div>
          {body ? this.bodyPreview(body) : "This issue's body is empty"}
        </CardBody>
      </Card>
    );
  }
}
export default Description;
