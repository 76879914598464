import React, { useEffect, useState } from 'react';
import {
  FiCalendar,
  FiChevronLeft,
  FiChevronRight,
  FiClock,
} from 'react-icons/fi';
import {
  Col,
  Collapse,
  Row,
  Button,
  ButtonGroup,
  Container,
  Spinner,
  Input,
} from 'reactstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import gb from 'date-fns/locale/en-GB';
import parseISO from 'date-fns/parseISO';
import './datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { getLastXDays } from '../../lib/helpers';
import styled, { css } from 'styled-components';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import NoContent from '../NoContent';
registerLocale('en-GB', gb);

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  background: ${({ theme, color }) =>
    color ? theme.colors.main[color]._100 : 'white'};
  color: ${({ theme, color }) => (color ? 'white' : 'inherit')};
  border-radius: 3px;
  border: none;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  padding: 0.5em 1em;
  height: fit-content;
  gap: 0.3em;
  input {
    border: none;
  }
  span {
    padding-left: 0.5rem;
  }

  ${({ small }) =>
    small &&
    css`
      padding: 0.5em 0.35em;
    `}

  ${({ smaller }) =>
    smaller &&
    css`
      padding: 0.35em 1em;
    `}

  &:hover {
    background: ${({ theme, color }) =>
      color ? theme.colors.main[color]._80 : theme.colors.grayscale._10};
  }
`;

const DateSelection = ({
  range,
  rangeWithTime,
  hidePrevNext,
  setDates,
  selectedDates,
  loading,
  disabled,
  showTimeInput,
  ...rest
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const onChangeRange = (dates) => {
    setDates(dates);
  };

  const nextDay = (startDate) => {
    const newDay = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate() + 1,
      startDate.getHours(),
      startDate.getMinutes(),
      startDate.getSeconds()
    );
    setDates([newDay, newDay]);
  };
  const previousDay = (startDate) => {
    const newDay = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate() - 1,
      startDate.getHours(),
      startDate.getMinutes(),
      startDate.getSeconds()
    );
    setDates([newDay, newDay]);
  };

  const ref = React.createRef();

  const DateInput = React.forwardRef((props, ref) => {
    return (
      <React.Fragment>
        <ButtonGroup>
          {!range && !hidePrevNext && (
            <StyledButton
              small
              onClick={() => previousDay(props.startDate || selectedDates)}
              disabled={loading}
            >
              <FiChevronLeft />
            </StyledButton>
          )}
          <StyledButton disabled={loading || disabled} onClick={props.onClick}>
            <FiCalendar />
            <span>
              {moment(props.startDate).format('DD.MM.YYYY')}
              {range &&
                ' ~ ' +
                  (props.endDate
                    ? moment(props.endDate).format('DD.MM.YYYY')
                    : 'Pick a date')}
            </span>
          </StyledButton>
          {!range && !hidePrevNext && (
            <StyledButton
              small
              onClick={() => nextDay(props.startDate)}
              disabled={
                moment(props.startDate).format('DD.MM.YYYY') ===
                  moment(+new Date()).format('DD.MM.YYYY') || loading
              }
            >
              <FiChevronRight />
            </StyledButton>
          )}
        </ButtonGroup>
        {loading && <Spinner color="dark" size="sm" className="ml-1" />}
      </React.Fragment>
    );
  });

  const rangeLabel = (day, isSingleDay) => {
    if (!isSingleDay) return `Last ${day} days`;
    switch (day) {
      case 0:
        return 'Today';
      case 1:
        return 'Yesterday';
      default:
        return `${day} days ago`;
    }
  };

  const listOfRanges = (ranges, isSingleDay = false) => (
    <ul style={{ listStyle: 'none', padding: '.25em 0' }}>
      {ranges.map((day) => (
        <li key={day}>
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setDates([
                getLastXDays(day),
                isSingleDay
                  ? new Date(getLastXDays(day).setHours(23, 59, 59, 999))
                  : new Date(),
              ]);
            }}
          >
            {rangeLabel(day, isSingleDay)}
          </span>
        </li>
      ))}
    </ul>
  );

  const calendarContainer = ({ className, children }) => {
    return (
      <Container className="bg-white rounded shadow">
        <Row>
          <Col className="p-0 pr-4">
            <DatePicker
              {...rest}
              selected={selectedDates[0]}
              onChange={onChangeRange}
              startDate={selectedDates[0]}
              endDate={selectedDates[1]}
              showPopperArrow={false}
              locale="en-GB"
              selectsRange
              maxDate={parseISO(moment().format())}
              inline
            />
          </Col>
          <Col className="pr-4 pt-3">
            <strong>Quick ranges</strong>
            {listOfRanges([2, 7, 30])}
            {listOfRanges([0, 1, 7, 30], true)}
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <React.Fragment>
      <div className="align-items-center">
        {range ? (
          <DatePicker
            {...rest}
            selected={selectedDates[0]}
            onChange={onChangeRange}
            startDate={selectedDates[0]}
            endDate={selectedDates[1]}
            locale="en-GB"
            selectsRange
            maxDate={parseISO(moment().format())}
            calendarContainer={calendarContainer}
            customInput={
              <DateInput
                ref={ref}
                startDate={selectedDates[0]}
                endDate={selectedDates[1]}
              />
            }
          />
        ) : rangeWithTime ? (
          <DateRangeSelectionWithTime
            loading={loading}
            disabled={disabled}
            setDates={setDates}
            selectedDates={selectedDates}
            listOfRanges={listOfRanges}
          />
        ) : (
          <DatePicker
            {...rest}
            selected={selectedDates[0]}
            onChange={(date) => {
              setDates([date, date]);
            }}
            showTimeInput={showTimeInput}
            // maxDate={parseISO(moment().format())} //NOTE: uncomment this line after done testing "mark task as completed"
            locale="en-GB"
            customInput={<DateInput ref={ref} startDate={selectedDates[0]} />}
          />
        )}
      </div>
      {range && (
        <Collapse isOpen={isCollapsed} className="p-3 bg-white shadow-sm">
          <Button close onClick={() => setIsCollapsed(!isCollapsed)} />
          <Row>
            <Col sm={2} className="pl-0">
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div className="pr-3 mb-2">
                  <DatePicker
                    {...rest}
                    selected={selectedDates[0]}
                    onChange={onChangeRange}
                    startDate={selectedDates[0]}
                    endDate={selectedDates[1]}
                    selectsRange
                    locale="en-GB"
                    maxDate={parseISO(moment().format())}
                    inline
                  />
                </div>
              </div>
            </Col>
            <Col>
              <strong>Quick ranges</strong>
              {listOfRanges([2, 7, 30])}
            </Col>
          </Row>
        </Collapse>
      )}
    </React.Fragment>
  );
};
const StyledDateInput = styled(DatePicker)`
  input {
    border: 1px solid #ccc;
    padding: 0.2em 0.25em;
    border-radius: 3px;
  }

  display: flex;

  /* .react-datepicker {
    display: flex;
  } */
`;
const StyledDateSelectionContainer = styled.div`
  position: relative;
`;

const StyledDropdown = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'inherit' : 'none')};

  /* Position */
  position: absolute;
  min-width: 318px;
  transform: translate(0, 5px);

  /* Displayed on top of other element */
  z-index: 10;
`;

const StyledDropdownContent = styled.div`
  display: flex;
  gap: 2.5em;
`;
const TimesTitle = styled.div`
  font-weight: 700;
  background-color: #f0f0f0;
  padding: 0.3em 0;
  border-bottom: 1px solid #dee2e6;
  text-align: center;
`;

const TimesContainer = styled.div``;
const TimeCard = styled.div`
  display: flex;
  text-align: right;
  flex-direction: column;
  padding: 0.1em 0.5em;
  border-radius: 3px;
  cursor: pointer;
  .label {
    text-align: left;
    font-size: 0.75em;
    color: ${({ theme }) => theme.colors.grayscale._50};
    font-weight: 500;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.grayscale._10};
  }
`;
const DateRangeSelectionWithTime = ({
  loading,
  disabled,
  setDates,
  selectedDates,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [startDate, setStartDate] = useState(selectedDates[0]);
  const [endDate, setEndDate] = useState(selectedDates[1]);
  const [startTime, setStartTime] = useState(selectedDates[0]);
  const [endTime, setEndTime] = useState(selectedDates[1]);
  const [isStartDateInputOpen, setIsStartDateInputOpen] = useState(false);
  const [isEndDateInputOpen, setIsEndDateInputOpen] = useState(false);

  //NOTE: the order of .where is kinda important. in startLotsRef if you put .where with end of date to be the last, the date(end of day) is actually set in input. So you'll have end of the date in first date selection input
  const startLotsRef = useFirestore()
    .collection('lot_history')
    .where('lot.start.ts', '<=', startDate.setHours(23, 59, 59, 999))
    .where('lot.start.ts', '>=', startDate.setHours(0, 0, 0, 0));
  const {
    status: statusStartLots,
    data: startDateLots,
  } = useFirestoreCollectionData(startLotsRef);

  const endLotsRef = useFirestore()
    .collection('lot_history')
    .where('lot.start.ts', '>=', endDate.setHours(0, 0, 0, 0))
    .where('lot.start.ts', '<=', endDate.setHours(23, 59, 59, 999));
  const {
    status: statusEndLots,
    data: endDateLots,
  } = useFirestoreCollectionData(endLotsRef);

  const applyDates = () => {
    setDates([startTime, endTime]);
  };

  const handleChangeStart = (date) => {
    setStartDate(date);
    const startOfDate = new Date(date.setHours(0, 0, 0, 0));
    setStartTime(startOfDate);
  };
  const handleChangeEnd = (date) => {
    setEndDate(date);
    const endOfDate = new Date(date.setHours(23, 59, 59, 999));
    setEndTime(endOfDate);
  };

  const calendarContainer = ({ mode }) => {
    const isStart = mode === 'start';
    const dateLots = isStart ? startDateLots : endDateLots;
    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          background: 'white',
          minWidth: 380,
          boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)',
          minHeight: 300,
        }}
      >
        <DatePicker
          selected={isStart ? startDate : endDate}
          startDate={startDate}
          endDate={endDate}
          onChange={(date) => {
            isStart ? handleChangeStart(date) : handleChangeEnd(date);
          }}
          locale="en-GB"
          showPopperArrow={false}
          // selectsRange
          minDate={!isStart && parseISO(moment(startDate).format())}
          maxDate={parseISO(moment().format())}
          inline
        />

        <div style={{ width: '100%', borderLeft: '1px solid #dee2e6' }}>
          <TimesTitle>Lots</TimesTitle>
          {statusStartLots === 'loading' ? (
            <Spinner color="secondary" size="sm" className="ml-1" />
          ) : !dateLots.length ? (
            <NoContent title=" ">No lots</NoContent>
          ) : (
            dateLots.map(({ lot, recipe }) => (
              <TimeCard
                key={lot.nr}
                onClick={() => {
                  isStart
                    ? setStartTime(new Date(lot.start.dt))
                    : setEndTime(new Date(lot.end.dt));
                  isStart
                    ? setIsStartDateInputOpen(!isStartDateInputOpen)
                    : setIsEndDateInputOpen(!isEndDateInputOpen);
                }}
              >
                <div className="label">
                  {lot.nr}, {recipe.name}
                </div>
                <div>
                  {moment(isStart ? lot.start.dt : lot.end.dt).format('HH:mm')}
                </div>
              </TimeCard>
            ))
          )}
        </div>
      </div>
    );
  };
  return (
    <StyledDateSelectionContainer>
      <StyledButton
        disabled={loading || disabled}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <FiCalendar />
        <span>
          {moment(selectedDates[0]).format('DD.MM.YYYY, HH:mm')} ~{' '}
          {endDate
            ? moment(selectedDates[1]).format('DD.MM.YYYY, HH:mm')
            : 'Pick a date'}
        </span>
        {loading && <Spinner color="secondary" size="sm" className="ml-1" />}
      </StyledButton>
      <StyledDropdown isOpen={isCollapsed} className="p-3 bg-white shadow">
        <Button
          close
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="ml-5"
        />
        <StyledDropdownContent>
          <div className="pl-0">
            From
            <br />
            <StyledDateInput
              calendarContainer={(children, ...props) =>
                calendarContainer({ mode: 'start', children, props })
              }
              locale="en-GB"
              selected={startTime}
              showTimeSelect
              open={isStartDateInputOpen}
              onClickOutside={() => setIsStartDateInputOpen(false)}
              onInputClick={() =>
                setIsStartDateInputOpen(!isStartDateInputOpen)
              }
              onChange={(date) => setStartTime(date)}
              dateFormat="dd.MM.yyyy, HH:mm"
            />
            <br />
            To
            <br />
            <StyledDateInput
              calendarContainer={() => calendarContainer({ mode: 'end' })}
              locale="en-GB"
              showTimeSelect
              selected={endTime}
              open={isEndDateInputOpen}
              onClickOutside={() => setIsEndDateInputOpen(false)}
              onInputClick={() => setIsEndDateInputOpen(!isEndDateInputOpen)}
              onChange={(date) => setEndTime(date)}
              dateFormat="dd.MM.yyyy, HH:mm"
            />
            <StyledButton
              smaller
              color="primary"
              onClick={() => {
                applyDates();
                setIsCollapsed(!isCollapsed);
              }}
              className="mt-2"
            >
              Apply Time Range
            </StyledButton>
          </div>
        </StyledDropdownContent>
      </StyledDropdown>
    </StyledDateSelectionContainer>
  );
};

export default DateSelection;
