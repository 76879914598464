import React, { useState, useEffect, useContext } from 'react';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import { Card, CardBody } from 'reactstrap';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/chart/custom.js';
import handGearIcon from '../../../media/hand-gear-white.svg';

import playIcon from '../../../media/play-solid.svg';
import stopIcon from '../../../media/stop-solid.svg';
import exclamationIcon from '../../../media/exclamation-solid.svg';
import { ThemeContext } from 'styled-components';
import { useStore } from '../../../store';
import uniq from 'lodash/uniq';
import 'firebase/firestore';
import 'firebase/database';
import { useDatabase, useDatabaseListData } from 'reactfire';

const LotCurrentStatusNew = () => {
  const [segmentsChartData, setSegmentsChartData] = useState([]);
  const [piecesChartData, setPiecesChartData] = useState([]);
  const [lotsChartData, setLotsChartData] = useState([]);

  const themeContext = useContext(ThemeContext);

  const DIVIDER_SEGMENT_POSITION = [0, 0.09];
  const ENTRY_SEGMENT_POSITION = [
    DIVIDER_SEGMENT_POSITION[1],
    0.09 + DIVIDER_SEGMENT_POSITION[1],
  ];
  const MAIN_SEGMENT_POSITION = [
    0.09 + DIVIDER_SEGMENT_POSITION[1],
    0.7866 + DIVIDER_SEGMENT_POSITION[1],
  ];
  const OVEN_SEGMENT_POSITION = [
    0.7866 + DIVIDER_SEGMENT_POSITION[1],
    1 + DIVIDER_SEGMENT_POSITION[1],
  ];

  const { state } = useStore();
  const DIVIDER_SERIAL_NUMBER = 12086;
  const [newPieces, setNewPieces] = useState([]);
  const DEFAULT_SEGMENTS_DATA = [
    {
      n: 'divider',
      id: DIVIDER_SERIAL_NUMBER,
      x1: DIVIDER_SEGMENT_POSITION[0],
      x2: DIVIDER_SEGMENT_POSITION[1],
    },
    {
      n: 'entry',
      id: 1,
      x1: ENTRY_SEGMENT_POSITION[0],
      x2: ENTRY_SEGMENT_POSITION[1],
    },
    {
      n: 'main',
      id: 2,
      x1: MAIN_SEGMENT_POSITION[0],
      x2: MAIN_SEGMENT_POSITION[1],
    },
    {
      n: 'oven',
      id: 3,
      x1: OVEN_SEGMENT_POSITION[0],
      x2: OVEN_SEGMENT_POSITION[1],
    },
  ];

  const [newSegments, setNewSegments] = useState(DEFAULT_SEGMENTS_DATA);

  const db = useDatabase();

  const lotsRef = db.ref('lotpos/parts');
  const lotsList = useDatabaseListData(lotsRef);
  const { data: lots } = lotsList;

  const segmentsRef = db.ref('lotpos/segments');
  const segmentsList = useDatabaseListData(segmentsRef);
  const { data: segments } = segmentsList;

  const machineStateRef = db.ref('machine_state');
  const machineStateList = useDatabaseListData(machineStateRef);
  const { data: machineState } = machineStateList;

  useEffect(() => {
    const pieces = editPiecesData(lots);
    setNewPieces(pieces);
    const segmentsOrg = reorganizeData(segments);
    setNewSegments(segmentsOrg);
  }, [lots, segments]);

  useEffect(() => {
    const segments = handleSegmentStates(newSegments);
    setNewSegments(segments);
  }, [machineState]);

  const reorganizeData = (data = {}) => {
    let arr = Object.entries(data).map((item) => {
      const { x1, x2, ...rest } = item[1];
      return {
        x1: Number(x1) + DIVIDER_SEGMENT_POSITION[1],
        x2: Number(x2) + DIVIDER_SEGMENT_POSITION[1],
        ...rest,
      };
    });
    arr.unshift({
      n: 'divider',
      id: DIVIDER_SERIAL_NUMBER,
      x1: DIVIDER_SEGMENT_POSITION[0],
      x2: DIVIDER_SEGMENT_POSITION[1],
    });

    return arr;
  };
  const editPiecesData = (data = []) => {
    const pieces = data.map((item) => {
      const { x1, x2, ...rest } = item;
      return {
        x1: Number(x1) + DIVIDER_SEGMENT_POSITION[1],
        x2: Number(x2) + DIVIDER_SEGMENT_POSITION[1],
        ...rest,
      };
    });
    return pieces;
  };

  useEffect(() => {
    newSegments && generateSegmentData(newSegments);
    newPieces && generatePiecesData(newPieces);
  }, [state.selectedProject, state.machinesState, newPieces]);

  const handleSegmentStates = (data) => {
    if (!machineState) return null;
    const segments = data.map((item, index) => {
      const segmentState =
        index === 0
          ? machineState.find(
              (item) => Number(item.machine_id) === DIVIDER_SERIAL_NUMBER
            )
          : machineState.find((item) => Number(item.machine_id) === index);

      return { ...item, state: segmentState };
    });
    return segments;
  };
  const generateSegmentData = () => {
    const segments = newSegments.map((item, index) => {
      const segmentState = machineState?.find(
        (state) => state.machine_id === item.id
      );
      return {
        statuses: {
          auto: segmentState?.machine_state === 'AUTO',
          manual: segmentState?.machine_state === 'MANUAL',
          off: segmentState?.machine_state === 'STOP',
          alarm: segmentState?.has_error === 'true',
        },
        isRunning: item?.seg_running === 't',
        name: item.n.toUpperCase(),
        value: [Number(item.x1), Number(item.x2)],
        itemStyle: {
          normal: {
            color: themeContext.colors.main.success._100,
            borderColor: '#fff',
            borderWidth: 3,
          },
        },
      };
    });
    setSegmentsChartData(segments);
  };
  const generateLotsData = () => {
    const lots = uniq(newPieces.map((item) => item.l));
    const lotsCoords = lots.map((lot) => {
      const coords = newPieces.filter((item) => item.l === lot);
      const min = Math.min(...coords.map((i) => Number(i.x1)));
      const max = Math.max(...coords.map((i) => Number(i.x2)));
      const name = newPieces.find((item) => item.l === lot).rn;
      return { name, x1: min, x2: max };
    });
    const data = lotsCoords.map((item) => {
      return {
        name: item.name,
        value: [Number(item.x1), Number(item.x2)],
        itemStyle: {
          normal: {
            borderColor: themeContext.colors.grayscale._70, //"#fff",
            borderWidth: 3,
            color: '#0000',
          },
        },
      };
    });

    return data;
  };
  const generatePiecesData = (lotData) => {
    const pieces = newPieces.map((item) => {
      const colorIndex = Number(item.l) % themeContext.colors.other.length;
      return {
        name: '',
        value: [Number(item.x1), Number(item.x2)],
        itemStyle: {
          normal: {
            color: themeContext.colors.other[colorIndex]._60, //main.secondary._100,
          },
        },
      };
    });

    const lots = generateLotsData();

    setPiecesChartData(pieces);
    setLotsChartData(lots);
  };

  function renderPieces(params, api) {
    var start = api.coord([api.value(0)]);
    var end = api.coord([api.value(1)]);
    var height = api.size([0, 1])[1];
    var rectShape = echarts.graphic.clipRectByRect(
      {
        x: start[0],
        y: start[1] - 25,
        width: end[0] - start[0],
        height: height,
      },
      {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height - 6,
      }
    );

    return (
      rectShape && {
        type: 'group',
        z2: 3,
        children: [
          {
            type: 'rect',
            shape: { ...rectShape },
            style: api.style(),
          },
        ],
      }
    );
  }
  function renderLots(params, api) {
    var start = api.coord([api.value(0)]);
    var end = api.coord([api.value(1)]);
    var height = api.size([0, 1])[1];
    var rectShape = echarts.graphic.clipRectByRect(
      {
        x: start[0],
        y: start[1] - 25,
        width: end[0] - start[0],
        height: height,
      },
      {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height - 6,
      }
    );

    return (
      rectShape && {
        type: 'group',
        z2: 3,
        children: [
          {
            type: 'rect',
            shape: { ...rectShape, r: [5, 5, 0, 0] },
            style: api.style(),
          },
          {
            type: 'text',
            style: {
              text: lotsChartData[params.dataIndex]?.name,
              textFont: api.font({ fontSize: 14, fontWeight: 'bold' }),
              textAlign: 'left',
              textVerticalAlign: 'bottom',
            },
            position: [start[0], start[1] - 28],
          },
        ],
      }
    );
  }
  function renderSegmentModeSwitcher2(params, api) {
    var start = api.coord([api.value(0)]);
    return {
      type: 'group',
      children: [
        {
          type: 'image',
          z2: 2,
          style: {
            image: playIcon,
            height: 13,
          },
          position: [start[0] + 17, 44],
          // position: [(start[0] + 13), 42],
        },
        {
          type: 'circle',
          z2: 1,
          shape: {
            cx: start[0] + 22,
            cy: 50,
            r: 12,
          },
          style: {
            fill: segmentsChartData[params.dataIndex].statuses.auto
              ? themeContext.colors.main.success._100
              : '#0000001A',
          },
        },
        {
          type: 'image',
          z2: 2,
          style: {
            image: stopIcon,
            height: 13,
          },
          position: [start[0] + 44, 44],
          //position: [(start[0] + 113), 42],
        },
        {
          type: 'circle',
          z2: 1,
          shape: {
            cx: start[0] + 50,
            cy: 50,
            r: 12,
          },
          style: {
            fill: segmentsChartData[params.dataIndex].statuses.off
              ? themeContext.colors.main.warning._100
              : '#0000001A',
          },
        },
        {
          type: 'image',
          z2: 2,
          style: {
            image: exclamationIcon,
            height: 13,
          },
          position: [start[0] + 72, 44],
        },
        {
          type: 'circle',
          z2: 1,
          shape: {
            cx: start[0] + 78,
            cy: 50,
            r: 12,
          },
          style: {
            fill: segmentsChartData[params.dataIndex].statuses.alarm
              ? themeContext.colors.main.danger._100
              : '#0000001A',
          },
        },
        {
          type: 'image',
          z2: 2,
          style: {
            image: handGearIcon,
            height: 18,
          },
          position: [start[0] + 97, 42],
          //position: [(start[0] + 62), 42],
        },
        {
          type: 'circle',
          z2: 1,
          shape: {
            cx: start[0] + 106,
            cy: 50,
            r: 12,
          },
          style: {
            fill: segmentsChartData[params.dataIndex].statuses.manual
              ? themeContext.colors.main.success._100
              : '#0000001A',
          },
        },
      ],
    };
  }

  const renderSegmentRunningStripe = (params, api) => {
    var start = api.coord([api.value(0)]);
    var end = api.coord([api.value(1)]);
    const statusColor = themeContext.colors.grayscale._30;
    //const statusColor = isRunning ? colorPalette[0] : colorPalette[1]

    return {
      type: 'rect',
      style: { fill: statusColor },
      shape: {
        x: start[0],
        y: start[1] + 105,
        width: end[0] - start[0],
        height: 30,
      },
    };
  };

  function renderParts(params, api) {
    var start = api.coord([api.value(0)]);
    var end = api.coord([api.value(1)]);
    var height = api.size([0, 1])[1];
    var rectShape = echarts.graphic.clipRectByRect(
      {
        x: start[0],
        y: start[1] - height / 2,
        width: end[0] - start[0],
        height: height,
      },
      {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height,
      }
    );

    const segmentStroke = {
      stroke: themeContext.colors.grayscale._30,
      fill: themeContext.colors.grayscale._20,
    };

    return (
      rectShape && {
        type: 'group',
        //z2: 4,
        children: [
          {
            type: 'rect',
            shape: rectShape,
            silent: true,
            style: { ...api.style(), ...segmentStroke, lineWidth: 10 },
          },
          {
            type: 'text',
            style: {
              text: segmentsChartData[params.dataIndex].name,
              textFont: api.font({ fontSize: 14, fontWeight: 'bold' }),
              textAlign: 'left',
              textVerticalAlign: 'bottom',
            },
            position: [start[0], 25],
          },
        ],
      }
    );
  }

  function loopOther(data) {
    return data.map((item) => {
      return `<span>${item.label}: ${item.value}</span> <br/>`;
    });
  }

  function renderTooltip(params) {
    if (!params.data.details) return null;
    const { status, mode, other } = params.data.details;

    return `
        <strong>1. ${status}</strong><br/>
        <strong>2.${mode}</strong><br />
        ${loopOther(other)}
        `;
  }

  const option = {
    tooltip: {
      formatter: (params) => renderTooltip(params),
    },
    grid: {
      top: 30,
      bottom: 0,
      left: 0,
      right: 0,
    },
    xAxis: {
      scale: true,
      show: false,
      max: function (value) {
        const max = segmentsChartData.length
          ? segmentsChartData[segmentsChartData.length - 1].value[1]
          : value.max;
        return max;
      },
    },
    yAxis: {
      data: [''],
      show: false,
    },
    series: [
      {
        z2: 1,
        type: 'custom',
        renderItem: renderParts,
        data: segmentsChartData,
        silent: true, // comment this out for events
      },
      {
        type: 'custom',
        renderItem: renderSegmentModeSwitcher2,
        data: segmentsChartData,
        silent: true, // comment this out for events
      },
      {
        type: 'custom',
        renderItem: renderSegmentRunningStripe,
        data: segmentsChartData,
        silent: true,
      },
      {
        type: 'custom',
        renderItem: renderPieces,
        data: piecesChartData,
        silent: true,
      },
      {
        type: 'custom',
        renderItem: renderLots,
        data: lotsChartData,
        silent: true,
      },
    ],
  };
  return (
    <Card className="mb-3" style={{ overflow: 'auto' }}>
      {/* <CardHeader>Experimental - Cloud</CardHeader> */}
      <CardBody style={{ minWidth: 1500 }}>
        <ReactEchartsCore
          echarts={echarts}
          option={option}
          style={{ height: 200 }}
        />
      </CardBody>
    </Card>
  );
};

export default LotCurrentStatusNew;
