import React, { useState } from 'react';
import InstructionEditor from './InstractionEditor';
import { Button } from 'reactstrap';
import ModalTemplate from '../ModalTemplate';

const Instructions = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <React.Fragment>
      <Button
        disabled={!data}
        className="ml-2"
        outline
        size="sm"
        onClick={toggle}
      >
        Instructions
      </Button>
      {!!data && (
        <ModalTemplate
          isOpen={isOpen}
          toggle={toggle}
          title="Instructions"
          breadcrumbs={data.breadcrumb}
        >
          <InstructionEditor data={data} />
        </ModalTemplate>
      )}
    </React.Fragment>
  );
};

export default Instructions;
