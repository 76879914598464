import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import FinishedLotsTable2 from '../partials/FinishedLotsTable2';
import CurrentRecipeNew from '../partials/CurrentRecipeNew';
import ActiveAlarms from '../partials/ActiveAlarms';
import AvailabilityTimeline2 from '../partials/AvailabilityTimeline2';
import Skeleton from 'react-loading-skeleton';
import LotTimelineNew from '../partials/LotTimelineNew';
import BlurOverlay from '../../../components/BlurOverlay';
import LotCurrentStatusNew from '../partials/LotCurrentStatusNew';

import 'firebase/firestore';
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocDataOnce,
} from 'reactfire';
import { useStore } from '../../../store';

const Dashboard = () => {
  const [currentRecipe, setCurrentRecipe] = useState({});
  const [alarms, setAlarms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { dispatch } = useStore();

  const lotsRef = useFirestore()
    .collection('lot_history')
    // .where('lot.start.ts', '>=', allDatesSelected[0].setHours(0, 0, 0, 0))
    // .where('lot.start.ts', '<=', allDatesSelected[1].setHours(23, 59, 59, 999));
    .where('lot.start.ts', '>=', new Date().setHours(0, 0, 0, 0))
    .where('lot.start.ts', '<=', new Date().setHours(23, 59, 59, 999));
  const { data: cloudData } = useFirestoreCollectionData(lotsRef);
  const projectRef = useFirestore().collection('project').doc('0');
  const { data: projectData } = useFirestoreDocDataOnce(projectRef);
  useEffect(() => {
    if (!cloudData) return;
    const result = cloudData.filter(
      (item) => item.counters.divider.pieces.total >= 10
    );
    setData(result);
  }, [cloudData]);

  const fetchData = async () => {
    const resultAlarms2 = await getAlarms2();
    setAlarms(resultAlarms2);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    dispatch({ type: 'SET_SELECTED_PROJECT', payload: projectData });
    const interval = setInterval(() => {
      if (loading) return;
      fetchData();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [projectData]);

  const getAlarms2 = async () => {
    if (!projectData) return [];
    const params = {
      iproject_id: projectData?.project_id,
      imodel_nr: projectData?.project_id,
      iserial_nr: projectData?.project_id,
    };
    return fetch(process.env.REACT_APP_PSQL_PROC, {
      method: 'POST',
      body: JSON.stringify({
        reqType: 'select',
        funcName:
          params.iproject_id === 'T02606'
            ? 'project.mas_active_alarms'
            : 'project.mas_active_alarms_v2',
        inputParameters: params,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => data || [])
      .catch((err) => []);
  };

  return (
    <Container fluid>
      {/* FIXME: overlay doesn't work on currentRecipe, because it doesn't exists anymore. should get machine state from firebase */}
      <BlurOverlay
        text={currentRecipe?.machine_state}
        activate={
          currentRecipe?.machine_state === 'LINE IS CURRENTLY NOT RUNNING'
        }
        styles={{
          paddingTop: '20vh',
          alignItems: 'flex-start',
        }}
      >
        <Row className="mt-4" width="100%">
          <Col>
            {loading ? (
              <Skeleton height={236} className="mb-4" />
            ) : (
              <CurrentRecipeNew />
            )}
            {loading ? (
              <Skeleton height={341} className="mb-4" />
            ) : (
              <LotCurrentStatusNew />
            )}
            {!!alarms.length && <ActiveAlarms data={{ alarms }} />}
            {loading ? (
              <Skeleton height={148} className="mb-4" />
            ) : (
              <AvailabilityTimeline2
                data={{ loading }}
                zoom
                dataNew={data}
                gridLeft={100}
              />
            )}
            {loading ? (
              <Skeleton height={148} className="mb-4" />
            ) : (
              <>
                <LotTimelineNew
                  data={data[data.length - 1]}
                  loading={loading}
                />
              </>
            )}
            {loading ? (
              <Skeleton height={300} className="mb-4" />
            ) : (
              <FinishedLotsTable2 cloudData={data} />
            )}
          </Col>
        </Row>
      </BlurOverlay>
    </Container>
  );
};

export default Dashboard;
