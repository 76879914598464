import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const ModalTemplate = ({ isOpen, toggle, breadcrumbs, title, children }) => {
  return (
    <Modal
      autoFocus={false}
      fade={false}
      isOpen={isOpen}
      contentClassName="shadow-lg"
      size="xl"
    >
      <ModalHeader toggle={toggle}>
        <div className="small text-muted">{breadcrumbs.join(' / ')}</div>
        <div>{title}</div>
      </ModalHeader>
      <ModalBody className="p-0">{children}</ModalBody>
    </Modal>
  );
};

export default ModalTemplate;
