import React from 'react';
import { Button } from 'reactstrap';
import { FiPlusCircle, FiEdit, FiCopy, FiTrash2 } from 'react-icons/fi';
import TooltipWrapper from '../../components/TooltipWrapper';

const ActionButtons = ({
  handleCreate,
  handleEdit,
  handleDuplicate,
  handleDelete,
  isDisabled,
  children,
}) => (
  <React.Fragment>
    <div className="bg-light py-2">
      {handleCreate && <CreateButton action={handleCreate} />}
      {handleEdit && <EditButton action={handleEdit} isDisabled={isDisabled} />}
      {handleDuplicate && <DuplicateButton action={handleDuplicate} />}
      {handleDelete && (
        <DeleteButton action={handleDelete} isDisabled={isDisabled} />
      )}
      {children && !isDisabled && (
        <span className="ml-3 border-left"> {children}</span>
      )}
    </div>
  </React.Fragment>
);

const CreateButton = ({ action }) => (
  <TooltipWrapper content="Create new">
    <Button
      outline
      size="sm"
      color="success"
      className="ml-3"
      onClick={() => action('create', {})}
    >
      <FiPlusCircle /> Create
    </Button>
  </TooltipWrapper>
);

const EditButton = ({ action, isDisabled }) => (
  <TooltipWrapper content="Edit">
    <Button
      disabled={isDisabled}
      outline
      size="sm"
      className="ml-2"
      onClick={() => action('edit')}
    >
      <FiEdit /> Edit
    </Button>
  </TooltipWrapper>
);

const DuplicateButton = ({ action, isDisabled }) => (
  <TooltipWrapper content="Duplicate - 🚧 coming soon 🚧">
    <Button disabled outline size="sm" className="ml-2" onClick={() => null}>
      <FiCopy /> Duplicate
    </Button>
  </TooltipWrapper>
);

const DeleteButton = ({ action, isDisabled }) => (
  <TooltipWrapper content="Delete">
    <Button
      outline
      color="danger"
      size="sm"
      disabled={isDisabled}
      className="ml-2"
      onClick={action}
    >
      <FiTrash2 /> Delete
    </Button>
  </TooltipWrapper>
);

export default ActionButtons;
