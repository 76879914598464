import { DateTime } from 'luxon';
import React from 'react';
import { MethodBadges } from './AgendaItem';
import { generateLogTimes } from './helpers';
import classnames from 'classnames';

const LogItem = ({ data, eventSelect }) => {
  const logTimes = generateLogTimes(data);
  return (
    <div className="grid-row" onClick={() => eventSelect(data)}>
      <div className="grid-cell item-description">
        <div className="small muted">
          {data.machine.description} &gt; {data.assembly}
        </div>
        <div className="task-name">{data.task}</div>
        <div>
          <MethodBadges data={data.method} />
        </div>
      </div>
      <div className="grid-cell item-notes">{data.comment}</div>
      <div className="grid-cell item-times">
        {DateTime.fromMillis(logTimes.completedTimeTS).toFormat('dd.LL.yyyy')}
        <div
          className={classnames(
            logTimes.isOverdue ? 'muted-danger' : 'muted',
            'small'
          )}
        >
          {logTimes.dueDateTimeTS &&
            DateTime.fromMillis(logTimes.dueDateTimeTS).toFormat(
              'dd.LL.yyyy'
            )}{' '}
          {logTimes.isOverdue && `(${logTimes.overdue} days)`}
        </div>
        <div className="small muted">
          Every {data.frequency[0].interval} {data.frequency[0].unit}
        </div>
      </div>
    </div>
  );
};

export default LogItem;
