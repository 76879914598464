import React, { useState, useEffect } from 'react';
import Breadcrumbs from '../../../components/Breadcrumb/Breadcrumb';
import DateSelection from '../../../components/DateSelection';
import {
  Container,
  Col,
  Row,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap';
import { useStore } from '../../../store';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import moment from 'moment';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { getLastXDays } from '../../../lib/helpers';

const EnergyRoelandt = (props) => {
  const { state, dispatch } = useStore();
  const [, setData] = useState(state.energy.data || []);
  const [, setError] = useState(false);
  const [dates, setDates] = useState(
    state.energy.dates || [getLastXDays(6), getLastXDays(0)]
  );
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState(state.energy.filtered || []);
  const [consumption, setConsumption] = useState(
    state.energy.consumption || {
      dates: [],
      divider: [],
      main: [],
      oven: [],
      gas: [],
      pieces: [],
      mass: [],
    }
  );
  const [piecesAndMass, setPiecesAndMass] = useState(
    state.energy.piecesAndMass || {
      piecesGood: [],
      piecesRejected: [],
      massGood: [],
      massRejected: [],
    }
  );

  const [oee, setOee] = useState(
    state.energy.oee || {
      availability: [],
      performance: [],
      quality: [],
      oee: [],
    }
  );

  useEffect(() => {
    if (!_.isEmpty(state.energy)) return;
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const resultReports = await getReports();
    setData(resultReports);

    const filtered = resultReports
      .filter((item) => !item.recipe)
      .sort((a, b) => a.unix_shift_start - b.unix_shift_start);
    setFiltered(filtered);

    const consumption = {
      dates: filtered.map((item) =>
        moment(item.data_start).format('ddd, DD.MM')
      ),
      divider: filtered.map((item) => Number(item.ol_total)),
      main: filtered.map((item) => Number(item.fkp_total)),
      oven: filtered.map((item) => Number(item.top_total)),
      elTotalSum: filtered
        .map(
          (item) =>
            Number(item.top_total) +
            Number(item.fkp_total) +
            Number(item.ol_total)
        )
        .reduce((a, b) => a + b, 0),
      gas: filtered.map((item) => Number(item.top_gas)),
      gasTotalSum: filtered
        .map((item) => Number(item.top_gas))
        .reduce((a, b) => a + b, 0),
    };
    setConsumption(consumption);

    const piecesAndMass = {
      pieces: filtered.map((item) => Number(item.total_pieces)),
      piecesRejected: filtered.map((item) => Number(item.reject_pieces)),
      piecesGood: filtered.map((item) => Number(item.good_pieces)),
      piecesRejectedSum: filtered
        .map((item) => Number(item.reject_pieces))
        .reduce((a, b) => a + b, 0),
      piecesTotalSum: filtered
        .map((item) => Number(item.total_pieces))
        .reduce((a, b) => a + b, 0),
      mass: filtered.map(
        (item) =>
          Number(item.good_pieces_mass) + Number(item.reject_pieces_mass)
      ),
      massRejected: filtered.map((item) =>
        Math.floor(Number(item.reject_pieces_mass) / 1000)
      ),
      massGood: filtered.map((item) =>
        Math.floor(Number(item.good_pieces_mass) / 1000)
      ),
      massRejectedSum: filtered
        .map((item) => Math.floor(Number(item.reject_pieces_mass) / 1000))
        .reduce((a, b) => a + b, 0),
      massTotalSum: filtered
        .map(
          (item) =>
            Number(item.good_pieces_mass) + Number(item.reject_pieces_mass)
        )
        .reduce((a, b) => a + b, 0),
    };
    setPiecesAndMass(piecesAndMass);

    const oee = {
      oee: filtered.map((item) => Math.floor(Number(item.oee))),
      availability: filtered.map((item) =>
        Math.floor(Number(item.availability))
      ),
      performance: filtered.map((item) => Math.floor(Number(item.performance))),
      quality: filtered.map((item) => Math.floor(Number(item.quality))),
    };
    setOee(oee);

    const res = {
      data: resultReports,
      filtered,
      consumption,
      piecesAndMass,
      oee,
    };
    dispatch({ type: 'SET_DATA_ENERGY_MODULE', payload: res });
    setLoading(false);
  };

  const getReports = async () => {
    const params = { iproject_id: state.selectedProject.project_id, days: 7 };
    return fetch(process.env.REACT_APP_PSQL_PROC, {
      method: 'POST',
      body: JSON.stringify({
        reqType: 'select',
        funcName: 'project.production_report_s',
        inputParameters: params,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        return data || [];
      })
      .catch((err) => {
        console.error(err);
        setError(true);
        return [];
      });
  };

  const breadcrumbItems = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Energy',
      link: '',
    },
  ];
  const optionElectricity = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: [
      {
        type: 'category',
        data: consumption.dates,
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: '{value} kWh',
        },
      },
    ],
    legend: {
      data: ['Divider', 'Main', 'Oven', 'Total'],
    },
    series: [
      {
        name: 'Divider',
        type: 'bar',
        stack: '1',
        itemStyle: {
          color: '#FFF1CF',
        },
        data: consumption.divider,
      },
      {
        name: 'Main',
        type: 'bar',
        stack: '1',
        itemStyle: {
          color: '#D0B4A0',
        },
        data: consumption.main,
      },
      {
        name: 'Oven',
        type: 'bar',
        stack: '1',
        itemStyle: {
          color: '#BD967F',
        },
        data: consumption.oven,
      },
      {
        name: 'Total',
        type: 'line',
        label: {
          show: true,
          position: 'top',
        },
        itemStyle: {
          color: '#96604F',
        },
        data: consumption.divider.map(
          (item, index) =>
            item + consumption.main[index] + consumption.oven[index]
        ),
      },
    ],
  };
  const optionGas = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: [
      {
        type: 'category',
        data: consumption.dates,
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: '{value} m^3',
        },
      },
    ],
    series: [
      {
        name: 'Gas',
        type: 'bar',
        data: consumption.gas,
        itemStyle: {
          color: '#BD967F',
        },
        label: {
          show: true,
          position: 'top',
        },
      },
    ],
  };

  const optionOee = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: [
      {
        type: 'category',
        data: consumption.dates,
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: '{value}%',
        },
      },
    ],
    legend: {
      data: ['Availability', 'Performance', 'Quality', 'OEE'],
    },
    series: [
      {
        name: 'Availability',
        type: 'bar',
        data: oee.availability,
        itemStyle: {
          color: '#FFF1CF',
        },
      },
      {
        name: 'Performance',
        type: 'bar',
        data: oee.performance,
        itemStyle: {
          color: '#D0B4A0',
        },
      },
      {
        name: 'Quality',
        type: 'bar',
        data: oee.quality,
        itemStyle: {
          color: '#BD967F',
        },
      },
      {
        name: 'OEE',
        type: 'line',
        data: oee.oee,
        itemStyle: {
          color: '#96604F',
        },
        label: {
          show: true,
          position: 'top',
        },
      },
    ],
  };

  const optionPiecesMass = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: [
      {
        type: 'category',
        data: consumption.dates,
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Pieces',
      },
      {
        type: 'value',
        name: 'Mass',
        axisLabel: {
          formatter: '{value} kg',
        },
      },
    ],
    legend: {
      data: ['Good Pieces', 'Ejected Pieces', 'Good Mass', 'Ejected Mass'],
    },
    series: [
      {
        name: 'Good Pieces',
        type: 'bar',
        stack: '1',
        data: piecesAndMass.piecesGood,
        itemStyle: {
          color: '#FFF1CF',
        },
      },
      {
        name: 'Ejected Pieces',
        type: 'bar',
        stack: '1',
        data: piecesAndMass.piecesRejected,
        itemStyle: {
          color: 'rgba(223, 57, 78,  1)',
        },
      },
      {
        name: 'Good Mass',
        type: 'bar',
        stack: '2',
        yAxisIndex: 1,
        data: piecesAndMass.massGood,
        itemStyle: {
          color: '#D0B4A0',
        },
      },
      {
        name: 'Ejected Mass',
        type: 'bar',
        stack: '2',
        yAxisIndex: 1,
        data: piecesAndMass.massRejected,
        itemStyle: {
          color: 'rgba(223, 57, 78,  1)',
        },
      },
    ],
  };
  const handlePerPieces = () => {
    setConsumption({
      ...consumption,
      divider: filtered
        .map((item) => Number(item.ol_total))
        .map((item, index) =>
          piecesAndMass.pieces[index]
            ? (item / (piecesAndMass.pieces[index] / 1000)).toFixed(2)
            : 0
        ),
      main: filtered
        .map((item) => Number(item.fkp_total))
        .map((item, index) =>
          piecesAndMass.pieces[index]
            ? (item / (piecesAndMass.pieces[index] / 1000)).toFixed(2)
            : 0
        ),
      oven: filtered
        .map((item) => Number(item.top_total))
        .map((item, index) =>
          piecesAndMass.pieces[index]
            ? (item / (piecesAndMass.pieces[index] / 1000)).toFixed(2)
            : 0
        ),
      gas: filtered
        .map((item) => Number(item.top_gas))
        .map((item, index) =>
          piecesAndMass.pieces[index]
            ? (item / (piecesAndMass.pieces[index] / 1000)).toFixed(2)
            : 0
        ),
    });
  };
  const handlePerTonne = () => {
    setConsumption({
      ...consumption,
      divider: filtered
        .map((item) => Number(item.ol_total))
        .map((item, index) =>
          piecesAndMass.mass[index]
            ? (item / (piecesAndMass.mass[index] / 1000000)).toFixed(2)
            : 0
        ),
      main: filtered
        .map((item) => Number(item.fkp_total))
        .map((item, index) =>
          piecesAndMass.mass[index]
            ? (item / (piecesAndMass.mass[index] / 1000000)).toFixed(2)
            : 0
        ),
      oven: filtered
        .map((item) => Number(item.top_total))
        .map((item, index) =>
          piecesAndMass.mass[index]
            ? (item / (piecesAndMass.mass[index] / 1000000)).toFixed(2)
            : 0
        ),
      gas: filtered
        .map((item) => Number(item.top_gas))
        .map((item, index) =>
          piecesAndMass.mass[index]
            ? (item / (piecesAndMass.mass[index] / 1000000)).toFixed(2)
            : 0
        ),
    });
  };
  const handleTotal = () => {
    setConsumption({
      ...consumption,
      divider: filtered.map((item) => Number(item.ol_total)),
      main: filtered.map((item) => Number(item.fkp_total)),
      oven: filtered.map((item) => Number(item.top_total)),
      gas: filtered.map((item) => Number(item.top_gas)),
    });
  };

  const bodyStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    fontSize: '1.5em',
    fontWeight: 'bold',
    flexWrap: 'wrap',
    paddingTop: '.25rem',
    paddingBottom: '.25rem',
    // cursor: "pointer"
  };

  return (
    <React.Fragment>
      <Breadcrumbs items={breadcrumbItems} />
      <DateSelection
        range
        setDates={setDates}
        selectedDates={dates}
        loading={loading}
      />
      <Container fluid>
        <Row className="mt-3">
          <Col lg={4}>
            {loading ? (
              <Skeleton height={130} />
            ) : (
              <Card className="mb-3">
                <CardHeader className="d-flex justify-content-between pb-1">
                  Loss
                </CardHeader>
                <CardBody style={bodyStyle}>
                  <div>{piecesAndMass.massRejectedSum}kg</div>
                  <div>{piecesAndMass.piecesRejectedSum} pcs</div>
                </CardBody>
              </Card>
            )}
          </Col>
          <Col lg={4}>
            {loading ? (
              <Skeleton height={130} />
            ) : (
              <Card className="mb-3">
                <CardHeader className="d-flex justify-content-between pb-1">
                  El. consumption{' '}
                </CardHeader>
                <CardBody style={bodyStyle}>
                  <div>{Math.floor(consumption.elTotalSum)}kWh</div>
                  <div>
                    {Math.floor(
                      consumption.elTotalSum /
                        (piecesAndMass.massTotalSum / 1000000)
                    )}
                    kWh/t
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
          <Col lg={4}>
            {loading ? (
              <Skeleton height={130} />
            ) : (
              <Card className="mb-3">
                <CardHeader className="d-flex justify-content-between pb-1">
                  Gas consumption{' '}
                </CardHeader>
                <CardBody style={bodyStyle}>
                  <div>
                    {consumption.gasTotalSum}m<sup>3</sup>
                  </div>
                  <div>
                    {Math.floor(
                      consumption.gasTotalSum /
                        (piecesAndMass.massTotalSum / 1000000)
                    )}
                    m<sup>3</sup>/t
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
          <Col sm={12}>
            {loading ? (
              <Skeleton height={380} />
            ) : (
              <Card className="mb-3">
                <CardHeader>Oee</CardHeader>
                <CardBody className="p-0">
                  <ReactEchartsCore echarts={echarts} option={optionOee} />
                </CardBody>
              </Card>
            )}
          </Col>
          <Col sm={12}>
            {loading ? (
              <Skeleton height={380} />
            ) : (
              <Card className="mb-3">
                <CardHeader>Pieces and mass</CardHeader>
                <CardBody className="p-0">
                  <ReactEchartsCore
                    echarts={echarts}
                    option={optionPiecesMass}
                  />
                </CardBody>
              </Card>
            )}
          </Col>
          <Col sm={12}>
            {loading ? (
              <Skeleton height={380} />
            ) : (
              <Card className="mb-3">
                <CardHeader>Electricity</CardHeader>
                <CardBody className="p-0">
                  <Button
                    size="sm"
                    color="light"
                    onClick={handleTotal}
                    className="ml-3"
                  >
                    total
                  </Button>
                  <Button
                    size="sm"
                    color="light"
                    onClick={handlePerTonne}
                    className="ml-3"
                  >
                    per tonne
                  </Button>
                  <Button
                    size="sm"
                    color="light"
                    onClick={handlePerPieces}
                    className="ml-3"
                  >
                    per 1000 pieces
                  </Button>
                  <ReactEchartsCore
                    echarts={echarts}
                    option={optionElectricity}
                  />
                </CardBody>
              </Card>
            )}
          </Col>
          <Col sm={12}>
            {loading ? (
              <Skeleton height={380} />
            ) : (
              <Card className="mb-3">
                <CardHeader>Gas</CardHeader>
                <CardBody className="p-0">
                  <Button
                    size="sm"
                    color="light"
                    onClick={handleTotal}
                    className="ml-3"
                  >
                    total
                  </Button>
                  <Button
                    size="sm"
                    color="light"
                    onClick={handlePerTonne}
                    className="ml-3"
                  >
                    per tonne
                  </Button>
                  <Button
                    size="sm"
                    color="light"
                    onClick={handlePerPieces}
                    className="ml-3"
                  >
                    per 1000 pieces
                  </Button>
                  <ReactEchartsCore echarts={echarts} option={optionGas} />
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default EnergyRoelandt;
