import React, { useState, useEffect } from 'react';
import {
  getInstructions,
  insertInstruction,
  getInstructionSequence,
} from '../calls/instructions';
import Skeleton from 'react-loading-skeleton';
import { Button, ModalFooter } from 'reactstrap';
import { Editor } from '@tinymce/tinymce-react';
import { toast } from 'react-toastify';

const InstructionEditor = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const result = await getInstructions({
        imaintanance_id: data.selected.maintenance.machine_maintanance_id,
        itask_id: data.selected.maintenance.task_id,
      });
      setContent(
        result.instructions.length !== 0
          ? result.instructions[0].html_content
          : ''
      );
      setLoading(result.loading);
    };
    fetchData();
  }, []);

  const handleEditorChange = (content, editor) => {
    setContent(content);
  };

  var entityMap = {
    // '&': '&amp;',
    // '<': '&lt;',
    // '>': '&gt;',
    // '"': '&quot;',
    "'": '&#39;',
    // '/': '&#x2F;',
    // '`': '&#x60;',
    // '=': '&#x3D;'
  };
  /** Try implement encoding intinyMCE init */
  const escapeHtml = (string) =>
    String(string).replace(/[']/g, (s) => entityMap[s]);

  const handleSave = async () => {
    const resultSeq = await getInstructionSequence();
    if (resultSeq.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }

    const {
      productionLine: { project_id },
      machine: { model_nr, serial_nr },
      assembly: { assembly_id },
      maintenance: { machine_maintanance_id: maintanance_id, task_id },
    } = data.selected;

    const params = {
      instruction_id: resultSeq,
      maintanance_id,
      project_id,
      model_nr,
      serial_nr,
      assembly_id,
      task_id,
      content_version: +new Date(),
      html_content: escapeHtml(content),
    };

    const result = await insertInstruction(params);
    if (result.error) {
      toast.error('Something went wrong. Try again.');
      return;
    }
    toast.success('Instruction created successfully.');
  };
  return loading ? (
    <Skeleton height={200} />
  ) : (
    <React.Fragment>
      <Editor
        initialValue={content}
        init={{
          height: 500,
          menubar: true,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
            'table',
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help ',
          /* enable title field in the Image dialog*/
          image_title: true,
          paste_data_images: true,
          /* enable automatic uploads of images represented by blob or data URIs*/
          automatic_uploads: false,
          /*
                URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                images_upload_url: 'postAcceptor.php',
                here we add custom filepicker only to Image dialog
              */
          file_picker_types: 'image',
          /** encode special characters within tags. CANT MAKE IT WORK */
          entity_encoding: 'raw',
          /* and here's our custom image picker*/
          file_picker_callback: function (cb, value, meta) {
            var input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            /*
                  Note: In modern browsers input[type="file"] is functional without
                  even adding it to the DOM, but that might not be the case in some older
                  or quirky browsers like IE, so you might want to add it to the DOM
                  just in case, and visually hide it. And do not forget do remove it
                  once you do not need it anymore.
                */

            input.onchange = function () {
              var file = this.files[0];

              var reader = new FileReader();
              reader.onload = function () {
                /*
                      Note: Now we need to register the blob in TinyMCEs image blob
                      registry. In the next release this part hopefully won't be
                      necessary, as we are looking to handle it internally.
                    */
                var id = 'blobid' + new Date().getTime();
                var blobCache =
                  window.tinymce.activeEditor.editorUpload.blobCache;
                var base64 = reader.result.split(',')[1];
                var blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);
                /* call the callback and populate the Title field with the file name */
                cb(blobInfo.blobUri(), { title: file.name });
              };
              reader.readAsDataURL(file);
            };

            input.click();
          },
        }}
        onEditorChange={handleEditorChange}
      />
      <ModalFooter>
        <Button disabled={!content} onClick={handleSave}>
          Save
        </Button>
      </ModalFooter>
    </React.Fragment>
  );
};

export default InstructionEditor;
