import moment from 'moment';

export function generateDurationString(seconds) {
  const duration = moment.duration(Number(seconds * 1000)); // has to be milliseconds
  let durationString = '';
  durationString += duration.years() > 0 ? `${duration.years()} years ` : '';
  durationString += duration.months() > 0 ? `${duration.months()} months ` : '';
  durationString += duration.days() > 0 ? `${duration.days()} days ` : '';
  durationString += `${duration.hours()}`;
  durationString += `:${('0' + duration.minutes()).slice(-2)}`; // ('0' + duration).slice(-2) : less than 10 add 0 to number
  durationString += `:${('0' + duration.seconds()).slice(-2)}`;
  return durationString;
}

export const generateDataForOEEPage = (data) => {
  const sums = {
    energy: summationEnergy(data),
    counters: summationCounters(data),
    durations: summationDuration(data),
    oee: summationOEE(data),
    times: summationTimes(data),
  };
  return sums;
};

export const concatAndSplitArraysFromJSON = (data, key) =>
  [].concat.apply(
    [],
    data.map((item) => item[key].split(','))
  );

export const generateArrayForChart = (data, date_ts, interval_ms) =>
  data.map((item, index) => [
    Number(date_ts) + index * Number(interval_ms),
    Number(item),
  ]);

const calculateOEE = (data, durations, key) => {
  const values = data.map((i) => i[key]);
  const durationSum = durations.reduce((a, b) => a + b, 0);
  const result = values
    .map((value, index) => (durations[index] / durationSum) * Number(value))
    .reduce((a, b) => a + b, 0);
  return Math.floor(result);
};

const summationTimes = (data) => {
  if (!data) return {};
  const stops = data.map((item) => item.run_times);
  const production = stops
    .map((item) => Number(item.production.ms))
    .reduce((a, b) => a + b, 0);
  const run = stops
    .map((item) => Number(item.run.ms))
    .reduce((a, b) => a + b, 0);
  const stop = stops
    .map((item) => Number(item.stop.ms))
    .reduce((a, b) => a + b, 0);
  const stop_planned = stops
    .map((item) => Number(item.stop_planned.ms))
    .reduce((a, b) => a + b, 0);
  const stop_unplanned = stops
    .map((item) => Number(item.stop_unplanned.ms))
    .reduce((a, b) => a + b, 0);

  return {
    production,
    run,
    stop,
    stop_planned,
    stop_unplanned,
  };
};

const summationOEE = (data) => {
  if (!data) return {};
  const OverallEE = data.map((item) => item.oee.divider);
  const durations = data.map((item) => item.lot.duration.ms);
  return {
    availability: calculateOEE(OverallEE, durations, 'availability'),
    oee: calculateOEE(OverallEE, durations, 'oee'),
    performance: calculateOEE(OverallEE, durations, 'performance'),
    quality: calculateOEE(OverallEE, durations, 'quality'),
  };
};

const summationDuration = (data) => {
  if (!data) return '-';
  const duration = data.map((item) => item.lot.duration);
  const ms = duration.map((item) => Number(item.ms)).reduce((a, b) => a + b, 0);

  return ms;
};
const summationCounters = (data) => {
  return {
    pieces: summationCountersPieces(data),
    mass_g: summationCountersMass(data),
  };
};
const summationCountersMass = (data) => {
  if (!data) return null;
  const counters = data.map((item) => item.counters.divider);

  const high = counters
    .map((item) => Number(item.mass_g.high))
    .reduce((a, b) => a + b, 0);
  const low = counters
    .map((item) => Number(item.mass_g.low))
    .reduce((a, b) => a + b, 0);
  const ok = counters
    .map((item) => Number(item.mass_g.ok))
    .reduce((a, b) => a + b, 0);
  const out = counters
    .map((item) => Number(item.mass_g.out))
    .reduce((a, b) => a + b, 0);
  const out_bad = counters
    .map((item) => Number(item.mass_g.out_bad))
    .reduce((a, b) => a + b, 0);
  const rejected = counters
    .map((item) => Number(item.mass_g.rejected))
    .reduce((a, b) => a + b, 0);
  const total = counters
    .map((item) => Number(item.mass_g.total))
    .reduce((a, b) => a + b, 0);
  const average_out = counters
    .map((item) => Number(item.mass_g.average_out))
    .reduce((a, b) => a + b, 0);

  return {
    high,
    low,
    ok,
    out,
    out_bad,
    rejected,
    total,
    average_out,
  };
};
const summationCountersPieces = (data) => {
  if (!data) return null;
  const counters = data.map((item) => item.counters.divider);

  const high = counters
    .map((item) => Number(item.pieces.high))
    .reduce((a, b) => a + b, 0);
  const low = counters
    .map((item) => Number(item.pieces.low))
    .reduce((a, b) => a + b, 0);
  const ok = counters
    .map((item) => Number(item.pieces.ok))
    .reduce((a, b) => a + b, 0);
  const out = counters
    .map((item) => Number(item.pieces.out))
    .reduce((a, b) => a + b, 0);
  const out_bad = counters
    .map((item) => Number(item.pieces.out_bad))
    .reduce((a, b) => a + b, 0);
  const rejected = counters
    .map((item) => Number(item.pieces.rejected))
    .reduce((a, b) => a + b, 0);
  const total = counters
    .map((item) => Number(item.pieces.total))
    .reduce((a, b) => a + b, 0);

  return {
    high,
    low,
    ok,
    out,
    out_bad,
    rejected,
    total,
  };
};

const summationEnergy = (data) => {
  if (!data) return;
  const energy = data.map((item) => item.energy);

  const compr_air_m3 = energy
    .map((item) => Number(item.compr_air_m3))
    .reduce((a, b) => a + b, 0);
  const entry_kwh = energy
    .map((item) => Number(item.entry_kwh))
    .reduce((a, b) => a + b, 0);
  const main_kwh = energy
    .map((item) => Number(item.main_kwh))
    .reduce((a, b) => a + b, 0);
  const mixers_kwh = energy
    .map((item) => Number(item.mixers_kwh))
    .reduce((a, b) => a + b, 0);
  const oven_gas_m3 = energy
    .map((item) => Number(item.oven_gas_m3))
    .reduce((a, b) => a + b, 0);
  const oven_kwh = energy
    .map((item) => Number(item.oven_kwh))
    .reduce((a, b) => a + b, 0);
  const recuperation_kwh = energy
    .map((item) => Number(item.recuperation_kwh))
    .reduce((a, b) => a + b, 0);
  const steam_main_m3 = energy
    .map((item) => Number(item.steam_main_m3))
    .reduce((a, b) => a + b, 0);
  const steam_oven_m3 = energy
    .map((item) => Number(item.steam_oven_m3))
    .reduce((a, b) => a + b, 0);

  return {
    compr_air_m3,
    entry_kwh,
    main_kwh,
    mixers_kwh,
    oven_gas_m3,
    oven_kwh,
    recuperation_kwh,
    steam_main_m3,
    steam_oven_m3,
  };
};
