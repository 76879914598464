export const getAssemblySequence = async () => {
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'sequence',
      funcName: 'project.project_machine_id_seq',
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => data.nextval);
};

export const getAssemblies = async ({ project_id, model_nr, serial_nr }) => {
  const params = {
    iproject_id: project_id,
    imodel_nr: model_nr,
    iserial_nr: serial_nr,
  };
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'select',
      funcName: 'project.machine_maintanance_assembly_s',
      inputParameters: params,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((response) => {
      const assemblies = response || [];
      return {
        assemblies,
        loading: false,
      };
    });
};

export const insertAssembly = async (data) => {
  const { action, selected, ...params } = data;
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'insert',
      funcName: 'project.machine_maintanance_assembly_i',
      inputParameters: params,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => ({
      error: err,
    }));
};

export const updateAssembly = async (data) => {
  const { action, selected, ...params } = data;
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'update',
      funcName: 'project.machine_maintanance_assembly_u',
      inputParameters: params,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => ({
      error: err,
    }));
};

export const deleteAssembly = async (data) => {
  const { action, selected, ...params } = data;
  return fetch(process.env.REACT_APP_PSQL_PROC, {
    method: 'POST',
    body: JSON.stringify({
      reqType: 'delete',
      funcName: 'project.machine_maintanance_assembly_d',
      inputParameters: params,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => ({
      error: err,
    }));
};
