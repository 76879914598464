import React, { useEffect, useState } from 'react';
//import {Chart} from 'react-google-charts';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import { Card, CardBody, CardHeader } from 'reactstrap';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/chart/custom.js';
import uniq from 'lodash/uniq';
import moment from 'moment';
import { generateDurationString } from '../../../helpers';
import NoContent from '../../../../../components/NoContent/NoContent';
import { getColorScheme } from '../../../../../lib/helpers';

const Timeline = ({ data, ...props }) => {
  const [events, setEvents] = useState({});
  const [categories, setCategories] = useState([]);
  const [zoomValues, setZoomValues] = useState({ start: 0, end: 100 });
  const colorScheme = getColorScheme();
  const setColor = (params, api) =>
    ({
      alarm: colorScheme.main.danger._100,
      warning: colorScheme.main.warning._100,
      info: colorScheme.main.info._100,
    }[params.value[4]] || '#6E7074');

  useEffect(() => {
    setEvents({
      datazoom: (params) => {
        const { start, end, dataZoomId } = params;
        // if there is no dataZoomId that means mouse wheel was used, which is "zoom type inside". ignore this action
        if (!dataZoomId) return;
        // only change zoomvalues when using yAxis zoom. use "2"(series 2) because in chart option.dataZoom is on 3rd place
        dataZoomId.indexOf('2') > -1 && setZoomValues({ start, end });
      },
    });
  }, []);

  useEffect(() => {
    const categories = getCategories();
    setCategories(categories);
  }, [data]);

  useEffect(() => {
    // 100% -(minus) getAppropriatePercentage(10) shows top 11 items instead of 10 for some reason, but 101 shows corretly 🙄
    const startPercentage = 101 - getAppropriatePercentage(10);
    setZoomValues({ start: startPercentage, end: 100 });
  }, [categories]);

  const getAppropriatePercentage = (numberOfItemsToShow) =>
    (100 * numberOfItemsToShow) / categories.length;

  const getCategories = () => uniq(data.map((item) => item[0]));
  const renderItem = (params, api) => {
    const categoryIndex = api.value(0);
    const start = api.coord([api.value(1), categoryIndex]);
    const end = api.coord([api.value(2), categoryIndex]);
    const height = api.size([0, 1])[1] * 0.6;

    const rectShape = echarts.graphic.clipRectByRect(
      {
        x: start[0],
        y: start[1] - height / 2,
        width: end[0] - start[0],
        height: height,
      },
      {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height,
      }
    );

    return (
      rectShape && {
        type: 'rect',
        shape: rectShape,
        style: api.style(),
      }
    );
  };

  const option = {
    tooltip: {
      formatter: function (params) {
        const start = moment(params.value[1]).format('DD.MM.YYYY HH:mm:ss');
        const end = moment(params.value[2]).format('DD.MM.YYYY HH:mm:ss');
        const duration = generateDurationString(params.value[3]);
        const type = params.value[4].toUpperCase();
        const machine = params.value[5];
        return `
                        <strong>[${type}]</strong><br/>
                        Machine: ${machine}<br/>
                        <strong>${params.name}</strong>
                        <p>
                            Start: ${start} <br />
                            End: ${end} <br />
                            Duration: ${duration}<br />
                        </p>`;
      },
    },
    dataZoom: [
      {
        type: 'slider',
        filterMode: 'weakFilter',
        showDataShadow: false,
        labelFormatter: '',
      },
      {
        type: 'inside',
        filterMode: 'weakFilter',
      },
      {
        type: 'slider',
        yAxisIndex: 0,
        right: 80,
        filterMode: 'weakFilter',
        labelFormatter: '',
        start: zoomValues.start,
        end: zoomValues.end,
      },
    ],
    grid: {
      containLabel: true,
      height: 380,
      left: 20,
    },
    xAxis: {
      scale: true,
      axisLabel: {
        formatter: function (val) {
          const date = moment(val).format('DD.MM.YYYY');
          const time = moment(val).format('HH:mm:ss');
          return `${date}\n${time}`;
        },
      },
    },
    yAxis: {
      data: categories, //.map(({alarm}) => alarm),
      axisTick: {
        alignWithLabel: true,
      },
      axisLabel: {
        //inside:true
        // show:false
        formatter: function (value, index) {
          const string = value; // data.find(item => item[0] === value)[5]
          let stripped = string.substring(0, 30);
          stripped += string.length > 30 ? '...' : '';
          return stripped;
        },
      },
    },
    series: [
      {
        type: 'custom',
        renderItem: renderItem,
        itemStyle: {
          normal: {
            opacity: 0.8,
            color: setColor,
          },
        },
        encode: {
          x: [1, 2],
          y: 0,
        },
        data: data,
      },
    ],
  };

  return !data.length ? (
    <NoContent style={{ height: '250px' }} title="No data">
      Try different date
    </NoContent>
  ) : (
    <ReactEchartsCore
      echarts={echarts}
      style={{ height: 480 }}
      onEvents={events}
      option={option}
    />
  );
  /* return (              
         <Chart
             width={'100%'}
             height={'600px'}
             chartType="Timeline"
             data={[columns, ...rows]}/>
         
     );*/
};

export default Timeline;
