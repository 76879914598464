import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

class Page500 extends Component {
  render() {
    return (
      <Container>
        <Row className="text-center">
          <Col>
            <span className="display-1 mr-4" style={{ color: '#de384d' }}>
              500
            </span>
            <h4 className="pt-3">Internal server error</h4>
            <p className="text-muted">
              The server encountered something unexpected that didn't allow it
              to complete the request. You can go{' '}
              <Link
                to={process.env.PUBLIC_URL + '/'}
                style={{ color: '#de384d', textDecoration: 'none' }}
              >
                back to app.
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Page500;
