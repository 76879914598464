import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Button,
} from 'reactstrap';

const SegmentForm = ({ toggle, isOpen, data, handleCreate, handleEdit }) => {
  const [segment, setSegment] = useState({});

  useEffect(() => {
    setSegment(data);
  }, [data]);

  const handleSubmitForm = () => {
    data.action === 'create' && handleCreate(segment);
    data.action === 'edit' && handleEdit(segment);
  };

  const handleCancelForm = () => {
    setSegment({});
    toggle();
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle}>
        {data.action === 'create'
          ? 'Create'
          : data.action === 'edit'
          ? 'Edit'
          : ''}{' '}
        segment
      </ModalHeader>
      <ModalBody>
        <Form autoComplete="off">
          {/*<FormGroup>
                        <Label for="segmentId">ID</Label>
                        <Input
                            disabled
                            type="text"
                            id="segmentId"
                            name="segment[id]"
                            value={segment.id} />
                    </FormGroup>*/}
          <FormGroup>
            <Label for="segmentPos">Ord.No.</Label>
            <Input
              type="number"
              id="segmentPos"
              name="segment[pos]"
              value={segment.pos}
              onChange={(e) =>
                setSegment({ ...segment, pos: Number(e.target.value) })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="Name">Name</Label>
            <Input
              type="text"
              id="Name"
              name="segment[name]"
              value={segment.name}
              onChange={(e) => setSegment({ ...segment, name: e.target.value })}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleCancelForm} color="light">
          Cancel
        </Button>
        <Button
          className="text-capitalize"
          onClick={handleSubmitForm}
          color="primary"
        >
          {data.action === 'edit' ? 'Confirm changes' : data.action}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SegmentForm;
