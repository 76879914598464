import React from 'react';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import moment, { duration } from 'moment';
import { Col, Row } from 'reactstrap';
import { generateDurationString } from '../../../Diagnostics/helpers';
import { getColorScheme } from '../../../../lib/helpers';

const Stops = ({ data }) => {
  const colorScheme = getColorScheme();
  const {
    stop_time_ms,
    stop_time_operator_ms,
    stop_time_other_ms,
    stops_with_reason_json,
    lot_time_ms,
    run_time_ms,

    production_time,
    operating_time,
    cleaning_time,
    waiting_time,
    prepare_time,
    minor_stops,
    planned_breaks,
    unplanned_stops,
  } = data;

  const valueStyle = {
    paddingLeft: '2em',
    fontWeight: 'bold',
    textAlign: 'right',
  };

  const optionOperating = {
    tooltip: {
      trigger: 'item',
      formatter: (params) =>
        `${params.name}: ${params.data.label} (${params.percent}%)`,
    },
    legend: {
      orient: 'vertical',
      left: 0,
      top: 25,
      data: ['Running', 'Stop', 'Operator Stops', 'Alarm Stops'],
    },
    series: [
      {
        name: 'Operating time',
        type: 'pie',
        radius: ['45%', '60%'],
        data: [
          {
            label: 'Running',
            value: run_time_ms,
            name: 'Running',
            itemStyle: {
              color: colorScheme.main.success._100,
            },
          },
          {
            label: 'Stop',
            value: stop_time_ms,
            name: 'Stop',
            itemStyle: {
              color: colorScheme.main.warning._100,
            },
          },
        ],
        left: 100,
        silent: true,
        height: 150,
        labelLine: {
          show: false,
        },
        label: {
          show: false,
        },
      },
      {
        name: 'operating time',
        type: 'pie',
        radius: [0, '40%'],
        data: [
          {
            label: 'Operator Stops',
            value: stop_time_operator_ms,
            name: 'Operator Stops',
            itemStyle: {
              color: colorScheme.main.warning._50,
            },
          },
          {
            label: 'Alarm Stops',
            value: stop_time_other_ms,
            name: 'Alarm Stops',
            itemStyle: {
              color: colorScheme.main.danger._100,
            },
          },
        ],
        left: 100,
        silent: true,
        height: 150,
        labelLine: {
          show: false,
        },
        label: {
          show: false,
        },
      },
    ],
  };
  const optionStops = {
    tooltip: {
      trigger: 'item',
      formatter: (params) =>
        `${params.name}: ${params.data.label} (${params.percent}%)`,
    },
    legend: {
      orient: 'vertical',
      left: 0,
      data: JSON.parse(stops_with_reason_json || '[]').map(
        (item) => item.stop_reason_name || 'Undefined'
      ),
    },
    series: [
      {
        name: 'Stop',
        type: 'pie',
        data: JSON.parse(stops_with_reason_json || '[]').map((item) => ({
          label: item.stop_reason_name || 'Undefined',
          name: item.stop_reason_name || 'Undefined',
          value: item.duration || 0,
          itemStyle: {
            color: item.stop_reason_color || 'rgba(223, 57, 78,  1)',
          },
        })),
        left: 100,
        silent: true,
        height: 150,
        labelLine: {
          show: false,
        },
        label: {
          show: false,
        },
      },
    ],
  };
  return (
    <React.Fragment>
      <div className="mb-1 border-top font-weight-bold">Production times</div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <div style={{ minWidth: 250, maxWidth: '25%', width: '100%' }}>
          <div className="d-flex justify-content-between">
            <span>Lot Duration</span>
            <span style={valueStyle}>
              {generateDurationString(lot_time_ms)}
            </span>
          </div>
          <div className="d-flex justify-content-between pl-2">
            <span>Running</span>
            <span style={valueStyle}>
              {generateDurationString(run_time_ms)}{' '}
            </span>
          </div>
          <div className="d-flex justify-content-between pl-2">
            <span>Stop</span>
            <span style={valueStyle}>
              {generateDurationString(stop_time_ms)}
            </span>
          </div>
          <div className="d-flex justify-content-between pl-3">
            <span>Operator Stops</span>
            <span style={valueStyle}>
              {generateDurationString(stop_time_operator_ms)}{' '}
            </span>
          </div>
          <div className="d-flex justify-content-between pl-3">
            <span>Alarm Stops</span>
            <span style={valueStyle}>
              {generateDurationString(stop_time_other_ms)}{' '}
            </span>
          </div>
        </div>
        <div style={{ minWidth: 250, maxWidth: '25%', width: '100%' }}>
          {
            <ReactEchartsCore
              style={{ height: 150, paddingLeft: '1em' }}
              echarts={echarts}
              option={optionOperating}
            />
          }
        </div>
        <div style={{ minWidth: 250, maxWidth: '25%', width: '100%' }}>
          {JSON.parse(stops_with_reason_json || '[]').length > 1 &&
            JSON.parse(stops_with_reason_json || '[]').map((item, index) => (
              <div key={index} className="d-flex justify-content-between">
                <span>{item.stop_reason_name || 'Undefined'}</span>
                <span style={valueStyle}>
                  {generateDurationString(item.duration)}
                </span>
              </div>
            ))}
        </div>
        <div style={{ minWidth: 250, maxWidth: '25%', width: '100%' }}>
          {JSON.parse(stops_with_reason_json || '[]').length > 1 && (
            <ReactEchartsCore
              echarts={echarts}
              style={{ height: 150, paddingLeft: '1em' }}
              option={optionStops}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Stops;
