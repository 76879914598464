import React, { useState, useEffect } from 'react';
import { IoCheckmarkCircleSharp, IoAddCircleSharp } from 'react-icons/io5';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import styled from 'styled-components';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { OrderItemDetailsModal } from '../../../../../Orders/views/partials/OrderItems';
import { useToggler } from '../../../../../../lib/helpers/customHooks';
import { insert, lazyFetch, process } from './helpers';
import { useStore } from '../../../../../../store';

const ItemsTreeTable = ({ data: origData, height = 500 }) => {
  const [isItemDetailsOpen, toggleItemDetails] = useToggler(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { state } = useStore();
  useEffect(() => {
    setData(origData);
  }, [origData]);

  function onExpand(event) {
    if (event.node.children) {
      return null;
    }
    setLoading(true);
    // TODO: Find out if its better if we use reactfire(subscription) here,
    //  so we can see "add" button as soon as its checked in settings in admin app
    lazyFetch(event.node.ref).then((raw) => {
      let processed = raw.map((child, index) => {
        return process(child.data, index, event.node.key);
      });
      setData([
        insert(
          data[0],
          event.node.key,
          processed.filter((item) => !item.data.ni_vidno),
          null
        ),
      ]);
      setLoading(false);
    });
  }
  return (
    <>
      <TreeTable
        value={data.filter((item) => !item.data.ni_vidno)}
        scrollable
        scrollHeight={`${height}px`}
        loading={loading}
        onExpand={onExpand}
      >
        <Column expander style={{ width: 50 }} />
        <Column
          header="Material"
          style={{ padding: '0.25em 1em', width: '35%' }}
          body={(e) => (
            <div
              style={{
                paddingLeft: `${e.data.Indentation * 20}px`,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {/* <ImageIcon style={{ width: 80, paddingLeft: '10px' }}/> */}
              <div style={{ textAlign: 'left' }}>
                <div
                  style={{ color: '#ccc', fontSize: '.85em', fontWeight: 500 }}
                >
                  {e.data.No}
                </div>
                <div>{e.data.Description}</div>
              </div>
            </div>
          )}
        />

        <Column
          style={{ padding: '0.25em 1em' }}
          field="QtyperParent"
          header="Quantity"
        />
        <Column
          style={{ padding: '0.25em 1em' }}
          field="Price"
          header="Price"
        />
        <Column
          field="Added"
          header="Added"
          style={{ textAlign: 'center', padding: '0.25em 1em' }}
          body={(e) => {
            if (!e.data.prodaja_pozicije) return null;
            const thisItemInOrder = state.selectedOrder?.items?.find(
              (item) => item.material_code === e.data.No
            );
            const isAdded = state.selectedOrder?.items && thisItemInOrder;

            // 1. preveri če se artikel lahko sploh prodaja
            return isAdded ? (
              <StyledItem>
                <span className="icon">
                  <IoCheckmarkCircleSharp />
                </span>
                <span>{thisItemInOrder?.quantity}x</span>
              </StyledItem>
            ) : (
              <AddItemButton
                data={e}
                setItem={setSelectedItem}
                toggle={toggleItemDetails}
                isAdded={isAdded}
              />
            );
          }}
        />
      </TreeTable>
      <OrderItemDetailsModal
        isOpen={isItemDetailsOpen}
        toggle={toggleItemDetails}
        data={selectedItem}
      />
    </>
  );
};
export const StyledItem = styled.div`
  display: flex;
  gap: 0.25em;
  align-items: baseline;
  justify-content: center;
  border-radius: 5px;
  .icon {
    font-size: 1.3em;
    color: ${({ theme }) => theme.colors.main.success._100};
  }
`;
export const StyledAddItem = styled.div`
  display: flex;
  gap: 0.25em;
  align-items: baseline;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grayscale._10};
  }
  .icon {
    font-size: 1.3em;
    color: ${({ theme, isAdded }) =>
      isAdded
        ? theme.colors.main.success._100
        : theme.colors.main.primary._100};
  }
`;

export const AddItemButton = ({ data, toggle, setItem, children, isAdded }) => {
  const handleAddItem = () => {
    toggle();
    setItem(data);
  };
  return (
    <StyledAddItem onClick={handleAddItem}>
      <span className="icon">
        <IoAddCircleSharp />
      </span>
      <span>Add</span>
    </StyledAddItem>
  );
};

// const ItemDetailsModal = ({ isOpen, toggle, data, isEdit = false }) => {
//   const { state, dispatch } = useStore();
//   const [quantity, setQuantity] = useState(data?.original?.quantity);
//   useEffect(() => {
//     setQuantity(data?.original?.quantity);
//   }, [isOpen]);
//   if (!data) return null;

//   const handleAddItem = () => {
//     const payload = {
//       ...state.selectedOrder,
//       items: [...state.selectedOrder?.items, data],
//     };
//     dispatch({
//       type: 'SELECTED_ORDER',
//       payload,
//     });
//     toggle();
//   };

//   const handleEditItem = () => {
//     const editItem = (data) => ({});
//     const items = state.selectedOrder.items ? editItem(data) : data;
//     const payload = {
//       ...state.selectedOrder,
//       items,
//     };

//     dispatch({
//       type: 'SELECTED_ORDER',
//       payload,
//     });
//     toggle();
//   };
//   return (
//     <Modal isOpen={isOpen} size="sm" toggle={toggle}>
//       <ModalHeader toggle={toggle}>nome?</ModalHeader>
//       {/* <ModalHeader toggle={toggle}>{data?.original?.name}</ModalHeader> */}
//       <ModalBody>
//         <div>
//           <Form>
//             <Row form>
//               <Col md={12}>
//                 <FormGroup>
//                   <Label for="quantity">Quantity</Label>
//                   <Input
//                     type="number"
//                     name="quantity"
//                     id="quantity"
//                     min="0"
//                     onChange={(e) => setQuantity(e.target.value)}
//                     value={quantity}
//                     // max={data?.original?.quantity}
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md={12}>
//                 <FormGroup>
//                   <Label for="note">Note</Label>
//                   <Input
//                     type="textarea"
//                     name="note"
//                     id="note"
//                     placeholder="(Optional)"
//                     value={data?.original?.note}
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md={12}>
//                 value:{' '}
//                 <span style={{ fontSize: '1.5em' }}>
//                   {data?.original?.value}
//                 </span>
//               </Col>
//             </Row>
//           </Form>
//         </div>
//       </ModalBody>
//       <ModalFooter>
//         <StyledButton
//           color="success"
//           onClick={() => (isEdit ? handleEditItem() : handleAddItem())}
//         >
//           {isEdit ? 'Edit' : 'Add'}
//         </StyledButton>
//       </ModalFooter>
//     </Modal>
//   );
// };
export default ItemsTreeTable;
// export { ItemDetailsModal };
