import React, { useEffect, useState } from 'react';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/component/visualMap';
import 'echarts/lib/chart/line.js';
import { CardBody, Card, CardHeader } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import { getColorScheme } from '../../../../../lib/helpers';
import {
  concatAndSplitArraysFromJSON,
  generateArrayForChart,
} from '../../../helpers';
const EnergyConsumption = ({
  loading,
  gridLeft,
  zoomSync,
  setZoomSync,
  dataNew,
}) => {
  const [events, setEvents] = useState({});
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    zoomSync &&
      setEvents({
        dataZoom: (params) => {
          const { start, end } = params.batch ? params.batch[0] : params;
          setZoomSync([start, end]);
        },
      });
  }, []);

  useEffect(() => {
    if (!dataNew.length) {
      setChartData({
        mixer1Data: [],
        mixer2Data: [],
        entryData: [],
        mainData: [],
        ovenData: [],
      });
      return null;
    }
    const { date_ts, interval_ms } = dataNew[0];

    const mixer1Arr = concatAndSplitArraysFromJSON(dataNew, 'mixer2_kwh');
    const mixer1Data = generateArrayForChart(mixer1Arr, date_ts, interval_ms);

    const mixer2Arr = concatAndSplitArraysFromJSON(dataNew, 'mixer2_kwh');
    const mixer2Data = generateArrayForChart(mixer2Arr, date_ts, interval_ms);

    const entryArr = concatAndSplitArraysFromJSON(dataNew, 'entry_kwh');
    const entryData = generateArrayForChart(entryArr, date_ts, interval_ms);

    const mainArr = concatAndSplitArraysFromJSON(dataNew, 'main_kwh');
    const mainData = generateArrayForChart(mainArr, date_ts, interval_ms);

    const ovenArr = concatAndSplitArraysFromJSON(dataNew, 'oven_kwh');
    const ovenData = generateArrayForChart(ovenArr, date_ts, interval_ms);

    setChartData({
      mixer1Data,
      mixer2Data,
      entryData,
      mainData,
      ovenData,
    });
  }, [dataNew]);

  const colorScheme = getColorScheme();
  const option = {
    grid: {
      left: gridLeft || 50,
      right: 20,
    },
    xAxis: {
      type: 'time',
      boundaryGap: false,
      // data: generatedDate,
      scale: true,
      //type:"time",
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value}kw/h',
      },
    },
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: ['Mixer 1', 'Mixer 2', 'Entry', 'Main', 'Oven'],
    },
    dataZoom: [
      {
        type: 'slider',
        filterMode: 'weakFilter',
        showDataShadow: false,
        top: 455,
        height: 10,
        handleIcon:
          'M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z', // jshint ignore:line
        handleSize: 20,
        labelFormatter: '',
        start: zoomSync ? zoomSync[0] : 0,
        end: zoomSync ? zoomSync[1] : 100,
      },
      {
        type: 'inside',
        filterMode: 'weakFilter',
      },
    ],
    series: [
      {
        type: 'line',
        stack: 'energy',
        sampling: 'average',
        name: 'Mixer 1',
        step: 'end',
        data: chartData.mixer1Data,
        symbol: 'none',
        areaStyle: {},
        itemStyle: {
          color: colorScheme.other[4]._100,
        },
        lineStyle: {
          width: 2,
        },
      },
      {
        type: 'line',
        stack: 'energy',
        sampling: 'average',
        name: 'Mixer 2',
        step: 'end',
        data: chartData.mixer2Data,
        symbol: 'none',
        areaStyle: {},
        itemStyle: {
          color: colorScheme.other[4]._80,
        },
        lineStyle: {
          width: 2,
        },
      },
      {
        type: 'line',
        stack: 'energy',
        sampling: 'average',
        name: 'Entry',
        step: 'end',
        data: chartData.entryData,
        symbol: 'none',
        areaStyle: {},
        itemStyle: {
          color: colorScheme.other[4]._60,
        },
        lineStyle: {
          width: 2,
        },
      },
      {
        type: 'line',
        stack: 'energy',
        sampling: 'average',
        name: 'Main',
        step: 'end',
        data: chartData.mainData,
        symbol: 'none',
        areaStyle: {},
        itemStyle: {
          color: colorScheme.other[4]._40,
        },
        lineStyle: {
          width: 2,
        },
      },
      {
        type: 'line',
        stack: 'energy',
        sampling: 'average',
        name: 'Oven',
        step: 'end',
        data: chartData.ovenData,
        symbol: 'none',
        areaStyle: {},
        itemStyle: {
          color: colorScheme.other[4]._20,
        },
        lineStyle: {
          width: 2,
        },
      },
      // {
      //   type: 'line',
      //   sampling: 'average',
      //   name: 'Mixer Utilization',
      //   data: data.map(({ time_ms, mixer_utilization }) => [
      //     Number(time_ms),
      //     Number(mixer_utilization),
      //   ]),
      //   symbol: 'none',
      //   itemStyle: {
      //     color: colorScheme.other[3]._100,
      //   },
      //   lineStyle: {
      //     width: 2,
      //   },
      // },
    ],
  };
  return loading ? (
    <Skeleton height={256} />
  ) : (
    <ReactEchartsCore
      onEvents={events}
      echarts={echarts}
      style={{ height: 480 }}
      option={option}
    />
  );
};

export default EnergyConsumption;
