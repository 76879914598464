import React, { useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import styled from 'styled-components';
import { StyledButton } from '../../../../components/DateSelection/DateSelection';

const StyledPartsList = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5em 1em;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grayscale._10};
  }

  .item-action {
    margin-left: 0.5em;
    color: ${({ theme }) => theme.colors.main.danger._100};
    cursor: pointer;
  }
`;

const PartsInput = ({ data, handlePartsChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isNewPart, setIsNewPart] = useState(false);
  const [selectedPart, setSelectedPart] = useState({});
  const [selectedPartIndex, setSelectedPartIndex] = useState(0);
  const toggle = () => setIsOpen(!isOpen);

  const handleSubmit = () => {
    // const tmp = data;
    // tmp.push(selectedPart);
    // const parts = isNewPart ? tmp : selectedPart;
    // console.log({ selectedPart, parts });
    const tmp = isNewPart ? [...data, selectedPart] : handleEditPart();
    handlePartsChange(tmp);
    toggle();
  };

  const handleEditPart = () => {
    const tmp = data;
    tmp.splice(selectedPartIndex, 1, selectedPart);
    return tmp;
  };

  const handleRemove = (index) => {
    const tmp = data;
    tmp.splice(index, 1);
    setSelectedPart({ parts: tmp });
  };

  return (
    <>
      {data.map((item, index) => (
        <StyledPartsList
          key={index}
          onClick={() => {
            setIsNewPart(false);
            setSelectedPart(item);
            setSelectedPartIndex(index);
            toggle();
          }}
        >
          <div className="item-details">
            <div className="text-muted small">{item.id}</div>
            <div>{`${item.qty}x ${item.name}`} </div>
          </div>
          <div
            className="item-action"
            onClick={(e) => {
              e.stopPropagation();
              handleRemove(index);
            }}
          >
            <FiTrash2 />
          </div>
        </StyledPartsList>
      ))}
      <StyledButton
        type="button"
        onClick={() => {
          setSelectedPart({});
          setIsNewPart(true);
          toggle();
        }}
      >
        Add part
      </StyledButton>
      <Modal toggle={toggle} isOpen={isOpen}>
        <ModalHeader toggle={toggle}>Add part</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label for="partId" sm={2}>
                ID
              </Label>
              <Col sm={10}>
                <Input
                  type="number"
                  name="selectedPart[id]"
                  value={selectedPart.id}
                  id="partId"
                  placeholder="Part ID"
                  onChange={(e) =>
                    setSelectedPart({
                      ...selectedPart,
                      id: Number(e.target.value),
                    })
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="name" sm={2}>
                Name
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="selectedPart[name]"
                  value={selectedPart.name}
                  id="name"
                  placeholder="Part name"
                  onChange={(e) =>
                    setSelectedPart({ ...selectedPart, name: e.target.value })
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="qty" sm={2}>
                Quantity
              </Label>
              <Col sm={10}>
                <Input
                  type="number"
                  name="selectedPart[qty]"
                  value={selectedPart.qty}
                  id="qty"
                  placeholder="Quantity"
                  onChange={(e) =>
                    setSelectedPart({
                      ...selectedPart,
                      qty: Number(e.target.value),
                    })
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={{ size: 10, offset: 2 }}>
                <StyledButton
                  type="button"
                  color="success"
                  onClick={handleSubmit}
                >
                  Apply changes
                </StyledButton>
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PartsInput;
