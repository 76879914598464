import React, { useState, useEffect } from 'react';
import { IoIosArrowRoundDown } from 'react-icons/io';
import { IoIosInformationCircle } from 'react-icons/io';
import {
  useTable,
  usePagination,
  useResizeColumns,
  useSortBy,
  useExpanded,
} from 'react-table';
import {
  FiChevronLeft,
  FiChevronsLeft,
  FiChevronRight,
  FiChevronsRight,
} from 'react-icons/fi';
import { IoIosArrowRoundUp } from 'react-icons/io';
//import tmpIssues from './tmpIssues.json'
//import tmpIssue from './tmpIssue.json'
import {
  Modal,
  ModalBody,
  ButtonGroup,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalHeader,
  Container,
  Row,
  Col,
  Spinner,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useStore } from '../../../../../store';
import classnames from 'classnames';
import Description from '../../Issues/partials/Description';
import Comments from '../../Issues/partials/Comments';
import Reply from '../../Issues/partials/Reply';
import { getColorScheme, getUserDetails } from '../../../../../lib/helpers';
import TooltipWrapper from '../../../../../components/TooltipWrapper';
import NoContent from '../../../../../components/NoContent/NoContent';

const Issues = ({ data, toggleWithData }) => {
  const [, setIssues] = useState([]);
  const [, setError] = useState('');
  const { state, dispatch } = useStore();

  useEffect(() => {
    if (
      state.isFromReportIssue &&
      state.issueMachineSerialNr.toString() === data.machine_id.toString()
    ) {
      getContent();
      dispatch({ type: 'SET_IS_FROM_REPORT_ISSUE', payload: false });
    }
  }, []);

  const getContent = async () => {
    dispatch({
      type: 'SET_SELECTED_ISSUE_MACHINE_SERIAL_NR',
      payload: data.machine_id,
    });

    const resultIssues = await getIssues();
    //const resultIssues = tmpIssues
    setIssues(resultIssues);
    const columns = [
      {
        id: 'priority',
        Header: 'Priority',
        accessor: 'priority',
      },
      {
        id: 'contact_name',
        Header: 'Name',
        accessor: 'contact_name',
      },
      {
        id: 'subject',
        Header: 'Subject',
        accessor: 'subject',
      },
      {
        id: 'date_of_submission',
        Header: 'Date',
        accessor: 'date_of_submission',
      },
      {
        id: 'time_of_submission',
        Header: 'Time',
        accessor: 'time_of_submission',
      },
    ];
    const header = 'Issues ';
    const body = (
      <IssuesList machine={data} data={resultIssues} columns={columns} />
    );
    const bodyProps = { className: 'p-0' };
    const modalData = { header, body, bodyProps, modalSize: 'lg' };
    toggleWithData(modalData);
  };

  const getIssues = async () => {
    return fetch(`${process.env.REACT_APP_API_PREFIX}queapi/v1/submissions/`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
      },
    })
      .then((res) => res.json())
      .then((data) => data || [])
      .catch((err) => {
        setError(true);
        console.error(err);
        return [];
      });
  };

  return (
    <div
      style={{ cursor: 'pointer', padding: '0.5rem 1rem' }}
      onClick={getContent}
    >
      <IoIosInformationCircle style={{ fontSize: '1.25rem' }} />
      <br />
      Issues
    </div>
  );
};

const IssuesList = ({ data, columns, machine }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [issue, setIssue] = useState({
    head: null,
    body: null,
    comments: null,
  });
  const [userDetails, setUserDetails] = useState(null);

  const { state, dispatch } = useStore();
  const colorScheme = getColorScheme();

  useEffect(() => {
    getUserDetails().then((data) => setUserDetails(data));
  }, []);

  const handleRowSelect = async (row) => {
    const resultIssue = await getIssue(row.original.uuid);
    //  const resultIssue = tmpIssue
    setIssue(destructSubmission(resultIssue));
    setIsOpen(!isOpen);
  };
  const toggle = () => setIsOpen(!isOpen);

  const destructSubmission = (issue) => {
    const head = issue.find((part) => part._form_id === '26'); // ninja forms with id 26 is 1.header
    const comments = issue.filter((part) => part._form_id === '28'); // ninja forms with id 28 is 3.attachments
    const body = issue.filter(
      (part) => part._form_id !== '26' && part._form_id !== '28'
    );
    return { head, comments, body: body[0] };
  };

  const refreshComments = async (id) => {
    setIsLoading(true);
    const resultIssue = await getIssue(id);
    setIssue(destructSubmission(resultIssue));
    setIsLoading(false);
  };
  const getIssue = async (id) => {
    return fetch(
      `${process.env.REACT_APP_API_PREFIX}queapi/v1/submissions/${id}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data || [])
      .catch((err) => {
        setError(true);
        console.error(err);
        return [];
      });
  };
  const {
    beginning_date_of_guarantee,
    guarantee_valid_till,
    production_year,
    project_id,
  } = state.selectedProject;
  const {
    machine_name,
    model_nr,
    alarm_topic,
    description,
    machine_id: serial_nr,
  } = machine;

  const newIssueParameters = {
    contact_name: userDetails && userDetails.name,
    project_id,
    machine_name,
    alarm_topic,
    model_nr,
    serial_nr,
    description,
    beginning_date_of_guarantee:
      machine.beginning_date_of_guarantee || beginning_date_of_guarantee,
    machine_type_1: description,
    guarantee_valid_till: machine.guarantee_valid_till || guarantee_valid_till,
    machine_name_1: machine_name,
    model_number: model_nr,
    production_year: machine.production_year || production_year,
    production_line_code: project_id,
    serial_number: serial_nr,
  };
  const filteredData = data.filter(
    (item) => item.serial_number.toString() === machine.machine_id.toString()
  );
  return (
    <React.Fragment>
      <Link
        to={{
          pathname: '/issues/newIssue/',
          state: { otherParameters: newIssueParameters },
        }}
      >
        <Button color="danger" className="m-2">
          Report Issue
        </Button>
      </Link>
      {/*<TableReact data={filteredData} columns={columns} hover handleRowSelect={handleRowSelect}/>*/}
      {!filteredData.length ? (
        <NoContent title="No issues reported" />
      ) : (
        <IssueTable
          data={filteredData}
          columns={columns}
          hover
          handleRowSelect={handleRowSelect}
        />
      )}
      <Modal
        autoFocus={false}
        fade={false}
        isOpen={isOpen}
        contentClassName="shadow-lg"
        size="xl"
      >
        <ModalHeader toggle={toggle}>Issue</ModalHeader>
        <ModalBody className="bg-light">
          <Container>
            <Row>
              <Col sm={12} className="mb-4">
                {issue.head && (
                  <Description head={issue.head} body={issue.body} />
                )}
              </Col>
              <Comments comments={issue.comments} />
              {isLoading && <Spinner color="dark" size="sm" className="ml-1" />}
              <Col>
                {issue.head && issue.head.status.value !== 'Closed' ? (
                  <Reply
                    uuid={issue.head.uuid.value}
                    submissionId={issue.head._sub_id}
                    refreshComments={refreshComments}
                  /> //statusUpdated={this.props.statusUpdated}
                ) : (
                  ''
                )}
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const IssueTable = ({ columns, data, rowIndex, handleRowSelect }) => {
  const colorScheme = getColorScheme();
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { selectedRowIds: { 0: true } },
    },
    useSortBy,
    useExpanded,
    usePagination,
    useResizeColumns
  );

  // Render the UI for your table
  return (
    <React.Fragment>
      <ul className="list-unstyled hover-primary">
        {page.map((row) => {
          prepareRow(row);
          return (
            <li
              {...row.getRowProps()}
              onClick={() => handleRowSelect && handleRowSelect(row)}
              style={{
                background:
                  (row.index === rowIndex && colorScheme.main.primary._20) ||
                  (row.index % 2 === 0 && 'rgba(0,0,0,.02)'),
                cursor: 'pointer',
              }}
            >
              <div className="d-flex ">
                <div
                  className="align-self-center"
                  style={{
                    flex: 0,
                    color:
                      row.values.priority === 'high'
                        ? colorScheme.main.warning._100
                        : row.values.priority === 'urgent'
                        ? colorScheme.main.danger._100
                        : colorScheme.grayscale._100,
                  }}
                >
                  <TooltipWrapper content={'Priority: ' + row.values.priority}>
                    {row.values.priority === 'low' && (
                      <IoIosArrowRoundDown size={32} />
                    )}
                    {row.values.priority === 'medium' && (
                      <IoIosArrowRoundUp size={32} />
                    )}
                    {row.values.priority === 'high' && (
                      <IoIosArrowRoundUp size={32} />
                    )}
                    {row.values.priority === 'urgent' && (
                      <IoIosArrowRoundUp size={32} />
                    )}
                  </TooltipWrapper>
                </div>
                <div style={{ flex: 1 }} className="p-2 d-flex flex-column">
                  <div className="d-flex justify-content-between">
                    <div>{row.values.contact_name}</div>
                    <div>
                      {row.values.date_of_submission},{' '}
                      {row.values.time_of_submission}
                    </div>
                  </div>
                  <strong>{row.values.subject}</strong>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
      <div className="text-right">
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <ButtonGroup size="sm" className="px-3 pb-3">
          <Button
            color="light"
            className={classnames(canPreviousPage && 'shadow-sm', 'ml-2')}
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <FiChevronsLeft />
          </Button>
          <Button
            color="light"
            className={classnames(canPreviousPage && 'shadow-sm', 'ml-2')}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <FiChevronLeft />
          </Button>
          <Button
            color="light"
            className={classnames(canNextPage && 'shadow-sm', 'ml-2')}
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <FiChevronRight />
          </Button>
          <Button
            color="light"
            className={classnames(canNextPage && 'shadow-sm', 'ml-2')}
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <FiChevronsRight />
          </Button>
          <UncontrolledButtonDropdown>
            <DropdownToggle caret color="light" className="shadow-sm ml-2">
              Show {pageSize}
            </DropdownToggle>
            <DropdownMenu>
              {['All', 10, 20, 30, 40, 50].map((pageSize, index) =>
                index === 0 ? (
                  <React.Fragment key={pageSize}>
                    <DropdownItem
                      value={data.length}
                      onClick={() => setPageSize(Number(data.length))}
                    >
                      Show All
                    </DropdownItem>
                    <hr />
                  </React.Fragment>
                ) : (
                  <DropdownItem
                    key={pageSize}
                    value={pageSize}
                    onClick={() => setPageSize(Number(pageSize))}
                  >
                    Show {pageSize}
                  </DropdownItem>
                )
              )}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </ButtonGroup>
      </div>
    </React.Fragment>
  );
};

export default Issues;
