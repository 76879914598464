import React, { useEffect, useState } from 'react';
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from 'react-icons/fi';
import styled from 'styled-components';
import { StyledButton } from '../../../../../components/DateSelection/DateSelection';

const StyledPagination = styled.div`
  display: flex;
  gap: 0.5em;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5em 1em;
`;

// const PAGE_SIZE_DEFAULT = 7;

export const sliceDataForPage = (data, pageIndex, pageSize = 5) =>
  data && data.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);

const maxPages = (data, pageSize) =>
  data ? Math.ceil(data.length / pageSize) : 0;

const Pagination = ({ data, handleChangePage, pageSize = 5 }) => {
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => setPageIndex(0), [data]);

  useEffect(() => {
    const pageData = sliceDataForPage(data, pageIndex, pageSize);
    handleChangePage(pageData);
  }, [pageIndex]);

  if (!data?.length) return null;
  return (
    data.length && (
      <StyledPagination>
        <StyledButton small onClick={() => setPageIndex(0)}>
          <FiChevronsLeft />
        </StyledButton>
        <StyledButton
          small
          onClick={() => {
            pageIndex && setPageIndex(pageIndex - 1);
          }}
        >
          <FiChevronLeft />
        </StyledButton>
        <div>
          {' '}
          page {pageIndex + 1} of {maxPages(data, pageSize)}{' '}
        </div>
        <StyledButton
          small
          onClick={() =>
            pageIndex < maxPages(data, pageSize) - 1 &&
            setPageIndex(pageIndex + 1)
          }
        >
          <FiChevronRight />
        </StyledButton>
        <StyledButton
          small
          onClick={() => setPageIndex(maxPages(data, pageSize) - 1)}
        >
          <FiChevronsRight />
        </StyledButton>
      </StyledPagination>
    )
  );
};
export default Pagination;
