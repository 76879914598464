import React, { useState, useEffect } from 'react';
import Breadcrumbs from '../../../../components/Breadcrumb/Breadcrumb';
import DateSelection from '../../../../components/DateSelection';
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  CardHeader,
  Alert,
} from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import { useStore } from '../../../../store';
import reverse from 'lodash/reverse';
import BarChart from './partials/Barchart';
import BarChartNew from './partials/BarchartNew';
import Table from './partials/Table';
import BarChartRoelandt from './partials/BarchartRoelandt';
import TableRoelandt from './partials/TableRoelandt';
import Timeline from './partials/Timeline';
import TimelineNew from './partials/TimelineNew';
import NoContent from '../../../../components/NoContent';
import Filter from '../../../../components/Filter';
import { getLastXDays } from '../../../../lib/helpers';
import { useNonInitialEffect } from '../../../../lib/helpers/customHooks';
import {
  getAlarmHistory,
  sumDuration,
  generateAlarmsTimelineData,
} from '../../helpers';
import Tabs from '../../../../components/Tabs';
import TrendBadge from '../../../../components/TrendBadge/TrendBadge';
import 'firebase/firestore';
import 'firebase/database';
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocDataOnce,
} from 'reactfire';
import _ from 'lodash';
import jsondata from './untitled-1.json';
import TableNew from './partials/TableNew';
import { StyledButton } from '../../../../components/DateSelection/DateSelection';
const Alarms = () => {
  const { state, dispatch } = useStore();

  const [data, setData] = useState(state.diagnostics.data || []);
  const [dataWithFirestore, setDataWithFirestore] = useState([]);
  const [filteredDataWithFirestore, setFilteredDataWithFirestore] = useState(
    null
  );
  const [activeDividerFilter, setActiveDividerFilter] = useState(false);
  const [alarmsDurationSum, setAlarmsDurationSum] = useState(
    state.diagnostics.alarmsDurationSum || []
  );
  const [alarmsTimeline, setAlarmsTimeline] = useState(
    state.diagnostics.alarmsTimeline || []
  );
  const [filteredData, setFilteredData] = useState(null);
  const [filteredAlarmsDurationSum, setFilteredAlarmsDurationSum] = useState(
    null
  );
  const [filteredAlarmsTimeline, setFilteredAlarmsTimeline] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingAlarmHistory, setLoadingAlarmHistory] = useState(false);
  const [isError, setError] = useState(false);
  const [dates, setDates] = useState(
    state.diagnostics.dates || [getLastXDays(0), getLastXDays(0)]
  );

  //const [dates, setDates] = useState(state.diagnostics.dates ||  [getLastXDays(8), getLastXDays(1)])

  /**
   * USING FIREBASE
   */
  const projectRef = useFirestore().collection('project').doc('0');
  const { data: projectData } = useFirestoreDocDataOnce(projectRef);
  // const alarmHistoryRef = useFirestore().collection('alarm_history');
  // const {
  //   status: alarmHistoryStatus,
  //   data: alarmHistory,
  // } = useFirestoreCollectionData(alarmHistoryRef);

  useEffect(() => {
    fetchData();
  }, []);

  // useEffect(() => {
  //   const hasNull = dates.some((item) => item === null);
  //   if (!alarmHistory || hasNull) return;

  //   // 1.izloči intervale v svoj array
  //   const intervals = alarmHistory.map((alarm) => alarm.intervals);

  //   // 2. prefiltriraj intervale glede na izbrani datum
  //   const filteredIntervals = intervals.map((interval) => {
  //     return interval.filter((item) => {
  //       return (
  //         item.start >= +new Date(dates[0]) &&
  //         item.end <= +new Date(dates[1]) &&
  //         !!item.end
  //       );
  //       // (interval.end <= +new Date(dates[1]) || !interval.end) // if end is null, alarm is currently active
  //     });
  //   });

  //   //3. prilepi prefiltrirane intervale v obsoječe alarme
  //   const newAlarmArray = filteredIntervals.map((interval, index) => {
  //     const { intervals, ...other } = alarmHistory[index];
  //     return { intervals: interval, ...other };
  //   });

  //   //4.filtriraj alarme ki nimajo intervalov
  //   const filteredAlarms = newAlarmArray.filter(
  //     (alarm) => alarm.intervals.length
  //   );

  //   // 5. shrani filtrirne alarme v state
  //   setDataWithFirestore(filteredAlarms);
  // }, [alarmHistory, dates]);

  useNonInitialEffect(() => {
    const hasNull = dates.some((item) => item === null);
    !hasNull && fetchData();
    //with firebase
    // !hasNull && filterAlarmsByDate();
  }, [dates, projectData]);

  const filterAlarmsByDate = () => {
    // const tmpAlarms = alarmHistory;
    // const tmpFilteredData = tmpAlarms
    //   .map((alarm, index) => {
    //     const tmp = alarm.intervals.filter(
    //       (interval) =>
    //         interval.start >= +new Date(dates[0]) &&
    //         interval.end <= +new Date(dates[1])
    //     );
    //     alarm.intervals = tmp;
    //     return tmp.length ? alarm : null;
    //   })
    //   .filter(Boolean);
    // setDataWithFirestore(tmpFilteredData);
  };
  const fetchData = async () => {
    if (!projectData) return [];
    setLoading(true);
    setLoadingAlarmHistory(!alarmsDurationSum.length);
    setError(false);
    const resultAlarmHistory = await getAlarmHistory({
      dates,
      projectId: projectData.project_id,
      setLoading,
      setLoadingAlarmHistory,
      setError,
    });
    setFilteredData(null);
    setFilteredAlarmsDurationSum(null);
    setData(resultAlarmHistory);

    const sortByDuration = sumDuration(resultAlarmHistory);
    setAlarmsDurationSum(sortByDuration);
    const alarmsTimelineData = generateAlarmsTimelineData(resultAlarmHistory);
    setAlarmsTimeline(alarmsTimelineData);

    const res = {
      alarmsDurationSum: sortByDuration,
      alarmsTimeline: alarmsTimelineData,
      data: resultAlarmHistory,
      dates,
    };
    dispatch({ type: 'SET_DATA_DIAGNOSTICS_MODULE', payload: res });
  };

  const filterAlarms = (data) => {
    setFilteredData(data);
    const sortByDuration = data && sumDuration(data);
    setFilteredAlarmsDurationSum(sortByDuration);
    const alarmsTimelineData = generateAlarmsTimelineData(data);
    setFilteredAlarmsTimeline(alarmsTimelineData);
  };

  const filterAlarmNew = () => {
    if (activeDividerFilter) {
      setFilteredDataWithFirestore(null);
    } else {
      const filtered = dataWithFirestore.filter(
        (alarm) => Number(alarm.machine.serial_nr) === 12086
      );
      setFilteredDataWithFirestore(filtered);
    }
    setActiveDividerFilter(!activeDividerFilter);
  };

  const breadcrumbItems = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Diagnostics',
      link: '',
    },
  ];

  const filterBy = [
    {
      label: 'Machines',
      type: 'machine_description',
      //active:["Dough Divider"] // Example if you wanted default filter to be selected..
    },
    {
      label: 'Alarms type',
      type: 'alarm_type',
    },
  ];

  const newFilterBy = [
    {
      label: 'Machines',
      type: 'machine.description',
      //active:["Dough Divider"] // Example if you wanted default filter to be selected..
    },
    {
      label: 'Alarms type',
      type: 'definition.almtype',
    },
  ];
  return (
    <React.Fragment>
      <Breadcrumbs items={breadcrumbItems} />
      <div style={{ padding: '0 1rem' }}>
        <DateSelection
          range
          setDates={setDates}
          selectedDates={dates}
          loading={isLoading}
        />
      </div>
      {state.selectedProject.project_id === 'T02606' ? null : (
        <>
          <Filter data={data} filterBy={filterBy} onChange={filterAlarms} />
          {/* <StyledButton onClick={filterAlarmNew}>
            {!activeDividerFilter ? 'Filter divider exp.' : 'Clear filter exp.'}
          </StyledButton> */}
        </>
      )}
      <Container fluid>
        <Row className="mt-3">
          {isError && (
            <Col sm={12} className="mb-3">
              <Alert color="warning">
                There was an error while getting data. Try different date.
              </Alert>
            </Col>
          )}
          <Col sm={12} className="mb-3">
            {isLoadingAlarmHistory ? (
              <Skeleton height={330} />
            ) : (
              <Card>
                <CardHeader>Alarms and Warnings</CardHeader>
                <CardBody className="p-0">
                  {!alarmsDurationSum.length ? (
                    <NoContent style={{ height: '250px' }} title="No data">
                      Try different date
                    </NoContent>
                  ) : (
                    <React.Fragment>
                      {state.selectedProject.project_id === 'T02606' ? (
                        <BarChartRoelandt data={alarmsDurationSum} />
                      ) : (
                        <Tabs tabs={['Duration', 'Timeline']}>
                          <BarChart
                            data={
                              filteredAlarmsDurationSum || alarmsDurationSum
                            }
                          />
                          <Timeline
                            data={filteredAlarmsTimeline || alarmsTimeline}
                          />
                        </Tabs>
                      )}
                      {state.selectedProject.project_id === 'T02606' ? (
                        <TableRoelandt
                          data={data}
                          durations={alarmsDurationSum}
                        />
                      ) : (
                        <Table
                          data={filteredData || data}
                          durations={
                            filteredAlarmsDurationSum || alarmsDurationSum
                          }
                        />
                      )}
                      {/* <Tabs tabs={['Duration exp.', 'Timeline exp.']}>
                        <BarChartNew
                          data={filteredDataWithFirestore || dataWithFirestore}
                        />
                        <TimelineNew
                          data={filteredDataWithFirestore || dataWithFirestore}
                        />
                      </Tabs> */}

                      {/* <TableNew
                          data={filteredDataWithFirestore || dataWithFirestore}
                        /> */}
                    </React.Fragment>
                  )}
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Alarms;
