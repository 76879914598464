import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { FiFile, FiAlertCircle, FiList, FiGrid } from 'react-icons/fi';

class EquipmentHeader extends Component {
  render() {
    localStorage.removeItem('qrcontent');
    const {
      beginning_date_of_guarantee,
      description: machine_type_1,
      description,
      guarantee_valid_till,
      machine_name: machine_name_1,
      machine_name,
      model_nr: model_number,
      model_nr,
      production_year,
      project_id: production_line_code,
      project_id,
      serial_nr: serial_number,
      serial_nr,
      alarm_topic,
    } = this.props.data;
    const { image } = this.props;

    const newIssueParameters = {
      project_id,
      machine_name,
      alarm_topic,
      model_nr,
      serial_nr,
      description,
      beginning_date_of_guarantee,
      machine_type_1,
      guarantee_valid_till,
      machine_name_1,
      model_number,
      production_year,
      production_line_code,
      serial_number,
    };

    const proprsForTasks = {
      alarmTopic: alarm_topic,
      beginningDateOfGuarantee: beginning_date_of_guarantee,
      description,
      guaranteeValidTill: guarantee_valid_till,
      machineName: machine_name,
      modelNumber: model_nr,
      productionYear: production_year,
      projectId: project_id,
      serialNumber: serial_nr,
    };

    const buttonStyle = {
      color: 'rgb(255, 255, 255)',
      backgroundColor: 'rgb(70, 69, 97)',
      borderColor: 'rgb(70, 69, 97)',
      marginBottom: '1rem',
    };
    const buttonReportStyle = {
      color: 'rgb(255, 255, 255)',
      backgroundColor: '#F62649',
      borderColor: '#F62649',
      marginBottom: '1rem',
    };
    return (
      <Card className="mb-4">
        <CardBody>
          <Row>
            <Col className="mb-3" lg={3}>
              <img src={image} alt="..." className="img-thumbnail mr-2" />
            </Col>
            <Col className="mb-3" lg={7}>
              <h3>
                {machine_name} <br />
                <small className="text-muted">{description}</small>
              </h3>
              Model: <strong> {model_nr} </strong> <br />
              Serial: <strong> {serial_nr} </strong> <br />
              {/*<strong>Status: </strong> <Badge color="success">Running</Badge> <br/>*/}
            </Col>
            <Col lg={2}>
              <Link
                to={{
                  pathname: '/mas/todo',
                  state: { machineSelected: proprsForTasks },
                }}
                className={classnames('btn', 'btn-sm', 'btn-block')}
                style={buttonStyle}
              >
                <FiList /> Tasks
              </Link>
              <Link
                to={{
                  pathname: '/mas/documentation',
                  state: { machineModelNumber: model_nr },
                }}
                className={classnames('btn', 'btn-sm', 'btn-block')}
                style={buttonStyle}
              >
                <FiFile /> Documentation
              </Link>
              <Link
                to={{
                  pathname: '/issues/newIssue/',
                  state: { otherParameters: newIssueParameters },
                }}
                className={classnames('btn', 'btn-sm', 'btn-block')}
                style={buttonReportStyle}
              >
                <FiAlertCircle /> Report issue
              </Link>
              <hr />
              <Link
                to="/"
                className={classnames(
                  'btn',
                  'btn-sm',
                  'btn-block',
                  'btn-outline-secondary'
                )}
              >
                <FiGrid /> Home
              </Link>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default EquipmentHeader;
