import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Button,
} from 'reactstrap';
import Select from 'react-select';
import { useStore } from '../../../store';

const UserForm = ({ toggle, isOpen, data, handleCreate, handleEdit }) => {
  const [user, setUser] = useState(data);
  const [tmpProjectIds, setTmpProjectIds] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const { state } = useStore();

  useEffect(() => {
    setUser(data);
    setProjectOptions(generateProjectOptions);
    setTmpProjectIds(generateTmpProjectIds);
  }, [data]);

  const generateProjectOptions = () => {
    return state.userProjects.map((project) => {
      return {
        value: project.project_id,
        label: `${project.project_id} - ${project.project_name}`,
      };
    });
  };
  const rolesOptions = () => {
    const siteAdminRole = {
      value: 'site-admin',
      label: `Site Admin`,
    };
    const roles = [
      {
        value: 'admin',
        label: `Admin`,
      },
      {
        value: 'technician',
        label: `Technician`,
      },
    ];

    return data.currentUser?.user_role === 'site-admin'
      ? [siteAdminRole, ...roles]
      : roles;
  };

  const generateTmpProjectIds = () => {
    if (!data.project_ids) return [];
    const projectIdsArr = data.project_ids
      .slice(1, data.project_ids.length - 1)
      .split(',');
    return projectOptions.filter((project) =>
      projectIdsArr.includes(project.value)
    );
  };

  const handleSubmitForm = () => {
    data.action === 'create' && handleCreate(user);
    data.action === 'edit' && handleEdit(user);
  };

  const handleProjectChange = (selectedOption) => {
    const project_ids = `{${selectedOption.map((item) => item.value)}}`;
    setUser({ ...user, project_ids });
  };
  const handleRoleChange = (selectedOption) => {
    setUser({ ...user, user_role: selectedOption.value });
  };

  const handleCancelForm = () => {
    setUser({});
    toggle();
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle}>
        {data.action === 'create'
          ? 'Create'
          : data.action === 'edit'
          ? 'Edit'
          : ''}{' '}
        User
      </ModalHeader>
      <ModalBody>
        <Form autoComplete="off">
          <FormGroup>
            <Label for="Name">Username</Label>
            <Input
              name="user[usr_name]"
              value={user.usr_name}
              onChange={(e) => setUser({ ...user, usr_name: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="password">Password</Label>
            <Input
              name="user[usr_pwd]"
              value={user.usr_pwd}
              onChange={(e) => setUser({ ...user, usr_pwd: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="Name">Ful name</Label>
            <Input
              name="user[name]"
              value={user.name}
              onChange={(e) => setUser({ ...user, name: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="CompanyName">Company name</Label>
            <Input
              name="user[company_name]"
              value={user.company_name}
              onChange={(e) =>
                setUser({ ...user, company_name: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="Projects">Projects</Label>
            <Select
              isMulti
              defaultValue={tmpProjectIds}
              name="user[project_ids]"
              options={projectOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleProjectChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="Roles">Role</Label>
            <Select
              defaultValue={rolesOptions().find(
                (item) => item.value === user.user_role
              )}
              name="user[project_ids]"
              options={rolesOptions()}
              classNamePrefix="select"
              onChange={handleRoleChange}
            />
            {/* <Input
                        name="user[user_role]"
                        value={user.user_role}
                      onChange={e => setUser({ ...user, user_role: e.target.value })} />*/}
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleCancelForm} color="light">
          Cancel
        </Button>
        <Button
          className="text-capitalize"
          onClick={handleSubmitForm}
          color="primary"
        >
          {data.action === 'edit' ? 'Confirm changes' : data.action}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UserForm;
