import styled from 'styled-components';
import { getColor } from './OrdersTable.helpers';

export const StyledBadge = styled.span`
  padding: 0.2em 0.5em;
  font-weight: 500;
  border-radius: 3px;
  color: white;

  font-size: 0.8em;
  text-align: center;
  color: ${({ theme, status }) => getColor({ theme, status, opacity: 100 })};
  background-color: ${({ theme, status }) =>
    getColor({ theme, status, opacity: 10 })};
`;
