import React from 'react';
import styled from 'styled-components';

const StyledStat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .value {
    font-size: 3em;
    font-weight: 500;
    color: ${({ theme, danger, success }) =>
      danger
        ? theme.colors.main.danger._100
        : success
        ? theme.colors.main.success._100
        : 'inherit'};
  }
`;

const StyledStatsContainer = styled.div`
  display: flex;
  gap: 1em;
  margin-bottom: 1em;
  justify-content: space-around;
`;

const SingleStatsContainer = ({ children }) => (
  <StyledStatsContainer>{children}</StyledStatsContainer>
);

const SingleStat = ({ value, label, danger, success }) => {
  return (
    <StyledStat danger={danger} success={success}>
      <div className="value">{value}</div>
      <div>{label}</div>
    </StyledStat>
  );
};

export default SingleStat;
export { SingleStatsContainer };
