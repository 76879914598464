import React, { useState } from 'react';
import SegmentsTable from './SegmentsTable';
import { Button } from 'reactstrap';
import ModalTemplate from '../ModalTemplate';

const Segments = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <React.Fragment>
      <Button
        disabled={!data}
        size="sm"
        outline
        className="ml-2"
        onClick={toggle}
      >
        Segments
      </Button>
      {!!data && (
        <ModalTemplate
          isOpen={isOpen}
          toggle={toggle}
          title="Segments"
          breadcrumbs={data.breadcrumb}
        >
          <SegmentsTable data={data} />
        </ModalTemplate>
      )}
    </React.Fragment>
  );
};

export default Segments;
