import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
} from 'reactstrap';
import { getGroups } from '../calls/groups';

const MaintenanceForm = ({
  toggle,
  isOpen,
  data,
  handleCreate,
  handleEdit,
  getIcon,
}) => {
  const [maintenance, setMaintenance] = useState(data);
  const [groups, setGroups] = useState([]);

  const initData = () => {
    return groups.length !== 0 && data.action === 'create'
      ? {
          ...data,
          personel_level_description: 'Trained',
          group_id: groups[0].group_id,
          group_name: groups[0].group_name,
        }
      : data;
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await getGroups();
      setGroups(result.groups);
      result.groups.length !== 0 &&
        setMaintenance({
          ...maintenance,
          group_name: result.groups[0].group_name,
          group_id: result.groups[0].group_id,
          personel_level_description: 'Trained',
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    const init = initData();
    setMaintenance({
      ...init,
    });
  }, [data]);

  const handleSubmitForm = () => {
    const {
      productionLine: { project_id },
      machine: { model_nr, serial_nr },
      assembly: { assembly_id },
    } = data.selected;
    const params = {
      ...maintenance,
      project_id,
      serial_nr,
      model_nr,
      assembly_id,
    };
    data.action === 'create' && handleCreate(params);
    data.action === 'edit' && handleEdit(params);
  };

  const handleCancelForm = () => {
    setMaintenance({});
    toggle();
  };

  const operation_method_arr = maintenance.operation_method
    ? maintenance.operation_method.split(',')
    : [];
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle}>
        {' '}
        {data.action === 'create'
          ? 'Create'
          : data.action === 'edit'
          ? 'Edit'
          : ''}{' '}
        maintenance{' '}
      </ModalHeader>
      <ModalBody>
        <Form autoComplete="off">
          <FormGroup>
            <Label for="task_order">Ord.No.</Label>
            <Input
              type="number"
              id="task_order"
              name="maintenance[task_order]"
              onChange={(e) =>
                setMaintenance({ ...maintenance, task_order: e.target.value })
              }
              value={maintenance.task_order}
            />
          </FormGroup>
          {/*<FormGroup>
                        <Label for="task_id">Task ID</Label>
                        <Input
                            type="text"
                            id="task_id"
                            name="maintenance[task_id]"
                            onChange={e => setMaintenance({ ...maintenance, task_id: e.target.value })}
                            value={maintenance.task_id} />
                    </FormGroup>*/}
          <FormGroup>
            <Label for="task_name">Name</Label>
            <Input
              type="text"
              id="task_name"
              name="maintenance[task_name]"
              onChange={(e) =>
                setMaintenance({ ...maintenance, task_name: e.target.value })
              }
              value={maintenance.task_name}
            />
          </FormGroup>
          <FormGroup>
            <Label for="group">Interval group</Label>
            <Input
              type="select"
              name="select"
              id="group"
              onChange={(e) => {
                setMaintenance({
                  ...maintenance,
                  group_id: Number(e.target.value),
                  group_name:
                    e.target.options[e.target.options.selectedIndex].attributes
                      .label.value,
                });
              }}
            >
              {data.action === 'edit' && !data.group_id && <option></option>}
              {groups.map((group) => (
                <option
                  key={group.group_id}
                  value={group.group_id}
                  label={group.group_name}
                  selected={group.group_id === maintenance.group_id}
                >
                  {group.group_name}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="frequency">Interval description</Label>
            <Input
              type="text"
              id="frequency"
              name="maintenance[frequency]"
              onChange={(e) =>
                setMaintenance({ ...maintenance, frequency: e.target.value })
              }
              value={maintenance.frequency}
            />
          </FormGroup>
          <FormGroup>
            <Label for="personel_level_description">Personel level</Label>
            <Input
              type="select"
              name="select"
              id="personel_level_description"
              onChange={(e) =>
                setMaintenance({
                  ...maintenance,
                  personel_level_description: e.target.value,
                })
              }
            >
              {data.action === 'edit' && !data.personel_level_description && (
                <option></option>
              )}
              <option
                value="Trained"
                selected={
                  !data.personel_level_description ||
                  'Trained' === maintenance.personel_level_description
                }
              >
                Trained
              </option>
              <option
                value="Qualified"
                selected={'Qualified' === data.personel_level_description}
              >
                Qualified
              </option>
              <option
                value="GOSTOL"
                selected={'GOSTOL' === data.personel_level_description}
              >
                GOSTOL
              </option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="estimated_task_duration">Estimated task duration</Label>
            <InputGroup>
              <Input
                type="number"
                id="estimated_task_duration"
                name="maintenance[estimated_task_duration]"
                onChange={(e) =>
                  setMaintenance({
                    ...maintenance,
                    estimated_task_duration: e.target.value,
                  })
                }
                value={maintenance.estimated_task_duration}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>minutes</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
          {/*<FormGroup>
                        <Label for="operation_method">Operation method</Label>
                        <Input                       
                            type="text"
                            id="operation_method"
                            name="maintenance[operation_method]"
                            onChange={e => setMaintenance({ ...maintenance, operation_method: e.target.value })}
                            value={maintenance.operation_method} />
                    </FormGroup>        */}
          <FormGroup>
            <Label for="method">Methods</Label>
            <FormGroup tag="fieldset">
              <Row>
                {[
                  'Visually',
                  'Tools',
                  'Water',
                  'Detergent',
                  'Grease',
                  'Oil',
                ].map((method) => (
                  <Col sm="6" key={method}>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={
                            !!operation_method_arr.find((el) => el === method)
                          }
                          onChange={(e) =>
                            setMaintenance({
                              ...maintenance,
                              operation_method: operation_method_arr.find(
                                (el) => el === method
                              )
                                ? operation_method_arr
                                    .filter((el) => el !== method)
                                    .join()
                                : [...operation_method_arr, method].join(),
                            })
                          }
                        />
                        {getIcon(method)} - {method}
                      </Label>
                    </FormGroup>
                  </Col>
                ))}
              </Row>
            </FormGroup>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleCancelForm} color="light">
          Cancel
        </Button>
        <Button
          className="text-capitalize"
          onClick={handleSubmitForm}
          color="primary"
        >
          {data.action === 'edit' ? 'Confirm changes' : data.action}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MaintenanceForm;
