import React, { useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../components/Breadcrumb/Breadcrumb';
import NoContent from '../../../components/NoContent';
import OrderDetails from './partials/OrderDetails';
import OrdersTable from './partials/OrdersTable';
import ItemsTable from './partials/OrderItems';
import OrderChat from './partials/OrderChat';
import { breadcrumbItems } from './helpers';
import { useStore } from '../../../store';
import OrderActivities from './partials/OrderActivities/OrderActivities';

const Orders = () => {
  const { state, dispatch } = useStore();

  useEffect(() => {
    dispatch({ type: 'SELECTED_ORDER', payload: null });
  }, []);

  return (
    <>
      <Breadcrumbs items={breadcrumbItems} />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <OrdersTable />
            <hr />
          </Col>
          {!state.selectedOrder ? (
            <Col>
              <NoContent>Select order for details</NoContent>
            </Col>
          ) : (
            <>
              <Col lg={6}>
                <OrderDetails />
              </Col>
              <Col lg={6}>
                <OrderActivities chat attachments />
              </Col>
              <Col lg={12}>
                <ItemsTable />
              </Col>
            </>
          )}
        </Row>
      </Container>
    </>
  );
};

export default Orders;
