import moment from 'moment';
import React from 'react';
import { IoMdEye } from 'react-icons/io';
import { Row, Col } from 'reactstrap';
import { getColorScheme } from '../../../../../lib/helpers';
import { useStore } from '../../../../../store';
import MachineState from './MachineState';

const Term = ({ label, value, unit }) => {
  return (
    <div>
      {label}:{' '}
      <strong>
        {value || '-'}
        {unit}
      </strong>
    </div>
    /* <div className="d-flex">
         <div className="text-right pr-1" style={{ flex: 1 }}>{label}:</div>
         <div className={"font-weight-bold"} style={{ flex: 1 }}>{value || "-"} </div>
     </div>*/
  );
};

const Details = ({ data, toggleWithData, machineState }) => {
  const { state } = useStore();
  const colorScheme = getColorScheme();
  const header = 'Machine details';
  const body = (
    <React.Fragment>
      {data.imageUrl ? (
        <img
          src={data.imageUrl}
          style={{
            marginBottom: '1em',
            //objectFit: "cover",
            width: '100%',
            //height: 300,
            backgroundColor: colorScheme.main.primary._20,
          }}
        />
      ) : (
        <img
          style={{
            width: '100%',
            backgroundColor: colorScheme.main.primary._20,
          }}
        />
      )}
      <div className="d-flex justify-content-between flex-wrap-reverse">
        <h3>{data.description}</h3>
        <span className="ml-auto">
          {machineState?.machine_state && (
            <MachineState data={machineState} large />
          )}
        </span>
        {/*<div className="blob red/yellow m-0"></div>*/}
      </div>

      <div className="mb-3">
        <Term label="Segment" value={data.segment_name} />
      </div>

      <Row className="mb-3">
        <Col
          lg={12}
          className="font-weight-bolder"
          style={{ color: colorScheme.main.primary._100 }}
        >
          GENERAL INFO
        </Col>
        <Col lg={6} className="mb-3">
          <Term label="Serial number" value={data.machine_id} />
          <Term label="Model number" value={data.model_nr} />
          <Term label="Machine name" value={data.machine_name} />
        </Col>
        <Col lg={6} className="mb-3">
          <Term
            label="Production year"
            value={
              data.production_year || state.selectedProject.production_year
            }
          />
          <Term
            label="Startup date"
            value={
              data.startup_date &&
              moment(data.startup_date.toDate()).format('DD.MM.YYYY')
            }
          />
          <Term
            label="Beginning of guarantee"
            value={
              data.beginning_date_of_guarantee ||
              state.selectedProject.beginning_date_of_guarantee
            }
          />
          <Term
            label="Guarantee valid till"
            value={
              data.guarantee_valid_till ||
              state.selectedProject.guarantee_valid_till
            }
          />
        </Col>
        {machineState?.work_time_hours && (
          <>
            <Col
              lg={12}
              className="font-weight-bolder"
              style={{ color: colorScheme.main.primary._100 }}
            >
              AVAILABILITY STATS
            </Col>
            <Col lg={6} className="mb-3">
              <Term
                label="Working hours"
                value={machineState?.work_time_hours}
                unit="h"
              />
              <Term
                label="In stop"
                value={machineState?.stop_time_hours}
                unit="h"
              />
              <Term
                label="Availability"
                value={machineState?.overall_aval_percent}
                unit="%"
              />
            </Col>
          </>
        )}
      </Row>
    </React.Fragment>
  );

  const modalData = {
    header,
    body,
    bodyProps: { className: 'pt-0' },
    modalSize: 'lg',
  };

  return (
    <div
      style={{ cursor: 'pointer', padding: '0.5rem 1rem' }}
      onClick={() => toggleWithData(modalData)}
    >
      <IoMdEye style={{ fontSize: '1.25rem' }} />
      <br />
      Details
    </div>
  );
};

export default Details;
