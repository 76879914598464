import React from 'react';
import ReactTable from 'react-table';
// import 'react-table/react-table.css'
import { FiX } from 'react-icons/fi';
import _ from 'lodash';
import NoContent from '../../../../../components/NoContent';

const Cart = (props) => {
  //const [cart, setCart] = useState(localStorage.masCart)
  const cart = localStorage.masCart;
  const removeFromCart = (cartItem) => '';
  const data = cart ? _.flatMap(JSON.parse(cart)) : [];
  const columns = [
    {
      id: 'name',
      Header: 'Name',
      accessor: 'name',
    },
    {
      id: 'ident',
      Header: 'Ident',
      accessor: 'ident',
    },
    {
      id: 'forMachine',
      Header: 'For machine',
      accessor: 'model_number',
    },
    {
      id: 'price',
      Header: 'price',
      accessor: 'regular_price',
    },
    {
      id: 'remove',
      Header: '',
      accessor: 'cart_item_key',
      Cell: (row) => (
        <div className="text-center text-danger">
          <FiX
            className="mx-1"
            style={{ cursor: 'pointer' }}
            onClick={() => removeFromCart(row)}
          />
        </div>
      ),
    },
  ];

  return data.length === 0 ? (
    <NoContent title="Cart is empty" />
  ) : (
    <React.Fragment>
      <ReactTable
        columns={columns}
        data={data}
        defaultPageSize={data.length}
        showPagination={false}
        style={{
          border: 'none',
          maxHeight: '100%',
        }}
        className="-highlight -striped"
      />
    </React.Fragment>
  );
};

export default Cart;
