import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../media/logotip_Gostol.png';
import SidebarContent from './SidebarContent';
import classnames from 'classnames';
import { UncontrolledCollapse } from 'reactstrap';
import { useStore } from '../../store';
import docCookies from '../../lib/docCookies';
import { FiExternalLink } from 'react-icons/fi';

const Sidebar = () => {
  const { state } = useStore();
  const isGostol =
    docCookies.getItem('userToken') ===
    '1|cfb661bb-f43e-4108-8d1d-43ac80256494';
  const isSidebarOpen = () => {
    const mql = window.matchMedia(`(min-width: 768px)`);
    if (mql.matches) {
      return state.isSidebarOpen ? ' active' : '';
    }
    return state.isSidebarOpen ? 'overlay active' : 'overlay';
  };

  const isListActive = (listHref) => {
    return window.location.hash === '#' + listHref ? 'active' : '';
  };

  return (
    <React.Fragment>
      <nav id="sidebar" className={classnames(isSidebarOpen(), 'border-right')}>
        <ul className="list-unstyled components">
          <div className="sidebar-header">
            <img className="img-fluid p-4" src={logo} alt="gostol logo" />
          </div>
          {SidebarContent.items.map((item, index) =>
            !item.children ? (
              item.gostolOnly ? (
                item.gostolOnly === isGostol ? (
                  <li key={index} className={isListActive(item.url)}>
                    <Link to={item.url} className="nav-link">
                      <span style={{ fontSize: 22 }} className="pr-3">
                        {item.icon}
                      </span>
                      {item.title}
                    </Link>
                  </li>
                ) : null
              ) : !item.external ? (
                <li key={index} className={isListActive(item.url)}>
                  <Link to={item.url} className="nav-link">
                    <span style={{ fontSize: 22 }} className="pr-3">
                      {item.icon}
                    </span>
                    {item.title}
                  </Link>
                </li>
              ) : (
                <a
                  className="nav-link"
                  href={item.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span style={{ fontSize: 22 }} className="pr-3">
                    {item.icon}
                  </span>
                  {item.title}
                  <span className="pl-2 text-muted">
                    <FiExternalLink />
                  </span>
                </a>
              )
            ) : (
              <React.Fragment>
                <li
                  key={index}
                  id={`toggler-${index}`}
                  className="dropdown-toggle nav-link"
                >
                  <span style={{ fontSize: 22 }} className="pr-3">
                    {item.icon}
                  </span>
                  {item.title}
                </li>
                <UncontrolledCollapse toggler={`#toggler-${index}`}>
                  {item.children.map(
                    (child, index) =>
                      child.showInSidebar && (
                        <li key={index} className={isListActive(child.url)}>
                          <Link
                            to={child.url}
                            className="nav-link"
                            style={{ paddingLeft: 54 }}
                          >
                            {child.name}
                          </Link>
                        </li>
                      )
                  )}
                </UncontrolledCollapse>
              </React.Fragment>
            )
          )}
        </ul>
      </nav>
    </React.Fragment>
  );
};

export default Sidebar;
