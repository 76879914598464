import React from 'react';
import { StyledTab } from './Tabs.styles';

const BasicTabs = ({ index, tab, selectedTab, setSelectedTab }) => {
  return (
    <StyledTab
      key={index}
      onClick={() => setSelectedTab(index)}
      active={selectedTab === index}
    >
      {tab}
    </StyledTab>
  );
};

export default BasicTabs;
