import React from 'react';
import { Badge } from 'reactstrap';
import { StyledBadge } from '../../modules/Orders/views/partials/OrdersTable/OrdersTable.styles';
import TooltipWrapper from '../TooltipWrapper';
import { StyledTab } from './Tabs.styles';

const CustomTab = ({ index, tab, selectedTab, setSelectedTab }) => {
  if (!tab) {
    console.error('Undefined tab format: ', tab);
    return null;
  }

  return (
    <TooltipWrapper content={tab.tooltipText} disabled={!tab.tooltipText}>
      <StyledTab
        key={index}
        onClick={() => setSelectedTab(index)}
        active={selectedTab === index}
        style={tab.styles}
      >
        {tab.beforeLabel}
        {tab.label}
        {tab.afterLabel}
      </StyledTab>
    </TooltipWrapper>
  );
};

export default CustomTab;
