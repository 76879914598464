import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import styled from 'styled-components';
import { StyledButton } from '../../../../../components/DateSelection/DateSelection';
import Tabs from '../../../../../components/Tabs';
import IssuesList from './IssuesList';
// import issuesData from './dummyIssuesListData.json';
import 'firebase/firestore';
import firebase from 'firebase';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import SingleStat, {
  SingleStatsContainer,
} from '../../../../../components/SingleStat';
import NewIssueModal from './NewIssueModal';

const StyledCardHeader = styled(CardHeader)`
  display: flex;
  gap: 1em;
  button {
    font-size: 0.75em;
  }
  align-items: center;
`;

const IssuesCard = ({ selectedMachine, machines }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openIssues, setOpenIssues] = useState([]);
  const [closedIssues, setClosedIssues] = useState([]);
  const [allIssues, setAllIssues] = useState([]);
  const toggle = () => setIsOpen(!isOpen);

  const handleOpenNewIssue = () => {
    toggle();
  };
  const issuesLogRef = useFirestore()
    .collection('machines')
    // never put null into .doc, it will start generating massive Snapshot listeners
    // temporary set to '12086' -> divider ID
    .doc(selectedMachine ? selectedMachine?.machine_id.toString() : '1')
    .collection('issues');
  // .where('status', '!=', 'closed');
  const { data: issuesData } = useFirestoreCollectionData(issuesLogRef);

  useEffect(() => {
    if (selectedMachine) return null;
    const fsdb = firebase.firestore();
    //Get all tasks
    fsdb
      .collectionGroup('issues')
      .limit(3) //remove limit when done testing
      .get()
      .then((querySnapshot) => {
        let tmp = [];
        querySnapshot.forEach((doc) => {
          tmp.push(doc.data());
        });
        setAllIssues(tmp);
      });
  }, [selectedMachine]);

  useEffect(() => {
    generateData();
  }, [issuesData]);

  const generateData = () => {
    const useData = selectedMachine ? issuesData : allIssues;
    const openIssues = useData?.filter(
      (item) => item?.status?.toLowerCase() !== 'closed'
    );
    const closedIssues = useData?.filter(
      (item) => item?.status?.toLowerCase() === 'closed'
    );
    setOpenIssues(openIssues);
    setClosedIssues(closedIssues);
  };

  return (
    <>
      <Card style={{ height: '100%' }}>
        <StyledCardHeader>
          Issues
          <StyledButton color="danger" onClick={handleOpenNewIssue}>
            New Issue
          </StyledButton>
        </StyledCardHeader>
        <CardBody className="p-0">
          <Tabs tabs={['Open', 'Closed']}>
            <React.Fragment>
              <SingleStatsContainer>
                <SingleStat value={openIssues?.length} label="Open" />
                <SingleStat
                  value={openIssues?.filter((item) => item.priority).length}
                  label="Priority"
                />
              </SingleStatsContainer>
              <IssuesList data={openIssues} />
            </React.Fragment>
            <IssuesList data={closedIssues} />
          </Tabs>
        </CardBody>
      </Card>
      <NewIssueModal
        isOpen={isOpen}
        toggle={toggle}
        selectedMachine={selectedMachine}
        machines={machines}
      />
    </>
  );
};

export default IssuesCard;
